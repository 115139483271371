import arrayMove from "array-move";
import * as Inject from "./helpers/inject.js";
import htmlToImage from "html-to-image";
import update from "immutability-helper";
import _ from "lodash";

let converting = false;

export function onSortEnd({ oldIndex, newIndex }) {
  this.setState(
    ({ content }) => ({
      content: arrayMove(content, oldIndex, newIndex),
    }),
    () => {
      this.injectCompany();
      // this.injectPayment();
      // this.injectDeliverables();
    }
  );
}

export function unableToSave() {
  this.setState(
    {
      saving: false,
    },
    () => {
      this.showNotification(
        "error",
        `Unable to save document, please try again!`
      );
    }
  );
}

export function loadDoc() {
  // console.log("loadDoc", this.props);
  if (this.props.match.params.id.length !== 24) {
    this.setState({ error: "Invalid SOW ID" });
  } else {
    this.setState(
      {
        ...Inject.defaultState,
        docId: this.props.match.params.id,
      },
      () => {
        this.displayDocOnLoad();
      }
    );
  }
}

export function handlePreview(e) {
  e.preventDefault();

  let file = e.target.files[0];
  let reader = new FileReader();

  if (e.target.files.length === 0) {
    return;
  }

  reader.onloadend = (e) => {
    this.setState({
      logo: [reader.result],
    });
  };

  reader.readAsDataURL(file);
}

export function handleConfirmNavigationClick() {
  this.closeWarningModal(() => {
    const { navigate } = this.props;
    const { lastLocation } = this.state;
    if (lastLocation) {
      this.setState(
        {
          confirmedNavigation: true,
        },
        () => {
          // Navigate to the previous blocked location with your navigate function
          navigate(lastLocation.pathname);
        }
      );
    }
  });
}

export function keyStrokes(event) {
  try {
    if (event.keyCode === 27) {
      // this.setState({
      //   preview: false
      // });

      if (this.state.showForm) {
        this.cancelSection();
      }

      if (this.state.settings) {
        this.toggleSettings();
      }

      this.cancelDiscount();
      this.cancelCustomDates();
      this.cancelCustomPrices();
    }

    if ((event.ctrlKey || event.metaKey) && event.which === 83) {

      if (this.state.dirty) {
        
        let canSave = false;
        if (this.state.docNum === "MAST" && !this.props.normalAdmin) {
          canSave = false;
        } else {
          canSave = true;
        }
        
        if (canSave){
          this.updateDoc({
            sentToClient: false,
            revised: false,
          });
        } 
        else {
          this.showNotification(
            "error",
            `You do not have permissions to edit a Master Template`
          );
        }
      }

      event.preventDefault();
      return false;
    }

    if (
      (event.ctrlKey || event.metaKey) &&
      event.which === 90 &&
      !this.state.showForm
    ) {
      document.querySelector("#undo-btn").click();

      event.preventDefault();
      return false;
    }

    if (
      (event.ctrlKey || event.metaKey) &&
      event.which === 89 &&
      !this.state.showForm
    ) {
      document.querySelector("#redo-btn").click();

      event.preventDefault();
      return false;
    }

    // if (
    //   (event.ctrlKey || event.metaKey) &&
    //   event.which === 17 &&
    //   this.state.preview
    // ) {
    //   this.convertToImg(0);
    //
    //   event.preventDefault();
    //   return false;
    // }
  } catch (err) {}
}

export function convertToImg(i) {
  if (converting) {
    console.log("PREVENTED PARALLEL CONVERSION");
    return false;
  }

  converting = true;
  // console.log("convert", i);
  try {
    let pages = document.querySelectorAll("#pages .page");

    htmlToImage
      .toSvgDataURL(pages[i])
      // .toPng(pages[i])
      .then(function (dataUrl) {
        var img = new Image();
        img.src = dataUrl;
        img.setAttribute("class", "img-page");
        img.setAttribute("style", `order: ${i}`);

        // if (pages[i].classList.contains('ty-page')) {
        //   img.setAttribute("class", "ty-page")
        // }

        document.querySelector("#print").append(img);
      })
      .then(() => {
        let progress =
          (this.state.generated.length /
            document.querySelectorAll("#pdf .page").length) *
          100;
        this.setState(
          {
            generated: [...this.state.generated, i],
            progress,
          },
          () => {
            converting = false;
            if (
              this.state.generated.length ===
                document.querySelectorAll("#pdf .page").length &&
              this.state.preview
            ) {
              // console.log("all done");
              document.body.classList.add("loaded-pdf");
              this.setState(
                {
                  loadingPDF: false,
                },
                () => {
                  if (this.props.pdf) {
                  } else {
                    window.print();
                  }
                }
              );
            } else {
              converting = false;
              // console.log("go to next page", pages[i]);
              this.convertToImg(i + 1);
              if (i === pages.length - 2) {
                this.setState({
                  progress: 100,
                });
              }
            }
          }
        );
      });
  } catch (err) {}
}

export function afterPrint(event) {
  if (this.state.preview) {
    if (window.location.search !== "?debug") {
      setTimeout(() => {
        try {
          document.querySelector("#leave-preview-btn").click();
        } catch (err) {}
      }, 300);
    }
  }
}

export function isHidden(el) {
  var style = window.getComputedStyle(el);
  return (
    style.display === "none" ||
    style.visibility === "hidden" ||
    style.visibility === "collapse"
  );
}

export function quickDeliverable(deliverableCategory, deliverableTable) {
  // console.log(deliverableCategory, deliverableTable);
  this.setState({
    settings: false,
    edit: true,
    editSection: `quick-${deliverableCategory}-${deliverableTable}`,
    deliverableCategory: deliverableCategory,
    deliverableTable: deliverableTable,
    showForm: true,
  });
}

export function saveContact(html) {
  this.setState({
    contactHTML: html,
    showForm: false,
    edit: false,
    reorder: false,
    editSection: null,
    undoRedo: false,
  });
}

export function editContact() {
  this.setState({
    settings: false,
    edit: true,
    // editContact: true,
    editSection: "contact",
    showForm: true,
    // editorHTML: this.state.contactHTML
    contactHTML: this.state.contactHTML,
  });
}

export function selectRow(e, i) {
  e.stopPropagation();
  // console.log("select row", i);

  let updatedRows;

  if (this.state.selectedRows.includes(i)) {
    let currentRows = [...this.state.selectedRows];

    let filteredRows = currentRows.filter((v) => v !== i);

    updatedRows = update(this.state.selectedRows, {
      $set: filteredRows,
    });
  } else {
    updatedRows = update(this.state.selectedRows, {
      $push: [i],
    });
  }

  this.setState(
    {
      selectedRows: updatedRows,
    },
    () => {
      // console.log(this.state.selectedRows);
    }
  );
}

export function deleteRows(e) {
  e.stopPropagation();

  let content = [...this.state.content];

  _.pullAt(content, this.state.selectedRows);

  this.setState({
    selectedRows: [],
    content,
  });
}

export function clearRows(e) {
  e.stopPropagation();
  this.setState({
    selectedRows: [],
  });
}

export function editSettings() {
  this.setState(
    {
      addBelowIndex: null,
    },
    () => {
      this.toggleSettings();
    }
  );
}

export function saveSettings(state) {
  // console.log("save settings", state);
  let newDocNum = state.newDocNum;

  if (!isNaN(state.newDocNum)) {
    newDocNum = parseInt(state.newDocNum, 10);
  } else {
    newDocNum = state.newDocNum;
  }
  // console.log("docNum", newDocNum);
  this.props.db
    .collection(this.props.collection)
    .count({ docNum: newDocNum })
    .then((doc) => {
      if (doc && newDocNum !== this.state.docNum) {
        this.showNotification(
          "error",
          `SOW number ${newDocNum} is already taken!`
        );
      } else {
        let generatedName = this.generateDocName(
          (state.newCrmCompany && state.newCrmCompany.label) ||
            state.newCompany,
          state.newType,
          state.newQuarter,
          state.newCustom,
          state.newYear,
          this.state.version
        );

        this.setState(
          {
            title: `${newDocNum}_${generatedName}`,
            crmCompany: state.newCrmCompany,
            company: state.newCompany,
            docNum: newDocNum,
            type: state.newType,
            quarter: state.newQuarter,
            custom: state.newCustom,
            docType: state.newDocType,
            year: state.newYear,
            customDate: state.newCustomDate,
            sigDate: state.newSigDate,
            currency: state.newCurrency,
            logoInternal: state.newLogoInternal,
            address: state.newAddress,
            fromMember: state.newFromMember,
            salesPerson: state.newSalesPerson,
            accountManagement: state.newAccountManagement,
            undoRedo: false,
            settings: false,
          },
          () => {
            this.injectCompany();
            this.injectDeliverables();
          }
        );
      }
    })
    .catch((err) => console.error(err));
}

export function toggleOptions() {
  this.setState({
    options: !this.state.options,
  });
}

export function toggleSettings() {
  this.setState({
    showForm: false,
    settings: !this.state.settings,
    newCrmCompany: this.state.crmCompany,
    newCompany: this.state.company,
    newDocNum: this.state.docNum,
    newType: this.state.type,
    newQuarter: this.state.quarter,
    newCustom: this.state.custom,
    newDocType: this.state.docType,
    newYear: this.state.year,
    newCustomDate: this.state.customDate,
    newSigDate: this.state.sigDate,
    newCurrency: this.state.currency,
    newLogoInternal: this.state.logoInternal,
    newAddress: this.state.address,
    fromMember: this.state.fromMember,
    salesPerson: this.state.salesPerson,
    accountManagement: this.state.accountManagement,
    edit: false,
    editSection: null,
  });
}

export function stringToNum(string) {
  if (this.state.docType === "Gift Estimate") {
    return parseFloat(string.replace(/,/g, "").replace("$", ""));
  } else {
    return parseInt(string.replace(/,/g, "").replace("$", ""));
  }
}

export function sortDeliverables() {
  let order = [];
  let sectionOrder = [];

  for (let item of document.querySelectorAll("#steps .deliverable-item")) {
    let title = item.querySelector("a").textContent;
    let category = item.dataset.category;
    let table = parseInt(item.closest("table").id.replace("table-", ""), 10);

    let section = {
      title,
      category,
      table,
    };

    if (order.includes(title)) {
      title =
        title +
        "_" +
        order.filter((v) => v.replace(/_[0-9]+/gm, "") === title).length;
    }

    order.push(title);
    sectionOrder.push(section);
  }

  // console.log("order", order);
  // console.log("section order", sectionOrder);

  let onlyDeliverables = this.state.content.filter(
    (section) => section.deliverable
  );

  // console.log("only", onlyDeliverables);

  let withoutDeliverables = this.state.content.filter(
    (section) => !section.deliverable
  );

  let sortedDeliverables = [];

  // order.forEach(key => {
  //   var found = false;
  //
  //   onlyDeliverables.forEach(item => {
  //     let formattedTitle = item["title"].trim();
  //
  //     let dupes = onlyDeliverables.filter(
  //       section => section.title === item.title
  //     );
  //
  //     // console.log('dupes', item.title, dupes);
  //
  //     if (dupes.length > 1) {
  //       for (let [i, dupe] of dupes.entries()) {
  //         if (dupe === item && i) {
  //           formattedTitle = formattedTitle + "_" + i;
  //         }
  //       }
  //     }
  //
  //     if (!found && formattedTitle === key.trim()) {
  //       sortedDeliverables.push(item);
  //       found = true;
  //       return false;
  //     } else return true;
  //   });
  // });

  sectionOrder.forEach((section) => {
    var found = false;

    onlyDeliverables.forEach((item) => {
      let formattedTitle = item["title"].trim().replace(/\s\s+/g, " ");

      if (
        !found &&
        formattedTitle === section.title.trim() &&
        item.deliverableCategory === section.category &&
        item.table === section.table
      ) {
        sortedDeliverables.push(item);
        found = true;
        return false;
      } else return true;
    });
  });

  let indexToInsert = withoutDeliverables.findIndex(
    (obj) => obj.title === "Service Descriptions"
  );

  withoutDeliverables.splice(indexToInsert + 1, 0, ...sortedDeliverables);

  let sortedContent = [...withoutDeliverables];

  // console.log("original", this.state.content);
  //
  // console.log("only", onlyDeliverables);
  // console.log("no", withoutDeliverables);
  // console.log("index", indexToInsert);
  // console.log("sorted deliv", sortedDeliverables);
  // console.log("sorted content", sortedContent);

  let updatedContent = update(this.state.content, {
    $set: sortedContent,
  });

  // console.log("final", updatedContent);

  if (this.state.content.length !== updatedContent.length) {
    this.showNotification("error", "Error saving section!");
  } else {
    this.setState({
      content: updatedContent,
    });
  }
}

export function mapOrder(array, order, key) {
  array.sort(function (a, b) {
    var A = a["title"],
      B = b["title"];

    if (order.indexOf(A) > order.indexOf(B)) {
      return 1;
    } else {
      return -1;
    }
  });

  return array;
}

export function addDiscount(e) {
  this.setState({
    discount: true,
  });
}

export function cancelDiscount() {
  this.setState({
    discount: false,
  });
}

export function saveDiscount(e) {
  e.preventDefault();

  this.setState(
    {
      newSpendDiscount: this.state.formNewSpendDiscount,
    },
    () => {
      this.setState(
        {
          discount: false,
          undoRedo: false,
        },
        () => {
          this.setTotalPrice();
        }
      );
    }
  );
}

export function reorder() {
  this.setState(
    {
      reorder: !this.state.reorder,
      showForm: false,
      editSection: null,
      undoRedo: false,
    },
    () => {
      this.injectCompany();
      this.injectPayment();
      this.injectDeliverables();
      this.injectVariables();
    }
  );
}

export function editSubTotalName(e, i, name) {
  console.log(i, name);
  this.setState({
    editSubTotalNameIndex: i,
    subTotalTableName: name,
  });
}

export function saveSubTotalName(e, i) {
  e.preventDefault();

  console.log(i, this.state.subTotalTableName);

  let currentSubTotals = [...this.state.subTotals];

  let objIndex = currentSubTotals.findIndex((f) => f.index === i);

  currentSubTotals[objIndex].name = this.state.subTotalTableName;

  console.log(currentSubTotals);
  console.log(this.state.subTotals);

  // this.setState(
  //   {
  //     // [`tableName-${i}`]: this.state[`formTableName-${i}`],
  //     // [`editTableName-${i}`]: false,
  //     subTotals: currentSubTotals,
  //     dirty: true,
  //   },
  //   () => {
  //     this.saveHistory();
  //     this.injectDeliverables();
  //     this.setTotalPrice();
  //   }
  // );
}

export function cancelSubTotalName(i) {
  this.setState({
    editSubTotalNameIndex: null,
    subTotalTableName: "",
  });
}

export function removeSubTotal(e, i) {
  e.preventDefault();
  console.log(i);

  let currentSubTotals = [...this.state.subTotals];

  let updatedSubTotals = currentSubTotals.filter((s) => s.index !== i);

  this.setState({
    subTotals: updatedSubTotals,
  });
}

export function addSubTotal(e) {
  e.stopPropagation();

  let subTotals = this.state.subTotals || [];

  if (subTotals.filter((f) => f.index === this.state.tables - 1).length) {
    return false;
  }

  subTotals.push({
    index: this.state.tables - 1,
    name: "",
  });

  this.setState(
    {
      subTotals,
    },
    () => {
      this.injectDeliverables();
    }
  );
}

export function applyTeam() {
  let newState = {};

  Object.keys(this.state).forEach((key) => {
    if (key.includes("member-")) {
      if (this.state[key]) {
        newState[key] = this.state[key];
      }

      // newState.push(key)
    }
  });
  this.setState(
    {
      team: newState,
      undoRedo: false,
    },
    () => {
      this.injectCompany();
    }
  );
}

export function displayResults(query, company) {
  var regex = new RegExp(
    query && query.trim().replace(/[-\\^$*+?.()|[\]{}]/g, "\\$&"),
    "i"
  );
  var regex2 = new RegExp(
    company && company.trim().replace(/[-\\^$*+?.()|[\]{}]/g, "\\$&"),
    "i"
  );
  var regex3 = new RegExp(
    query &&
      query
        .trim()
        .replace("-", "–")
        .replace(/[-\\^$*+?.()|[\]{}]/g, "\\$&"),
    "i"
  );
  if (query && query.length) {
    this.props.db
      .collection(this.props.collection)
      .count(query ? { Title: regex2, "content.title": { $regex: regex } } : {})
      .then((total) => {
        this.setState({
          total,
        });
        this.props.db
          .collection(this.props.collection)
          .find(
            query
              ? {
                  $and: [
                    { Title: regex2 },
                    {
                      $or: [
                        { "content.title": { $regex: regex } },
                        { "content.title": { $regex: regex3 } },
                      ],
                    },
                  ],
                }
              : {},
            {
              limit: this.state.limit,
              sort: { $natural: -1 },
              projection: {
                Title: 1,
                content: 1,
              },
            }
          )
          .asArray()
          .then((documents) => {
            // console.log(documents);
            this.setState({
              query,
              documents,
              // loading: false,
              searching: false,
            });
          });
      });
  } else {
    this.setState({
      documents: [],
    });
  }
}

export function importFinished() {
  this.showNotification("success", "Section imported!");
  this.setState({
    documents: [],
  });
}

export function clearImport() {
  // console.log("clear import");
  this.setState({
    documents: [],
  });
}

export function printPDF() {
  this.sentConfirm(() => {
    // console.log("PRINT PDF");
    this.setState(
      {
        loadingPreview: false,
        loadingPDF: true,
        generated: [],
        progress: 0,
      },
      () => {
        document.body.classList.remove("scanned", "loaded-pdf", "debug");
        document.querySelector("#print").innerHTML = "";

        setTimeout(() => {
          this.convertToImg(0);
        }, 1000);
      }
    );
  });
}

export function toggleCompanyLink() {
  this.setState({
    showCompanyLink: !this.state.showCompanyLink,
  });
}

export function linkCompany() {
  this.setState({
    settings: true,
    showCompanyLink: false,
  });
}

export function cancelCompanyLink() {
  this.setState(
    {
      crmCompany: null,
      showCompanyLink: false,
      title: `${this.state.docNum}_${this.generateDocName(
        this.state.company,
        this.state.type,
        this.state.quarter,
        this.state.custom,
        this.state.year,
        this.state.version
      )}`,
    },
    () => {
      this.injectCompany();
    }
  );
}

export function setCompany() {
  this.updateDoc({
    crmCompany: this.state.crmCompany,
  });
}
