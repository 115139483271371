/**
 *
 * Reports Chart
 *
 */

import React from "react";
import PropTypes from "prop-types";
import { Grid } from "@material-ui/core";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  // Legend,
  ResponsiveContainer,
  LabelList,
} from "recharts";
import ByPersonTable from "./ByPersonTable";

function Chart(props) {
  let hasUSD = props.totals.filter((o) => o.price).length;
  let hasEUR = props.totals.filter((o) => o.priceEUR).length;
  let hasGBP = props.totals.filter((o) => o.priceGBP).length;

  return (
    <Grid container id="chart">
      <Grid item md={6} pr={1}>
        <ResponsiveContainer>
          <BarChart
            data={props.totals}
            margin={{
              top: 20,
              right: 0,
              left: -20,
              bottom: 0,
            }}
          >
            <CartesianGrid strokeDasharray="1 1" vertical={false} />
            <XAxis dataKey="fName" tick={{ fontSize: 12 }} interval={0} />
            <YAxis
              tick={{ fontSize: 12 }}
              // width={30}
              label={{ value: "# SOWs", angle: -90 }}
            />
            <Bar isAnimationActive={false} dataKey="number" fill="#12B796">
              <LabelList
                dataKey="number"
                position="top"
                fill="black"
                fontSize="14"
              />
            </Bar>
          </BarChart>
        </ResponsiveContainer>
      </Grid>
      <Grid item md={6} pl={1}>
        <ByPersonTable
          headCells={[
            {
              id: "name",
              label: props.title,
              visible: true,
            },
            {
              id: "number",
              numeric: true,
              label: "# SOWs",
              visible: true,
            },
            {
              id: "price",
              numeric: true,
              label: "Total ($)",
              visible: hasUSD,
            },
            {
              id: "priceEUR",
              numeric: true,
              label: "Total (€)",
              visible: hasEUR,
            },
            {
              id: "priceGBP",
              numeric: true,
              label: "Total (£)",
              visible: hasGBP,
            },
          ]}
          rows={props.totals}
          formatCurrency={props.formatCurrency}
          hasUSD={hasUSD}
          hasEUR={hasEUR}
          hasGBP={hasGBP}
        />
      </Grid>
    </Grid>
  );
}

Chart.propTypes = {
  totals: PropTypes.array.isRequired,
  formatCurrency: PropTypes.func.isRequired,
  numDocs: PropTypes.number.isRequired,
};

export default Chart;
