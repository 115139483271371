import * as React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import {
  Autocomplete,
  TextField,
  Avatar,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Paper,
  CircularProgress,
} from "@material-ui/core";
import { visuallyHidden } from "@material-ui/utils";
import _ from "lodash";
import { Link } from "react-router-dom";

var ObjectID = require("bson-objectid");

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  table: {
    "& th": {
      backgroundColor: "white",
    },
    "& td": {
      position: "relative",
    },
  },
  action: {
    position: "absolute",
    right: "16px",
    top: "50%",
    transform: "translateY(-50%)",
  },
  sortSpan: visuallyHidden,
  container: {
    maxHeight: "calc(100vh - 270px)",
  },
}));

function UserListTableHead(props) {
  const { classes, order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {props.headCells.map(
          (headCell) =>
            !!headCell.visible && (
              <TableCell
                key={headCell.id}
                align={headCell.numeric ? "right" : "left"}
                sortDirection={orderBy === headCell.id ? order : false}
              >
                <TableSortLabel
                  active={orderBy === headCell.id}
                  direction={orderBy === headCell.id ? order : "asc"}
                  onClick={createSortHandler(headCell.id)}
                >
                  {headCell.label}
                  {orderBy === headCell.id ? (
                    <span className={classes.sortSpan}>
                      {order === "desc"
                        ? "sorted descending"
                        : "sorted ascending"}
                    </span>
                  ) : null}
                </TableSortLabel>
              </TableCell>
            )
        )}
      </TableRow>
    </TableHead>
  );
}

UserListTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  // numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number,
};

export default function UserListTable(props) {
  let { headCells, rows, type } = props;
  const classes = useStyles();
  const [order, setOrder] = React.useState("desc");
  const [orderBy, setOrderBy] = React.useState("number");

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <TableContainer className={classes.container}>
          <Table stickyHeader className={classes.table}>
            <UserListTableHead
              classes={classes}
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              rowCount={rows?.length}
              headCells={headCells}
            />
            <TableBody>
              {!rows?.length &&
                (type === "permissions" ? (
                  <TableRow>
                    <TableCell>
                      <CircularProgress size={18} />
                    </TableCell>
                  </TableRow>
                ) : (
                  <TableRow>
                    <TableCell>No one's here!</TableCell>
                  </TableRow>
                ))}
              {_.orderBy(rows, orderBy, order).map((row, index) => {
                return (
                  <TableRow
                    hover
                    tabIndex={-1}
                    key={`row-${index}`}
                    onClick={
                      type === "team"
                        ? () => props.editMember(ObjectID(row._id).toString())
                        : null
                    }
                    style={{
                      cursor: type === "team" ? "pointer" : "default",
                    }}
                  >
                    {headCells.map((cell, i) => {
                      let showAvatar =
                        (type === "active-users" &&
                          cell.id === "data.user.name") ||
                        (type === "permissions" && cell.id === "meta.name");
                      let isRoleCell =
                        type === "permissions" && cell.id === "role";
                      return (
                        <TableCell key={`cell-${index}-${i}`}>
                          {showAvatar && (
                            <Avatar
                              src={
                                row?.data?.user?.picture || row?.meta?.picture
                              }
                              alt={row?.data?.user?.name || row?.meta?.name}
                              sx={{
                                "&": {
                                  float: "left",
                                  width: 20,
                                  height: 20,
                                  mr: 1,
                                },
                              }}
                            />
                          )}

                          {type === "active-users" &&
                          cell.id === "data.roomname" ? (
                            <>
                              <Link to={`/${row.data.roomname}`}>
                                {row.data.roomname}
                              </Link>
                              <Button
                                size="small"
                                variant="outlined"
                                color="error"
                                onClick={() => props.kickUser(row.id)}
                                className={classes.action}
                              >
                                Kick
                              </Button>
                            </>
                          ) : (
                            <>
                              {isRoleCell ? (
                                <Autocomplete
                                  disableClearable
                                  autoHighlight
                                  value={
                                    row.role
                                      ? {
                                          value: row.role,
                                          label: row.role,
                                        }
                                      : null
                                  }
                                  options={[
                                    {
                                      value: "User",
                                      label: "User",
                                    },
                                    {
                                      value: "Admin",
                                      label: "Admin",
                                    },
                                    {
                                      value: "Super Admin",
                                      label: "Super Admin",
                                    },
                                  ]}
                                  getOptionLabel={(option) =>
                                    option?.label || ""
                                  }
                                  getOptionSelected={(option, value) =>
                                    option.value === value.value
                                  }
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      // label="Role"
                                      variant="outlined"
                                      size="small"
                                      margin="normal"
                                      style={{ margin: 0, width: 160 }}
                                    />
                                  )}
                                  onChange={(e, value) =>
                                    props.handleRoleChange(row.id, value)
                                  }
                                  style={{
                                    paddingRight: "83px",
                                    marginTop: "-13.5px",
                                    marginBottom: "-13.5px",
                                  }}
                                />
                              ) : (
                                _.get(row, cell.id)
                              )}
                            </>
                          )}

                          {isRoleCell && (
                            <Button
                              size="small"
                              variant="outlined"
                              color="error"
                              onClick={() => props.removeUserConfirm(row.id)}
                              className={classes.action}
                            >
                              Remove
                            </Button>
                          )}
                        </TableCell>
                      );
                    })}
                    {/* <TableCell
                      component="th"
                      id={`user-list-table-${index}`}
                      scope="row"
                      className={classes.name}
                    >
                      {row.name}
                    </TableCell> */}
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </div>
  );
}
