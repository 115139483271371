/**
 *
 * App
 *
 */

import React from "react";
// import { BrowserRouter as Router, Route } from "react-router-dom";
import {
  BrowserRouter,
  Switch,
  Route,
  Link,
  // Redirect
} from "react-router-dom";
import axios from "axios";
import {
  Stitch,
  RemoteMongoClient,
  AnonymousCredential,
} from "mongodb-stitch-browser-sdk";
import Home from "./components/Home";
import Admin from "./components/Admin";
import Reports from "./components/Reports";
import Doc from "./components/Doc";
import Brief from "./components/Brief";
import Error from "./components/Error";
import PrivateRoute from "./components/PrivateRoute";
import ScrollToTop from "./components/ScrollToTop";
import { Auth0Context } from "./react-auth0-wrapper";
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import AdapterMoment from "@material-ui/lab/AdapterMoment";
import LocalizationProvider from "@material-ui/lab/LocalizationProvider";
import {
  AppBar,
  Container,
  Grid,
  Tooltip,
  IconButton,
  Menu,
  MenuItem,
  Snackbar,
  Alert,
  CircularProgress,
  Divider,
} from "@material-ui/core";
import HistoryIcon from "@material-ui/icons/History";
import LibraryBooksIcon from "@material-ui/icons/LibraryBooks";
import InsertChartIcon from "@material-ui/icons/InsertChart";
import "./scss/App.scss";
// import config from "./config.json";
import UserMenu from "./components/UserMenu";
import autoBind from "react-autobind";
// import { deviceType, browserName } from "react-device-detect";
import * as Sentry from "@sentry/react";
var ObjectID = require("bson-objectid");
const isFirefox = typeof InstallTrigger !== "undefined";

export class App extends React.Component {
  constructor() {
    super();
    this.state = {
      client: undefined,
      collection: process.env.REACT_APP_COLLECTION,
      alert: false,
      variant: "info",
      message: "",
      time: "",
      loggedOut: false,
      normalAdmin: false,
      superAdmin: false,
      params: null,
      dark: false,
      recentlyViewed: JSON.parse(localStorage.getItem("recently-viewed")) || [],
      showAlert: false,
      team: [],
    };

    autoBind(this);
  }

  static contextType = Auth0Context; // eslint-disable-line no-undef

  componentDidMount() {
    // console.log(process.env.NODE_ENV);
    let params = new URLSearchParams(window.location.search);

    const client = Stitch.initializeDefaultAppClient("sow-pbjnh");

    const mongodb = client.getServiceClient(
      RemoteMongoClient.factory,
      "mongodb-atlas"
    );
    const db = mongodb.db("sow");

    this.setState(
      {
        db,
        client,
        params,
        dark:
          localStorage.getItem("dark-mode") &&
          !window.location.search.includes("?token=")
            ? JSON.parse(localStorage.getItem("dark-mode"))
            : false,
      },
      () => {
        this.fetchAllUsers();
      }
    );

    document.title = "SOW Generator";
    // console.log(deviceType);
    // console.log(browserName);
    this.setTheme();

    // document.addEventListener("click", (event) => {
    //   try {
    //     for (let tooltip of document.querySelectorAll(".MuiTooltip-popper")) {
    //       tooltip?.remove();
    //     }
    //   } catch (err) {}
    // });
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevState.dark !== this.state.dark) {
      this.setTheme();
    }

    if (prevState.user !== this.state.user) {
      // console.log("user");

      if (
        this.state.user["https://sow.thatsnice.com/roles"].includes("admin")
      ) {
        this.setState({
          normalAdmin: true,
        });
      }

      if (
        this.state.user["https://sow.thatsnice.com/roles"].includes(
          "super-admin"
        )
      ) {
        this.setState({
          superAdmin: true,
        });
      }
    }
  }

  shouldComponentUpdate(prevState, nextState) {
    // if (this.state.user !== nextState.user) {
    //   console.log("user update");
    //   if (nextState.user["https://sow.thatsnice.com/roles"].includes("admin")) {
    //     this.setState({
    //       normalAdmin: true,
    //     });
    //   }
    //
    //   if (
    //     nextState.user["https://sow.thatsnice.com/roles"].includes(
    //       "super-admin"
    //     )
    //   ) {
    //     this.setState({
    //       superAdmin: true,
    //     });
    //   }
    //   return true;
    // }
    if (
      this.state.client !== nextState.client ||
      this.state.time !== nextState.time ||
      this.state.loggedOut !== nextState.loggedOut ||
      this.state.normalAdmin !== nextState.normalAdmin ||
      this.state.superAdmin !== nextState.superAdmin ||
      this.state.dark !== nextState.dark ||
      this.state.recentlyViewed !== nextState.recentlyViewed ||
      this.state.alert !== nextState.alert ||
      this.state.showAlert !== nextState.showAlert ||
      this.state.anchorEl !== nextState.anchorEl
    ) {
      return true;
    }
    return false;
  }

  fetchAllUsers() {
    axios
      .get("/api/getAuthUsers")
      .then((response) => {
        // console.log("auth users", response.data);

        let allUsers = response.data;

        this.state.client.auth
          .loginWithCredential(new AnonymousCredential())
          .then(() => {
            this.state.db
              .collection("member")
              .find(
                {},
                {
                  projection: {
                    Name: 1,
                    Email: 1,
                    _id: 1,
                    id: 1,
                  },
                }
              )
              .asArray()
              .then((team) => {
                let combined = allUsers.map((user) => ({
                  ...user,
                  id: [
                    ...user.id,
                    ObjectID(
                      team.find((u) => u.Email === user.email)?.["_id"]
                    ).toString(),
                  ],
                }));

                this.setState({
                  team: combined,
                });
                this.context.setTeam(combined);
              })
              .catch((err) => {
                console.error("failed to fetch users from db", err);
              });
          })
          .catch(console.error);
      })
      .catch((err) => {
        console.error("failed to fetch users from auth0", err);
      });
  }

  updateRecentlyViewed(array) {
    this.setState({
      recentlyViewed: array,
    });
  }

  setTheme() {
    // if (this.state.dark) {
    //   document.querySelector("body").classList.add("dark");
    // } else {
    //   document.querySelector("body").classList.remove("dark");
    // }
    localStorage.setItem("dark-mode", this.state.dark);
  }

  toggleTheme() {
    this.setState({
      dark: !this.state.dark,
    });
  }

  loggedIn(user) {
    this.setState({
      user: user,
    });
  }

  loggedOut() {
    axios
      .get("/api/kickUser", {
        params: {
          email: this.state.user.email,
        },
      })
      .then((response) => {
        // console.log("kicked user", response);
      })
      .catch(console.error);

    this.setState({
      loggedOut: true,
    });
  }

  openRecent(e) {
    this.setState({
      anchorEl: e.currentTarget,
    });
  }

  closeRecent() {
    this.setState({
      anchorEl: null,
    });
  }

  showAlert() {
    this.setState({
      showAlert: true,
    });
  }

  hideAlert() {
    this.setState({
      alert: false,
      showAlert: false,
    });
  }

  // function App() {
  render() {
    const theme = createMuiTheme({
      palette: {
        primary: {
          main: "#12B796",
          contrastText: "#fff",
        },
        secondary: {
          main: "#1E364E",
        },
        success: {
          main: "#4caf50",
          contrastText: "#fff",
        },
        warning: {
          main: "#ff9800",
          contrastText: "#fff",
        },
      },
    });

    return (
      <MuiThemeProvider theme={theme}>
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <Sentry.ErrorBoundary
            fallback={
              <Container>
                <Grid pt={5} style={{ textAlign: "center" }}>
                  <Alert
                    variant="filled"
                    severity="error"
                    style={{ display: "inline-flex" }}
                  >
                    Something went wrong! This incidence has been recorded.
                    Please try again.
                  </Alert>
                </Grid>
              </Container>
            }
          >
            {!this.state.client ? (
              <div id="spinner">
                <CircularProgress disableShrink />
              </div>
            ) : (
              <div id="app-container" className={`${isFirefox ? " ff" : ""}`}>
                <BrowserRouter>
                  <ScrollToTop />
                  {this.state.params &&
                  this.state.params.has("token") ? null : (
                    <AppBar
                      color="transparent"
                      id="top-nav"
                      className={`${!this.state.user ? "no-user" : ""}`}
                      style={{
                        backgroundColor: "rgb(234 234 234)",
                        boxShadow: "none",
                      }}
                    >
                      <Container>
                        <Grid display="flex" alignItems="center" ml={-1}>
                          <Tooltip disableInteractive title="SOWs">
                            <Link to="/">
                              <IconButton>
                                <LibraryBooksIcon
                                  size="small"
                                  color="primary"
                                />
                              </IconButton>
                            </Link>
                          </Tooltip>
                          <Tooltip disableInteractive title="Reports">
                            <Link to="/reports">
                              <IconButton>
                                <InsertChartIcon size="small" color="primary" />
                              </IconButton>
                            </Link>
                          </Tooltip>
                          <Tooltip disableInteractive title="Recent">
                            <IconButton
                              aria-controls="simple-menu"
                              aria-haspopup="true"
                              onClick={this.openRecent}
                            >
                              <HistoryIcon size="small" color="primary" />
                            </IconButton>
                          </Tooltip>
                          <Menu
                            anchorEl={this.state.anchorEl}
                            keepMounted
                            open={Boolean(this.state.anchorEl)}
                            onClose={this.closeRecent}
                            onClick={this.closeRecent}
                          >
                            <MenuItem disabled style={{ paddingTop: 0 }}>
                              Recently Viewed
                            </MenuItem>
                            <Divider />
                            {this.state.recentlyViewed.length ? (
                              this.state.recentlyViewed?.map((doc) => {
                                return (
                                  <MenuItem
                                    key={doc.id}
                                    component={Link}
                                    to={`/${doc.id}`}
                                  >
                                    {doc.title}
                                  </MenuItem>
                                );
                              })
                            ) : (
                              <MenuItem>
                                You haven't viewed any SOWs yet
                              </MenuItem>
                            )}
                          </Menu>
                          <UserMenu
                            loggedIn={this.loggedIn}
                            loggedOut={this.loggedOut}
                            toggleTheme={this.toggleTheme}
                            dark={this.state.dark}
                            goToAdmin={this.goToAdmin}
                            fetchAllUsers={this.fetchAllUsers}
                          />
                        </Grid>
                      </Container>
                    </AppBar>
                  )}

                  <Switch>
                    <PrivateRoute
                      path="/admin"
                      render={(props) => (
                        <Admin
                          {...props}
                          db={this.state.db}
                          collection={this.state.collection}
                          client={this.state.client}
                          normalAdmin={this.state.normalAdmin}
                          superAdmin={this.state.superAdmin}
                          user={this.state.user}
                          alert={(variant, message, time) => {
                            this.setState({
                              alert: true,
                              variant,
                              message,
                              time,
                            });
                          }}
                        />
                      )}
                    />
                    <PrivateRoute
                      path="/reports"
                      render={(props) => (
                        <Reports
                          {...props}
                          db={this.state.db}
                          collection={this.state.collection}
                          client={this.state.client}
                          normalAdmin={this.state.normalAdmin}
                          superAdmin={this.state.superAdmin}
                          user={this.state.user}
                          alert={(variant, message, time) => {
                            this.setState({
                              alert: true,
                              variant,
                              message,
                              time,
                            });
                          }}
                        />
                      )}
                    />
                    <PrivateRoute
                      path="/:id/brief/:brief_id?"
                      // path={["/:id/brief", "/:id/brief/:brief_id"]}
                      render={(props) => (
                        <Brief
                          {...props}
                          db={this.state.db}
                          collection={this.state.collection}
                          client={this.state.client}
                          normalAdmin={this.state.normalAdmin}
                          superAdmin={this.state.superAdmin}
                          user={this.state.user}
                          alert={(variant, message, time) => {
                            this.setState({
                              alert: true,
                              variant,
                              message,
                              time,
                            });
                          }}
                          dark={this.state.dark}
                        />
                      )}
                    />
                    {this.state.params &&
                    this.state.params.has("error") &&
                    this.state.params.get("error").length ? (
                      <Route
                        path="/"
                        exact
                        render={(props) => (
                          <Error
                            {...props}
                            client={this.state.client}
                            error={this.state.params.get("error")}
                            message={this.state.params.get("error_description")}
                          />
                        )}
                      />
                    ) : (
                      <PrivateRoute
                        path="/"
                        exact
                        render={(props) => (
                          <Home
                            {...props}
                            db={this.state.db}
                            collection={this.state.collection}
                            client={this.state.client}
                            normalAdmin={this.state.normalAdmin}
                            superAdmin={this.state.superAdmin}
                            alert={(variant, message, time) => {
                              this.setState({
                                alert: true,
                                variant,
                                message,
                                time,
                              });
                            }}
                          />
                        )}
                      />
                    )}

                    {this.state.params &&
                    this.state.params.has("token") &&
                    this.state.params.get("token").length ? (
                      <Route
                        path="/:id"
                        render={(props) => (
                          <Doc
                            {...props}
                            db={this.state.db}
                            collection={this.state.collection}
                            client={this.state.client}
                            token={this.state.params.get("token")}
                            pdf={this.state.params.has("pdf") ? true : false}
                          />
                        )}
                      />
                    ) : (
                      <PrivateRoute
                        path="/:id"
                        render={(props) => (
                          <Doc
                            {...props}
                            db={this.state.db}
                            collection={this.state.collection}
                            client={this.state.client}
                            normalAdmin={this.state.normalAdmin}
                            superAdmin={this.state.superAdmin}
                            user={this.state.user}
                            alert={(variant, message, time) => {
                              this.setState({
                                alert: true,
                                variant,
                                message,
                                time,
                              });
                            }}
                            loggedOut={this.state.loggedOut}
                            dark={this.state.dark}
                            updateRecentlyViewed={this.updateRecentlyViewed}
                          />
                        )}
                      />
                    )}
                  </Switch>
                </BrowserRouter>
                <Snackbar
                  open={this.state.alert}
                  autoHideDuration={6000}
                  onClose={this.hideAlert}
                  anchorOrigin={{ vertical: "top", horizontal: "center" }}
                >
                  <Alert
                    onClose={this.hideAlert}
                    severity={this.state.variant}
                    elevation={6}
                    variant="filled"
                  >
                    {this.state.message}
                  </Alert>
                </Snackbar>
              </div>
            )}
          </Sentry.ErrorBoundary>
        </LocalizationProvider>
      </MuiThemeProvider>
    );
  }
}

export default App;
