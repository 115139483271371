import React from "react";
import { Box, Menu, Button, IconButton } from "@material-ui/core";
import LoadingButton from "@material-ui/lab/LoadingButton";
import autoBind from "react-autobind";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import DeleteIcon from "@material-ui/icons/Delete";
import VisibilityIcon from "@material-ui/icons/Visibility";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import QueueIcon from "@material-ui/icons/Queue";
import AddBoxIcon from "@material-ui/icons/AddBox";
import AssignmentIcon from "@material-ui/icons/Assignment";
import { Link } from "react-router-dom";

class Options extends React.Component {
  constructor() {
    super();

    this.state = {
      anchorEl: null,
    };

    autoBind(this);
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.total !== this.props.total) {
      this.handleClose();
    }
  }

  handleMenu(event) {
    this.setState({ anchorEl: event.currentTarget }, () => {
      // event.currentTarget.closest('.MuiPaper-root').classList.add('active')
    });
  }

  handleClose() {
    this.setState({ anchorEl: null });
  }

  render() {
    const { anchorEl } = this.state;
    const { doc, cloning } = this.props;
    const open = Boolean(anchorEl);

    return (
      <Box className="options-btn">
        <IconButton
          size="small"
          aria-owns={open ? "menu-appbar" : null}
          aria-haspopup="true"
          onClick={this.handleMenu}
        >
          <MoreVertIcon />
        </IconButton>
        <Menu
          className="options-menu"
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          open={open}
          onClose={this.handleClose}
          // onClick={this.handleClose}
          sx={{
            "& .MuiMenu-paper": {
              width: "100%",
              maxWidth: 170,
            },
            "& .MuiButton-root": {
              justifyContent: "flex-start",
              padding: "6px 16px",
              borderRadius: 0,
              height: 40,
            },
            "& .MuiButton-startIcon": {
              marginRight: 3,
            },
            // "& a": {
            //   ":hover": {
            //     color: "inherit",
            //   },
            // },
          }}
        >
          <Button
            fullWidth
            startIcon={<VisibilityIcon />}
            variant="text"
            color="primary"
            component={Link}
            to={`/${doc._id.toString()}`}
          >
            View
          </Button>
          {doc.docNum !== "MAST" && !doc.approved && (
            <LoadingButton
              fullWidth
              pendingPosition="start"
              startIcon={<AddBoxIcon />}
              variant="text"
              color="inherit"
              onClick={(e) =>
                this.props.cloneDoc(e, doc._id.toString(), true, doc.Title)
              }
              pending={cloning}
            >
              New Version
            </LoadingButton>
          )}
          <LoadingButton
            fullWidth
            pendingPosition="start"
            startIcon={<FileCopyIcon />}
            variant="text"
            color="inherit"
            onClick={(e) =>
              this.props.cloneDoc(e, doc._id.toString(), null, doc.Title)
            }
            pending={cloning}
          >
            Clone
          </LoadingButton>
          <LoadingButton
            fullWidth
            pendingPosition="start"
            startIcon={<QueueIcon />}
            variant="text"
            color="inherit"
            onClick={(e) => {
              this.handleClose();
              this.props.openBatchDialog(doc._id.toString(), doc.Title);
            }}
            pending={cloning}
          >
            Batch Clone
          </LoadingButton>
          {(doc.approved || doc.docNum === "MAST") && (
            <Button
              fullWidth
              startIcon={<AssignmentIcon />}
              variant="text"
              color="inherit"
              component={Link}
              to={`/${doc._id.toString()}/brief`}
            >
              Briefs
            </Button>
          )}
          <Button
            fullWidth
            startIcon={<DeleteIcon />}
            variant="text"
            color="error"
            onClick={(e) => {
              this.handleClose();
              this.props.openDialog(doc._id.toString(), doc.Title);
            }}
          >
            Delete
          </Button>
        </Menu>
      </Box>
    );
  }
}

export default Options;
