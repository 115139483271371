/**
 *
 * Error
 *
 */

import React from "react";
import PropTypes from "prop-types";
import { Alert, Container, Button } from "@material-ui/core";
import { Link } from "react-router-dom";

const Error = (props) => {
  return (
    <Container
      id="error"
      // style={{
      //   position: "fixed",
      //   top: 0,
      //   left: 0,
      //   right: 0,
      //   bottom: 0,
      //   display: "flex",
      //   alignItems: "flex-start",
      //   justifyContent: "center",
      //   padding: "2rem",
      // }}
    >
      <Alert
        severity="error"
        action={
          props.user ? (
            <Button color="inherit" size="small" component={Link} to="/">
              Return to Home
            </Button>
          ) : null
        }
        // style={{
        //   width: "100%",
        // }}
      >
        {props.message}
      </Alert>
    </Container>
  );
};

Error.propTypes = {
  message: PropTypes.string.isRequired,
};

export default Error;
