import axios from "axios";
import update from "immutability-helper";

export function handleEditorChange(editorHTML, editor) {
  // console.log("CHANGE DETECTED", editorHTML);
  this.setState({ editorHTML });
}

export function handleExecCommand(e, editor) {
  this.setState({ editorHTML: e.target.getContent() });
}

export function handleReactSelectInputChange(inputValue, action) {
  if (action.action !== "input-blur" && action.action !== "menu-close") {
    this.setState({
      formTitle:
        typeof inputValue === "string"
          ? inputValue.replace(/\s\s+/g, " ")
          : inputValue,
    });
  }
}

export function handleCreateTitle(event, value, reason) {
  console.log("blur", value, reason);
  // this.setState({
  //   // sectionTitles: [...this.state.sectionTitles, inputValue],
  //   formTitle: value
  // });
}

export function handleFocus(element) {
  // console.log("on focus");
  // if (this.state.formTitle) {
  //   this.select.state.inputValue = this.state.formTitle;
  // }

  if (this.state.formDeliverableCategory) {
    let selectedCategory = this.props.deliverableCategories.find(
      (v) => v.category === this.state.formDeliverableCategory
    );

    const sectionTitles = selectedCategory?.titles.map((v) => ({
      label: v.title,
      value: v.title,
    }));

    this.setState({
      sectionTitles,
    });
  }
}

export function handleMenuClose() {
  this.select.blur();
}

// attempt to merge cells in the deliverables table
export function checkMerge(expand, target, index, array) {
  let duration = parseInt(expand, 20);
  for (let i = index + 1; i < duration + index; i++) {
    if (array[i][target]) {
      this.props.showNotification(
        "error",
        "Cannot merge cells: make sure only one merge cell has a value"
      );
      return false;
    } else {
      return true;
    }
  }
}

export function saveContact(e) {
  e.preventDefault();

  this.props.saveContact(this.state.editorHTML);
}

export function saveSection(e) {
  e.preventDefault();
  let updatedContent;
  let subsections = [];
  let data = {};

  if (this.state.formSubsections.length) {
    for (let i in this.state.formSubsections) {
      subsections.push({
        title: this.state[`${i}-formSubsectionTitle`],
        quantity: this.state[`${i}-formSubsectionQuantity`],
        price: this.state[`${i}-formSubsectionPrice`],
        unit: this.state[`${i}-formSubsectionUnit`],
        existingSpend: this.state[`${i}-formSubsectionExistingSpend`],
        passThrough: this.state[`${i}-formSubsectionPassThrough`],
        expandNewSpend: this.state[`${i}-formSubsectionExpandNewSpend`],
        expandExistingSpend: this.state[`${i}-formSubsectionExpandExistingSpend`],
        expandPassThrough: this.state[`${i}-formSubsectionExpandPassThrough`],
      });
    }
  }

  data = {
    title: this.state.formTitle,
    // title: window.SOW_SECTION_TITLE,
    appendixItem: this.state.formAppendix,
    deliverable: this.state.formDeliverable,
    break: this.state.formBreak,
    quantity: this.state.formQuantity,
    price: this.state.formPrice,
    existingSpend: this.state.formExistingSpend,
    passThrough: this.state.formPassThrough,
    discount: this.state.formDiscount,
    deliverableCategory: this.state.formDeliverableCategory,
    editorHTML: this.state.editorHTML,
    subsections: subsections,
    table: (this.state.formDeliverable && this.state.formTable) || 0, // eslint-disable-line no-mixed-operators
    expandNewSpend: this.state.formExpandNewSpend,
    expandExistingSpend: this.state.formExpandExistingSpend,
    expandPassThrough: this.state.formExpandPassThrough,
    // deliverableNewSpendDiscount: this.state.formDeliverableNewSpendDiscount
  };

  if (this.state.edit) {
    if (data.expandNewSpend) {
      let mergeResult = this.checkMerge(
        data.expandNewSpend,
        "price",
        this.props.editSection,
        this.props.content
      );

      if (!mergeResult) {
        return false;
      }
    }

    if (data.expandExistingSpend) {
      let mergeResult = this.checkMerge(
        data.expandExistingSpend,
        "existingSpend",
        this.props.editSection,
        this.props.content
      );

      if (!mergeResult) {
        return false;
      }
    }

    if (data.expandPassThrough) {
      let mergeResult = this.checkMerge(
        data.expandPassThrough,
        "passThrough",
        this.props.editSection,
        this.props.content
      );

      if (!mergeResult) {
        return false;
      }
    }

    updatedContent = update(this.props.content, {
      [this.props.editSection]: {
        $set: data,
      },
    });
  } else {
    if (typeof this.props.addBelowIndex === "number") {
      let newContent = [...this.props.content];
      newContent.splice(this.props.addBelowIndex + 1, 0, data);
      updatedContent = update(this.props.content, {
        $set: newContent,
      });
    } else if (
      this.state.formDeliverable &&
      this.state.formDeliverableCategory
    ) {
      let insertDeliverable =
        this.findLast(
          this.props.content,
          (obj) =>
            obj.deliverableCategory === this.state.formDeliverableCategory
        ) || this.props.content.length;

      let newContent = [...this.props.content];
      newContent.splice(insertDeliverable + 1, 0, data);
      updatedContent = update(this.props.content, {
        $set: newContent,
      });
    } else {
      updatedContent = update(this.props.content, {
        $push: [data],
      });
    }
  }

  this.setState(
    {
      // content: updatedContent,
      // showForm: false,
      formTitle: "",
      formAppendix: false,
      formDeliverable: false,
      formBreak: false,
      // reorder: false
    },
    () => {
      // this.setState({
      //   editSection: null
      // });
      // this.injectCompany();
      // this.injectPayment();
    }
  );

  this.props.saveSection(updatedContent);
}

export function addSection() {
  try {
    window.tinymce.activeEditor.undoManager.clear();
  } catch (err) {}

  this.setState({
    edit: false,
    editContact: false,
    // editSection: undefined,
    // settings: false,
    // showForm: true,
    formTitle: "",
    formAppendix: false,
    formDeliverable: false,
    formBreak: false,
    formQuantity: "",
    formPrice: "",
    formExistingSpend: "",
    formPassThrough: "",
    formDiscount: "",
    formDeliverableCategory: "",
    formTable: 0,
    editorHTML: "<p>Enter copy here.</p>",
    formSubsections: [],
    Search: "",
  });

  let newState = {};

  Object.keys(this.state).forEach((key) => {
    if (key.includes("-formSubsection")) {
      newState[key] = "";
    }
  });

  this.setState(newState);
}

export function editSection(i) {
  try {
    window.tinymce.activeEditor.undoManager.clear();
  } catch (err) {}

  let section = this.props.content[i];
  // console.log(`Editing section ${i}`, section);
  // console.log(JSON.stringify(section));
  this.setState({
    // settings: false,
    edit: true,
    editContact: false,
    // editSection: i,
    // showForm: true,
    formTitle: section.title,
    formText: section.html,
    formAppendix: section.appendixItem,
    formDeliverable: section.deliverable,
    formBreak: section.break,
    formQuantity: section.quantity || "",
    formPrice: section.price || "",
    formExistingSpend: section.existingSpend || "",
    formPassThrough: section.passThrough || "",
    formDiscount: section.discount || "",
    formDeliverableCategory: section.deliverableCategory || "",
    editorHTML: section.editorHTML,
    formSubsections: section.subsections || [],
    formTable: section.table || 0,
    formExpandNewSpend: section.expandNewSpend || "",
    formExpandExistingSpend: section.expandExistingSpend || "",
    formExpandPassThrough: section.expandPassThrough || "",
    // formDeliverableNewSpendDiscount: section.deliverableNewSpendDiscount
  });

  let newState = {};

  Object.keys(this.state).forEach((key) => {
    if (key.includes("-formSubsection")) {
      newState[key] = "";
    }
  });

  this.setState(newState, () => {
    if (section.subsections) {
      // section.subsections.map((sub, i) => {
      //   this.setState({
      //     [`${i}-formSubsectionTitle`]: sub.title,
      //     [`${i}-formSubsectionPrice`]: sub.price,
      //     [`${i}-formSubsectionQuantity`]: sub.quantity,
      //     [`${i}-formSubsectionExistingSpend`]: sub.existingSpend,
      //     [`${i}-formSubsectionPassThrough`]: sub.passThrough
      //   });
      // });

      for (const [i, sub] of section.subsections.entries()) {
        this.setState({
          [`${i}-formSubsectionTitle`]: sub.title,
          [`${i}-formSubsectionPrice`]: sub.price,
          [`${i}-formSubsectionUnit`]: sub.unit,
          [`${i}-formSubsectionQuantity`]: sub.quantity,
          [`${i}-formSubsectionExistingSpend`]: sub.existingSpend,
          [`${i}-formSubsectionPassThrough`]: sub.passThrough,
          [`${i}-formSubsectionExpandNewSpend`]: sub.expandNewSpend,
          [`${i}-formSubsectionExpandExistingSpend`]: sub.expandExistingSpend,
          [`${i}-formSubsectionExpandPassThrough`]: sub.expandPassThrough,
        });
      }
    }
  });

  // if (section.editorHTML.includes("payments-table")) {
  //   console.log("has table");
  // }
}

export function toggleImportModal() {
  this.setState({
    import: !this.state.import,
    importHTML: null,
    importIndex: null,
    importTitle: null,
  });
}

export function importSection(i, title) {
  // console.log(i, title);
  this.props.clearImport();
  if (i >= 0 || i === "new" || i.includes("quick")) {
    let formattedTitle = title
      .replace("[Company Name] ", "")
      .replace(/ *\([^)]*\) */g, "REMOVEHERE");
    // .replace(/\u2013|\u2014/g, "-");

    if (formattedTitle.includes(",")) {
      formattedTitle = formattedTitle.substring(0, formattedTitle.indexOf(","));
    }

    if (formattedTitle.includes("REMOVEHERE")) {
      formattedTitle = formattedTitle.substring(
        0,
        formattedTitle.indexOf("REMOVEHERE")
      );
    }

    this.setState({
      import: true,
      Search: formattedTitle,
    });
  } else {
    this.setState({
      Search: "",
      import: true,
    });
  }
}

export function clearSearch() {
  this.setState(
    {
      query: "",
      Search: "",
    },
    () => {
      // this.displayDocs(this.state.query);
    }
  );
}

export function queueImport(title, html, index) {
  this.setState({
    importHTML: html,
    importIndex: index,
    importTitle: title,
  });
}

export function enableSearching() {
  this.setState({
    searching: true,
    importIndex: null,
    importHTML: null,
    importTitle: null,
  });
}

export function injectImport() {
  if (this.state.importHTML) {
    this.setState(
      {
        editorHTML: this.state.importHTML,
        showImportModal: false,
        formTitle: this.state.formTitle
          ? this.state.formTitle
          : this.state.importTitle,
      },
      () => {
        // this.showNotification("success", "Section imported!");
        this.props.importFinished();
        this.setState({
          import: false,
          importIndex: null,
          importHTML: null,
          importTitle: null,
        });
      }
    );
  }
}

export function addSubsection() {
  this.setState({
    formSubsections: update(this.state.formSubsections, {
      $push: [
        {
          title: "",
        },
      ],
    }),
  });
}

export function deleteSubsection(e, i) {
  e.preventDefault();

  let subsections = [];
  if (this.state.formSubsections.length) {
    subsections = [...this.state.formSubsections];
  }
  subsections.splice(i,1);

  subsections.forEach((e,i)=>{
    this.setState({
      [`${i}-formSubsectionTitle`] : e.title,
      [`${i}-formSubsectionQuantity`] : e.quantity,
      [`${i}-formSubsectionPrice`] : e.price,
      [`${i}-formSubsectionUnit`] : e.unit,
      [`${i}-formSubsectionExistingSpend`] : e.existingSpend,
      [`${i}-formSubsectionPassThrough`] : e.passThrough,
      [`${i}-formSubsectionExpandNewSpend`] : e.expandNewSpend,
      [`${i}-formSubsectionExpandExistingSpend`] : e.expandExistingSpend,
      [`${i}-formSubsectionExpandPassThrough`] : e.expandPassThrough,
    });
  })
  
  this.setState({formSubsections : subsections});
}

export function moveSubsection(e, i, dir) {
  e.preventDefault();

  let subsections = [];
  if (this.state.formSubsections.length) {
    for (let i in this.state.formSubsections) {
      subsections.push({
        title: this.state[`${i}-formSubsectionTitle`],
        quantity: this.state[`${i}-formSubsectionQuantity`],
        price: this.state[`${i}-formSubsectionPrice`],
        unit: this.state[`${i}-formSubsectionUnit`],
        existingSpend: this.state[`${i}-formSubsectionExistingSpend`],
        passThrough: this.state[`${i}-formSubsectionPassThrough`],
        expandNewSpend: this.state[`${i}-formSubsectionExpandNewSpend`],
        expandExistingSpend: this.state[ `${i}-formSubsectionExpandExistingSpend`],
        expandPassThrough: this.state[`${i}-formSubsectionExpandPassThrough`],
      });
    }
  }

  //console.log(this.state.formSubsections);
  console.log(subsections);
 // console.log(this.props);

  let newPos = null;
  if (dir === 'up'){ newPos = i-1 }
  else if (dir === 'down') { newPos = i+1 }
  let sub = subsections.splice(i,1)[0];
  subsections.splice(newPos, 0, sub);

  subsections.forEach((e,i)=>{
    this.setState({
      [`${i}-formSubsectionTitle`] : e.title,
      [`${i}-formSubsectionQuantity`] : e.quantity,
      [`${i}-formSubsectionPrice`] : e.price,
      [`${i}-formSubsectionUnit`] : e.unit,
      [`${i}-formSubsectionExistingSpend`] : e.existingSpend,
      [`${i}-formSubsectionPassThrough`] : e.passThrough,
      [`${i}-formSubsectionExpandNewSpend`] : e.expandNewSpend,
      [`${i}-formSubsectionExpandExistingSpend`] : e.expandExistingSpend,
      [`${i}-formSubsectionExpandPassThrough`] : e.expandPassThrough,
    
    });

  })

  //console.log(this.state.formSubsections);

  /*
  this.setState({
    formSubsections: update(this.state.formSubsections, {
      $set: [subsections],
    }),
  });
  */
  
  this.setState({
    formSubsections : subsections
  },
    () => { console.log(this.state.formSubsections); console.log(subsections) }
  );

  

}

export function getCompanyAddress() {
  return axios
    .get("/api/crmGetCompanyAddress", {
      params: {
        id: this.props.crmCompany.value,
      },
    })
    .then((response) => {
      // console.log(response);
      return response.data;
    })
    .catch(console.error);
}

export function getCompanyContact() {
  return axios
    .get("/api/crmGetCompanyContact", {
      params: {
        id: this.props.crmCompany.value,
      },
    })
    .then((response) => {
      // console.log(response);
      return response.data;
    })
    .catch(console.error);
}
