import update from "immutability-helper";
import axios from "axios";

export function getSignedRequest(file) {
  const xhr = new XMLHttpRequest();
  xhr.open("GET", `/api/sign-s3?file-name=${file.name}&file-type=${file.type}`);
  xhr.onreadystatechange = () => {
    if (xhr.readyState === 4) {
      if (xhr.status === 200) {
        const response = JSON.parse(xhr.responseText);
        // console.log(response)
        this.uploadFile(file, response.signedRequest, response.url);
      } else {
        this.showNotification("error", "Could not get signed URL.");
        this.setState({
          uploading: false,
        });
      }
    }
  };
  xhr.send();
}

export function uploadFile(file, signedRequest, url) {
  const xhr = new XMLHttpRequest();
  xhr.open("PUT", signedRequest);
  xhr.onreadystatechange = () => {
    if (xhr.readyState === 4) {
      if (xhr.status === 200) {
        // console.log(file, url)

        let updatedFiles = update(this.state.attachedFiles, {
          $push: [
            {
              name: file.name,
              url: url,
              // key: data.key,
              // location: data.location
            },
          ],
        });

        this.setState(
          {
            attachedFiles: updatedFiles,
            uploading: false,
          },
          () => {
            if (this.state.approved) {
              let previousDate =
                this.state.customDate || this.state.lastModified.time;
              this.updateDoc({
                customDate: previousDate,
              });
            } else {
              this.updateDoc();
            }
          }
        );
      } else {
        this.showNotification("error", "Could not upload file.");
        this.setState({
          uploading: false,
        });
      }
    }
  };
  xhr.send(file);
}

export function uploadS3(e) {
  e.preventDefault();

  let file = e.target.files[0];
  let reader = new FileReader();

  if (e.target.files.length === 0) {
    return;
  }

  // let name = e.target.value.replace(/.*[\\]/, "");

  this.setState({
    uploading: true,
  });

  reader.onloadend = (e) => {
    // console.log("upload S3");
    this.getSignedRequest(file);
  };

  reader.readAsDataURL(file);
}

export function deleteFile() {
  let { deleteFileInfo } = this.state;
  axios
    .get("/api/deletefile", {
      params: {
        key: deleteFileInfo.key,
      },
    })
    .then((response) => {
      // console.log("subtask", response);
      // console.log(response);
      let updatedFiles = update(this.state.attachedFiles, {
        $splice: [[deleteFileInfo.index, 1]],
      });

      this.setState(
        {
          attachedFiles: updatedFiles,
          attachmentConfirm: false,
          deleteFileInfo: null,
        },
        () => {
          if (this.state.approved) {
            let previousDate =
              this.state.customDate || this.state.lastModified.time;
            this.updateDoc({
              customDate: previousDate,
            });
          } else {
            this.updateDoc();
          }
        }
      );
    })
    .catch((error) => {
      console.error(error);
      this.showNotification("error", "Could not delete file!");
    });
}
