// import axios from "axios";
// import { AnonymousCredential } from "mongodb-stitch-browser-sdk";
var ObjectID = require("bson-objectid");

export let createdBy;
export let createdAt;

export async function displayDoc() {
  // query the remote DB and update the component state
  await this.props.db
    .collection(this.props.collection)
    .findOne({ _id: ObjectID(this.state.docId) })
    .then((doc) => {
      if (!doc) {
        this.setState({ error: "SOW not found" });
        document.querySelector("#app-container").classList.remove("doc-view");
        return false;
      }
      // if (this.props.token && this.props.token !== "123") {
      //   this.setState({ error: "You are not authorized to view this document" });
      //   return false;
      // }
      // console.log("doc data", doc);
      document.querySelector("#app-container").classList.add("doc-view");
      this.injectDocState(doc, () => {
        this.setState({
          dirty: false,
        });
        createdBy = doc.createdBy;
        createdAt = doc._id.getTimestamp();

        // if (doc.teamworkID) {
        //   axios
        //     .get("/api/teamwork/check", {
        //       params: {
        //         projectID: this.state.teamworkID,
        //       },
        //     })
        //     .then((response) => {
        //       // console.log("teamwork check", response);
        //
        //       if (
        //         response.data.project &&
        //         response.data.project.status === "active"
        //       ) {
        //       } else {
        //         this.setState({
        //           teamworkID: null,
        //         });
        //       }
        //     })
        //     .catch(console.error);
        // }

        if (this.props.pdf) {
          // console.log("show pdf");
          this.togglePreview();
        }

        if (!this.props.token) {
          let recent =
            JSON.parse(localStorage.getItem("recently-viewed")) || [];

          recent = recent.filter((doc) => doc.id !== this.state.docId);

          recent.unshift({
            id: this.state.docId,
            title: this.state.title,
          });

          localStorage.setItem(
            "recently-viewed",
            JSON.stringify(recent.slice(0, 10))
          );

          this.props.updateRecentlyViewed(recent.slice(0, 10));
        }
      });
    })
    .then(() => {
      // grab document revision history
      this.props.db
        .collection("revisions")
        .count({
          docId: this.state.docId,
        })
        .then((total) => {
          this.setState({
            totalRevisions: total,
          });
          this.props.db
            .collection("revisions")
            .find(
              {
                docId: this.state.docId,
              },
              {
                limit: this.state.revisionLimit,
                sort: { $natural: -1 },
                projection: {
                  saved: 1,
                  "data.approved": 1,
                  "data.sentToClient": 1,
                  "data.rejected": 1,
                  "data.revised": 1,
                },
              }
            )
            .asArray()
            .then((revisions) => {
              this.setState({
                revisions,
              });
              // console.log("revisions", revisions);
            })
            .catch(console.error);
        });
    })
    .catch((err) => {
      console.error(err);
    });
}

export function checkToken() {
  if (this.props.token) {
    this.props.db
      .collection(this.props.collection)
      .findOne(
        { _id: ObjectID(this.state.docId) },
        {
          projection: {
            token: 1,
          },
        }
      )
      .then((doc) => {
        if (this.props.token && this.props.token !== doc.token) {
          this.setState({
            error: "You are not authorized to view this document",
          });
          return false;
        } else {
          this.displayDoc();
          // setTimeout(() => {
          //   if (this.props.pdf) {
          //     console.log("show pdf");
          //     this.togglePreview();
          //
          //     setTimeout(() => {
          //       this.printPDF();
          //     }, 3000);
          //   }
          // }, 3000);
        }
      })
      .catch((err) => {
        console.error(err);
      });
  } else {
    this.displayDoc();
  }
}

export function loadMoreRevisions() {
  this.setState(
    {
      revisionLimit: this.state.revisionLimit + 5,
      loadingMoreRevisions: true,
    },
    () => {
      this.props.db
        .collection("revisions")
        .find(
          {
            docId: this.state.docId,
          },
          {
            limit: this.state.revisionLimit,
            sort: { $natural: -1 },
          }
        )
        .asArray()
        .then((revisions) => {
          this.setState({
            revisions,
            loadingMoreRevisions: false,
          });
          // console.log("load more revisions", revisions);
        })
        .catch(console.error);
    }
  );
}

export function fetchCurrencyRates() {
  // if (
  //   !this.state.rates ||
  //   this.state.rates === "undefined"
  //   // ||
  //   // (this.state.rates &&
  //   //   moment(this.state.rates.date) !== moment(this.state.date))
  // ) {
  //   fetch("https://api.exchangeratesapi.io/latest?base=USD&symbols=EUR,GBP")
  //     .then(res => res.json())
  //     .then(
  //       result => {
  //         this.setState({
  //           rates: result,
  //           loaded: true
  //         });
  //       },
  //       error => {
  //         console.error(error);
  //         // this.setState({
  //         //   isLoaded: true,
  //         //   error
  //         // });
  //       }
  //     )
  //     .then(() => {
  //       this.injectCompany();
  //       this.injectVariables();
  //       this.injectDeliverables();
  //       this.injectPayment();
  //     });
  // } else {
  // console.log(moment(this.state.rates.date).format(), moment(this.state.date).format())
  this.setState(
    {
      loaded: true,
    },
    () => {
      // console.log("inject");
      this.injectCompany();
      this.injectVariables();
      this.injectDeliverables();
      this.injectPayment();
    }
  );
  // }
}

export function displayDocOnLoad() {
  // console.log("displayDocOnLoad");
  // this.props.client.auth
  //   .loginWithCredential(new AnonymousCredential())
  //   .then(this.checkToken)
  //   .then(this.fetchTeam)
  //   .then(this.fetchCategories)
  //   .catch(console.error);

  this.displayDoc()
    .then(() => {
      // this.fetchTeam();
      // this.fetchCategories();

      Promise.all([this.fetchTeam(), this.fetchCategories()]).then(() => {
        this.setState(
          {
            loaded: true,
          }
          // () => {
          //   this.injectDeliverables();
          // }
        );
      });
    })
    .catch(console.error);
}

export function fetchTeam() {
  // console.log("fetchTeam");
  // query the remote DB and update the component state
  this.props.db
    .collection("member")
    .find(
      {},
      {
        // limit: this.state.limit,
        // sort: { $natural: -1 }
      }
    )
    .asArray()
    .then((members) => {
      // console.log("members", members);

      this.setState({
        members,
        nigelTitle: members.find((m) => m.id === "5d3f5acdbb93c625ea71c798")
          ?.Title,
      });
    })
    .catch(console.error);
}

export function fetchCategories() {
  this.props.db
    .collection("categories")
    .find({})
    .asArray()
    .then((result) => {
      this.setState(
        {
          deliverableCategories: result[0].data,
          // deliverableCategories: categories
        },
        () => {
          this.injectDeliverables();
        }
      );
    })
    .catch(console.error);
}
