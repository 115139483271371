/**
 *
 * Steps
 *
 */

import React from "react";
import PropTypes from "prop-types";
import {
  Alert,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Avatar,
  Box,
  ButtonGroup,
  Button,
  Checkbox,
  CircularProgress,
  FormGroup,
  FormControlLabel,
  Grid,
  IconButton,
  Typography,
  Tooltip,
  TextField,
  InputAdornment,
} from "@material-ui/core";
import LoadingButton from "@material-ui/lab/LoadingButton";
import AddBoxIcon from "@material-ui/icons/AddBox";
import DeleteIcon from "@material-ui/icons/Delete";
import DoneIcon from "@material-ui/icons/Done";
import CloseIcon from "@material-ui/icons/Close";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ReplayIcon from "@material-ui/icons/Replay";
import DatePicker from "@material-ui/lab/DatePicker";
import UploadIcon from "@material-ui/icons/Upload";
import WarningIcon from "@material-ui/icons/Warning";
import _ from "lodash";
import DeliverablesTable from "../DeliverablesTable";
import {
  formatCurrency,
  toNum,
  getCurrencySymbol,
} from "../../../utils/functions";
import AnchorLink from "react-anchor-link-smooth-scroll";
import ScrollIntoView from "react-scroll-into-view";
import moment from "moment";
// import { CopyToClipboard } from "react-copy-to-clipboard";
import "./styles.scss";

class Steps extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      expanded: "deliverables",
    };

    this.formatCurrency = formatCurrency.bind(this);
    this.getCurrencySymbol = getCurrencySymbol.bind(this);
    this.toNum = toNum.bind(this);
  }

  handleExpand(panel) {
    this.setState({
      expanded: panel === this.state.expanded ? false : panel,
    });
  }

  render() {
    let { state, tableNames, splitTotal, canApprove, token } = this.props;
    let { expanded } = this.state;
    let stepsEditable = !state.approved;
    return (
      <Box
        p={2}
        id="steps"
        style={!state.isAdmin && state.admin ? { overflow: "hidden" } : {}}
        className={`${state.approved ? "approved" : ""}${
          state.options ? " show" : ""
        }`}
      >
        <div id="accordions">
          <Accordion
            disableGutters
            defaultExpanded
            expanded={expanded === "deliverables"}
            onChange={() => this.handleExpand("deliverables")}
            style={{ display: stepsEditable ? "block" : "none" }}
          >
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography variant="h6">Deliverables</Typography>
              {!!state.selectedRows.length && (
                <ButtonGroup
                  size="small"
                  sx={{
                    "&": {
                      position: "absolute",
                      right: "3.25rem",
                      top: "50%",
                      transform: "translateY(-50%)",
                    },
                  }}
                >
                  <Button
                    variant="outlined"
                    color="error"
                    onClick={this.props.deleteRows}
                  >
                    Delete
                  </Button>
                  <Button
                    variant="outlined"
                    color="secondary"
                    onClick={this.props.clearRows}
                  >
                    Deselect
                  </Button>
                </ButtonGroup>
              )}
            </AccordionSummary>
            <AccordionDetails>
              <div
                id="generated-tables"
                className={state.deliverableCategories.length ? "" : "loading"}
              >
                <CircularProgress size={24} color="primary" />
                {_.times(state.tables, (tableIndex) => {
                  if (state[`tableName-${tableIndex}`]?.includes("Optional")) {
                    return false;
                  }
                  return (
                    <Deliverables
                      key={`deliverables-table-${tableIndex}`}
                      state={state}
                      {...this.props}
                      tableNames={tableNames}
                      tableIndex={tableIndex}
                    />
                  );
                })}
                <table
                  className="sow-total total-table table table-sm table-striped table-bordered"
                  style={{ maxWidth: state.totalWidth }}
                >
                  <tfoot>
                    {state.newSpendDiscount ? (
                      <tr
                        className={`discount editable${
                          state.discount ? " selected" : ""
                        }`}
                        onClick={this.props.addDiscount}
                      >
                        <td className="fit" colSpan="">
                          Discount
                        </td>
                        <td
                          name="newSpendDiscount"
                          className="currency has-value text-center"
                        >
                          -{this.formatCurrency(state.newSpendDiscount)}
                        </td>
                      </tr>
                    ) : null}
                    <tr
                      className="total editable"
                      onClick={this.props.addDiscount}
                    >
                      <td className="fit" colSpan="">
                        SOW Total
                      </td>
                      <td
                        colSpan=""
                        className={`currency has-value ${
                          state.hasPassThrough || state.hasExistingSpend
                            ? "text-center"
                            : ""
                        }`}
                        style={{ whiteSpace: "nowrap" }}
                      >
                        {state.totalPrice
                          ? this.formatCurrency(state.totalPrice)
                          : "Free"}
                      </td>
                    </tr>
                  </tfoot>
                </table>
                <form
                  className={`quick-edit-form arrow-top ${
                    state.discount ? "show" : ""
                  }`}
                  onSubmit={this.props.saveDiscount}
                >
                  <Grid display="flex" alignItems="center">
                    <Box mr={1} sx={{ flex: 1 }}>
                      <TextField
                        fullWidth
                        margin="dense"
                        label="Discount"
                        variant="outlined"
                        type="number"
                        size="small"
                        name="formNewSpendDiscount"
                        value={state.formNewSpendDiscount}
                        onChange={this.props.handleInputChange}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              {this.getCurrencySymbol(state.currency)}
                            </InputAdornment>
                          ),
                          inputProps: {
                            min: 0,
                            step: 0.1,
                          },
                        }}
                      />
                    </Box>
                    <Box display="flex" alignItems="center">
                      <IconButton size="small" variant="outlined" type="submit">
                        <DoneIcon color="primary" />
                      </IconButton>
                      <IconButton
                        size="small"
                        variant="outlined"
                        type="reset"
                        onClick={this.props.cancelDiscount}
                      >
                        <CloseIcon />
                      </IconButton>
                    </Box>
                  </Grid>
                </form>

                {_.times(state.tables, (tableIndex) => {
                  let adjustedIndex = Math.abs(tableIndex - state.tables);
                  if (
                    !state[`tableName-${adjustedIndex}`]?.includes("Optional")
                  ) {
                    return false;
                  }
                  return (
                    <Deliverables
                      key={`optional-deliverables-table-${adjustedIndex}`}
                      state={state}
                      {...this.props}
                      tableNames={tableNames}
                      tableIndex={adjustedIndex}
                    />
                  );
                })}
              </div>
            </AccordionDetails>
          </Accordion>

          <Accordion
            disableGutters
            expanded={expanded === "payment"}
            onChange={() => this.handleExpand("payment")}
            style={{ display: stepsEditable ? "block" : "none" }}
          >
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography variant="h6">Payment Schedule</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <form onSubmit={this.props.savePayment}>
                <FormGroup
                  row
                  sx={{
                    "&": {
                      m: -0.5,
                    },
                    "& > :not(style)": {
                      flex: 1,
                      m: 0.5,
                    },
                    "& > :not(style):last-of-type": {
                      flex: "auto",
                      maxWidth: "107px;",
                      "> *": {
                        mt: 0,
                      },
                    },
                  }}
                >
                  <Grid item>
                    <DatePicker
                      size="small"
                      label="Start Date"
                      inputFormat="MM/DD/yyyy"
                      value={
                        state.newStartDate &&
                        moment(state.newStartDate).toDate()
                      }
                      onChange={(value) =>
                        this.props.handleDateChange("newStartDate", value)
                      }
                      renderInput={(params) => (
                        <TextField {...params} size="small" />
                      )}
                    />
                  </Grid>
                  <Grid item>
                    <DatePicker
                      size="small"
                      label="End Date"
                      inputFormat="MM/DD/yyyy"
                      minDate={ moment(state.startDate).add(1, 'M') }
                      value={
                        state.newEndDate && moment(state.newEndDate).toDate()
                      }
                      onChange={(value) =>
                        this.props.handleDateChange("newEndDate", value)
                      }
                      renderInput={(params) => (
                        <TextField {...params} size="small" />
                      )}
                    />
                  </Grid>
                  <Grid item>
                    <TextField
                      fullWidth
                      size="small"
                      margin="normal"
                      label="# of Payments"
                      variant="outlined"
                      type="number"
                      name="newPaymentSplit"
                      value={state.newPaymentSplit}
                      onChange={this.props.handleInputChange}
                      InputProps={{
                        inputProps: {
                          min: 0,
                          max: 36,
                        },
                      }}
                    />
                  </Grid>
                </FormGroup>
                <FormGroup
                  row
                  sx={{
                    "&": {
                      m: -0.5,
                      mb: 1,
                    },
                    "& > :not(style)": {
                      flex: 1,
                      m: 0.5,
                    },
                  }}
                >
                  <Grid item>
                    <FormControlLabel
                      control={
                        <Checkbox
                          color="primary"
                          checked={state.newUponApproval}
                          onChange={this.props.handleInputChange}
                          name="newUponApproval"
                        />
                      }
                      label="Due Upon Approval"
                    />
                  </Grid>
                  <Grid item>
                    {state.paymentsWarning ? (
                      <Button
                        fullWidth
                        disableElevation
                        variant="contained"
                        color="error"
                        type="reset"
                        style={{ pointerEvents: "none" }}
                        // disabled
                      >
                        {state.paymentsUneven
                          ? "Uneven split!"
                          : "Prices don't add up!"}
                      </Button>
                    ) : (
                      <Button fullWidth variant="outlined" type="submit">
                        Apply
                      </Button>
                    )}
                  </Grid>
                </FormGroup>
                <Grid
                  display="flex"
                  alignItems="center"
                  className={`quick-edit-form arrow-bottom right ${
                    state.customPrice ? "show" : ""
                  }`}
                >
                  <Box mr={1}>
                    <TextField
                      fullWidth
                      margin="dense"
                      label={`Payment ${state.customPriceIndex + 1} Price`}
                      variant="outlined"
                      type="number"
                      step={state.docType === "Gift Estimate" ? "0.01" : "1"}
                      size="small"
                      name={`price-${state.customPriceIndex}`}
                      value={
                        state[`price-${state.customPriceIndex}`] || undefined
                      }
                      onChange={this.props.handleInputChange}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            {this.getCurrencySymbol(state.currency)}
                          </InputAdornment>
                        ),
                        inputProps: {
                          min: 0,
                        },
                      }}
                    />
                  </Box>
                  <Box
                    display="flex"
                    alignItems="center"
                    sx={{ flex: "0 !important" }}
                  >
                    <Tooltip disableInteractive title="Reset all prices">
                      <IconButton
                        size="small"
                        variant="outlined"
                        onClick={this.props.resetCustomPrices}
                      >
                        <ReplayIcon color="error" />
                      </IconButton>
                    </Tooltip>
                    <IconButton
                      size="small"
                      variant="outlined"
                      color="primary"
                      type="reset"
                      onClick={this.props.cancelCustomPrices}
                    >
                      <DoneIcon />
                    </IconButton>
                  </Box>
                </Grid>
                <Grid
                  display="flex"
                  alignItems="center"
                  className={`quick-edit-form arrow-bottom right ${
                    state.customPaymentDate ? "show" : ""
                  }`}
                >
                  <Box mr={1}>
                    <DatePicker
                      size="small"
                      inputFormat="MM/DD/yyyy"
                      label={`Payment ${state.customDateIndex + 1} Date`}
                      value={
                        (state[`date-${state.customDateIndex}`] &&
                          moment(
                            state[`date-${state.customDateIndex}`]
                          ).toDate()) ||
                        null
                      }
                      onChange={(value) =>
                        this.props.handleDateChange(
                          `date-${state.customDateIndex}`,
                          value
                        )
                      }
                      renderInput={(params) => (
                        <TextField {...params} size="small" fullWidth />
                      )}
                    />
                  </Box>
                  <Box
                    display="flex"
                    alignItems="center"
                    sx={{ flex: "0 !important" }}
                  >
                    <Tooltip disableInteractive title="Reset all dates">
                      <IconButton
                        size="small"
                        variant="outlined"
                        onClick={this.props.resetCustomDates}
                      >
                        <ReplayIcon color="error" />
                      </IconButton>
                    </Tooltip>
                    <IconButton
                      size="small"
                      variant="outlined"
                      color="primary"
                      type="reset"
                      onClick={this.props.cancelCustomDates}
                    >
                      <DoneIcon />
                    </IconButton>
                  </Box>
                </Grid>
              </form>
              {parseInt(state.paymentSplit, 10) !== 0 &&
              state.totalPrice &&
              state.startDate ? (
                <div>
                  <table
                    className={`payments-table ${
                      state.paymentsWarning ? `text-danger` : ``
                    } table table-sm table-striped table-bordered`}
                  >
                    <tbody>
                      {state.paymentArray.map((period, i) => {
                        return (
                          <React.Fragment key={`period-${i + 1}`}>
                            <tr>
                              <td className="fit">
                                Payment {i + 1} of {splitTotal}{" "}
                                {i === 0 && state.paymentArray.length > 1 ? (
                                  <>/ Deposit &emsp;</>
                                ) : (
                                  ""
                                )}
                                {i + 1 === splitTotal &&
                                state.paymentArray.length > 1
                                  ? "/ Final"
                                  : ""}
                              </td>
                              <td
                                name={`date-${i}`}
                                className={`editable${
                                  i === state.customDateIndex ? " selected" : ""
                                }`}
                                onClick={(e) => this.props.addCustomDate(e, i)}
                              >
                                Due:{" "}
                                {i === 0 && state.uponApproval ? (
                                  <>Upon Project Approval</>
                                ) : state[`date-${i}`] ? (
                                  moment(state[`date-${i}`]).format(
                                    "MMMM Do, YYYY"
                                  )
                                ) : (
                                  period.due
                                )}
                              </td>
                              <td
                                name={`price-${i}`}
                                className={`currency has-value editable${
                                  i === state.customPriceIndex
                                    ? " selected"
                                    : ""
                                }`}
                                onClick={(e) => this.props.addCustomPrice(e, i)}
                              >
                                {this.formatCurrency(
                                  state[`price-${i}`]
                                    ? state[`price-${i}`]
                                    : period.price
                                )}
                              </td>
                            </tr>
                            {i + 1 === splitTotal && (
                              <tr className="total">
                                <td colSpan="2">Total</td>
                                <td className="currency has-value">
                                  {this.formatCurrency(
                                    state.customPriceTotal
                                      ? state.customPriceTotal
                                      : state.paymentArray.reduce(
                                          (a, b) =>
                                            a + this.toNum(b["price"] || 0),
                                          0
                                        )
                                  )}
                                </td>
                              </tr>
                            )}
                          </React.Fragment>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              ) : (
                ""
              )}
            </AccordionDetails>
          </Accordion>
          <Accordion
            disableGutters
            expanded={expanded === "team"}
            onChange={() => this.handleExpand("team")}
            style={{ display: stepsEditable ? "block" : "none" }}
          >
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography variant="h6">Team</Typography>
            </AccordionSummary>
            <AccordionDetails>
              {state.departments.map((dept, i) => {
                let deptMembers = state.members?.filter(
                  (member) => member.Department === dept
                );
                return (
                  <div className="department" key={i}>
                    <Accordion
                      disableGutters
                      square
                      defaultExpanded
                      sx={{
                        "&": {
                          boxShadow: "none",
                          ".MuiFormControlLabel-label": {
                            fontSize: 14,
                          },
                        },
                      }}
                    >
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        style={{
                          padding: 0,
                          borderBottom: "1px solid rgba(0,0,0,.1)",
                        }}
                      >
                        <Typography variant="h6" style={{ fontSize: "1rem" }}>
                          {dept} ({deptMembers?.length})
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails style={{ padding: 0 }}>
                        <div className="team">
                          {deptMembers?.map((member, j) => {
                            return (
                              <React.Fragment key={j}>
                                <FormGroup row>
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        color="primary"
                                        size="small"
                                        checked={
                                          !!state[
                                            `member-${member._id.toString()}`
                                          ]
                                        }
                                        onChange={this.props.handleInputChange}
                                        name={`member-${member._id.toString()}`}
                                      />
                                    }
                                    label={member.Name}
                                  />
                                </FormGroup>
                              </React.Fragment>
                            );
                          })}
                        </div>
                      </AccordionDetails>
                    </Accordion>
                    {/* <hr /> */}
                  </div>
                );
              })}
              <Button
                fullWidth
                variant="outlined"
                type="submit"
                onClick={this.props.applyTeam}
              >
                Apply
              </Button>
            </AccordionDetails>
          </Accordion>

          {state.docNum !== "MAST" && !token && (
            <Accordion
              disableGutters
              expanded={!stepsEditable || expanded === "approve"}
              onChange={() => this.handleExpand("approve")}
              defaultExpanded={!stepsEditable}
              sx={
                stepsEditable
                  ? {}
                  : {
                      "&": {
                        marginTop: 0,
                        borderTopLeftRadius: "4px",
                        borderTopRightRadius: "4px",
                        "&:before": {
                          display: "none",
                        },
                      },
                    }
              }
            >
              <AccordionSummary
                sx={{
                  "&": {
                    cursor: stepsEditable
                      ? "pointer !important"
                      : "default !important",
                  },
                }}
                expandIcon={stepsEditable && <ExpandMoreIcon />}
              >
                <Typography variant="h6">
                  {!stepsEditable ? "Attachments" : "Approve"}
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                {state.rejected ? (
                  <Alert severity="error">
                    You must unreject this SOW before approving.
                  </Alert>
                ) : (
                  <Box>
                    {/* {state.token ? (
                      <>
                        <h6>Secure Link:</h6>
                        <div>
                          <Box display="flex" alignItems="center">
                            <a
                              href={state.link}
                              target="_blank"
                              rel="noreferrer"
                            >
                              {state.link}
                            </a>

                            <CopyToClipboard
                              onCopy={this.props.onCopy}
                              text={state.link}
                            >
                              <Tooltip disableInteractive title="Copy link">
                                <IconButton color="primary" title="Copy link">
                                  <MdContentCopy />
                                </IconButton>
                              </Tooltip>
                            </CopyToClipboard>
                          </Box>
                        </div>
                      </>
                    ) : (
                      <Button
                        fullWidth
                        variant="outlined"
                        onClick={this.props.generateToken}
                        disabled={!state.canGenerate}
                      >
                        Generate Secure Link
                      </Button>
                    )}
                    <hr /> */}
                    <div
                      className={`file-upload-container ${
                        state.uploading ? "uploading" : ""
                      }`}
                    >
                      {state.uploading ? (
                        <>
                          <CircularProgress size={16} color="secondary" />
                          &emsp;Uploading...
                        </>
                      ) : (
                        <>
                          <label htmlFor="file-upload">
                            <UploadIcon />
                            &nbsp;
                            <Typography variant="overline">
                              Upload File
                            </Typography>
                          </label>
                          <input
                            id="file-upload"
                            type="file"
                            accept="image/*,application/pdf,application/msword"
                            onChange={this.props.uploadS3}
                          />
                        </>
                      )}
                    </div>
                    <div id="attached-files">
                      {state.attachedFiles &&
                        state.attachedFiles.map((file, i) => {
                          return (
                            <Box
                              pl={1}
                              key={file.url || file.key}
                              // className="editable"
                            >
                              <a
                                href={
                                  file.url ||
                                  `https://approved-sow-prod.s3.us-east-1.amazonaws.com/${encodeURIComponent(
                                    file.key
                                  )}`
                                }
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                {file.name}
                              </a>
                              {!state.approved ||
                              (state.approved && this.props.normalAdmin) ? (
                                <IconButton
                                  // className="delete-file"
                                  onClick={() =>
                                    this.props.deleteFileConfirm({
                                      key:
                                        file.key ||
                                        decodeURIComponent(
                                          file.url.replace(
                                            "https://approved-sow-prod.s3.amazonaws.com/",
                                            ""
                                          )
                                        ),
                                      index: i,
                                      name: file.name,
                                    })
                                  }
                                >
                                  <DeleteIcon
                                    color="error"
                                    style={{ fontSize: "1.1rem" }}
                                  />
                                </IconButton>
                              ) : null}
                            </Box>
                          );
                        })}
                    </div>
                    {stepsEditable && (
                      <>
                        <Box
                          display="flex"
                          sx={{
                            "&": {
                              pt: 2,
                              pb: 2,
                            },
                            "& > :not(style)": {
                              flex: 1,
                              m: 0.5,
                            },
                          }}
                        >
                          {state?.attachedFiles.length === 0 && (
                            <FormControlLabel
                              sx={{
                                "&": { marginLeft: "-9px !important" },
                                "& span": { fontSize: "12px" },
                              }}
                              control={
                                <Checkbox
                                  size="small"
                                  color="primary"
                                  checked={state.newManualApprove}
                                  onChange={this.props.handleInputChange}
                                  name="newManualApprove"
                                />
                              }
                              label="Approve without uploading a signed document"
                            />
                          )}
                          <DatePicker
                            size="small"
                            inputFormat="MM/DD/yyyy"
                            label="Date to display on SOW"
                            value={moment(
                              state.customDate || state?.lastModified?.time
                            ).toDate()}
                            onChange={(value) =>
                              this.props.handleDateChange("customDate", value)
                            }
                            renderInput={(params) => (
                              <TextField {...params} size="small" fullWidth />
                            )}
                            style={{ margin: 0, marginTop: "5px" }}
                          />
                        </Box>
                        <Button
                          disableElevation
                          fullWidth
                          variant="contained"
                          color="success"
                          disabled={!canApprove}
                          onClick={this.props.approveConfirm}
                          // style={{ width: "100%" }}
                        >
                          Approve SOW
                        </Button>
                      </>
                    )}
                  </Box>
                )}
              </AccordionDetails>
            </Accordion>
          )}
          {!state.isAdmin && state.admin && (
            <div id="edit-overlay">
              <WarningIcon />
              <Typography variant="h5">
                This SOW is currently being edited by{" "}
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  mb={2}
                >
                  <Avatar
                    sx={{
                      "&": {
                        width: 30,
                        height: 30,
                      },
                    }}
                    src={state.admin.picture}
                    alt={state.admin.given_name}
                  />
                  &ensp;
                  {state.admin.given_name}
                </Box>
              </Typography>
              {state.adminIdle || this.props.superAdmin ? (
                <>
                  {state.adminIdle && (
                    <Box m={2}>
                      <p>However, they seem to be idle.</p>
                    </Box>
                  )}
                  <LoadingButton
                    disableElevation
                    variant="contained"
                    color={state.rejectTakeOver ? "error" : "primary"}
                    className="public-btn"
                    onClick={this.props.requestTakeOver}
                    pending={state.requestingTakeOver}
                  >
                    {state.rejectTakeOver
                      ? "Request Denied. Try Again"
                      : "Request Take Over"}
                  </LoadingButton>
                </>
              ) : null}
              {/* {!state.adminIdle && state.rejectTakeOver && (
                <>
                  <p>Take Over request denied! Awkward...</p>
                </>
              )} */}
            </div>
          )}
        </div>

        <div id="toc">
          <Typography variant="h6">Index of Contents</Typography>
          <ul>
            {state.content.map((section, i) => {
              return (
                !!section.title?.trim().length && (
                  <li
                    key={`toc-${i}`}
                    className={`${
                      section.appendixItem ? "appendix-item" : ""
                    } ${section.deliverable ? "deliverable" : ""}`}
                  >
                    <ScrollIntoView
                      selector={`#section-${i}`}
                      style={{ display: "inline-block" }}
                    >
                      <AnchorLink offset="100" href={`#section-${i}`}>
                        {section.title.replace(
                          "[Company Name]",
                          (state.crmCompany && state.crmCompany.label) ||
                            state.company
                        )}
                      </AnchorLink>
                    </ScrollIntoView>
                  </li>
                )
              );
            })}
          </ul>
        </div>
      </Box>
    );
  }
}

function Deliverables(props) {
  return (
    <div
      id={`table-group-${props.tableIndex}`}
      className="table-group"
      key={`deliverables-table-${props.tableIndex}`}
    >
      <DeliverablesTable
        tableIndex={props.tableIndex}
        deliverableCategories={props.state.deliverableCategories}
        content={props.state.content}
        currency={props.state.currency}
        totals={props.state.totals}
        tables={props.state.tables}
        totalWidth={props.state.totalWidth}
        totalWidthSteps={props.state.totalWidthSteps}
        hasExistingSpend={props.state.hasExistingSpend}
        hasPassThrough={props.state.hasPassThrough}
        editSection={props.editSection}
        editTableName={(e) => props.editTableName(e, props.tableIndex)}
        quickDeliverable={props.quickDeliverable}
        saveTableName={(e) => props.saveTableName(e, props.tableIndex)}
        cancelTableName={() => props.cancelTableName(props.tableIndex)}
        editTableNameIndex={props.state[`editTableName-${props.tableIndex}`]}
        formTableName={props.state[`formTableName-${props.tableIndex}`]}
        handleInputChange={props.handleInputChange}
        editSubTotalName={(e) => props.editSubTotalName(e, props.tableIndex)}
        subTotalTableName={props.state.subTotalTableName}
        editSubTotalNameIndex={props.state.editSubTotalNameIndex}
        saveSubTotalName={(e) => props.saveSubTotalName(e, props.tableIndex)}
        cancelSubTotalName={() => props.cancelSubTotalName(props.tableIndex)}
        removeSubTotal={(e) => props.removeSubTotal(e, props.tableIndex)}
        {...props.tableNames}
        deliverableNumber={props.state.deliverableNumber}
        selectRow={props.selectRow}
        selectedRows={props.state.selectedRows}
        docType={props.state.docType}
        subTotals={props.state.subTotals}
      />
      <Box
        className="table-toolbar"
        sx={{
          "&": {
            mt: props.state.docType === "Gift Estimate" ? 0 : -3,
          },
          "& svg": {
            fontSize: "1rem",
          },
        }}
      >
        <Tooltip disableInteractive title="Add Table Below">
          <IconButton
            onClick={(e) => props.addTable(e, props.tableIndex)}
            size="small"
          >
            <AddBoxIcon color="primary" />
          </IconButton>
        </Tooltip>

        {!!props.tableIndex && (
          <Tooltip disableInteractive title="Remove Table">
            <IconButton
              onClick={(e) => props.removeTable(e, props.tableIndex)}
              size="small"
            >
              <DeleteIcon color="error" />
            </IconButton>
          </Tooltip>
        )}
      </Box>
    </div>
  );
}

Steps.propTypes = {
  state: PropTypes.object.isRequired,
  tableNames: PropTypes.object,
  splitTotal: PropTypes.number,
  canApprove: PropTypes.bool,
};

export default Steps;
