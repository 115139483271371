/**
 *
 * Preview
 *
 */

import React from "react";
import {
  Box,
  Grid,
  Tooltip,
  Typography,
  IconButton,
  Menu,
  MenuItem,
} from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import NigelSig from "../../../images/nigel_sig.png";
import UploadIcon from "@material-ui/icons/Upload";
import moment from "moment";
import AnchorLink from "react-anchor-link-smooth-scroll";
import ScrollIntoView from "react-scroll-into-view";
import {
  sortableContainer,
  sortableElement,
  sortableHandle,
} from "react-sortable-hoc";
import PropTypes from "prop-types";
// import SignatureCanvas from "react-signature-canvas";
import _ from "lodash";
// import { ContextMenu, MenuItem, ContextMenuTrigger } from "react-contextmenu";
import "./styles.scss";
import autoBind from "react-autobind";
var ObjectID = require("bson-objectid");

const DragHandle = sortableHandle(() => (
  <span className="drag-handle">::</span>
));

const SortableItem = sortableElement(({ section, index, tableNames }) => (
  <section
    id={`section-${index}`}
    className={`reordering ${section.deliverable ? "deliverable-item" : ""}${
      section.appendixItem ? " appendix-item" : ""
    }${section.break && !section.deliverable ? " page-break" : ""}`}
    key={`section-${index}`}
  >
    <DragHandle />
    {/* <h5 className="small" style={{margin: 0}}>{tableNames && tableNames[section.table] && tableNames[section.table].label}</h5> */}
    <Typography variant="h6">{section.title}</Typography>
  </section>
));

const SortableContainer = sortableContainer(({ children }) => {
  return <div id="reorder">{children}</div>;
});

class Preview extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      inverted:
        localStorage.getItem("invert-doc") &&
        !window.location.search.includes("?token=")
          ? JSON.parse(localStorage.getItem("invert-doc"))
          : false,
      contextMenu: null,
    };

    autoBind(this);
  }

  componentDidMount() {
    // if (this.props.approvedBy && this.props.approvedBy.signature) {
    //   this.injectSignature();
    // }
    this.setInvert();
  }

  componentDidUpdate(prevProps, prevState) {
    // if (
    //   this.props.approvedBy !== prevProps.approvedBy &&
    //   this.props.approvedBy &&
    //   this.props.approvedBy.signature
    // ) {
    //   this.injectSignature();
    // }
    // if (
    //   this.props.team !== prevProps.team
    // ) {
    //   console.log('team changed');
    // }
    if (prevState.inverted !== this.state.inverted) {
      this.setInvert();
    }
  }

  toggleInvert() {
    this.setState(
      {
        inverted: !this.state.inverted,
      },
      () => {
        this.setInvert();
      }
    );
  }

  setInvert() {
    if (this.state.inverted) {
      document.querySelector("#main").classList.add("inverted");
    } else {
      document.querySelector("#main").classList.remove("inverted");
    }
    localStorage.setItem("invert-doc", this.state.inverted);
  }

  injectSignature() {
    this.sigCanvas.fromData(this.props.approvedBy.signature);
    this.sigCanvas.off();
    // document.querySelector("#sig-preview").innerHTML = ""
    // document.querySelector("#sig-preview").append(this.sigCanvas.getTrimmedCanvas())
    this.setState({
      sigImg: this.sigCanvas.getTrimmedCanvas().toDataURL(),
    });
  }

  handleContextMenu(event, index, deliverable) {
    event.preventDefault();
    this.setState({
      contextMenu:
        this.state.contextMenu === null
          ? {
              mouseX: event.clientX - 2,
              mouseY: event.clientY - 4,
              index,
              deliverable,
            }
          : null,
    });
  }

  handleClose() {
    this.setState({
      contextMenu: null,
    });
  }

  render() {
    let date = this.props.customDate
      ? this.props.customDate
      : (this.props.lastModified && this.props.lastModified.time) ||
        this.props.date;

    if (
      !this.props.customDate &&
      this.props.approved &&
      this.props.approvedBy &&
      this.props.approvedBy?.time?.getTime() <
        this.props.lastModified?.time?.getTime()
    ) {
      date = this.props.approvedBy.time;
    }

    let delivNum = 0;

    let templateException = false;

    if (
      this.props.content.filter((section) => section.deliverable).length ===
        1 &&
      this.props.docType === "Research Report"
    ) {
      templateException = true;
    }

    let tableNames = [];
    _.times(this.props.tables, (i) => {
      // return (
      //   <option value={i} key={i}>
      //     {this.props[`tableName-${i}`] ||
      //       `Deliverables ${i + 1}`}
      //   </option>
      // );

      tableNames.push({
        value: i,
        label: this.props[`tableName-${i}`] || `Deliverables ${i + 1}`,
      });
    });

    return (
      <Grid
        id="document"
        className={`document${this.state.inverted ? " inverted" : ""}`}
      >
        {/* {this.props.dark && (
          <OverlayTrigger
            placement="top"
            overlay={<Tooltip disableInteractive>Invert Document</Tooltip>}
            delay={{ show: 250 }}
          >
            <Button
              id="invert-doc"
              size="sm"
              variant="light"
              onClick={this.toggleInvert}
              className="public-btn"
            >
              {this.state.inverted ? <MdInvertColorsOff /> : <MdInvertColors />}
            </Button>
          </OverlayTrigger>
        )} */}
        <header>
          <Grid container justifyContent="space-between" className="logos">
            <Grid item xs={3} className="editable" onClick={this.props.editSettings}>
                <img src={this.props.logoInternal} alt="That's Nice" />
            </Grid>
            <Grid
              item
              className={`company-logo-container ${
                this.props.logo ? "" : "no-logo"
              }`}
              xs={3}
              style={{
                position: "relative",
              }}
            >
              <label htmlFor="logo-upload">
                <UploadIcon />
                <Typography variant="overline">Upload Logo</Typography>
              </label>
              <input
                id="logo-upload"
                type="file"
                accept="image/*"
                onChange={this.props.handlePreview}
                title="Logo"
              />
              {!!this.props.logo && (
                <img src={this.props.logo} alt={this.props.company} />
              )}
            </Grid>
          </Grid>
          <table id="tn-info">
            <tbody>
              <tr>
                <td className="editable" onClick={this.props.editSettings}>
                  {this.props.address === "Paris" ? (
                    <p className="small">
                      <strong>That’s Nice, Paris</strong>
                      <br />
                      17 Rue Scribe
                      <br />
                      5th Floor
                      <br />
                      75009 Paris
                      <br />
                      France
                    </p>
                  ) : (
                    <p className="small">
                      <strong>That’s Nice LLC</strong>
                      <br />
                      89 Fifth Avenue
                      <br />
                      5th Floor
                      <br />
                      New York, NY 10003
                      <br />
                      USA
                    </p>
                  )}
                </td>
                <td>
                  <p className="small">
                    <strong>Research Services</strong>
                    <br />
                    Nice Insight
                    <br />
                    Pharma’s Almanac
                    <br />
                    FTE/PTE Research
                  </p>
                </td>
                <td>
                  <p className="small">
                    <strong>Agency Services</strong>
                    <br />
                    Strategy & Content
                    <br />
                    Design & Creative
                    <br />
                    Digital & Multimedia
                    <br />
                    Exhibit & Gifts
                    <br />
                    Media & PR
                  </p>
                </td>
                <td>
                  <p className="small">
                    <strong>Business Services</strong>
                    <br />
                    Nice Consulting
                    <br />
                    Nice Match
                    <br />
                  </p>
                </td>
                <td>
                  <p className="small">
                    <strong> Websites</strong>
                    <br />
                    www.thatsnice.com
                    <br />
                    www.niceinsight.com
                    <br />
                    www.pharmasalmanac.com
                    <br />
                    www.nicematch.com
                  </p>
                </td>
              </tr>
            </tbody>
          </table>
        </header>

        <br />
        <br />

        <Typography
          variant="h5"
          className="editable"
          onClick={this.props.editSettings}
        >
          {this.props.docType}:{" "}
          <span className="orange">{this.props.company}</span>
        </Typography>
        <hr />
        <table id="contact-info">
          <tbody>
            <tr>
              <td
                className={`editable ${
                  this.props.editSectionIndex === "contact" ? "active" : ""
                }`}
                onClick={this.props.editContact}
                dangerouslySetInnerHTML={{
                  __html: this.props.contactHTML,
                }}
              />
              <td className="editable" onClick={this.props.editSettings}>
                <span>
                  <strong>From:</strong>
                </span>
                <br />
                {!!this.props.salesPerson?.length &&
                  this.props.salesPerson.map((member) => {
                    return (
                      <p key={member.value}>
                        {member.label}
                        <br />
                        {this.props.members &&
                          this.props.members.find(
                            (m) => ObjectID(m._id).toString() === member.value
                          ).Title}
                      </p>
                    );
                  })}
                <span>
                  <strong>Email:</strong>
                </span>
                <br />
                <p>
                  {!!this.props.salesPerson?.length &&
                    this.props.salesPerson.map((member) => {
                      return (
                        <React.Fragment key={member.value}>
                          <span>
                            {this.props.members &&
                              this.props.members.find(
                                (m) =>
                                  ObjectID(m._id).toString() === member.value
                              ).Email}
                          </span>
                          <br />
                        </React.Fragment>
                      );
                    })}
                </p>
                <span>
                  <strong>Office Phone:</strong>
                </span>
                <br />
                <p>
                  {this.props.address === "Paris"
                    ? "+33 630 630 400"
                    : "+1 212 366-4455"}
                </p>
                <span>
                  <strong>File Reference:</strong>
                </span>
                <br />
                <p style={{ wordBreak: "break-word" }}>{this.props.title}</p>
                <span>
                  <strong>Date:</strong>
                </span>
                <br />
                <p>{moment(date).format("L")}</p>
              </td>
            </tr>
          </tbody>
        </table>
        <hr />
        <Box display="flex" justifyContent="space-between">
          <Typography variant="h6" sx={{ "&": { whiteSpace: "nowrap" } }}>
            Confidential for{" "}
            <span className="orange">{this.props.company}</span> Only
          </Typography>
          <Typography variant="h6">
            Document Version:{" "}
            <span className="orange">{this.props.version}</span>
          </Typography>{" "}
        </Box>
        <br />
        {this.props.content.length && (
          <div id="index-of-contents">
            <span className="orange">Index of Contents:</span>
            <br />
            <ul>
              {this.props.content.map((section, i) => {
                if (
                  (!section.deliverable ||
                    (section.deliverable && templateException)) &&
                  section.title &&
                  section.title.trim().length
                ) {
                  return (
                    <li
                      className={section.appendixItem ? "appendix-item" : ""}
                      key={`section-${i}`}
                    >
                      <ScrollIntoView
                        selector={`#section-${i}`}
                        style={{ display: "inline-block" }}
                      >
                        <AnchorLink offset="100" href={`#section-${i}`}>
                          {section.title.replace("[Company Name] ", "")}
                        </AnchorLink>
                      </ScrollIntoView>
                    </li>
                  );
                } else {
                  return false;
                }
              })}
            </ul>
            <br />
          </div>
        )}
        <div id="content" className="row">
          {this.props.reorder ? (
            <SortableContainer
              onSortEnd={this.props.onSortEnd}
              lockAxis={"y"}
              disableAutoscroll={true}
            >
              {this.props.content.map((section, index) => (
                <SortableItem
                  key={`item-${index}`}
                  index={index}
                  section={section}
                  tableNames={tableNames}
                />
              ))}
            </SortableContainer>
          ) : (
            this.props.content.map((section, i) => {
              if (section.deliverable) {
                delivNum++;
              }

              return (
                <React.Fragment key={`section-${i}`}>
                  <section
                    id={`section-${i}`}
                    onClick={() => this.props.editSection(i)}
                    className={`editable ${
                      i === this.props.editSectionIndex ? " active" : ""
                    }${
                      section.deliverable && !templateException
                        ? " deliverable-item"
                        : ""
                    }${section.appendixItem ? " appendix-item" : ""}${
                      i === this.props.addBelowIndex ? " adding-below" : ""
                    }${
                      section.break && !section.deliverable ? " page-break" : ""
                    }`}
                    onContextMenu={(e) =>
                      this.handleContextMenu(e, i, section.deliverable)
                    }
                  >
                    {!section.deliverable ||
                    delivNum !== 1 ||
                    (section.deliverable && i === 0 && templateException) ? (
                      <hr />
                    ) : null}

                    {/* <hr /> */}

                    {/* {section.appendixItem || i === 0 ? (
                    <hr className="separator" />
                  ) : (
                    ""
                  )} */}
                    {/* <h5 className="small" style={{margin: 0}}>{tableNames[section.table] && tableNames[section.table].label}</h5> */}
                    <Typography variant="h6" className="section-title" mb={1}>
                      {section.title}
                    </Typography>
                    <div
                      className="block"
                      dangerouslySetInnerHTML={{
                        __html: section.editorHTML,
                      }}
                    />
                    {section.title.includes("Activities Summary") && (
                      <>
                        {/* <br /> */}
                        <span className="clearfix deliverables-table">
                          [Deliverables]
                        </span>
                      </>
                    )}
                    {section.title.includes("Payment Schedule") ? (
                      <>
                        <span className="payments-table" />
                        <div id="sig-required">
                          <em>
                            Signature required for approval to start services
                            listed above. Signature represents full
                            understanding and agreement of fees and payment
                            schedule listed above, as well as service
                            descriptions and Terms stated in this document.
                          </em>
                        </div>
                        <div id="sig-block">
                          <div>
                            {this.props.docType.includes(
                              "Order Form"
                            ) ? null : (
                              <>
                                <img src={NigelSig} alt="Nigel Walker" />
                                <p>___________________________</p>
                                <p>
                                  Date: {moment(this.props.sigDate).format("L")}
                                </p>
                                <p>Name: Nigel Walker</p>
                                <p>Title: {this.props.nigelTitle}</p>
                                <p>Company: That's Nice LLC</p>
                              </>
                            )}
                          </div>
                          <div>
                            <p>
                              {this.props.eApproved
                                ? "Digital Signature: "
                                : "Approval Signature: "}
                              <span className="sig-preview">
                                {this.props.eApproved &&
                                  this.props.approvedBy &&
                                  this.props.approvedBy.name}
                                {/* {this.props.approvedBy &&
                              this.props.approvedBy.signature ? (
                                <>
                                  <SignatureCanvas
                                    penColor="black"
                                    ref={ref => {
                                      this.sigCanvas = ref;
                                    }}
                                  />
                                  <img
                                    src={this.state.sigImg}
                                    alt="Signature"
                                  />
                                </React.Fragment>
                              ) : null} */}
                              </span>
                            </p>
                            <p>
                              Date:{" "}
                              <span>
                                {this.props.eApproved &&
                                  this.props.approvedBy &&
                                  moment(this.props.approvedBy.time).format(
                                    "dddd, MMMM Do, YYYY"
                                  )}
                              </span>
                            </p>
                            <p>
                              Name:{" "}
                              <span>
                                {this.props.eApproved &&
                                  this.props.approvedBy &&
                                  this.props.approvedBy.name}
                              </span>
                            </p>
                            <p>
                              Title:{" "}
                              <span>
                                {this.props.eApproved &&
                                  this.props.approvedBy &&
                                  this.props.approvedBy.title}
                              </span>
                            </p>
                            <p>
                              Company:{" "}
                              <span>
                                {this.props.eApproved &&
                                this.props.approvedBy &&
                                this.props.approvedBy.company
                                  ? this.props.approvedBy.company
                                  : this.props.approvalCompany
                                  ? this.props.approvalCompany
                                  : this.props.company}
                              </span>
                            </p>
                          </div>
                        </div>
                      </>
                    ) : (
                      ""
                    )}
                    {section.title.includes("About That") &&
                      this.props.team &&
                      this.props.team.length ? (
                        <>
                          {/* <br /> */}
                          <Typography variant="h5" sx={{ "&": { mb: 1 } }}>
                            <span className="company-name" /> Team
                          </Typography>
                          <table className="team-table table table-sm table-bordered">
                            <tbody>
                              {this.props.departments.map((dept, i) => {
                                let members = this.props.team.filter(
                                  (el) => el.Department === dept
                                );
                                if (members.length) {
                                  return (
                                    <React.Fragment key={i}>
                                      <tr className="gray-bg">
                                        <th scope="col" colSpan="3">
                                          {dept}
                                        </th>
                                      </tr>
                                      {members.map((member, j) => {
                                        let experience = "";

                                        if (member.Experience) {
                                          experience = member.Experience;
                                        } else {
                                          if (member.StartDate) {
                                            experience = `${moment(
                                              member.StartDate
                                            )
                                              .from(date, true)
                                              .replace(
                                                "a ",
                                                "1 "
                                              )} at That's Nice${
                                              member.PharmaDate
                                                ? `, ${moment(member.PharmaDate)
                                                    .from(date, true)
                                                    .replace(
                                                      " years",
                                                      ""
                                                    )} in Pharma`
                                                : ""
                                            }`;
                                          }

                                          if (
                                            member.PharmaDate &&
                                            !member.StartDate
                                          ) {
                                            experience = `${moment(
                                              member.PharmaDate
                                            ).from(date, true)} in Pharma`;
                                          }

                                          if (member.LifeSciencesDate) {
                                            experience = `${moment(
                                              member.LifeSciencesDate
                                            ).from(
                                              date,
                                              true
                                            )} serving Life Sciences`;
                                          }
                                        }

                                        return (
                                          <React.Fragment key={j}>
                                            <tr>
                                              <td>{member.Name}</td>
                                              <td>{member.Title}</td>
                                              <td>{experience}</td>
                                            </tr>
                                            <tr>
                                              <td colSpan="3">
                                                {member.Description}
                                                <br />
                                                <br />
                                              </td>
                                            </tr>
                                          </React.Fragment>
                                        );
                                      })}
                                    </React.Fragment>
                                  );
                                } else {
                                  return false;
                                }
                              })}
                            </tbody>
                          </table>
                        </>
                      ) : ""}
                    <div className="deliverable-toolbar">
                      {/* <Tooltip disableInteractive title="Delete Section">
                        <IconButton
                          size="small"
                          className="delete-section"
                          onClick={(e) => this.props.removeSection(e, i)}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </Tooltip> */}
                      <div></div>
                      <Tooltip disableInteractive title="Add Section Below">
                        <IconButton
                          size="small"
                          className="add-below"
                          onClick={(e) =>
                            this.props.addBelow(e, i, section.deliverable)
                          }
                        >
                          <AddCircleOutlineIcon />
                        </IconButton>
                      </Tooltip>
                    </div>
                  </section>
                </React.Fragment>
              );
            })
          )}
        </div>
        {this.props.approved ? null : (
          <Menu
            open={this.state.contextMenu !== null}
            onClose={this.handleClose}
            anchorReference="anchorPosition"
            anchorPosition={
              this.state.contextMenu !== null
                ? {
                    top: this.state.contextMenu?.mouseY,
                    left: this.state.contextMenu?.mouseX,
                  }
                : undefined
            }
            sx={{
              "& .MuiSvgIcon-root": {
                fontSize: 20,
                // color: theme.palette.text.secondary,
                marginRight: 1,
              },
            }}
          >
            <MenuItem
              onClick={(e) => {
                this.handleClose();
                this.props.addBelow(
                  null,
                  this.state.contextMenu?.index,
                  this.state.contextMenu?.deliverable
                );
              }}
            >
              <AddCircleOutlineIcon />
              Add Section Below
            </MenuItem>
            <MenuItem
              onClick={() => {
                this.handleClose();
                this.props.removeSection(null, this.state.contextMenu?.index);
              }}
            >
              <DeleteIcon color="error" />
              <Typography color="error">Delete Section</Typography>
            </MenuItem>
          </Menu>
        )}
        <footer>
          <Box display="flex" justifyContent="center">
            <span>That's Nice LLC</span>
            <span>
              New York | San Diego | Chicago | London | Frankfurt | Shanghai
            </span>
          </Box>
          <Box display="flex" justifyContent="space-between">
            <span>{this.props.title}</span>
            <span>CONFIDENTIAL</span>
            <span className="page-count" />
          </Box>
        </footer>
      </Grid>
    );
  }
}

Preview.propTypes = {
  title: PropTypes.string,
  lastModified: PropTypes.object,
  company: PropTypes.string,
  version: PropTypes.number,
  content: PropTypes.array,
  reorder: PropTypes.bool,
  onSortEnd: PropTypes.func,
  team: PropTypes.array,
  handlePreview: PropTypes.func,
  editContact: PropTypes.func,
  editSection: PropTypes.func,
  editSectionIndex: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  editSettings: PropTypes.func,
  departments: PropTypes.array,
  logo: PropTypes.array,
  contactHTML: PropTypes.string,
  salesPerson: PropTypes.array,
  addBelow: PropTypes.func,
  addBelowIndex: PropTypes.number,
  docType: PropTypes.string,
};

export default Preview;
