/* eslint-disable no-loop-func */

function calculateTotalPriceWorker() {
  this.addEventListener("message", (e) => {
    let docs = JSON.parse(e.data);
    const toNum = (value, state) => {
      if (state) {
        if (state && state.docType && state.docType === "Gift Estimate") {
          return parseFloat(value, 10);
        } else {
          return parseInt(value, 10);
        }
      } else {
        if (state && state.docType && state.docType === "Gift Estimate") {
          return parseFloat(value, 10);
        } else {
          return parseInt(value, 10);
        }
      }
    };

    const calculate = (state) => {
      let totals = {};
      let totalPT = 0;
      let totalDS = 0;
      let totalPrice = 0;
      let optionalIndex;
      let optionalIndexes = [];
      Object.keys(state).forEach((key) => {
        if (
          key.startsWith("tableName-") &&
          (state[key] !== null || state[key] !== undefined)
        ) {
          if (state[key]?.includes("Optional")) {
            optionalIndex = parseInt(key.replace("tableName-", ""), 10);
            optionalIndexes.push(optionalIndex);
          }
        }
      });

      for (let i = 0; i < state.tables; i++) {
        totals[`${i}-existingSpendTotal`] = 0;
        totals[`${i}-passThroughTotal`] = 0;
        totals[`${i}-newSpendTotal`] = 0;

        let filteredSection = state.content.filter(
          (el) => parseInt(el.table) === i
        );

        filteredSection.forEach((section) => {
          if (section.deliverable) {
            let existingSpend = toNum(section.existingSpend || 0, state),
              passThrough = toNum(section.passThrough, state),
              discount = toNum(section.discount || 0, state),
              quantity = parseInt(section.quantity, 10) || 1,
              price = toNum(section.price || 0, state),
              sectionTotal,
              passThroughDiscount = false;

            if (discount && passThrough && !price) {
              passThroughDiscount = true;
              passThrough =
                toNum(section.passThrough, state) - discount / quantity;
              sectionTotal = quantity ? passThrough * quantity : passThrough;
            } else {
              price = toNum(section.price || 0, state) - discount / quantity;
              sectionTotal = quantity ? price * quantity : price;
            }

            if (passThrough || passThrough === 0) {
            } else if (isNaN(passThrough)) {
              passThrough = 0;
            }

            totalPT = totalPT + passThrough;

            if (!passThroughDiscount) {
              totalDS = totalDS + sectionTotal;
            }

            totals[`${i}-existingSpendTotal`] =
              totals[`${i}-existingSpendTotal`] + existingSpend - existingSpend;
            totals[`${i}-passThroughTotal`] =
              totals[`${i}-passThroughTotal`] + passThrough;
            totals[`${i}-newSpendTotal`] = passThroughDiscount
              ? totals[`${i}-newSpendTotal`]
              : totals[`${i}-newSpendTotal`] + sectionTotal;

            if (optionalIndexes.includes(i)) {
              totalPT = totalPT - passThrough;
              totalDS = totalDS - sectionTotal;
              totalPrice = totalPrice - passThrough - sectionTotal;
            }

            if (section.subsections) {
              section.subsections.forEach((sub) => {
                let subExistingSpend = toNum(sub.existingSpend || 0, state),
                  subPassThrough = toNum(sub.passThrough || 0, state),
                  subPrice = toNum(sub.price || 0, state),
                  subQuantity = parseInt(sub.quantity, 10) || 1,
                  subSectionTotal = subQuantity
                    ? subPrice * subQuantity
                    : subPrice;

                totalPT = totalPT + subPassThrough;

                if (!passThroughDiscount) {
                  totalDS = totalDS + subSectionTotal;
                }

                totals[`${i}-existingSpendTotal`] =
                  totals[`${i}-existingSpendTotal`] +
                  subExistingSpend -
                  subExistingSpend;
                totals[`${i}-passThroughTotal`] =
                  totals[`${i}-passThroughTotal`] + subPassThrough;
                totals[`${i}-newSpendTotal`] =
                  totals[`${i}-newSpendTotal`] + subSectionTotal;

                if (optionalIndexes.includes(i)) {
                  totalPT = totalPT - subPassThrough;
                  totalDS = totalDS - subSectionTotal;
                  totalPrice = totalPrice - subPassThrough - subSectionTotal;
                }
              });
            }
          }
        });
      }

      let totalSum = Object.values(totals).reduce((a, b) => a + b, 0);

      totalDS = totalDS - toNum(state.newSpendDiscount || 0, state);

      totalPrice =
        totalPrice + totalSum - toNum(state.newSpendDiscount || 0, state);

      return {
        totals,
        totalPT,
        totalDS,
        totalPrice,
      };
    };

    docs.map((doc) => {
      let totals = calculate(doc);
      return Object.assign(doc, {
        totalPrice: totals.totalPrice,
        passThrough: totals.totalPT,
        directSpend: totals.totalDS,
      });
    });

    this.postMessage(docs);
  });
}

export default calculateTotalPriceWorker;
