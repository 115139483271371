import axios from "axios";

export function slackMessage(message, alert) {
  if (window.location.hostname === "sow.thatsnice.com") {
    let slackURL;

    if (alert) {
      slackURL =
        "https://hooks.slack.com/services/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";
    } else {
      slackURL =
        "https://hooks.slack.com/services/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";
    }

    axios
      .post(
        slackURL,
        {
          blocks: [
            {
              type: "section",
              text: {
                type: "mrkdwn",
                text: `<https://sow.thatsnice.com/${this.state.docId}|${this.state.doc.Title}>: ${message}`,
              },
            },
          ],
        },
        {
          headers: {
            Accept: "application/json, text/plain, */*",
            "Content-type": "application/x-www-form-urlencoded",
          },
        }
      )
      .then((response) => {
        // console.log("res", response);
      })
      .catch((error) => {
        console.error("err", error);
      });
  } else {
    console.log("send slack message:", message);
  }
}
