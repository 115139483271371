import React from "react";
import {
  Box,
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
} from "@material-ui/core";
import LoadingButton from "@material-ui/lab/LoadingButton";
import DeleteIcon from "@material-ui/icons/Delete";
import WarningIcon from "@material-ui/icons/Warning";

export default function Confirm(props) {
  let color = props.color || "error";
  let showChip = false;

  if (props.highlight === "Sent to Client") {
    showChip = "info";
  } else if (props.highlight === "Approved") {
    showChip = "success";
  }

  return (
    <div>
      <Dialog
        open={props.open}
        onClose={props.closeDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title" disableTypography>
          <Box display="flex" alignItems="center">
            {props.title.includes("Delete") ? (
              <DeleteIcon color="error" />
            ) : (
              <WarningIcon color={color} />
            )}
            &ensp;
            <Typography variant="h6" color={color}>
              {props.title}
            </Typography>
          </Box>
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            id="alert-dialog-description"
            sx={
              showChip
                ? {
                    display: "flex",
                    alignItems: "center",
                  }
                : {}
            }
          >
            {props.text}
            {showChip ? (
              <>
                &nbsp;
                <Chip
                  color={showChip}
                  label={props.highlight}
                  style={{ height: "24px" }}
                />
              </>
            ) : (
              props.highlight && (
                <Typography variant="p" color="primary">
                  {props.highlight}
                </Typography>
              )
            )}
            ?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={props.closeDialog} color="secondary">
            {props.cancelLabel || "Cancel"}
          </Button>
          <LoadingButton
            id="modal-confirm"
            onClick={props.confirmAction}
            color={color}
            style={{ minWidth: "81px" }}
            pending={props.inFlight}
          >
            {props.confirmLabel}
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </div>
  );
}
