import sections from "./defaultSectionData";
import Logo from "../../../images/tn_logo.svg";

export const defaultSections = [
  sections.objective,
  sections.why,
  sections.execution,
  sections.overview,
  sections.activities,
  sections.payments,
  sections.serviceDescriptions,
  sections.initialPlan,
  sections.about,
  sections.terms,
  sections.banking,
];

export const defaultState = {
  doc: undefined,
  docId: null,
  version: null,
  error: null,
  content: defaultSections,
  dirty: false,
  company: "[Company Name]",
  type: null,
  year: null,
  showForm: false,
  edit: false,
  editorHTML:
    "<p>Enter copy here. Insert the company name using the 'Insert Company' button above or by manually typing the code [Company Name].</p>",
  deliverableCategories: [],
  totalPrice: 0,
  newPaymentSplit: "",
  paymentArray: [],
  newUponApproval: false,
  newSpendDiscount: "",
  formNewSpendDiscount: "",
  contactHTML:
    '<p><span className="small"><strong>To:<br /></strong></span>Contact Name<br />Contact Title<br />contact@email.com</p><p><span className="small"><strong>Main Contact:<br /></strong></span>Contact Name<br />Contact Title<br />contact@email.com</p><p>Address</p><p>Tel: 123-456-7890</p><p>www.website.com</p>',
  searching: false,
  documents: [],
  limit: 10,
  departments: [
    "Strategy and Consulting",
    "Research",
    "Scientific Content",
    "Creative",
    "Project Management",
    "Event Management",
    "Web / Digital",
  ],
  tables: 1,
  totals: {},
  subTotals: [],
  loaded: false,
  fromMember: [],
  salesPerson: null,
  accountManagement: null,
  loadingPDF: false,
  progress: 0,
  saving: false,
  revisionIndex: null,
  historyArray: [],
  historyIndex: 0,
  undoRedo: false,
  revisionLimit: 5,
  loadingMoreRevisions: false,
  newManualApprove: false,
  attachedFiles: [],
  uploading: false,
  approved: false,
  deliverableNumber: 1,
  hasError: false,
  selectedRows: [],
  token: null,
  canGenerate: true,
  link: "",
  showCompanyLink: false,
  options: false,
  settings: false,
  approvalName: "",
  approvalTitle: "",
  approvalCompany: "",
  approvalEmail: "",
  preview: false,
  cloning: false,
  batchCloning: false,
  sentToClientConfirm: false,
  sentCallback: null,
  approveConfirm: false,
  unapproveConfirm: false,
  attachmentConfirm: false,
  takeOverConfirm: false,
  takeOverName: null,
  deleteFileInfo: null
};

export function injectDocState(doc, callback) {
  Object.keys(this.state).forEach((key) => {
    if (
      key.startsWith("tableName-") ||
      key.startsWith("price-") ||
      key.startsWith("date-")
    ) {
      delete this.state[key];
    }
  });

  let tableNames = {};
  // console.log('inject this state', doc);

  Object.keys(doc).forEach((key) => {
    if (key.startsWith("tableName-")) {
      tableNames[key] = doc[key];
    }
  });

  let fromMember = doc.fromMember || [];
  // inject old from members
  for (let key in doc) {
    if (key.startsWith("from") && doc[key] === true && !fromMember.length) {
      if (key.replace("from", "") === "Nigel") {
        fromMember.push({
          label: "Nigel Walker",
          value: "5d3f5acdbb93c625ea71c798",
        });
      } else if (key.replace("from", "") === "Gwenael") {
        fromMember.push({
          label: "Gwenael Servant",
          value: "5e0b7ef58dc3d4b4f8466f27",
        });
      } else if (key.replace("from", "") === "Guy") {
        fromMember.push({
          label: "Guy Tiene",
          value: "5d3f5e02bb93c625ea71c79a",
        });
      } else if (key.replace("from", "") === "Mark") {
        fromMember.push({
          label: "Mark Allen",
          value: "5d3f5cd0bb93c625ea71c799",
        });
      } else if (key.replace("from", "") === "Wei") {
        fromMember.push({
          label: "Wei Gao",
          value: "5d3f6037bb93c625ea71c7a0",
        });
      } else if (key.replace("from", "") === "Yiyi") {
        fromMember.push({
          label: "Yiyi Liu",
          value: "5d3f605cbb93c625ea71c7a1",
        });
      } else if (key.replace("from", "") === "Lulu") {
        fromMember.push({
          label: "Shiyun (Lulu) Ruan",
          value: "5d93852a0b8aca89db6987b9",
        });
      }
    }
  }

  fromMember = [...new Set(fromMember.map((o) => JSON.stringify(o)))].map((s) =>
    JSON.parse(s)
  );

  let salesPerson = doc.salesPerson || [];

  // salesPerson = salesPerson.concat(fromMember)
  //
  // salesPerson = [
  //   ...new Set(salesPerson.map((o) => JSON.stringify(o))),
  // ].map((s) => JSON.parse(s));

  this.setState(
    {
      doc,
      ...doc.customPrices,
      ...doc.customDates,
      ...doc.team,
      ...tableNames,
      title: doc.Title,
      docNum: doc.docNum,
      newDocNum: doc.docNum,
      version: doc.version || 1,
      newTitle: doc.Title,
      crmCompany: doc.crmCompany,
      newCrmCompany: doc.crmCompany,
      company: (doc.crmCompany && doc.crmCompany.label) || doc.company,
      newCompany: (doc.crmCompany && doc.crmCompany.label) || doc.company,
      logo: doc.logo,
      content: doc.content || defaultSections,
      type: doc.type,
      newType: doc.type,
      quarter: doc.quarter,
      newQuarter: doc.quarter,
      custom: doc.custom,
      newCustom: doc.custom,
      docType: doc.docType || "Strategic Plan",
      newDocType: doc.docType,
      year: doc.year,
      newYear: doc.year,
      date: doc.date ? new Date(doc.date) : null,
      // newDate: doc.date ? new Date(doc.date) : null,
      customDate: doc.customDate || null,
      newCustomDate: doc.customDate || null,
      sigDate: doc.sigDate ? new Date(doc.sigDate) : null,
      newSigDate: doc.sigDate ? new Date(doc.sigDate) : null,
      currency: doc.currency || "USD",
      newCurrency: doc.currency,
      logoInternal: doc.logoInternal || Logo,
      newLogoInternal: doc.logoInternal,
      address: doc.address || "New York",
      newAddress: doc.address,
      startDate: doc.startDate ? new Date(doc.startDate) : null,
      newStartDate: doc.startDate ? new Date(doc.startDate) : null,
      endDate: doc.endDate ? new Date(doc.endDate) : null,
      newEndDate: doc.endDate ? new Date(doc.endDate) : null,
      paymentSplit: doc.paymentSplit,
      newPaymentSplit: doc.paymentSplit || "",
      uponApproval: doc.uponApproval,
      newUponApproval: doc.uponApproval || false,
      newSpendDiscount: doc.newSpendDiscount || "",
      formNewSpendDiscount: doc.newSpendDiscount || "",
      contactHTML: doc.contactHTML || defaultState.contactHTML,
      team: doc.team,
      tables: doc.tables || 1,
      rates: doc.rates,
      fromMember: fromMember,
      newFromMember: fromMember,
      salesPerson: salesPerson,
      newSalesPerson: salesPerson,
      accountManagement: doc.accountManagement,
      newAccountManagement: doc.accountManagement,
      attachedFiles: doc.attachedFiles || [],
      approved: doc.approved,
      approvedBy: doc.approvedBy,
      sentToClient: doc.sentToClient,
      token: doc.token || null,
      link: `${window.location.origin + window.location.pathname}?token=${
        doc.token
      }`,
      rejected: doc.rejected,
      revised: doc.revised,
      teamworkID: doc.teamworkID,
      eApproved: doc.eApproved,
      approvalCompany: doc.approvalCompany || doc.company,
      lastModified: doc.lastModified,
      subTotals: doc.subTotals || [],
      // showCompanyLink: !doc.crmCompany && this.state.isAdmin ? true : false
    },
    () => {
      document.title = this.state.title;
      this.fetchCurrencyRates();

      if (this.props.token) {
        // console.log(this.state.approvedBy);
        if (
          (this.state.approvedBy && !this.state.approvedBy.ip) ||
          !this.state.approvedBy
        ) {
          this.getLocAndIP();
        }
      }

      if (callback) {
        callback();
      }
    }
  );
}

export function injectPayment() {
  try {
    if (document.querySelector("#steps .payments-table")) {
      let paymentsTable = document.querySelector("#steps .payments-table")
        .parentNode.innerHTML;
      for (let table of document.querySelectorAll(
        "#document .payments-table"
      )) {
        table.innerHTML = paymentsTable;
      }
    } else {
      for (let table of document.querySelectorAll(
        "#document .payments-table"
      )) {
        table.innerHTML = "";
      }
    }
  } catch (err) {
    // console.log(err);
  }
}

export function injectDeliverables() {
  // console.log("inject deliverables");

  for (let [i, item] of document
    .querySelectorAll("#steps .deliverable-item")
    .entries()) {
    item.querySelector("th").textContent = i + 1;
  }

  try {
    // for (let group of document.querySelectorAll('.table-group')) {
    //   group.classList.remove('.optional-group')
    // }

    if (document.querySelector("#generated-tables .optional-table")) {
      // let optionalTable = document.querySelector(
      //   "#generated-tables .optional-table"
      // );
      //
      // optionalTable.closest('.table-group').classList.add('optional-group')
      //
      // let optionalGroup = document.querySelector(
      //   "#generated-tables .optional-group"
      // );
      // if (
      //   document
      //     .querySelector("#generated-tables .optional-table")
      //     ?.nextSibling.classList.contains("section-total")
      // ) {
      //   let optionalTableTotal = document.querySelector(
      //     "#generated-tables .optional-table"
      //   ).nextSibling;
      //   optionalGroup.parentNode.appendChild(optionalGroup);
      //   // optionalGroup.parentNode.appendChild(optionalTableTotal);
      // } else {
      //   optionalGroup.parentNode.appendChild(optionalGroup);
      // }
    } else {
      if (
        document
          .querySelector("#generated-tables .sow-total")
          ?.nextElementSibling?.id.includes("table-")
      ) {
        let id = document.querySelector("#generated-tables .sow-total")
          .nextElementSibling.id;
        let idNum = parseInt(id.split("-")[1], 10);

        let moveTable = document.querySelector(`#generated-tables #${id}`);
        let moveTableTotal = document.querySelector(`#generated-tables #${id}`)
          .nextSibling;

        document
          .querySelector(
            `#generated-tables #table-${idNum - 1} + .section-total`
          )
          .after(moveTable);
        document
          .querySelector(`#generated-tables #${id}`)
          .after(moveTableTotal);
      }
    }
  } catch (err) {
    console.error("error with optional", err);
  }

  let allTotals = [];

  for (let table of document.querySelectorAll(
    "#generated-tables .sub-total.total-table"
  )) {
    table.classList.add("hidden");
  }

  for (let table of document.querySelectorAll(
    "#generated-tables .deliverables-table"
  )) {
    let title = table.querySelector("thead th:first-of-type").textContent;

    let phase = title.match(
      /Phase[ ]\bM{0,4}(CM|CD|D?C{0,3})(XC|XL|L?X{0,3})(IX|IV|V?I{0,3})\b/g
    );

    // let slug = phase[0] ? phase[0].toLowerCase().replace(/ /, "-") : null;
    let slug = phase && phase[0] ? phase[0] : null;

    // console.log(slug);

    if (slug) {
      allTotals.push({
        id: table.id,
        phase: slug,
        price:
          parseFloat(
            table.nextSibling
              .querySelector(".has-value.currency")
              ?.textContent.replace(/,/g, "")
          ) || 0,
      });
    }
  }

  if (allTotals.length) {
    for (let item of allTotals) {
      let phase = item.phase;

      let samePhase = allTotals.filter((p) => p.phase === phase);
      let total = samePhase.reduce((a, b) => a + b.price, 0);

      if (total) {
        total = this.formatCurrency(total);
      } else {
        total = "Free";
      }

      let lastTable = samePhase[samePhase.length - 1];

      // console.log(lastTable.id, total)
      try {
        document
          .getElementById(lastTable.id)
          .nextSibling.nextSibling.querySelector("td:first-of-type").textContent =
          phase + " Total";
        document
          .getElementById(lastTable.id)
          .nextSibling.nextSibling.querySelector("td:first-of-type")
          .classList.remove("editable");
        document
          .getElementById(lastTable.id)
          .nextSibling.nextSibling.querySelector(".currency").textContent = total;
        document
          .getElementById(lastTable.id)
          .nextSibling.nextSibling.classList.remove("hidden");
      } catch (err) {}
    }
  }

  // let subTotals = document.querySelectorAll(
  //   "#generated-tables .sub-total.total-table"
  // );
  //
  // if (subTotals.length) {
  //   let allTotals = [];
  //   for (let table of document.querySelectorAll(
  //     "#generated-tables .section-total"
  //   )) {
  //     allTotals.push(
  //       parseFloat(
  //         table
  //           .querySelector(".has-value.currency")
  //           .textContent.replace(/,/g, "")
  //       ) || 0
  //     );
  //   }
  //
  //   // console.log('ALL', allTotals)
  //   // console.log('SUB', subTotals)
  //
  //   for (let table of subTotals) {
  //     // checkPrev(table.previousSibling, table.dataset.id)
  //     let subTotals = this.state.subTotals.sort((a, b) =>
  //       a.index > b.index ? 1 : -1
  //     );
  //
  //     // console.log(subTotals);
  //
  //     let index = parseInt(table.dataset.id);
  //     let prevIndex = subTotals.findIndex(f => f.index === index) - 1;
  //     let nextIndex = subTotals.findIndex(f => f.index === index) + 1;
  //
  //
  //
  //     let spliced = [...allTotals];
  //     spliced = spliced.splice(subTotals[prevIndex]?.index + 1 || 0,  index + 1);
  //     console.log(index, spliced);
  //
  //     document.querySelector(
  //       `.total-table.sub-total[data-id="${index}"] .has-value`
  //     ).innerHTML = this.formatCurrency(spliced.reduce((a, b) => a + b, 0))
  //   }
  // }

  // function checkPrev(el, index) {
  //   let subtotal = 0;
  //   try {
  //     if (el) {
  //       el.classList.add("checked");
  //       if (!el.classList.contains("section-total")) {
  //         checkPrev(el.previousSibling);
  //         // } else if (el.classList.contains('sub-total', 'total-table')) {
  //         // } else if (el.matches('.sub-total.total-table')) {
  //         //   return false
  //       } else if (el.classList.contains("section-total")) {
  //         let total = parseFloat(
  //           el
  //             .querySelector(".has-value.currency")
  //             .textContent.replace(/,/g, "")
  //         );
  //         subtotal = subtotal + total;
  //         console.log(
  //           index,
  //           el.querySelector(".has-value.currency").textContent
  //         );
  //         checkPrev(el.previousSibling);
  //       }
  //     }
  //   } catch (err) {}
  //
  //   try {
  //     document.querySelector(
  //       `.total-table.sub-total[data-id="${index}"] .has-value`
  //     ).innerHTML = subtotal;
  //   } catch (err) {}
  // }

  try {
    let deliverablesTables = document.querySelectorAll(
      "#generated-tables .table"
    );
    let previewTable = document.querySelector("#document .deliverables-table");
    previewTable.innerHTML = "";

    for (let table of deliverablesTables) {
      previewTable.append(table.cloneNode(true));
    }

    this.totalWidth();

    this.makeOrange(
      document.querySelectorAll(
        "#content .deliverables-table .deliverable-item a"
      )
    );
  } catch (err) {
    // console.log(err);
  }
}
