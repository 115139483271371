import * as React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Paper,
  Chip,
  Tooltip,
} from "@material-ui/core";
import { visuallyHidden } from "@material-ui/utils";
import { FixedSizeList as List } from "react-window";
import moment from "moment";
import _ from "lodash";
import { Link } from "react-router-dom";

const cellHeight = 33;

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
    "& table, .MuiTable-root": {
      "& tbody > div, .MuiTableBody-root > div": {
        overflowX: "hidden !important",
      },
      "& tr > *, .MuiTableRow-root > *": {
        "&:first-child": {
          flex: 1,
        },
        "&:nth-child(2)": {
          width: 140,
        },
        "&:nth-child(3)": {
          width: 180,
        },
        "&:nth-child(4)": {
          width: 180,
        },
        "&:nth-child(5)": {
          width: 110,
        },
        "&:last-child": {
          width: 140,
        },
      },
    },
  },
  table: {
    "& .MuiTableCell-head": {
      whiteSpace: "nowrap",
    },
    "& tbody span:not([class])": {
      opacity: 0.5,
    },
    "& .MuiChip-root": {
      width: "100%",
    },
  },
  name: {
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    // maxWidth: 400,
  },
  row: {
    display: "flex",
  },
  chipContainer: {
    pt: 0,
    pb: 0,
    display: "flex",
    alignItems: "center",
  },
  sortSpan: visuallyHidden,
}));

function SOWListHead(props) {
  const { classes, order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead component="div">
      <TableRow style={{ display: "flex" }} component="div">
        {props.headCells.map(
          (headCell) =>
            !!headCell.visible && (
              <TableCell
                component="div"
                key={headCell.id}
                align={headCell.numeric ? "right" : "left"}
                sortDirection={orderBy === headCell.id ? order : false}
              >
                <TableSortLabel
                  active={orderBy === headCell.id}
                  direction={orderBy === headCell.id ? order : "asc"}
                  onClick={createSortHandler(headCell.id)}
                >
                  {headCell.label}
                  {orderBy === headCell.id ? (
                    <span className={classes.sortSpan}>
                      {order === "desc"
                        ? "sorted descending"
                        : "sorted ascending"}
                    </span>
                  ) : null}
                </TableSortLabel>
              </TableCell>
            )
        )}
      </TableRow>
    </TableHead>
  );
}

SOWListHead.propTypes = {
  classes: PropTypes.object.isRequired,
  // numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  // onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

function renderStatusChip(param) {
  let color;

  switch (param) {
    case "Approved":
      color = "success";
      break;
    case "Sent to Client":
      color = "info";
      break;
    case "Rejected":
      color = "error";
      break;
    case "Revised":
      color = "secondary";
      break;
    default:
      break;
  }

  return (
    <Chip
      size="small"
      color={color}
      label={param}
      style={{ visibility: color ? "visible" : "hidden" }}
    />
  );
}

export default function SOWList(props) {
  let { headCells, rows, formatCurrency, height } = props;
  const classes = useStyles();
  const [order, setOrder] = React.useState("desc");
  const [orderBy, setOrderBy] = React.useState("number");

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  rows = _.orderBy(rows, orderBy, order);

  const overallHeight = height;
  const listHeight = rows.length * cellHeight;

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <TableContainer>
          <Table
            id="sow-list"
            className={classes.table}
            size="small"
            component="div"
          >
            <SOWListHead
              classes={classes}
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
              headCells={headCells}
            />
            <TableBody component="div">
              <List
                height={listHeight > overallHeight ? height : listHeight}
                itemCount={rows.length}
                itemSize={cellHeight}
                width="100%"
              >
                {({ index, style }) => {
                  let row = rows[index];
                  return (
                    <TableRow
                      hover
                      tabIndex={-1}
                      key={`row-${index}`}
                      className={`${classes.row} ${row.currency}`}
                      style={style}
                      component="div"
                    >
                      <TableCell
                        component="div"
                        id={`sow-list-table-${index}`}
                        scope="row"
                        className={classes.name}
                      >
                        <Link to={`/${row._id.toString()}`}>
                          <span className="doc-title">{row.Title}</span>
                        </Link>
                      </TableCell>

                      <TableCell
                        className={classes.chipContainer}
                        component="div"
                      >
                        {renderStatusChip(row.status)}
                      </TableCell>

                      <TableCell component="div">
                        {row.displaySalesPerson}
                      </TableCell>

                      <TableCell component="div">
                        {row.displayAccountManagement}
                      </TableCell>

                      <TableCell component="div">
                        {moment(row.displayDate).format("L")}
                      </TableCell>

                      <TableCell
                        component="div"
                        align="right"
                        className="currency has-value"
                        style={{
                          marginRight:
                            rows.length * cellHeight > height ? "-8px" : 0, // account for scrollbar
                        }}
                      >
                        <Tooltip
                          arrow
                          placement="left"
                          title={
                            <table className="cost-breakdown">
                              <tbody>
                                <tr>
                                  <td>Pass Through:&ensp;</td>
                                  <td className="currency">
                                    {formatCurrency(
                                      parseFloat(row.passThrough).toFixed(2)
                                    )}
                                  </td>
                                </tr>
                                <tr>
                                  <td>Direct Spend:&ensp;</td>
                                  <td className="currency">
                                    {formatCurrency(
                                      parseFloat(row.directSpend).toFixed(2)
                                    )}
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          }
                        >
                          <span>
                            {formatCurrency(
                              parseFloat(row.totalPrice).toFixed(2)
                            )}
                          </span>
                        </Tooltip>
                      </TableCell>
                    </TableRow>
                  );
                }}
              </List>
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </div>
  );
}
