/**
 *
 * Admin functions
 *
 */

import axios from "axios";
import _ from "lodash";
var ObjectID = require("bson-objectid");

export const defaultMember = {
  memberId: "",
  memberName: "",
  memberTitle: "",
  memberEmail: "",
  memberDescription: "",
  memberDepartment: "",
  memberExperience: "",
  memberStartDate: null,
  memberPharmaDate: null,
  userEmail: "",
};

export function onFocus() {
  if (this.props.superAdmin && this.state.tab === "permissions") {
    this.fetchRules();
  }

  if (this.state.tab === "team") {
    this.fetchTeam();
  }
}

export function handleTabChange(e, value) {
  this.setState(
    {
      tab: value,
    },
    () => {
      if (value === "permissions") {
        this.fetchRules();
      }
    }
  );
}

export function fetchTeam() {
  // console.log("fetch team");
  this.props.db
    .collection("member")
    .find(
      {},
      {
        // limit: this.state.limit,
        sort: { Name: 1 },
      }
    )
    .asArray()
    .then((members) => {
      // console.log("members", members);

      this.setState({ members });
    })
    .catch(console.error);
}

export function fetchActiveUsers() {
  // console.log("fetch active");
  axios.get("/api/getUsers").then((res) => {
    let activeUsers = [];

    for (let [user, data] of Object.entries(res.data)) {
      activeUsers.push({ id: user, data });
    }

    this.setState({
      activeUsers: activeUsers.length ? activeUsers : null,
    });
  });
}

export function kickUser(user) {
  axios
    .get("api/kickUser", {
      params: {
        email: user,
        requester: this.props.user.email,
      },
    })
    .then((res) => {
      // console.log(res);
    });
}

export function fetchRules() {
  // console.log("fetch rules");
  axios.get("/api/getRules").then((res) => {
    // console.log("rules", res.data);

    this.setState(
      {
        rulesAccess: res.data.find((rule) => rule.id === this.state.accessId)
          .script,
        rulesRoles: res.data.find((rule) => rule.id === this.state.rolesId)
          .script,
      },
      () => {
        let extractedUsers = JSON.stringify(this.state.rulesAccess)
          .match(/whitelist = \[(.*?)\]/)[1]
          .match(/'(.*?)'/g);
        let extractedAdmins = JSON.stringify(this.state.rulesRoles)
          .match(/whitelist = \[(.*?)\]/)[1]
          .match(/'(.*?)'/g);
        let extractedSuperAdmins = JSON.stringify(this.state.rulesRoles)
          .match(/superAdmin = \[(.*?)\]/)[1]
          .match(/'(.*?)'/g);
        let users = [];
        let admins = [];
        let superAdmins = [];
        let roleArray = [];

        for (let user of extractedUsers) {
          users.push(user.replace(/'/g, ""));
        }

        for (let admin of extractedAdmins) {
          admins.push(admin.replace(/'/g, ""));
        }

        for (let superAdmin of extractedSuperAdmins) {
          superAdmins.push(superAdmin.replace(/'/g, ""));
        }

        for (let user of users) {
          let role = "User";

          if (superAdmins.includes(user)) {
            role = "Super Admin";
          } else if (admins.includes(user)) {
            role = "Admin";
          }

          roleArray.push({
            id: user,
            role,
            meta: this.getUserInfo(user),
          });
        }

        this.setState(
          {
            users: users.sort(),
            admins: admins.sort(),
            superAdmins: _.orderBy(superAdmins, ["id"], ["asc"]),
            roleArray,
          },
          () => {}
        );
      }
    );
  });
}

export function updateRoleArray() {
  let roleArray = [...this.state.roleArray];

  let updatedRoleArray = roleArray.map((user) => {
    let role = "User";

    if (this.state.superAdmins.includes(user.id)) {
      role = "Super Admin";
    } else if (this.state.admins.includes(user.id)) {
      role = "Admin";
    }

    return {
      ...user,
      role,
    };
  });

  this.setState({
    roleArray: _.orderBy(updatedRoleArray, ["id"], ["asc"]),
  });
}

export function saveUsers() {
  let updatedRule = JSON.stringify(this.state.rulesAccess).replace(
    /whitelist = \[(.*?)\]/,
    `whitelist = ${JSON.stringify(this.state.users).replace(/"/g, "'")}`
  );
  // console.log(JSON.stringify(this.state.rulesAccess));
  // console.log(JSON.parse(updatedRule));

  axios
    .get("/api/saveRule", {
      params: {
        name: this.state.accessId,
        script: JSON.parse(updatedRule),
      },
    })
    .then((res) => {
      this.fetchRules();
    });
}

export function saveRoles() {
  let updatedRule = JSON.stringify(this.state.rulesRoles)
    .replace(
      /whitelist = \[(.*?)\]/,
      `whitelist = ${JSON.stringify(this.state.admins).replace(/"/g, "'")}`
    )
    .replace(
      /superAdmin = \[(.*?)\]/,
      `superAdmin = ${JSON.stringify(this.state.superAdmins).replace(
        /"/g,
        "'"
      )}`
    );

  axios
    .get("/api/saveRule", {
      params: {
        name: this.state.rolesId,
        script: JSON.parse(updatedRule),
      },
    })
    .then((res) => {
      this.showNotification("success", "Role updated!");
      this.fetchRules();
    });
}

export function editMember(id) {
  let member = this.state.members.find(
    (m) => ObjectID(m._id).toString() === ObjectID(id).toString()
  );

  if (member.hasOwnProperty("_id")) {
    this.setState({
      showEditMember: true,
      memberId: ObjectID(member._id).toString(),
      memberName: member.Name,
      memberTitle: member.Title,
      memberEmail: member.Email,
      memberDescription: member.Description,
      memberDepartment: member.Department,
      memberExperience: member.Experience,
      memberStartDate: member.StartDate,
      memberPharmaDate: member.PharmaDate,
    });
  } else {
    this.addMember();
  }
}

export function addMember() {
  this.setState({
    showEditMember: true,
    ...defaultMember,
  });
}

export function saveMember(e) {
  e.preventDefault();
  if (this.state.memberId) {
    this.props.db
      .collection("member")
      .updateOne(
        {
          _id: ObjectID(this.state.memberId),
        },
        {
          $set: {
            Name: this.state.memberName,
            Title: this.state.memberTitle,
            Email: this.state.memberEmail,
            Description: this.state.memberDescription,
            Department: this.state.memberDepartment,
            Experience: this.state.memberExperience,
            StartDate: this.state.memberStartDate,
            PharmaDate: this.state.memberPharmaDate,
          },
        }
      )
      .then(() => {
        this.setState(
          {
            showEditMember: false,
          },
          () => {
            this.fetchTeam();
          }
        );
      });
  } else {
    if (this.state.memberName && this.state.memberName.trim().length) {
      this.props.db
        .collection("member")
        .insertOne({
          Name: this.state.memberName,
          Title: this.state.memberTitle,
          Email: this.state.memberEmail,
          Description: this.state.memberDescription,
          Department: this.state.memberDepartment,
          Experience: this.state.memberExperience,
          StartDate: this.state.memberStartDate,
          PharmaDate: this.state.memberPharmaDate,
        })
        .then(() => {
          this.setState(
            {
              showEditMember: false,
            },
            () => {
              this.fetchTeam();
            }
          );
        });
    }
  }
}

export function cancelEditMember() {
  this.setState({
    showEditMember: false,
  });
}

export function addUser(e) {
  e.preventDefault();

  if (this.state.userEmail.endsWith("@thatsnice.com")) {
    // console.log("valid");

    if (this.state.users.includes(this.state.userEmail)) {
      this.showNotification("error", `This user already exists!`);
      return false;
    }

    let users = [...this.state.users];

    users.push(this.state.userEmail);

    let roleArray = [...this.state.roleArray];

    roleArray.push({
      id: this.state.userEmail,
      role: "User",
    });

    this.setState(
      {
        users: users.sort(),
        roleArray: _.orderBy(roleArray, ["id"], ["asc"]),
        userEmail: "",
      },
      () => {
        this.saveUsers();
        this.showNotification("success", `User added!`);
      }
    );
  } else {
    this.showNotification("error", `Enter a valid That's Nice email!`);
  }
}

export function removeUser(user) {
  if (!user) {
    console.error("no user id provided");
  }

  let users = [...this.state.users];
  users = users.filter((u) => u !== user);

  let roleArray = [...this.state.roleArray];
  roleArray = roleArray.filter((u) => u.id !== user);

  this.setState(
    {
      users: users.sort(),
      roleArray: roleArray,
      removeConfirm: false,
    },
    () => {
      this.saveUsers();
      this.showNotification("success", `User removed!`);
    }
  );
  // this.dialog.show({
  //   title: `Remove User`,
  //   body: `Are you sure want to revoke access for ${user}?`,
  //   actions: [
  //     Dialog.Action("Cancel", () => {
  //       this.dialog.hide();
  //       return false;
  //     }),
  //     Dialog.Action(
  //       "Revoke Access",
  //       () => {
  //
  //       },
  //       "btn-danger"
  //     ),
  //   ],
  //   bsSize: "medium",
  //   onHide: (dialog) => {
  //     // dialog.hide();
  //   },
  // });
}

export function handleMenuOpen(user) {
  setTimeout(() => {
    let menu = document.querySelector(`#${user} .react-select__menu`);
    let styles = getComputedStyle(menu);
    if (styles.top.includes("-")) {
      menu.classList.add("bottom");
    } else {
      menu.classList.add("top");
    }
  }, 0);
}

export function handleRoleChange(user, role) {
  // console.log(user, role);

  if (role.value === "User") {
    this.setState(
      {
        admins: this.state.admins.filter((u) => u !== user),
        superAdmins: this.state.superAdmins.filter((u) => u !== user),
      },
      () => {
        this.updateRoleArray();
        this.saveRoles();
      }
    );
  } else if (role.value === "Admin") {
    let updatedAdmins = [...this.state.admins];

    if (!this.state.admins.includes(user)) {
      updatedAdmins.push(user);
    }

    this.setState(
      {
        admins: updatedAdmins.sort(),
        superAdmins: this.state.superAdmins.filter((u) => u !== user),
      },
      () => {
        this.updateRoleArray();
        this.saveRoles();
      }
    );
  } else if (role.value === "Super Admin") {
    let updatedSuperAdmins = [...this.state.superAdmins];

    if (!this.state.superAdmins.includes(user)) {
      updatedSuperAdmins.push(user);
    }

    this.setState(
      {
        admins: this.state.admins.filter((u) => u !== user),
        superAdmins: _.orderBy(updatedSuperAdmins, ["id"], ["asc"]),
      },
      () => {
        this.updateRoleArray();
        this.saveRoles();
      }
    );
  }
}
