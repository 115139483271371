import moment from "moment";

export function savePayment(e) {
  e.preventDefault();

  let duration = moment(this.state.newEndDate)
    .add(1, "days")
    .diff(moment(this.state.newStartDate).startOf("month"), "months");

  // console.log("duration", duration);

  // if (!this.state.totalPrice) {
  //   this.showNotification(
  //     "error",
  //     `Deliverables currently have no pricing!`
  //   );
  //   return false;
  // }

  if (
    this.state.customPriceTotal &&
    parseFloat(this.state.totalPrice.toFixed(2)) !==
      parseFloat(this.state.customPriceTotal.toFixed(2))
  ) {
    this.showNotification("error", `Prices in Payment table don't add up!`);
    return false;
  } else if (
    parseInt(this.state.newPaymentSplit, 10) !== 0 &&
    (duration / parseInt(this.state.newPaymentSplit, 10)) % 1 !== 0
  ) {
    this.showNotification("error", `Unable to split payments equally!`);
    this.setState({
      paymentsUneven: true,
      // paymentsWarning: true
    });
    return false;
  } else {
    this.setState(
      {
        startDate: this.state.newStartDate,
        endDate: this.state.newEndDate,
        paymentSplit: this.state.newPaymentSplit,
        uponApproval: this.state.newUponApproval,
        paymentsUneven: false,
        paymentsWarning: false,
      },
      () => {
        let newState = {};

        Object.keys(this.state).forEach((key) => {
          if (key.startsWith("price-")) {
            newState[key] = this.state[key];
          }
        });

        let newDateState = {};

        Object.keys(this.state).forEach((key) => {
          if (key.startsWith("date-")) {
            newDateState[key] = this.state[key];
          }
        });

        this.setState(
          {
            customPrices: newState,
            customPriceIndex: null,
            customPriceTotal: "",
            customDates: newDateState,
            customDateIndex: null,
            customPaymentDate: false,
            paymentsWarning: false,
            customPrice: false,
          },
          () => {
            this.injectPayment();
            this.injectVariables();

            try {
              document
                .querySelector("#document .payments-table")
                .closest("section")
                .scrollIntoView();
            } catch (err) {}
          }
        );
      }
    );
  }
}

export function resetCustomPrices() {
  let newState = {};

  Object.keys(this.state).forEach((key) => {
    if (key.startsWith("price-")) {
      newState[key] = "";
    }
  });

  this.setState(newState);
  this.setState({
    customPriceIndex: null,
    customPriceTotal: "",
    paymentsWarning: false,
    customPrice: false,
    undoRedo: false,
  });
}

export function cancelCustomPrices() {
  this.setState({
    customPriceIndex: null,
    customPrice: false,
  });
}

export function resetCustomDates() {
  let newState = {};

  Object.keys(this.state).forEach((key) => {
    if (key.startsWith("date-")) {
      newState[key] = "";
    }
  });

  this.setState(newState);
  this.setState({
    customDateIndex: null,
    paymentsWarning: false,
    customPaymentDate: false,
    undoRedo: false,
  });
}

export function cancelCustomDates() {
  this.setState({
    customDateIndex: null,
    customPaymentDate: false,
  });
}

export function calculatePayments() {
  // console.log("calc payments");

  let paymentArray = [],
    pricePer = (
      this.state.totalPrice / parseInt(this.state.paymentSplit)
    ).toFixed(2),
    paymentSplit = parseInt(this.state.paymentSplit),
    pricePerRounded = pricePer.toString().split(".")[0],
    duration = moment(this.state.newEndDate)
      .add(1, "days")
      .diff(moment(this.state.newStartDate).startOf("month"), "months"),
    // inc = Math.floor(parseInt(duration)/paymentSplit) || 1
    inc = parseInt(duration) / paymentSplit || 1;

  // console.log(duration, inc);

  for (let i = 1; i <= duration; i += inc) {
    let format;

    if (this.state.uponApproval) {
      format = "MMMM [1st], YYYY";
    } else {
      format = "MMMM Do, YYYY";
    }

    let period = {
      due: moment(this.state.newStartDate)
        .add(i - 1, "month")
        .format(format),
      price:
        i > 1
          ? pricePerRounded
          : this.state.docType === "Gift Estimate"
          ? (
              this.state.totalPrice -
              pricePerRounded * (paymentSplit - 1)
            ).toFixed(2)
          : this.state.totalPrice - pricePerRounded * (paymentSplit - 1),
    };
    paymentArray.push(period);
  }

  this.setState(
    {
      paymentArray: paymentArray,
    },
    () => {
      this.injectPayment();
    }
  );
}

export function addCustomPrice(e, i) {
  e.stopPropagation();
  let price = this.stringToNum(e.target.textContent);
  this.setState({
    customPrice: true,
    customPriceIndex: i,
    [`price-${i}`]: price,
    undoRedo: false,
  });
}

export function addCustomDate(e, i) {
  e.stopPropagation();
  let date = e.target.textContent.replace("Due: ", "");
  if (date === "Upon Project Approval") {
    date = this.state.startDate;
  } else {
    date = moment(date, "MMMM Do, YYYY").toDate();
  }
  this.setState({
    customPaymentDate: true,
    customDateIndex: i,
    [`date-${i}`]: date,
    undoRedo: false,
  });
}

export function checkPrices() {
  let total = 0;
  for (let price of document.querySelectorAll('#steps td[name^="price-"]')) {
    price = this.stringToNum(price.innerHTML);
    total = total + price;
  }
  this.setState(
    {
      customPriceTotal: total,
    },
    () => {
      // console.log(
      //   parseFloat(total),
      //   parseFloat(this.state.totalPrice.toFixed(2))
      // );
      if (parseFloat(total) !== parseFloat(this.state.totalPrice.toFixed(2))) {
        this.setState({
          paymentsWarning: true,
        });
      } else {
        this.setState({
          paymentsWarning: false,
        });
      }
    }
  );
}

export function setTotalPrice() {
  let totalsObj = this.calculateTotalPrice(this.state);

  this.setState(
    {
      totals: totalsObj.totals,
      totalPrice: totalsObj.totalPrice,
    },
    () => {
      // if (this.state.totalPrice !== 0) {
      this.calculatePayments();
      // if (this.state.formDeliverable) {
      this.injectDeliverables();
      // }
      // }
    }
  );
}
