/**
 *
 * Brief Deliverable
 *
 */

import React from "react";
import PropTypes from "prop-types";
import "./styles.scss";
import {
  Box,
  Button,
  Chip,
  Grid,
  IconButton,
  TextField,
  Tooltip,
  Typography,
} from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import moment from "moment";
import DatePicker from "@material-ui/lab/DatePicker";
import EditForm from "../EditForm";

function Deliverable(props) {
  let { index, deliverable, state } = props;
  return (
    <Box
      className="deliverable-container"
      sx={{
        "& .MuiChip-label": {
          fontWeight: "bold",
        },
        "& .editable .MuiFormControl-root": {
          input: {
            padding: ".375rem .75rem",
          },
        },
      }}
    >
      <table className="brief-table table table-sm">
        <tbody>
          <tr>
            <th>Deliverable {index + 1}</th>
            <td
              className="editable"
              onClick={() => props.editDeliverableTitle(index)}
            >
              <TextField
                fullWidth
                margin="none"
                type="text"
                size="small"
                name={`${index}-title`}
                value={deliverable.title}
                onChange={props.handleQuickInputChange}
                placeholder="Enter Project Name"
              />
            </td>
          </tr>
          <tr>
            <th>Description</th>
            <td style={{ padding: 0 }}>
              <EditForm
                state={deliverable.description}
                editorChange={(html) =>
                  props.editorChange(html, index, "description")
                }
              />
            </td>
          </tr>
          <tr>
            <th>Job Number</th>
            <td style={{ padding: 0 }}>
              <EditForm
                state={deliverable.jobNumber}
                editorChange={(html) =>
                  props.editorChange(html, index, "jobNumber")
                }
              />
            </td>
          </tr>
          <tr>
            <th>Key Dates</th>
            <td>
              {!!deliverable?.milestones?.length && (
                <ul className="milestones">
                  {deliverable.milestones.map((item, i) => {
                    let prevProject = deliverable.milestones[i - 1]?.project;

                    let thisSelected = false;
                    if (
                      state.editIndex === index &&
                      state.editMilestoneIndex === i
                    ) {
                      thisSelected = true;
                    }
                    let key = `milestone-${index}-${i}`;
                    return (
                      <li
                        key={key}
                        id={key}
                        className={`${
                          prevProject && prevProject !== item.project
                            ? "new"
                            : ""
                        }`}
                        style={{ position: "relative" }}
                      >
                        <div
                          className={`editable${
                            thisSelected ? " selected" : ""
                          }`}
                          onClick={() => props.editMilestone(index, i)}
                          style={{
                            display: "flex",
                            alignItems: "center",
                            marginBottom: "0.3rem",
                          }}
                        >
                          <Typography
                            variant="h6"
                            style={{
                              display: "flex",
                              alignItems: "center",
                              marginRight: "5px",
                            }}
                          >
                            <Chip
                              size="small"
                              label={moment(item.date).format("MM/DD/YY")}
                            />
                          </Typography>
                          {item.project}: {item.milestone}
                          <Tooltip
                            disableInteractive
                            title="Delete Milestone"
                            placement="right"
                          >
                            <IconButton
                              size="small"
                              className="delete-milestone"
                              onClick={(e) =>
                                props.deleteMilestone(e, index, i)
                              }
                              style={{
                                position: "absolute",
                                right: 0,
                                top: "50%",
                                transform: "translateY(-50%)",
                                marginTop: "-0.15rem",
                              }}
                            >
                              <DeleteIcon
                                color="error"
                                style={{ fontSize: "1rem" }}
                              />
                            </IconButton>
                          </Tooltip>
                        </div>
                      </li>
                    );
                  })}
                </ul>
              )}

              <form onSubmit={(e) => props.addMilestone(e, index)}>
                <Grid
                  container
                  sx={{
                    "& .MuiGrid-item:not(:last-of-type)": {
                      mr: 1,
                    },
                  }}
                >
                  <Grid item xs>
                    <DatePicker
                      size="small"
                      label="Date"
                      inputFormat="MM/DD/yyyy"
                      value={state[`${index}-date`] || null}
                      onChange={(value) =>
                        props.handleDateChange(`${index}-date`, value)
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          size="small"
                          required
                          InputLabelProps={{ required: false, shrink: true }}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs>
                    <TextField
                      fullWidth
                      margin="none"
                      label="Project"
                      variant="outlined"
                      type="text"
                      size="small"
                      name={`${index}-project`}
                      value={state[`${index}-project`]}
                      onChange={props.handleInputChange}
                      required
                      InputLabelProps={{ required: false, shrink: true }}
                    />
                  </Grid>
                  <Grid item xs>
                    <TextField
                      fullWidth
                      margin="none"
                      label="Milestone"
                      variant="outlined"
                      type="text"
                      size="small"
                      name={`${index}-milestone`}
                      value={state[`${index}-milestone`]}
                      onChange={props.handleInputChange}
                      required
                      InputLabelProps={{ required: false, shrink: true }}
                    />
                  </Grid>
                  <Grid item xs>
                    <TextField
                      fullWidth
                      margin="none"
                      label="Team"
                      variant="outlined"
                      type="text"
                      size="small"
                      name={`${index}-team`}
                      value={state[`${index}-team`]}
                      onChange={props.handleInputChange}
                      required
                      InputLabelProps={{ required: false, shrink: true }}
                    />
                  </Grid>
                  <Grid item xs style={{ maxWidth: 90 }}>
                    <Button
                      disableElevation
                      fullWidth
                      type="submit"
                      variant="contained"
                      color={
                        state.editMode && state.editIndex === index
                          ? "success"
                          : "primary"
                      }
                    >
                      {state.editMode && state.editIndex === index
                        ? "Apply"
                        : "Add"}
                    </Button>
                  </Grid>
                </Grid>
              </form>
            </td>
          </tr>
        </tbody>
      </table>
      <div className="deliverable-toolbar">
        {state.brief.content?.length > 1 ? (
          <Tooltip
            disableInteractive
            title="Delete Deliverable"
            placement="right"
          >
            <IconButton
              size="small"
              className="delete-deliverable"
              onClick={() => props.deleteDeliverable(index)}
            >
              <DeleteIcon color="error" />
            </IconButton>
          </Tooltip>
        ) : (
          <div></div>
        )}
        <Tooltip
          disableInteractive
          title="Add Deliverable Below"
          placement="right"
        >
          <IconButton
            size="small"
            className="add-below"
            onClick={() => props.addDeliverable(index)}
          >
            <AddCircleOutlineIcon />
          </IconButton>
        </Tooltip>
      </div>
    </Box>
  );
}

Deliverable.propTypes = {
  index: PropTypes.number.isRequired,
  deliverable: PropTypes.object.isRequired,
  state: PropTypes.object.isRequired,
};

export default Deliverable;
