import update from "immutability-helper";

export function addSection() {
  this.setState({
    edit: false,
    // editContact: false,
    editSection: "new",
    settings: false,
    showForm: true,
    query: "",
  });
}

export function removeSection(e, i) {
  e?.stopPropagation();

  const updatedContent = update(this.state.content, { $splice: [[i, 1]] });
  this.setState(
    {
      showForm: false,
      editSection: null,
      content: updatedContent,
      undoRedo: false,
      addBelowIndex: null,
    },
    () => {
      this.injectCompany();
      this.injectVariables();
    }
  );
}

export function editSection(i) {
  this.setState({
    settings: false,
    editSection: i,
    showForm: true,
    addBelowIndex: null,
  });
}

export function saveSection(updatedContent) {
  // console.log(updatedContent);

  this.setState(
    {
      content: updatedContent,
      showForm: false,
      reorder: false,
      undoRedo: false,
    },
    () => {
      this.sortDeliverables();
      this.setState({
        editSection: null,
        addBelowIndex: null,
        revisionIndex: null,
      });
      this.injectCompany();
      this.injectVariables();
    }
  );
}

export function cancelSection() {
  this.setState({
    edit: false,
    editSection: null,
    showForm: false,
    addBelowIndex: null,
  });
}

export function addBelow(e, i, deliverable) {
  e?.stopPropagation();

  this.setState(
    {
      addBelowIndex: i,
    },
    () => {
      if (deliverable) {
        document
          .querySelector(`#steps .deliverable-item a[href='#section-${i}']`)
          .closest("tbody")
          .querySelector(".deliverable-category")
          .click();
      } else {
        this.addSection();
      }

      let editorHeight = document.querySelector("#add-section").offsetHeight;
      let section = document.querySelectorAll("#content section")[
        this.state.addBelowIndex
      ];

      let distanceFromTop = section.getBoundingClientRect().bottom;
      let threshold = window.innerHeight - editorHeight - 140;
      let scroll = distanceFromTop - threshold;
      if (distanceFromTop > threshold) {
        document.querySelector("#app-container").scrollBy({
          top: scroll + 140,
          behavior: "smooth",
        });
      }
    }
  );
}
