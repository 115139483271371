/**
 *
 * Index
 *
 */
// import './wdyr';
import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { Auth0Provider } from "./react-auth0-wrapper";
import config from "./auth_config.json";
import HttpsRedirect from "react-https-redirect";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import { CssBaseline } from "@material-ui/core";
// A function that routes the user to the right place
// after login
const onRedirectCallback = (appState) => {
  // Temporary Firefox workaround
  window.location.hash = window.location.hash; // eslint-disable-line no-self-assign
  window.history.replaceState(
    {},
    document.title,
    appState && appState.targetUrl
      ? appState.targetUrl
      : window.location.pathname
  );
};

Sentry.init({
  dsn:
    process.env.NODE_ENV === "production"
      ? "https://09c2e24b0681428b99332887ee6c35fa@o542587.ingest.sentry.io/5662563"
      : null,
  release: localStorage.getItem("app-version") || "no-version",
  integrations: [new Integrations.BrowserTracing()],
  tracesSampleRate: 0.1,
  ignoreErrors: ["ResizeObserver loop limit exceeded"],
});

ReactDOM.render(
  <Auth0Provider
    domain={config.domain}
    client_id={config.clientId}
    redirect_uri={window.location.origin}
    onRedirectCallback={onRedirectCallback}
  >
    <HttpsRedirect>
      <CssBaseline />
      <App />
    </HttpsRedirect>
  </Auth0Provider>,
  document.getElementById("root")
);

serviceWorker.unregister();
