var ObjectID = require("bson-objectid");

export function prepDocState() {
  let tableNames = {};

  Object.keys(this.state).forEach((key) => {
    if (key.startsWith("tableName-")) {
      tableNames[key] = this.state[key];
    }
  });

  let customPrices = {};

  Object.keys(this.state).forEach((key) => {
    if (key.startsWith("price-")) {
      customPrices[key] = this.state[key];
    }
  });

  let customDates = {};

  Object.keys(this.state).forEach((key) => {
    if (key.startsWith("date-")) {
      customDates[key] = this.state[key];
    }
  });

  let data = {
    Title: this.state.title,
    docNum: this.state.docNum,
    crmCompany: this.state.crmCompany,
    company:
      (this.state.crmCompany && this.state.crmCompany.label) ||
      this.state.company,
    logo: this.state.logo,
    logoInternal: this.state.logoInternal,
    content: this.state.content,
    type: this.state.type,
    year: this.state.year,
    custom: this.state.custom,
    docType: this.state.docType,
    date: this.state.date ? new Date(this.state.date) : null,
    customDate: this.state.customDate ? new Date(this.state.customDate) : null,
    sigDate: this.state.sigDate ? new Date(this.state.sigDate) : null,
    currency: this.state.currency,
    address: this.state.address,
    clone: false,
    startDate: this.state.startDate ? new Date(this.state.startDate) : null,
    endDate: this.state.endDate ? new Date(this.state.endDate) : null,
    paymentSplit: this.state.paymentSplit,
    uponApproval: this.state.uponApproval,
    customPrices: this.state.customPrices
      ? this.state.customPrices
      : customPrices,
    customDates: this.state.customDates ? this.state.customDates : customDates,
    newSpendDiscount: this.state.newSpendDiscount,
    contactHTML: this.state.contactHTML,
    team: this.state.team,
    tables: this.state.tables,
    ...tableNames,
    rates: this.state.rates,
    lastModified: {
      id: this.context.user.id,
      time: new Date(),
    },
    fromMember: this.state.fromMember,
    salesPerson: this.state.salesPerson,
    accountManagement: this.state.accountManagement,
    attachedFiles: this.state.attachedFiles,
    approved: this.state.approved,
    sentToClient: this.state.sentToClient,
    rejected: this.state.rejected,
    revised: this.state.revised,
    teamworkID: this.state.teamworkID ? this.state.teamworkID : null,
    eApproved: this.state.eApproved,
    subTotals: this.state.subTotals,
    token: this.state.token,
    // approvalCompany: this.state.company,
  };

  return data;
}

export function updatedSalesPerson(value) {
  let previousDate = this.state.customDate || this.state.lastModified.time;
  this.updateDoc(
    {
      salesPerson: value,
      customDate: previousDate,
    },
    () => {
      if (value?.label) {
        this.slackMessage(
          `[APPROVED SOW MODIFIED] updated Sales Person to ${value.label} by ${this.state.user.name}`
        );
      } else {
        this.slackMessage(
          `[APPROVED SOW MODIFIED] Sales Person removed by ${this.state.user.name}`
        );
      }
    }
  );
}

export function updatedAccountManagement(value) {
  let previousDate = this.state.customDate || this.state.lastModified.time;
  this.updateDoc(
    {
      accountManagement: value,
      customDate: previousDate,
    },
    () => {
      if (value?.label) {
        this.slackMessage(
          `[APPROVED SOW MODIFIED] updated Account Management to ${value.label} by ${this.state.user.name}`
        );
      } else {
        this.slackMessage(
          `[APPROVED SOW MODIFIED] Account Management removed by ${this.state.user.name}`
        );
      }
    }
  );
}

export function updateDoc(params, callback) {
  if (!this.state.isAdmin) {
    this.showNotification("error", "You are not authorized to save!");
    return false;
  }

  this.setState({
    saving: true,
  });

  let data = this.prepDocState();

  if (params) {
    data = { ...data, ...params };
  }

  this.props.db
    .collection(this.props.collection)
    .updateOne(
      {
        _id: ObjectID(this.state.docId),
      },
      {
        $set: data,
      }
    )
    .then(this.displayDoc)
    .then((result) => {
      this.showNotification("success", `SOW saved!`);
      this.setState(
        {
          dirty: false,
          saving: false,
          revisionIndex: null,
        },
        () => {
          // console.log("remove dupe history");
          // if (this.state.historyArray.length > 1) {
          //   const updatedHistory = update(this.state.historyArray, {
          //     $splice: [[0, 1]]
          //   });
          //   this.setState({
          //     historyArray: updatedHistory
          //   });
          // }
          if (callback) {
            callback();
          }
        }
      );
      if (this.state.user) {
        this.socket.emit("docSaved", this.state.docId);
      }
    })
    .then((result) => {
      if (this.props.collection === "document") {
        if (
          !this.state.revisions.length &&
          !this.state.approved &&
          this.state.historyArray[this.state.historyArray.length - 1]
        ) {
          this.props.db
            .collection("revisions")
            .insertOne({
              docId: this.state.docId,
              data: this.state.historyArray[this.state.historyArray.length - 1],
              saved: {
                id: this.context.user.id,
                time: this.state.lastModified.time || new Date(),
              },
            })
            .then((result) => {
              // console.log("backup saved");
            });
        }

        this.props.db
          .collection("revisions")
          .insertOne({
            docId: this.state.docId,
            data: data,
            saved: {
              id: this.context.user.id,
              time: new Date(),
            },
          })
          .then((result) => {
            // console.log("revision saved");
            sessionStorage.removeItem("sow-recent-history");
          });
      }
    })
    .catch((err) => {
      console.error(err);

      this.unableToSave();
    });
}
