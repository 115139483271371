/**
 *
 * TopBar
 *
 */

import React from "react";
import {
  Alert,
  Box,
  Chip,
  Container,
  ButtonGroup,
  IconButton,
  Button,
  Tooltip,
  CircularProgress,
  Fab,
  Popover,
  Typography,
  Paper,
} from "@material-ui/core";
import UndoIcon from "@material-ui/icons/Undo";
import RedoIcon from "@material-ui/icons/Redo";
import PrintIcon from "@material-ui/icons/Print";
import VisibilityIcon from "@material-ui/icons/Visibility";
import FormatLineSpacingIcon from "@material-ui/icons/FormatLineSpacing";
import AddIcon from "@material-ui/icons/Add";
import AlarmAddIcon from "@material-ui/icons/AlarmAdd";
import SettingsIcon from "@material-ui/icons/Settings";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import SaveIcon from "@material-ui/icons/Save";
import MenuIcon from "@material-ui/icons/Menu";
import { Link } from "react-router-dom";
import "./styles.scss";
// import TeamworkLogo from "../../../images/teamwork.svg";
import PropTypes from "prop-types";
import moment from "moment";
import autoBind from "react-autobind";

class TopBar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      approvedTooltip: false,
    };

    autoBind(this);

    this.approvedBtn = React.createRef();
  }

  handleTooltipOpen(key) {
    this.setState({
      [key]: true,
    });
  }

  handleTooltipClose(key) {
    this.setState({
      [key]: false,
    });
  }

  render() {
    let { open } = this.state;
    let canSave = false;
    if (this.props.dirty) {
      if (this.props.docNum === "MAST" && !this.props.normalAdmin) {
        canSave = false;
      } else {
        canSave = true;
      }
    }

    return (
      <>
        <Alert
          variant="filled"
          severity="error"
          id="cannot-edit"
          style={{
            visibility: "hidden",
            alignItems: "center",
            justifyContent: "center",
            borderRadius: 0,
          }}
        >
          You can only edit one SOW at a time. Please close all other SOWs and
          then refresh this page.
        </Alert>
        <Paper
          id="top-bar"
          display="flex"
          style={{
            position: "fixed",
            right: 0,
            left: 0,
            zIndex: 1030,
          }}
        >
          <div className="blur-mask" />
          {/* <Link id="home-btn" to="/">
            <MdHome />
          </Link> */}
          <Container>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
            >
              <Box display="flex" flexWrap="no-wrap">
                {this.props.preview ? (
                  <ButtonGroup size="small">
                    <Tooltip disableInteractive title="Leave PDF Preview">
                      <Button
                        color="secondary"
                        id="leave-preview-btn"
                        className="public-btn"
                        onClick={this.props.togglePreview}
                      >
                        <ExitToAppIcon />
                      </Button>
                    </Tooltip>
                  </ButtonGroup>
                ) : (
                  <ButtonGroup size="small">
                    <Tooltip disableInteractive title="Options">
                      <Button
                        id="options-btn"
                        color={this.props.options ? "primary" : "secondary"}
                        onClick={this.props.toggleOptions}
                        className="public-btn"
                        style={{ marginRight: "10px" }}
                      >
                        <MenuIcon />
                      </Button>
                    </Tooltip>
                  </ButtonGroup>
                  // <ButtonGroup size="sm">
                  //   <OverlayTrigger
                  //     placement="top"
                  //     overlay={<Tooltip disableInteractive>Options</Tooltip>}
                  //     delay={{ show: 250 }}
                  //   >
                  //     <Button
                  //       id="options-btn"
                  //       color={this.props.options ? "primary" : "secondary"}
                  //       onClick={this.props.toggleOptions}
                  //       className="public-btn"
                  //       style={{ marginRight: "10px" }}
                  //     >
                  //       <MdMenu />
                  //     </Button>
                  //   </OverlayTrigger>
                  // </ButtonGroup>
                )}
                {!this.props.approved && this.props.preview ? null : (
                  <Box
                    id="undo-redo"
                    size="small"
                    variant="text"
                    sx={{
                      "& .MuiButton-root": {
                        minWidth: "auto",
                      },
                    }}
                  >
                    <Tooltip
                      disableInteractive
                      enterDelay={250}
                      enterNextDelay={250}
                      title="Undo"
                    >
                      <Button
                        id="undo-btn"
                        color="secondary"
                        onClick={this.props.undo}
                        disabled={
                          !this.props.historyArray?.length ||
                          this.props.historyArray?.length ===
                            (this.props.historyIndex + 1 || 0)
                        }
                      >
                        <UndoIcon />
                      </Button>
                    </Tooltip>
                    <Tooltip
                      disableInteractive
                      enterDelay={250}
                      enterNextDelay={250}
                      title="Redo"
                    >
                      <Button
                        id="redo-btn"
                        color="secondary"
                        onClick={this.props.redo}
                        disabled={this.props.historyIndex === 0}
                      >
                        <RedoIcon />
                      </Button>
                    </Tooltip>
                  </Box>
                )}
                {this.props.brief && (
                  // <Link to={this.props.viewAll}>View All Briefs</Link>
                  <Button
                    color="secondary"
                    // startIcon={<KeyboardArrowLeftIcon />}
                    component={Link}
                    to={this.props.viewAll}
                  >
                    View All Briefs
                  </Button>
                )}
              </Box>
              <Box
                display="flex"
                alignItems="center"
                className="title-container"
              >
                {this.props.approved && (
                  <>
                    <Popover
                      open={this.state.approvedTooltip}
                      anchorEl={this.approvedBtn.current}
                      onClose={() => this.handleTooltipClose("approvedTooltip")}
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "center",
                      }}
                      transformOrigin={{
                        vertical: "top",
                        horizontal: "center",
                      }}
                    >
                      <Box p={2} style={{ maxWidth: 300 }}>
                        <p>
                          This SOW was{" "}
                          {this.props.eApproved ? "e-Approved" : "approved"} by{" "}
                          <strong>
                            {this.props.approvedBy
                              ? this.props.approvedBy.name
                              : this.props.lastModified.name}
                          </strong>{" "}
                          on{" "}
                          <em>
                            {this.props.approvedBy
                              ? moment(this.props.approvedBy.time).format(
                                  "LLLL"
                                )
                              : moment(this.props.lastModified.time).format(
                                  "LLLL"
                                )}
                          </em>
                          {this.props.approvedBy &&
                            this.props.approvedBy.acknowledgedBy && (
                              <>
                                {" "}
                                and acknowledged by{" "}
                                <strong>
                                  {this.props.approvedBy.acknowledgedBy.name}
                                </strong>{" "}
                                on{" "}
                                <em>
                                  {moment(
                                    this.props.approvedBy.acknowledgedBy.time
                                  ).format("LLLL")}
                                </em>
                              </>
                            )}
                          .
                        </p>
                        {/* {this.props.token && (
                          <>
                            {this.props?.approvedBy?.location?.address && (
                              <div className="small">
                                <br />
                                Location:{" "}
                                {this.props.approvedBy.location.address}
                                <br />
                                IP: {this.props.approvedBy.ip}
                                <br />
                                <br />
                              </div>
                            )}

                            <a
                              href={`${
                                window.location.origin +
                                window.location.pathname
                              }?token=${this.props.token}`}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              View Secure Link
                            </a>
                            <br />
                          </>
                        )} */}
                        {this.props.attachedFiles && (
                          <div id="attached-files">
                            {this.props.attachedFiles.map((file, i) => {
                              return (
                                <div key={file.url || file.key}>
                                  <a
                                    href={
                                      file.url ||
                                      `https://approved-sow-prod.s3.us-east-1.amazonaws.com/${encodeURIComponent(
                                        file.key
                                      )}`
                                    }
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    style={{ fontSize: "12px" }}
                                  >
                                    {file.name}
                                  </a>
                                </div>
                              );
                            })}
                          </div>
                        )}
                        {this.props.superAdmin && (
                          <Button
                            size="small"
                            id="unapprove-btn"
                            variant="outlined"
                            color="error"
                            onClick={() => {
                              this.props.unapproveSOW();
                              this.handleTooltipClose("approvedTooltip");
                            }}
                          >
                            Unapprove and Edit
                          </Button>
                        )}
                      </Box>
                    </Popover>
                    <Chip
                      ref={this.approvedBtn}
                      id="approved-btn"
                      size="small"
                      label="Approved"
                      color="success"
                      onClick={() => this.handleTooltipOpen("approvedTooltip")}
                    />
                  </>
                )}
                {this.props.sentToClient && !this.props.approved && (
                  <Chip size="small" label="Sent to Client" color="info" />
                )}
                {this.props.rejected && (
                  <Chip size="small" label="Rejected" color="error" />
                )}
                {this.props.revised && (
                  <Chip size="small" label="Revised" color="secondary" />
                )}
                <Button
                  size="small"
                  color="inherit"
                  variant="text"
                  onClick={this.props.editSettings}
                  disabled={this.props.preview || this.props.brief}
                  style={{
                    padding: 0,
                    textTransform: "none",
                    color: "inherit",
                    margin: "auto"
                  }}
                >
                  <Typography
                    variant="h6"
                    id="doc-title"
                    className={`${
                      this.props.docNum !== "MAST" &&
                      !this.props.approved &&
                      !this.props.rejected &&
                      !this.props.revised &&
                      this.props.lastModified &&
                      moment(this.props.lastModified.time).isBefore(
                        moment(new Date()).subtract(2, "weeks")
                      )
                        ? //   ||
                          // (this.props.docNum !== "MAST" &&
                          //   this.props.approved &&
                          //   this.props.eApproved &&
                          //   !this.props.approvedBy.acknowledgedBy)
                          "action-needed"
                        : ""
                    }`}
                  >
                    {this.props.title}
                  </Typography>
                </Button>
                {this.props.docNum !== "MAST" &&
                !this.props.approved &&
                !this.props.rejected &&
                !this.props.revised &&
                this.props.lastModified &&
                moment(this.props.lastModified.time).isBefore(
                  moment(new Date()).subtract(2, "weeks")
                ) ? (
                  <Tooltip
                    disableInteractive
                    enterDelay={250}
                    enterNextDelay={250}
                    title="Extend time by 2 weeks"
                  >
                    <IconButton
                      disableElevation
                      size="small"
                      id="extend-btn"
                      variant="contained"
                      onClick={() => {
                        if (this.state.sentToClient) {
                          this.props.updateDoc({
                            sentToClient: true,
                          });
                        } else {
                          this.props.updateDoc();
                        }
                      }}
                      disabled={this.props.saving}
                      style={{ width: 30, height: 30 }}
                    >
                      {this.props.saving ? (
                        <CircularProgress size={16} color="secondary" />
                      ) : (
                        <AlarmAddIcon color="secondary" />
                      )}
                    </IconButton>
                  </Tooltip>
                ) : null}
                {/* {this.props.approved &&
                this.props.eApproved &&
                !this.props.approvedBy.acknowledgedBy ? (
                  <OverlayTrigger
                    placement="top"
                    overlay={<Tooltip disableInteractive>Confirm Approval</Tooltip>}
                    delay={{ show: 250 }}
                  >
                    <Button
                      size="sm"
                      id="confirm-approval-btn"
                      variant="primary"
                      onClick={this.props.confirmApproval}
                      className="public-btn"
                    >
                      {this.props.saving ? (
                        <CircularProgress size={16} color="success" />
                      ) : (
                        <MdDone />
                      )}
                    </Button>
                  </OverlayTrigger>
                ) : null} */}
              </Box>
              <Box
                sx={{
                  "& .MuiButton-root": {
                    minWidth: "auto",
                  },
                }}
              >
                {this.props.preview || this.props.brief ? (
                  <Tooltip
                    disableInteractive
                    enterDelay={250}
                    enterNextDelay={250}
                    title="Print"
                  >
                    <Button
                      id="preview-btn"
                      className="public-btn"
                      color={!this.props.brief ? "primary" : "secondary"}
                      disabled={this.props.loadingPDF || this.props.saving}
                      onClick={this.props.printPDF}
                    >
                      <PrintIcon />
                    </Button>
                  </Tooltip>
                ) : (
                  <Tooltip
                    disableInteractive
                    enterDelay={250}
                    enterNextDelay={250}
                    title="PDF Preview"
                  >
                    <Button
                      id="preview-btn"
                      className="public-btn"
                      color="secondary"
                      onClick={this.props.togglePreview}
                    >
                      <VisibilityIcon />
                    </Button>
                  </Tooltip>
                )}
                {!this.props.preview && !this.props.brief && (
                  <>
                    <Tooltip
                      disableInteractive
                      title="Add Section"
                      position="top"
                    >
                      <Fab
                        size="medium"
                        color="primary"
                        id="add-section-btn"
                        variant="contained"
                        onClick={this.props.addSection}
                        aria-label="add section"
                      >
                        <AddIcon />
                      </Fab>
                    </Tooltip>
                    {!this.props.approved && (
                      <Tooltip
                        disableInteractive
                        enterDelay={250}
                        enterNextDelay={250}
                        title="Reorder"
                      >
                        <Button
                          color={this.props.reorder ? "primary" : "secondary"}
                          onClick={this.props.toggleReorder}
                        >
                          <FormatLineSpacingIcon />
                        </Button>
                      </Tooltip>
                    )}
                    <Tooltip
                      disableInteractive
                      enterDelay={250}
                      enterNextDelay={250}
                      title="Settings"
                    >
                      <Button
                        id="settings-btn"
                        color={this.props.settings ? "primary" : "secondary"}
                        onClick={this.props.toggleSettings}
                        className="public-btn"
                      >
                        <SettingsIcon />
                      </Button>
                    </Tooltip>
                  </>
                )}
                {!this.props.preview && !this.props.approved && (
                  <Tooltip
                    disableInteractive
                    enterDelay={250}
                    enterNextDelay={250}
                    title="Save"
                    open={open}
                  >
                    <Button
                      id="save-btn"
                      color="success"
                      variant="text"
                      disabled={!canSave || this.props.saving}
                      onClick={() => {
                        this.props.updateDoc({
                          sentToClient: false,
                          revised: false,
                        });
                        this.handleTooltipClose("open");
                      }}
                      onMouseEnter={() => this.handleTooltipOpen("open")}
                      onMouseLeave={() => this.handleTooltipClose("open")}
                    >
                      {this.props.saving ? (
                        <CircularProgress size={16} color="success" />
                      ) : (
                        <SaveIcon />
                      )}
                    </Button>
                  </Tooltip>
                )}
              </Box>
            </Box>
          </Container>
        </Paper>
      </>
    );
  }
}

TopBar.propTypes = {
  title: PropTypes.string,
  docNum: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  reorder: PropTypes.bool,
  settings: PropTypes.bool,
  preview: PropTypes.bool,
  dirty: PropTypes.bool,
  editSettings: PropTypes.func,
  toggleReorder: PropTypes.func,
  toggleSettings: PropTypes.func,
  togglePreview: PropTypes.func,
  updateDoc: PropTypes.func,
  addSection: PropTypes.func,
  saving: PropTypes.bool,
  undo: PropTypes.func,
  redo: PropTypes.func,
  historyArray: PropTypes.array,
  historyIndex: PropTypes.number,
  approved: PropTypes.bool,
  sentToClient: PropTypes.bool,
  name: PropTypes.string,
  time: PropTypes.string,
  unapproveSOW: PropTypes.func,
  attachedFiles: PropTypes.array,
  printPDF: PropTypes.func,
  loadingPDF: PropTypes.bool,
  normalAdmin: PropTypes.bool,
  superAdmin: PropTypes.bool,
};

export default TopBar;
