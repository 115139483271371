import * as React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Paper,
} from "@material-ui/core";
import { visuallyHidden } from "@material-ui/utils";
import _ from "lodash";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  paper: {
    width: "100%",
  },
  table: {
    "& .MuiTableCell-head": {
      whiteSpace: "nowrap",
    },
    "& tbody span": {
      opacity: 0.5,
    },
    "& tr > *:first-child": {
      maxWidth: 160,
    },
  },
  name: {
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    maxWidth: 100,
  },
  sortSpan: visuallyHidden,
}));

function ByPersonTableHead(props) {
  const { classes, order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {props.headCells.map(
          (headCell) =>
            !!headCell.visible && (
              <TableCell
                key={headCell.id}
                align={headCell.numeric ? "right" : "left"}
                sortDirection={orderBy === headCell.id ? order : false}
              >
                <TableSortLabel
                  active={orderBy === headCell.id}
                  direction={orderBy === headCell.id ? order : "asc"}
                  onClick={createSortHandler(headCell.id)}
                >
                  {headCell.label}
                  {orderBy === headCell.id ? (
                    <span className={classes.sortSpan}>
                      {order === "desc"
                        ? "sorted descending"
                        : "sorted ascending"}
                    </span>
                  ) : null}
                </TableSortLabel>
              </TableCell>
            )
        )}
      </TableRow>
    </TableHead>
  );
}

ByPersonTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  // numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  // onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

export default function ByPersonTable(props) {
  let { headCells, rows, formatCurrency, hasUSD, hasEUR, hasGBP } = props;
  const classes = useStyles();
  const [order, setOrder] = React.useState("desc");
  const [orderBy, setOrderBy] = React.useState("number");

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <TableContainer>
          <Table className={classes.table} size="small">
            <ByPersonTableHead
              classes={classes}
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
              headCells={headCells}
              hasUSD={hasUSD}
              hasEUR={hasEUR}
              hasGBP={hasGBP}
            />
            <TableBody>
              {_.orderBy(rows, orderBy, order).map((row, index) => {
                return (
                  <TableRow hover tabIndex={-1} key={`row-${index}`}>
                    <TableCell
                      component="th"
                      id={`by-person-table-${index}`}
                      scope="row"
                      className={classes.name}
                    >
                      {row.name}
                    </TableCell>
                    <TableCell align="right">{row.number}</TableCell>
                    {!!hasUSD && (
                      <TableCell align="right">
                        {row.price ? formatCurrency(row.price) : 0}
                      </TableCell>
                    )}
                    {!!hasEUR && (
                      <TableCell align="right">
                        {row.priceEUR ? formatCurrency(row.priceEUR) : 0}
                      </TableCell>
                    )}
                    {!!hasGBP && (
                      <TableCell align="right">
                        {row.priceGBP ? formatCurrency(row.priceGBP) : 0}
                      </TableCell>
                    )}
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </div>
  );
}
