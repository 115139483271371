/**
 *
 * Brief
 *
 */

import React from "react";
import PropTypes from "prop-types";
import {
  Alert,
  Avatar,
  Box,
  Button,
  Container,
  Grid,
  IconButton,
  Typography,
  TextField,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Paper,
  Tooltip,
  Checkbox,
  FormControlLabel,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import Confirm from "../Confirm";
import LoadingButton from "@material-ui/lab/LoadingButton";
import { Auth0Context } from "../../react-auth0-wrapper";
import autoBind from "react-autobind";
import { Link } from "react-router-dom";
import * as Functions from "./functions.js";
import Logo from "../../images/tn_logo.svg";
import "./styles.scss";
import moment from "moment";
import TopBar from "../Doc/TopBar";
import { handleInputChange, handleDateChange } from "../../utils/inputHandlers";
import { showNotification, getUserInfo } from "../../utils/functions";
import _ from "lodash";
import EditForm from "./EditForm";
import Error from "../Error";
import Deliverable from "./Deliverable";
import NavigationPrompt from "react-router-navigation-prompt";
import Options from "./Options";

var ObjectID = require("bson-objectid");

class Brief extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      docTitle: "",
      showCreateBrief: false,
      deliverables: [],
      sowID: null,
      brief: {
        _id: this.props.match.params.brief_id,
      },
      saving: false,
      error: "",
      briefs: null,
      allMilestones: [],
      projectManager: [],
      loading: true,
      showAlert: true,
      open: false,
      confirmDelete: false,
      deleting: false,
    };

    autoBind(this);

    this.showNotification = showNotification.bind(this);
    this.getUserInfo = getUserInfo.bind(this);

    this.handleInputChange = handleInputChange.bind(this);
    this.handleDateChange = handleDateChange.bind(this);

    this.handleQuickInputChange = Functions.handleQuickInputChange.bind(this);
    this.hideAlert = Functions.hideAlert.bind(this);
    this.fetchBriefs = Functions.fetchBriefs.bind(this);
    this.fetchBrief = Functions.fetchBrief.bind(this);
    this.toggleBriefForm = Functions.toggleBriefForm.bind(this);
    this.createBrief = Functions.createBrief.bind(this);
    this.cancelCreateBrief = Functions.cancelCreateBrief.bind(this);
    this.updateBrief = Functions.updateBrief.bind(this);
    this.deleteBrief = Functions.deleteBrief.bind(this);
    this.addMilestone = Functions.addMilestone.bind(this);
    this.deleteMilestone = Functions.deleteMilestone.bind(this);
    this.deleteDeliverable = Functions.deleteDeliverable.bind(this);
    this.addDeliverable = Functions.addDeliverable.bind(this);
    this.scrollToMilestone = Functions.scrollToMilestone.bind(this);
    this.resetFields = Functions.resetFields.bind(this);
    this.editMilestone = Functions.editMilestone.bind(this);
    this.editDeliverableTitle = Functions.editDeliverableTitle.bind(this);
    this.editorChange = Functions.editorChange.bind(this);
    this.keyStrokes = Functions.keyStrokes.bind(this);
    this.onBeforePrint = Functions.onBeforePrint.bind(this);
  }

  static contextType = Auth0Context; // eslint-disable-line no-undef

  componentDidMount() {
    this.fetchBriefs();
    document.addEventListener("keydown", this.keyStrokes, false);
    window.addEventListener("beforeprint", this.onBeforePrint, false);
    document.querySelector("body").style.removeProperty("overflow");
  }

  componentWillUnmount() {
    document
      .querySelector("#app-container")
      .classList.remove("doc-view", "briefs");
    document.removeEventListener("keydown", this.keyStrokes, false);
    window.removeEventListener("beforeprint", this.onBeforePrint, false);
  }

  componentWillReceiveProps(nextProps) {
    const newBriefID = nextProps.match.params.brief_id;
    if (newBriefID && this.state.brief?._id?.toString() !== newBriefID) {
      this.setState({
        brief: {
          _id: newBriefID,
        },
      });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.match.url !== prevProps.match.url) {
      let briefID = this.props.match.params.brief_id;

      if (briefID) {
        this.setState({ loading: true });
      }

      document
        .querySelector("#app-container")
        .classList.remove("doc-view", "briefs");
      this.resetFields();
      this.fetchBriefs();
    }

    if (
      this.state.brief.content &&
      this.state.brief.content !== prevState.brief.content
    ) {
      // console.log("milestone change");

      if (this.state?.brief?.content?.length) {
        let allMilestones = [];

        for (let [i, deliverable] of this.state.brief.content.entries()) {
          if (deliverable?.milestones?.length) {
            for (let [j, milestone] of deliverable.milestones.entries()) {
              milestone.id = `${i}-${j}`;
            }

            allMilestones = allMilestones.concat([...deliverable.milestones]);
          }
        }

        allMilestones = _.sortBy(allMilestones, ["date"]);

        this.setState({ allMilestones });
      }

      if (prevState.brief.content) {
        // console.log("set dirty");
        this.setState({ dirty: true });
      }
    }

    if (
      prevState.brief._id &&
      prevState.brief.content?.length &&
      prevState.brief !== this.state.brief
    ) {
      this.setState({ dirty: true });
    }
  }

  openDialog(deleteId, deleteTitle) {
    this.setState({ confirmDelete: true, deleteId, deleteTitle });
  }

  closeDialog() {
    this.setState({ confirmDelete: false });
  }

  render() {
    return (
      <Container
        id="briefs"
        className={`${!this.state.brief._id ? "briefs-view" : ""}`}
        style={{
          paddingBottom: "60px",
        }}
        sx={{
          "& .editable .MuiFormControl-root": {
            ".MuiInputBase-root": {
              borderRadius: "0px",
              "&:not(.Mui-focused) .MuiOutlinedInput-notchedOutline": {
                borderColor: "transparent",
              },
            },
            input: {
              padding: "0.3rem",
              fontFamily: "Arial, sans-serif",
            },
          },
          "& #error": {
            marginTop: "65px",
            p: 0,
          },
        }}
      >
        {(this.state.loading || !this.props.user) && (
          <div id="spinner">
            <CircularProgress />
          </div>
        )}
        {this.state.error ? (
          <Error message={this.state.error} user={this.props.user} />
        ) : (
          <>
            {this.state.docTitle ? (
              <>
                {this.state.brief._id ? (
                  <>
                    <NavigationPrompt when={this.state.dirty}>
                      {({ onConfirm, onCancel }) => (
                        <Confirm
                          open={true}
                          text="You have unsaved changes, are you sure you want to leave"
                          closeDialog={onCancel}
                          title="Unsaved Changes"
                          confirmAction={onConfirm}
                          confirmLabel="Leave without saving"
                        />
                      )}
                    </NavigationPrompt>
                    <TopBar
                      brief
                      title={this.state.brief.title}
                      viewAll={`/${this.state.sowID}/brief`}
                      dirty={this.state.dirty}
                      saving={this.state.saving}
                      updateDoc={this.updateBrief}
                      printPDF={() => window.print()}
                    />
                    <Grid>
                      <Box id="brief-document" className="document">
                        {this.state.showAlert && (
                          <Alert
                            severity="warning"
                            onClose={this.hideAlert}
                            sx={{
                              "&": {
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                mb: 2,
                              },
                            }}
                          >
                            Make sure to turn margins ON when printing briefs
                          </Alert>
                        )}
                        <table
                          borderless
                          id="brief-table-container"
                          className="table table-borderless"
                        >
                          <thead>
                            <tr>
                              <td>
                                <Grid container className="logos">
                                  <Grid item xs={3}>
                                    <img src={Logo} alt="logo" />
                                  </Grid>
                                </Grid>
                              </td>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>
                                <Grid container>
                                  <Grid item xs>
                                    <Typography variant="h5">
                                      Creative Brief
                                    </Typography>
                                  </Grid>
                                  <Grid item xs>
                                    <table
                                      id="header-table"
                                      className="table table-sm"
                                    >
                                      <tbody>
                                        <tr>
                                          <th>Client Name</th>
                                          <td>{this.state.company}</td>
                                        </tr>
                                        <tr>
                                          <th>Project Name</th>
                                          <td className="editable">
                                            <TextField
                                              fullWidth
                                              margin="none"
                                              type="text"
                                              size="small"
                                              name="projectTitle"
                                              value={this.state.brief.title}
                                              onChange={
                                                this.handleQuickInputChange
                                              }
                                              placeholder="Enter Project Name"
                                            />
                                          </td>
                                        </tr>
                                        {!this.state.docTitle.includes(
                                          "MAST_"
                                        ) && (
                                          <tr>
                                            <th>SOW Number</th>
                                            <td>{this.state.docTitle}</td>
                                          </tr>
                                        )}
                                        <tr>
                                          <th>Project Manager</th>
                                          <td className="editable">
                                            <TextField
                                              fullWidth
                                              margin="none"
                                              type="text"
                                              size="small"
                                              name="projectManager"
                                              value={
                                                this.state.brief.projectManager
                                              }
                                              onChange={
                                                this.handleQuickInputChange
                                              }
                                              placeholder="Enter Project Manager"
                                            />
                                          </td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  </Grid>
                                </Grid>
                                <Box>
                                  <table className="table table-sm">
                                    <thead>
                                      <tr>
                                        <th>Project Overview & Objectives:</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      <tr>
                                        <td style={{ padding: 0 }}>
                                          <EditForm
                                            state={
                                              this.state.brief?.description
                                            }
                                            editorChange={(html) =>
                                              this.editorChange(
                                                html,
                                                null,
                                                "description"
                                              )
                                            }
                                          />
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </Box>
                                <Box>
                                  {this.state?.brief?.content?.map(
                                    (deliverable, index) => {
                                      return (
                                        <Deliverable
                                          key={`deliverable - ${index}`}
                                          deliverable={deliverable}
                                          index={index}
                                          state={this.state}
                                          editDeliverableTitle={
                                            this.editDeliverableTitle
                                          }
                                          handleQuickInputChange={
                                            this.handleQuickInputChange
                                          }
                                          handleInputChange={
                                            this.handleInputChange
                                          }
                                          handleDateChange={
                                            this.handleDateChange
                                          }
                                          editorChange={this.editorChange}
                                          editMilestone={this.editMilestone}
                                          addMilestone={this.addMilestone}
                                          deleteMilestone={this.deleteMilestone}
                                          deleteDeliverable={
                                            this.deleteDeliverable
                                          }
                                          addDeliverable={this.addDeliverable}
                                        />
                                      );
                                    }
                                  )}
                                </Box>
                                <br />
                                <Box>
                                  <Typography variant="h6">
                                    Timeline:
                                  </Typography>
                                  <table
                                    id="timeline-table"
                                    className="table table-sm"
                                  >
                                    <thead>
                                      <tr>
                                        <th>Date</th>
                                        <th>Project</th>
                                        <th>Milestone</th>
                                        <th>Team</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {this.state.allMilestones.map(
                                        (milestone, i) => {
                                          let newDate = false;
                                          let prevDate =
                                            this.state.allMilestones[i - 1]
                                              ?.date &&
                                            moment(
                                              this.state.allMilestones[i - 1]
                                                .date
                                            ).format("MM/DD/YY");
                                          let thisDate = moment(
                                            milestone.date
                                          ).format("MM/DD/YY");

                                          if (prevDate !== thisDate) {
                                            newDate = true;
                                          }

                                          let rowSpan = this.state.allMilestones.filter(
                                            (m) =>
                                              moment(m.date).format(
                                                "MM/DD/YY"
                                              ) === thisDate
                                          ).length;

                                          return (
                                            <tr
                                              key={`${i}-${milestone.project}-${milestone.milestone}`}
                                              className="editable"
                                              onClick={() =>
                                                this.scrollToMilestone(
                                                  milestone.id
                                                )
                                              }
                                            >
                                              {newDate && (
                                                <td rowSpan={rowSpan}>
                                                  {thisDate}
                                                </td>
                                              )}
                                              <td>{milestone.project}</td>
                                              <td>{milestone.milestone}</td>
                                              <td>{milestone.team}</td>
                                            </tr>
                                          );
                                        }
                                      )}
                                    </tbody>
                                  </table>
                                </Box>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </Box>
                    </Grid>
                  </>
                ) : (
                  <>
                    <Grid
                      container
                      style={{ position: "relative" }}
                      justifyContent="space-between"
                    >
                      <Grid item>
                        <Typography variant="h5">
                          <span>
                            <Link to={`/${this.state.sowID}`}>
                              {this.state.docTitle}
                            </Link>
                            {` `}
                            Briefs
                          </span>
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Button
                          variant="outlined"
                          onClick={this.toggleBriefForm}
                        >
                          Create Brief
                        </Button>
                      </Grid>
                    </Grid>
                    <hr />
                    <Grid>
                      <Box>
                        {this.state.briefs && this.state.briefs.length ? (
                          <div id="brief-list" className="listing">
                            <>
                              {this.state.briefs.map((brief, index) => {
                                let userInfo = brief.lastModified?.id
                                  ? this.getUserInfo(brief.lastModified.id)
                                  : this.getUserInfo(brief.createdBy);
                                return (
                                  <Paper
                                    className={`doc id-${brief._id.toString()}`}
                                    key={ObjectID(brief._id).toString()}
                                  >
                                    <div className="list-name">
                                      <Link
                                        to={`/${
                                          this.state.sowID
                                        }/brief/${ObjectID(
                                          brief._id
                                        ).toString()}`}
                                      >
                                        <Typography
                                          variant="h6"
                                          className="doc-title"
                                        >
                                          {brief.title}
                                        </Typography>
                                      </Link>
                                    </div>
                                    <div className="list-meta">
                                      <Box display="flex" alignItems="center">
                                        <Tooltip
                                          disableInteractive
                                          title={
                                            brief.lastModified?.time
                                              ? moment(
                                                  brief.lastModified.time
                                                ).format("lll")
                                              : moment(
                                                  ObjectID(
                                                    brief._id
                                                  ).getTimestamp()
                                                ).format("lll")
                                          }
                                          placement="left"
                                        >
                                          <Typography
                                            sx={{
                                              "&": {
                                                fontSize: "12px",
                                                mr: 2,
                                                opacity: 0.6,
                                              },
                                            }}
                                          >
                                            {brief.lastModified?.time
                                              ? moment(
                                                  brief.lastModified.time
                                                ).fromNow()
                                              : moment(
                                                  ObjectID(
                                                    brief._id
                                                  ).getTimestamp()
                                                ).fromNow()}
                                          </Typography>
                                        </Tooltip>
                                        <span>
                                          <Tooltip
                                            disableInteractive
                                            title={userInfo?.name}
                                            placement="right"
                                          >
                                            <Avatar
                                              src={userInfo?.picture}
                                              alt={userInfo?.name}
                                              sx={{
                                                "&": { width: 28, height: 28 },
                                              }}
                                            />
                                          </Tooltip>
                                        </span>
                                      </Box>
                                      <Options
                                        doc={brief}
                                        openDialog={this.openDialog}
                                        sowID={this.state.sowID}
                                      />
                                    </div>
                                  </Paper>
                                );
                              })}
                            </>
                          </div>
                        ) : (
                          <>
                            {this.state.briefs && !this.state.briefs.length ? (
                              <Typography variant="h6">
                                No briefs created yet
                              </Typography>
                            ) : (
                              <div className="no-results">
                                <CircularProgress />
                              </div>
                            )}
                          </>
                        )}
                      </Box>
                    </Grid>

                    <Dialog
                      id="brief-modal"
                      open={this.state.showCreateBrief}
                      onClose={this.toggleBriefForm}
                      fullWidth
                      maxWidth="sm"
                    >
                      <DialogTitle
                        disableTypography
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                        }}
                      >
                        <Box display="flex" alignItems="center">
                          <Typography variant="h6">Create Brief</Typography>
                        </Box>
                        <Box mr={-2} mt={-1}>
                          <IconButton
                            aria-label="close"
                            onClick={this.toggleBriefForm}
                          >
                            <CloseIcon />
                          </IconButton>
                        </Box>
                      </DialogTitle>
                      <DialogContent>
                        <form onSubmit={this.createBrief}>
                          <TextField
                            fullWidth
                            margin="normal"
                            label="Project Name"
                            variant="outlined"
                            type="text"
                            size="small"
                            name="newDocNum"
                            defaultValue={`Brief ${
                              this.state.briefs?.length + 1
                            }`}
                            required
                            InputLabelProps={{ required: false }}
                          />
                          <div>
                            <Typography variant="overline">
                              Select Deliverables to Import
                            </Typography>
                            <Box
                              style={{
                                maxHeight: "60vh",
                                overflow: "auto",
                              }}
                            >
                              {this.state.deliverables.map(
                                (deliverable, index) => {
                                  let key = `${index} - ${deliverable.title}`;
                                  return (
                                    <Box key={key}>
                                      <FormControlLabel
                                        control={
                                          <Checkbox
                                            size="small"
                                            color="primary"
                                            name={deliverable.title}
                                          />
                                        }
                                        label={deliverable.title}
                                        style={{ marginBottom: 0 }}
                                      />
                                    </Box>
                                  );
                                }
                              )}
                            </Box>
                          </div>
                          <DialogActions style={{ paddingRight: 0 }}>
                            <Button
                              type="reset"
                              onClick={this.cancelCreateBrief}
                              color="secondary"
                            >
                              Cancel
                            </Button>

                            <LoadingButton
                              type="submit"
                              color="primary"
                              pending={this.state.saving}
                            >
                              Create Brief
                            </LoadingButton>
                          </DialogActions>
                        </form>
                      </DialogContent>
                    </Dialog>
                  </>
                )}
              </>
            ) : (
              <div id="spinner">
                <CircularProgress />
              </div>
            )}
          </>
        )}
        <Confirm
          open={this.state.confirmDelete}
          title="Delete Brief"
          text="Are you sure you want to delete "
          highlight={this.state.deleteTitle}
          id={this.state.deleteId}
          closeDialog={this.closeDialog}
          confirmAction={this.deleteBrief}
          confirmLabel="Delete"
          inFlight={this.state.deleting}
        />
      </Container>
    );
  }
}

Brief.propTypes = {
  user: PropTypes.object,
};

export default Brief;
