/**
 *
 * Settings
 *
 */

import React from "react";
import PropTypes from "prop-types";
import {
  Avatar,
  Container,
  Box,
  Chip,
  Drawer,
  Divider,
  IconButton,
  Button,
  Typography,
  Autocomplete,
  Grid,
  TextField,
  Tabs,
  Tab,
  Tooltip,
  List,
  ListItem,
  MenuItem,
  InputBase,
} from "@material-ui/core";
import LoadingButton from "@material-ui/lab/LoadingButton";
import CloseIcon from "@material-ui/icons/Close";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import AddBoxIcon from "@material-ui/icons/AddBox";
import AssignmentIcon from "@material-ui/icons/Assignment";
import ThumbDownIcon from "@material-ui/icons/ThumbDown";
import DatePicker from "@material-ui/lab/DatePicker";
import { withRouter, Link } from "react-router-dom";
import moment from "moment";
import axios from "axios";
import "./styles.scss";
import autoBind from "react-autobind";
import {
  handleInputChange,
  handleDateChange,
  handleReactSelect,
} from "../../utils/inputHandlers";
import { getUserInfo } from "../../utils/functions";
import { Auth0Context } from "../../react-auth0-wrapper";
import Logo from "../../images/tn_logo.svg";
import LogoPA from "../../images/pa_logo.svg";

var ObjectID = require("bson-objectid");

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box pt={1}>
          <Box>{children}</Box>
        </Box>
      )}
    </div>
  );
}

class Settings extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      newCrmCompany: undefined,
      newCompany: "",
      newDocNum: "",
      newType: "",
      newQuarter: "",
      newCustom: "",
      newDocType: "",
      newYear: "",
      newCurrency: "USD",
      newLogoInternal: Logo,
      newAddress: "New York",
      newCustomDate: null,
      newSigDate: moment().toDate(),
      newFromMember: [],
      newSalesPerson: [],
      newAccountManagement: [],
      tab: 0,
      memberOptions: [],
    };

    autoBind(this);

    this.handleInputChange = handleInputChange.bind(this);
    this.handleDateChange = handleDateChange.bind(this);
    this.handleReactSelect = handleReactSelect.bind(this);
    this.getUserInfo = getUserInfo.bind(this);
  }

  static contextType = Auth0Context; // eslint-disable-line no-undef

  componentDidMount() {
    this.injectSettings();
  }

  componentDidUpdate(prevProps) {
    // console.log(prevProps.show, this.props.show);
    if (!prevProps.show && this.props.show) {
      this.injectSettings();
    }
  }

  // static getDerivedStateFromProps(props, state) {
  //  console.log(props)
  // }

  createMemberOptions() {
    if (this.state.memberOptions.length) {
      return false;
    }

    let memberOptions = this.props.members
      ?.sort(this.compare)
      .map((member) => ({
        label: member.Name,
        value: member._id.toString(),
      }));
    if (memberOptions?.length) {
      this.setState({
        memberOptions,
      });
    }
  }

  injectSettings() {
    console.log("inject settings", this.props);
    // console.log("inject settings", this.props);
    this.setState({
      newCrmCompany: this.props.crmCompany,
      newCompany: this.props.company,
      newDocNum: this.props.docNum,
      newType: this.props.type,
      newQuarter: this.props.quarter,
      newCustom: this.props.custom,
      newDocType: this.props.docType || "Strategic Plan",
      newYear: this.props.year,
      newCustomDate: this.props.customDate || null,
      newSigDate: this.props.sigDate,
      newCurrency: this.props.currency || "USD",
      newLogoInternal: this.props.logoInternal || Logo,
      newAddress: this.props.address || "New York",
      newFromMember: this.props.fromMember || [],
      newSalesPerson: this.props.salesPerson || [],
      newAccountManagement: this.props.accountManagement || [],
    });
  }

  handleSubmit(e) {
    e.preventDefault();
    if (!this.props.approved) {
      this.props.onSubmit(this.state);
    }
  }

  handleTabChange(e, value) {
    this.setState({
      tab: value,
    });
  }

  handleReactSelectMulti(key, target) {
    this.setState(
      {
        [key]: target,
      },
      () => {
        if (key === "newSalesPerson" && this.props.approved) {
          // console.log(value)
          this.props.updatedSalesPerson(target);
        } else if (key === "newAccountManagement" && this.props.approved) {
          // console.log(value)
          this.props.updatedAccountManagement(target);
        }
      }
    );
  }

  compare(a, b) {
    let comparison = 0;
    if (a.Name > b.Name) {
      comparison = 1;
    } else if (a.Name < b.Name) {
      comparison = -1;
    }
    return comparison;
  }

  getCompanySuggestions(inputValue) {
    return axios
      .get("/api/crmGetCompany", {
        params: {
          company: inputValue,
        },
      })
      .then((response) => {
        let options = [];

        for (let result of response.data.list) {
          options.push({
            label: result.name,
            value: result.id,
          });
        }

        return options;
      })
      .catch(console.error);
  }

  render() {
    return (
      <Drawer
        id="settings-drawer"
        anchor={this.props.side ? "right" : "bottom"}
        open={this.props.show}
        variant={this.props.side ? "persistent" : "permanent"}
      >
        <Container>
          <div id={this.props.side ? "settings" : "add-doc"}>
            <Grid
              item
              xs={12}
              display="flex"
              justifyContent="space-between"
              flexDirection="column"
            >
              <div id="main-settings">
                <div>
                  {/* <button
                type="button"
                className="close public-btn"
                aria-label="Close"
                onClick={this.props.onClick}
              >
                <span aria-hidden="true">&times;</span>
              </button> */}

                  {this.props.side && (
                    <>
                      <Box
                        mb={1}
                        display="flex"
                        alignItems="center"
                        justifyContent="space-between"
                      >
                        <Typography variant="h5">Settings</Typography>
                        <IconButton
                          className="public-btn"
                          size="small"
                          onClick={this.props.onClick}
                          sx={{ "&": { mr: -1 } }}
                        >
                          <CloseIcon />
                        </IconButton>
                      </Box>
                      <Divider />
                      <Grid>
                        <Tabs
                          value={this.state.tab}
                          onChange={this.handleTabChange}
                          indicatorColor="primary"
                          textColor="primary"
                          centered
                        >
                          <Tab label="Details" />
                          <Tab label="Revisions" />
                        </Tabs>
                      </Grid>
                    </>
                  )}
                  <TabPanel id="main-tab" value={this.state.tab} index={0}>
                    <form onSubmit={(e) => this.handleSubmit(e)}>
                      {!isNaN(this.state.newDocNum) && (
                        <TextField
                          fullWidth
                          margin="normal"
                          label="SOW Number"
                          variant="outlined"
                          type="number"
                          size="small"
                          name="newDocNum"
                          value={this.state.newDocNum}
                          onChange={this.handleInputChange}
                          required
                          InputLabelProps={{ required: false }}
                          disabled={this.props.approved}
                        />
                      )}
                      <TextField
                        fullWidth
                        margin="normal"
                        label="Company Name"
                        variant="outlined"
                        type="text"
                        size="small"
                        name="newCompany"
                        value={this.state.newCompany}
                        onChange={this.handleInputChange}
                        required
                        InputLabelProps={{ required: false }}
                        disabled={this.props.approved}
                      />

                      <Autocomplete
                        autoHighlight
                        disableClearable
                        value={
                          (this.state.newType && {
                            value: this.state.newType,
                            label: this.state.newType,
                          }) ||
                          null
                        }
                        options={[
                          { value: "AOR", label: "AOR" },
                          { value: "PA", label: "PA" },
                          { value: "Custom", label: "Custom" },
                        ]}
                        getOptionLabel={(option) => option?.label || ""}
                        getOptionSelected={(option, value) =>
                          option.value === value.value
                        }
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Contract Type"
                            variant="outlined"
                            size="small"
                            margin="normal"
                            required
                            InputLabelProps={{ required: false }}
                          />
                        )}
                        onChange={(e, value) =>
                          this.handleReactSelect("newType", value)
                        }
                        disabled={this.props.approved}
                      />
                      {this.state.newType === "PA" && (
                        <TextField
                          fullWidth
                          margin="normal"
                          label="Quarter(s)"
                          variant="outlined"
                          type="text"
                          size="small"
                          name="newQuarter"
                          value={this.state.newQuarter}
                          onChange={this.handleInputChange}
                          disabled={this.props.approved}
                        />
                      )}
                      {this.state.newType === "Custom" && (
                        <TextField
                          fullWidth
                          margin="normal"
                          label="Custom Type"
                          variant="outlined"
                          type="text"
                          size="small"
                          name="newCustom"
                          value={this.state.newCustom}
                          onChange={this.handleInputChange}
                          disabled={this.props.approved}
                        />
                      )}
                      {this.props.side && (
                        <Autocomplete
                          autoHighlight
                          disableClearable
                          value={
                            (this.state.newDocType && {
                              value: this.state.newDocType,
                              label: this.state.newDocType,
                            }) ||
                            null
                          }
                          options={[
                            {
                              value: "Strategic Plan",
                              label: "Strategic Plan",
                            },
                            { value: "Order Form", label: "Order Form" },
                            {
                              value: "Research Report",
                              label: "Research Report",
                            },
                            // {
                            //   value: "Biologics Report Order Form",
                            //   label: "Biologics Report Order Form"
                            // },
                            // {
                            //   value: "Covid Report Order Form",
                            //   label: "Covid Report Order Form"
                            // },
                            {
                              value: "Exhibit/Event Proposal",
                              label: "Exhibit/Event Proposal",
                            },
                            { value: "Gift Estimate", label: "Gift Estimate" },
                            {
                              value: "Statement of Work",
                              label: "Statement of Work",
                            },
                            { value: "Proposal", label: "Proposal" },
                          ]}
                          getOptionLabel={(option) => option?.label || ""}
                          getOptionSelected={(option, value) =>
                            option.value === value.value
                          }
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="SOW Type"
                              variant="outlined"
                              size="small"
                              margin="normal"
                              required
                              InputLabelProps={{ required: false }}
                            />
                          )}
                          onChange={(e, value) =>
                            this.handleReactSelect("newDocType", value)
                          }
                          disabled={this.props.approved}
                        />
                      )}
                      <Autocomplete
                        autoHighlight
                        disableClearable
                        value={
                          (this.state.newYear && {
                            value: this.state.newYear,
                            label: this.state.newYear,
                          }) ||
                          null
                        }
                        options={[
                          { value: "2022", label: "2022" },
                          { value: "2023", label: "2023" },
                          { value: "2024", label: "2024" },
                          { value: "2025", label: "2025" },
                          { value: "2026", label: "2026" },
                          { value: "2027", label: "2027" },
                        ]}
                        getOptionLabel={(option) => option?.label || ""}
                        getOptionSelected={(option, value) =>
                          option.value === value.value
                        }
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Contract Year"
                            variant="outlined"
                            size="small"
                            margin="normal"
                            required
                            InputLabelProps={{ required: false }}
                          />
                        )}
                        onChange={(e, value) =>
                          this.handleReactSelect("newYear", value)
                        }
                        disabled={this.props.approved}
                      />

                      {this.props.side ? (
                        <>
                          <Autocomplete
                            autoHighlight
                            disableClearable
                            value={
                              this.state.newCurrency && {
                                value: this.state.newCurrency,
                                label: this.state.newCurrency,
                              }
                            }
                            options={[
                              { value: "USD", label: "USD ($)" },
                              { value: "EUR", label: "EUR (€)" },
                              { value: "GBP", label: "GBP (£)" },
                            ]}
                            getOptionLabel={(option) => option?.label || ""}
                            getOptionSelected={(option, value) => {
                              console.log(option);
                              console.log(value);
                              return option.value === value.value
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label="Currency"
                                variant="outlined"
                                size="small"
                                margin="normal"
                                required
                                InputLabelProps={{ required: false }}
                              />
                            )}
                            onChange={(e, value) =>
                              this.handleReactSelect("newCurrency", value)
                            }
                            disabled={this.props.approved}
                          />
                          <DatePicker
                            size="small"
                            label="SOW Date"
                            inputFormat="MM/DD/yyyy"
                            value={this.state.newCustomDate || this.state.date}
                            onChange={(value) =>
                              this.handleDateChange("newCustomDate", value)
                            }
                            renderInput={(params) => (
                              <Tooltip
                                disableInteractive
                                placement="left"
                                title="Date to display on the SOW"
                              >
                                <TextField {...params} size="small" />
                              </Tooltip>
                            )}
                            disabled={this.props.approved}
                          />
                          <DatePicker
                            size="small"
                            label="Signature Date"
                            inputFormat="MM/DD/yyyy"
                            value={this.state.newSigDate}
                            onChange={(value) =>
                              this.handleDateChange("newSigDate", value)
                            }
                            renderInput={(params) => (
                              <Tooltip
                                disableInteractive
                                placement="left"
                                title="Date to display under the signature"
                              >
                                <TextField {...params} size="small" />
                              </Tooltip>
                            )}
                            disabled={this.props.approved}
                          />
                          <Autocomplete
                            autoHighlight
                            disableClearable
                            value={
                              this.state.newAddress && {
                                value: this.state.newAddress,
                                label: this.state.newAddress,
                              }
                            }
                            options={[
                              { value: "New York", label: "New York" },
                              { value: "Paris", label: "Paris" },
                            ]}
                            getOptionLabel={(option) => option?.label || ""}
                            getOptionSelected={(option, value) =>
                              option.value === value.value
                            }
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label="Address"
                                variant="outlined"
                                size="small"
                                margin="normal"
                                required
                                InputLabelProps={{ required: false }}
                              />
                            )}
                            onChange={(e, value) =>
                              this.handleReactSelect("newAddress", value)
                            }
                            disabled={this.props.approved}
                          />

                          <Autocomplete
                            multiple
                            autoHighlight
                            className="public-btn"
                            value={this.state.newSalesPerson}
                            options={this.state.memberOptions}
                            getOptionLabel={(option) => option?.label || ""}
                            getOptionSelected={(option, value) =>
                              option.value === value.value
                            }
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label="Sales Person"
                                variant="outlined"
                                size="small"
                                margin="normal"
                              />
                            )}
                            onChange={(e, value) =>
                              this.handleReactSelectMulti(
                                "newSalesPerson",
                                value
                              )
                            }
                            onOpen={this.createMemberOptions}
                          />
                          <Autocomplete
                            multiple
                            autoHighlight
                            className="public-btn"
                            value={this.state.newAccountManagement}
                            options={this.state.memberOptions}
                            getOptionLabel={(option) => option?.label || ""}
                            getOptionSelected={(option, value) =>
                              option.value === value.value
                            }
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label="Account Management"
                                variant="outlined"
                                size="small"
                                margin="normal"
                              />
                            )}
                            onChange={(e, value) =>
                              this.handleReactSelectMulti(
                                "newAccountManagement",
                                value
                              )
                            }
                            onOpen={this.createMemberOptions}
                          />
                          <Autocomplete
                            autoHighlight
                            disableClearable
                            value={
                              this.state.newLogoInternal && {
                                value: this.state.newLogoInternal,
                                label: this.state.newLogoInternal,
                              }
                            }
                            options={[
                              { value: Logo, label: "That's Nice" },
                              { value: LogoPA, label: "Pharma's Almanac" },
                            ]}
                            getOptionLabel={(option) => option?.label || ""}
                            getOptionSelected={(option, value) =>
                              option.value === value.value
                            }
                            renderInput={(params) => { 
                              return (
                              <div class="internal-logo-wrapper">
                                <img src={params.inputProps.value} alt="internal-logo" />
                                <TextField
                                  {...params}
                                  label="Internal Logo"
                                  variant="outlined"
                                  size="small"
                                  margin="normal"
                                  required
                                  InputLabelProps={{ required: false }}
                                >
                                </TextField>
                              </div>
                            )}}
                            renderOption={(params,option) => {
                              return (
                                <MenuItem {...params} class="internal-logo-option">
                                  <img src={option.value} alt="internal-logo" />
                                </MenuItem>
                              );
                            }}
                            onChange={(e, value) => {
                              this.handleReactSelect("newLogoInternal", value)
                            }}
                            disabled={this.props.approved}
                          />
                        </>
                      ) : (
                        ""
                      )}
                      {!this.props.approved && (
                        <Grid mt={1}>
                          <LoadingButton
                            disableElevation
                            id="apply-btn"
                            variant="contained"
                            color="primary"
                            type="submit"
                            style={{ width: "100%" }}
                            pending={this.props.adding}
                          >
                            {this.props.side ? "Apply" : "Create SOW"}
                          </LoadingButton>
                        </Grid>
                      )}
                    </form>
                  </TabPanel>
                  <TabPanel value={this.state.tab} index={1}>
                    <div id="revisions">
                      {this.props.revisions && this.props.revisions.length ? (
                        <List className={`revision-list small`}>
                          {this.props.revisions.map((revision, i) => {
                            return (
                              <ListItem
                                button
                                key={`revision-${i}`}
                                data-id={revision._id.toString()}
                                className={`revision editable ${
                                  this.props.revisionIndex === i
                                    ? "selected"
                                    : ""
                                }`}
                                onClick={() => {
                                  this.props.loadRevision(
                                    revision._id.toString(),
                                    i
                                  );
                                }}
                              >
                                <span>
                                  {moment(
                                    revision.saved.time ||
                                      ObjectID(revision._id).getTimestamp()
                                  ).format("lll")}
                                  &emsp;{" "}
                                  {revision.data.approved &&
                                    !revision.data.sentToClient &&
                                    !revision.data.rejected && (
                                      <Chip
                                        size="small"
                                        label="Approved"
                                        color="success"
                                      />
                                    )}
                                  {!revision.data.approved &&
                                    revision.data.sentToClient &&
                                    !revision.data.rejected && (
                                      <Chip
                                        size="small"
                                        label="Sent to Client"
                                        color="info"
                                      />
                                    )}
                                  {!revision.data.approved &&
                                    !revision.data.sentToClient &&
                                    revision.data.rejected && (
                                      <Chip
                                        size="small"
                                        label="Rejected"
                                        color="error"
                                      />
                                    )}
                                  {!revision.data.approved &&
                                    !revision.data.sentToClient &&
                                    revision.data.revised && (
                                      <Chip
                                        size="small"
                                        label="Revised"
                                        color="secondary"
                                      />
                                    )}
                                </span>
                                <span className="by-line">
                                  by{" "}
                                  {this.getUserInfo(revision.saved.id)?.name ||
                                    revision.saved.name}
                                </span>
                              </ListItem>
                            );
                          })}
                        </List>
                      ) : (
                        <Box
                          p={2}
                          display="flex"
                          alignItems="center"
                          justifyContent="center"
                        >
                          <Typography variant="overline" color="textSecondary">
                            No recent revisions
                          </Typography>
                        </Box>
                      )}
                      {this.props.revisions &&
                        this.props.totalRevisions >
                          this.props.revisions.length && (
                          <Box mb={2}>
                            <LoadingButton
                              fullWidth
                              type="button"
                              variant="outlined"
                              onClick={this.props.loadMoreRevisions}
                              pending={this.props.loadingMoreRevisions}
                            >
                              Load More
                            </LoadingButton>
                            <br />
                          </Box>
                        )}
                      {typeof this.props.revisionIndex === "number" ? (
                        <Box
                          display="flex"
                          fullWidth
                          sx={{
                            "&": {
                              m: -0.5,
                            },
                            "& > :not(style)": {
                              flex: 1,
                              m: 0.5,
                            },
                          }}
                        >
                          <Button
                            variant="outlined"
                            color="primary"
                            onClick={this.props.applyRevision}
                          >
                            Revert
                          </Button>
                          <Button
                            variant="outlined"
                            color="secondary"
                            onClick={this.props.cancelRevision}
                          >
                            Cancel
                          </Button>
                        </Box>
                      ) : (
                        ""
                      )}
                    </div>
                  </TabPanel>
                </div>
              </div>
              <Box id="additional-settings" pb={2}>
                {sessionStorage.getItem("sow-recent-history") &&
                  JSON.parse(sessionStorage.getItem("sow-recent-history"))
                    ?.docNum === this.props.docNum && (
                    <Box mb={2}>
                      <Button
                        fullWidth
                        size="small"
                        color="secondary"
                        variant="outlined"
                        onClick={this.props.restore}
                      >
                        Restore Unsaved Changes
                      </Button>
                    </Box>
                  )}
                <Box
                  display="flex"
                  sx={{
                    "&": {
                      m: -0.5,
                    },
                    "& > :not(style)": {
                      flex: 1,
                      m: 0.5,
                    },
                  }}
                >
                  <Tooltip
                    disableInteractive
                    placement="top"
                    title="Clone"
                    enterDelay={250}
                    enterNextDelay={250}
                  >
                    <LoadingButton
                      size="small"
                      variant="outlined"
                      color="secondary"
                      id="clone-doc"
                      className="public-btn"
                      onClick={this.props.clone}
                      pending={this.props.cloning}
                    >
                      <FileCopyIcon fontSize="small" />
                    </LoadingButton>
                  </Tooltip>
                  {this.props.docNum !== "MAST" && !this.props.approved && (
                    <Tooltip
                      disableInteractive
                      placement="top"
                      title="New Version"
                      enterDelay={250}
                      enterNextDelay={250}
                    >
                      <LoadingButton
                        size="small"
                        variant="outlined"
                        color="secondary"
                        id="new-version"
                        onClick={this.props.newVersion}
                        pending={this.props.cloning}
                      >
                        <AddBoxIcon fontSize="small" />
                      </LoadingButton>
                    </Tooltip>
                  )}

                  {(this.props.approved || this.props.docNum === "MAST") && (
                    <Tooltip
                      disableInteractive
                      placement="top"
                      title="Briefs"
                      enterDelay={250}
                      enterNextDelay={250}
                    >
                      <Button
                        component={Link}
                        size="small"
                        variant="outlined"
                        className="public-btn"
                        to={`/${this.props.match.params.id}/brief`}
                      >
                        <AssignmentIcon fontSize="small" />
                      </Button>
                    </Tooltip>
                  )}
                  {/* {this.props.importingTeamwork ? (
              <Button variant="link" disabled className="public-btn">
                <Spinner animation="border" role="status" size="small">
                  <span className="sr-only">Loading...</span>
                </Spinner>
                &nbsp;Importing into Teamwork...
              </Button>
            ) : this.props.teamworkID ? (
              <Button
                variant="link"
                href={`https://thatsnice.teamwork.com/#/projects/${this.props.teamworkID}/tasks`}
                target="_blank"
                className="public-btn"
              >
                View Teamwork Project
              </Button>
            ) : (
              <Button
                variant="link"
                id="import-teamwork"
                className="text-primary public-btn"
                onClick={this.props.importTeamwork}
              >
                Import into Teamwork
              </Button>
            )} */}

                  {/* <br />
            <Button
              variant="link"
              id="delete-doc"
              className="text-danger"
              onClick={this.props.delete}
            >
              Delete Document
            </Button> */}
                  {!this.props.approved && (
                    <>
                      {this.props.docNum !== "MAST" && this.props.rejected ? (
                        <Tooltip
                          disableInteractive
                          placement="top"
                          title="Unreject"
                          enterDelay={250}
                          enterNextDelay={250}
                        >
                          <Button
                            disableElevation
                            size="small"
                            variant="contained"
                            color="error"
                            id="unreject-doc"
                            onClick={this.props.unrejectSOW}
                          >
                            <ThumbDownIcon fontSize="small" />
                          </Button>
                        </Tooltip>
                      ) : (
                        <Tooltip
                          disableInteractive
                          placement="top"
                          title="Reject"
                          enterDelay={250}
                          enterNextDelay={250}
                        >
                          <Button
                            size="small"
                            variant="outlined"
                            color="error"
                            id="reject-doc"
                            onClick={this.props.rejectSOW}
                          >
                            <ThumbDownIcon fontSize="small" />
                          </Button>
                        </Tooltip>
                      )}
                    </>
                  )}

                  {/* <hr />
            <div className="small text-secondary">
              {this.props.lastModified ? (
                <>
                  <span>
                    Last modified by: <br />
                    {this.props.lastModified.name}{" "}
                    <span
                      title={moment(this.props.lastModified.time).format(
                        "LLLL"
                      )}
                    >
                      ({moment(this.props.lastModified.time).fromNow()})
                    </span>
                  </span>
                  <br />
                  <br />
                </React.Fragment>
              ) : null}
              <span>
                Created by: <br />
                {this.props.createdBy}
              </span>
            </div> */}
                </Box>
                <hr />
                <Box className="small">
                  <Box>
                    Created by:
                    <Grid display="flex">
                      <Grid
                        item
                        mr={1}
                        display="flex"
                        alignItems="center"
                        justifyContent="content"
                      >
                        <Avatar
                          alt={this.getUserInfo(this.props.createdBy)?.name}
                          src={this.getUserInfo(this.props.createdBy)?.picture}
                          sx={{
                            "&": {
                              width: 30,
                              height: 30,
                            },
                          }}
                        />
                      </Grid>
                      <Grid item>
                        {this.getUserInfo(this.props.createdBy)?.name ||
                          "User not found"}
                        <br />
                        <em>{moment(this.props.createdAt).format("llll")}</em>
                      </Grid>
                    </Grid>
                  </Box>
                </Box>
              </Box>
            </Grid>
          </div>
        </Container>
      </Drawer>
    );
  }
}

Settings.propTypes = {
  onSubmit: PropTypes.func,
  onClick: PropTypes.func,
  company: PropTypes.string,
  type: PropTypes.string,
  quarter: PropTypes.string,
  custom: PropTypes.string,
  year: PropTypes.string,
  adding: PropTypes.bool,
  show: PropTypes.bool,
  docNum: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  docType: PropTypes.string,
  // date: PropTypes.number
  currency: PropTypes.string,
  delete: PropTypes.func,
  clone: PropTypes.func,
  newVersion: PropTypes.func,
  createdBy: PropTypes.string,
  lastModified: PropTypes.object,
  fromMember: PropTypes.array,
  revisions: PropTypes.array,
  loadRevision: PropTypes.func,
  cancelRevision: PropTypes.func,
  applyRevision: PropTypes.func,
  revisionIndex: PropTypes.number,
  totalRevisions: PropTypes.number,
  loadMoreRevisions: PropTypes.func,
  restore: PropTypes.func,
  // createdAt: PropTypes.string,
  normalAdmin: PropTypes.bool,
};

export default withRouter(Settings);
