import _ from "lodash";

export function addTable(e, i) {
  e.stopPropagation();

  // console.log(this.state.formTable);
  let selectedIndex = i !== null ? i : this.state.formTable;

  if (
    Number.isInteger(selectedIndex) &&
    selectedIndex + 1 !== this.state.tables
  ) {
    let updatedTableNames = {};

    let stateKeys = Object.keys(this.state);

    stateKeys.push(`tableName-${this.state.tables}`);

    stateKeys.forEach((key) => {
      if (key.startsWith("tableName-")) {
        let index = parseInt(key.replace("tableName-", ""));

        if (index > selectedIndex) {
          if (index === selectedIndex + 1) {
            updatedTableNames[key] = "";
          } else {
            updatedTableNames[key] = this.state[`tableName-${index - 1}`];
          }
          // this.setState({
          //   [key]: prevName,
          // });
        } else if (index === selectedIndex) {
          // updatedTableNames[key] = ''
          // this.setState({
          //   [key]: '',
          // });
        }
      }
    });

    // console.log('updatedNames', updatedTableNames)

    // let currentContent = [...this.state.content];
    let currentContent = _.cloneDeep(this.state.content);

    for (let section of currentContent) {
      if (section.table > selectedIndex) {
        section.table = section.table + 1;
      }
    }

    let currentTotals = { ...this.state.totals };

    Object.keys(currentTotals).forEach((key) => {
      let index = parseInt(key.split("-")[0]);
      let type = key.split("-")[1];

      // console.log(index, type)

      if (index > selectedIndex) {
        if (index === selectedIndex + 1) {
          currentTotals[key] = 0;
        } else {
          currentTotals[key] = this.state.totals[`${index - 1}-${type}`];
        }
      }
    });

    currentTotals[
      `${this.state.tables}-existingSpendTotal`
    ] = this.state.totals[`${this.state.tables - 1}-existingSpendTotal`];
    currentTotals[`${this.state.tables}-newSpendTotal`] = this.state.totals[
      `${this.state.tables - 1}-newSpendTotal`
    ];
    currentTotals[`${this.state.tables}-passThroughTotal`] = this.state.totals[
      `${this.state.tables - 1}-passThroughTotal`
    ];

    // console.log(this.state.totals)
    // console.log(currentTotals)

    this.setState({
      ...updatedTableNames,
      content: currentContent,
      tables: this.state.tables + 1,
      totals: currentTotals,
    });
  } else {
    this.setState({
      tables: this.state.tables + 1,
    });
  }

  this.setState(
    {
      // tables: this.state.tables + 1,
      undoRedo: false,
    },
    () => {
      this.injectDeliverables();
    }
  );
}

export function removeTable(e, i) {
  e.stopPropagation();

  // console.log(this.state.formTable);
  let selectedIndex = i !== null ? i : this.state.formTable;
  let includedSections = this.state.content.filter(
    (section) => section.deliverable && section.table === selectedIndex
  );

  console.log(includedSections);

  if (includedSections.length) {
    this.showNotification(
      "error",
      "Can not remove table with deliverables in it! Please move the deliverables to another table before removing."
    );
    return false;
  }

  if (Number.isInteger(selectedIndex)) {
    let updatedTableNames = {};

    Object.keys(this.state).forEach((key) => {
      if (key.startsWith("tableName-")) {
        let index = parseInt(key.replace("tableName-", ""));

        if (index >= selectedIndex) {
          updatedTableNames[key] = this.state[`tableName-${index + 1}`];
          // this.setState({
          //   [key]: this.state[`tableName-${index + 1}`],
          // });
        }
      }
    });

    let currentContent = _.cloneDeep(this.state.content);

    for (let section of currentContent) {
      if (section.table >= selectedIndex) {
        section.table = section.table - 1;
      }
    }

    let currentTotals = { ...this.state.totals };

    Object.keys(currentTotals).forEach((key) => {
      let index = parseInt(key.split("-")[0]);
      let type = key.split("-")[1];

      // console.log(index, type)

      if (index >= selectedIndex) {
        currentTotals[key] = currentTotals[`${index + 1}-${type}`];
      }
    });

    // console.log(currentTotals)

    this.setState({
      ...updatedTableNames,
      content: currentContent,
      tables: this.state.tables - 1,
      totals: currentTotals,
    });
  }

  // this.setState(
  //   {
  //     tables: this.state.tables - 1,
  //     [`tableName-${this.state.tables - 1}`]: null,
  //     undoRedo: false,
  //   },
  //   () => {
  //     this.injectDeliverables();
  //   }
  // );
}

export function editTableName(e, i) {
  this.setState({
    [`editTableName-${i}`]: true,
    [`formTableName-${i}`]: e.target.textContent,
  });
}

export function saveTableName(e, i) {
  e.preventDefault();
  this.setState(
    {
      [`tableName-${i}`]: this.state[`formTableName-${i}`],
      [`editTableName-${i}`]: false,
      dirty: true,
      undoRedo: false,
    },
    () => {
      this.saveHistory();
      this.injectDeliverables();
      this.setTotalPrice();
    }
  );
}

export function cancelTableName(i) {
  this.setState({
    [`editTableName-${i}`]: false,
  });
}

export function totalWidth() {
  try {
    let totalWidth =
      document.querySelector("#content #table-0 .existing-spend").offsetWidth +
      document.querySelector("#content #table-0 .pass-through").offsetWidth +
      (this.state.hasExistingSpend && this.state.hasPassThrough ? 1 : 2) *
        document.querySelector("#content #table-0 .new-spend").offsetWidth +
      1;

    this.setState({
      totalWidth,
    });
  } catch (err) {
    console.error(err);
  }

  this.setStepsTableWidth();
}

export function setStepsTableWidth() {
  try {
    let totalWidthSteps =
      document.querySelector("#steps #table-0 .existing-spend").offsetWidth +
      document.querySelector("#steps #table-0 .pass-through").offsetWidth +
      (this.state.hasExistingSpend && this.state.hasPassThrough ? 1 : 2) *
        document.querySelector("#steps #table-0 .new-spend").offsetWidth +
      1;

    // for (let table of document.querySelectorAll("#steps .total-table")) {
    //   table.setAttribute('style', `max-width: ${totalWidthSteps}px`)
    // }
    // let fitWidth = 0;
    // for (let cell of document.querySelectorAll(
    //   "#steps #table-0 thead .fit"
    // )) {
    //   if (!this.isHidden(cell)) {
    //     fitWidth = cell.offsetWidth;
    //     break;
    //   }
    // }

    let style = document.createElement("style");
    style.setAttribute("id", "total-table-width");
    let html = `
        #steps .total-table {
          max-width:  ${totalWidthSteps}px !important;
        }`;
    style.innerHTML = html;
    if (!document.querySelector("#total-table-width")) {
      document.head.appendChild(style);
    } else {
      document.querySelector("#total-table-width").innerHTML = html;
    }

    // document.querySelector("#generated-tables").classList.remove("loading");
  } catch (err) {
    // console.error(err);
  }
}
