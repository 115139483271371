/**
 *
 * Search
 *
 */

import React from "react";
import {
  Box,
  Grid,
  CircularProgress,
  TextField,
  IconButton,
} from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import CloseIcon from "@material-ui/icons/Close";
import PropTypes from "prop-types";

let searchTO;

class Search extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      // searching: false,
      query: props.query || "",
    };
  }

  componentDidMount() {
    // if (this.props.query) {
    //   this.setState({
    //     query: this.props.query
    //   });
    // } else if (localStorage.getItem("sow-filters") && JSON.parse(localStorage.getItem("sow-filters")).query) {
    //   this.setState({
    //     query: JSON.parse(localStorage.getItem("sow-filters")).query
    //   });
    // }
  }

  // componentDidUpdate(prevProps, nextProps) {
  //   console.log(
  //     "did update",
  //     prevProps.searching,
  //     this.props.searching,
  //     nextProps.searching
  //   );
  //   // if (this.props.searching !== nextProps.searching) {
  //   //   this.setState({
  //   //     searching: nextProps.searching
  //   //   });
  //   // }
  // }

  // static getDerivedStateFromProps(props, state) {
  //  console.log(props)
  // }

  onSearch(e) {
    let value = e.target.value;

    clearTimeout(searchTO);

    if (e.target.name === "company") {
      this.setState({
        company: value,
      });
    } else {
      if (e) {
        // this.setState({
        //   query: e.target.value
        // });
        this.props.handleInputChange(e);
      }
    }

    // if (!value.trim().length) {
    //   return false;
    // }

    if (!this.props.searching) {
      this.props.onSearch();
    }

    searchTO = setTimeout(() => {
      this.props.onFinish(this.props.query, this.state.company);

      if (!this.props.company) {
        this.props.clearDocs();
      }

      if (document.querySelector("#show-approved")) {
        localStorage.setItem(
          "sow-filters",
          JSON.stringify({
            // showApproved: document.querySelector("#show-approved").checked,
            query: this.props.query,
          })
        );
      }
    }, 700);
  }

  clearQuery() {
    this.setState(
      {
        query: "",
      },
      () => {
        if (!this.props.company) {
          localStorage.setItem(
            "sow-filters",
            JSON.stringify({
              // showApproved: document.querySelector("#show-approved").checked,
              query: "",
            })
          );
        }
        this.props.onClear();
      }
    );
  }

  render() {
    return (
      <Grid container>
        {this.props.company && (
          <>
            <Grid>
              <TextField
                fullWidth
                label="SOW Name"
                variant="outlined"
                type="search"
                size="small"
                name="company"
                value={this.state.importCompanyName}
                onChange={this.onSearch.bind(this)}
              />
            </Grid>
            &emsp;
          </>
        )}
        <Grid className="search">
          <TextField
            fullWidth
            label={this.props.company ? "Section Name" : "Search"}
            variant="outlined"
            type="search"
            size="small"
            name="Search"
            value={this.props.query || ""}
            onChange={this.onSearch.bind(this)}
          />
          <div className="search-actions" style={{ pointerEvents: "none" }}>
            {!!this.props.searching && <CircularProgress size={20} />}
            {!this.props.searching && !this.props.query && (
              <SearchIcon color="disabled" />
            )}
            {this.props.query && !this.props.searching && (
              <Box mr={-0.25}>
                <IconButton
                  size="small"
                  onClick={this.clearQuery.bind(this)}
                  style={{ pointerEvents: "all" }}
                >
                  <CloseIcon />
                </IconButton>
              </Box>
            )}
          </div>
        </Grid>
      </Grid>
    );
  }
}

Search.propTypes = {
  onFinish: PropTypes.func,
  onClear: PropTypes.func,
  searching: PropTypes.bool,
  onSearch: PropTypes.func,
  handleInputChange: PropTypes.func,
  query: PropTypes.string,
  clearDocs: PropTypes.func,
};

export default Search;
