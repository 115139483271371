import moment from "moment";
import _ from "lodash";
// import { AnonymousCredential } from "mongodb-stitch-browser-sdk";
import update from "immutability-helper";

export function setSalesPerson() {
  if (this.props.normalAdmin) {
    return false;
  } else {
    let memberId = this.props.user.email;
    let match = this.state.members.find(
      (member) => member.Email === this.props.user.email
    );

    if (match) {
      memberId = match._id.toString();
    }

    this.setState({
      salesPerson: {
        label: this.props.user.name,
        value: memberId,
      },
    });
  }
}

export async function fetchTeam() {
  await this.props.db
    .collection("member")
    .find({})
    .asArray()
    .then((members) => {
      this.setState(
        {
          members,
        },
        () => {
          if (this.props.user) {
            this.setSalesPerson();
          }
        }
      );
    })
    .catch(console.error);
}

export function fetchFilterData() {
  this.fetchTeam().then(() => {
    this.props.db
      .collection(this.props.collection)
      .find(
        {
          company: {
            $nin: ["MAST", "Master", "MASTER"],
          },
        },
        {
          projection: {
            // might as well grab members that were deleted and no longer in mongo
            company: 1,
            salesPerson: 1,
            accountManagement: 1,
          },
        }
      )
      .asArray()
      .then((documents) => {
        let companiesArray = [];
        let salesPersonArray = [];
        let accountManagementArray = [];

        for (let doc of documents) {
          companiesArray.push({
            label: doc["company"].trim(),
            value: doc["company"].replace(/\s/g, "").toLowerCase(),
          });

          if (doc.salesPerson) {
            for (let salesPerson of doc.salesPerson) {
              salesPersonArray.push({
                Name: salesPerson.label,
                _id: salesPerson.value,
              });
            }
          }
          if (doc.accountManagement) {
            for (let accountManagement of doc.accountManagement) {
              accountManagementArray.push({
                Name: accountManagement.label,
                _id: accountManagement.value,
              });
            }
          }
        }

        let uniqueCompanies = this.removeDuplicates(companiesArray, "value");
        let uniqueSalesPersons = this.removeDuplicates(salesPersonArray, "_id");
        let uniqueAccountManagement = this.removeDuplicates(
          accountManagementArray,
          "_id"
        );

        let currentMembers = [...this.state.members];

        for (let member of [
          ...uniqueSalesPersons,
          ...uniqueAccountManagement,
        ]) {
          let match = currentMembers.find(
            (m) => member._id === m._id.toString()
          );

          if (!match) {
            currentMembers.push(member);
          }
        }

        this.setState({
          companies: _.sortBy(uniqueCompanies, "value"),
          members: currentMembers,
        });
      })
      .catch(console.error);
  });
}

export function loadMembers() {
  if (!this.state.members.length) {
    // this.fetchTeam();
  }
}

export function clearDocs() {
  this.setState({
    lastDocNum: null,
    documents: [],
  });
}

export function clearSearch() {
  this.setState(
    {
      lastDocNum: null,
      documents: [],
      query: "",
      Search: "",
    },
    () => {
      this.displayDocs(this.state.query);
    }
  );
}

export function clearFilters(key) {
  this.setState(
    {
      [key]: false,
      reportFilters: _.omit(this.state.reportFilters, [key]),
    },
    () => {
      this.runReport();
    }
  );
}

export function toggleAdvanced() {
  this.setState(
    {
      showAdvanced: !this.state.showAdvanced,
    },
    () => {
      this.setListHeight();
    }
  );
}

export function setListHeight() {
  setTimeout(() => {
    try {
      let height =
        document
          .querySelector("#sow-list .MuiTableBody-root")
          .getBoundingClientRect().top + 65;
      // console.log(window.innerHeight - height);
      this.setState({
        listHeight: window.innerHeight - height - 16,
      });

      // document
      //   .querySelector("#sow-list tbody")
      //   .setAttribute("style", `max-height: calc(100vh - ${height}px)`);
    } catch (err) {
      console.error(err);
    }
  }, 0);
}

export function displayDocs(query, reset) {
  // console.log("display docs");

  if (query) {
    query = query.trim();
  }

  if (query && !query.length) {
    this.setState({
      loading: false,
      searching: false,
    });
    return false;
  }

  var find;
  let thisQuery;
  let statusFilter = [];

  if (this.state.showApprovedReport) {
    statusFilter.push({ approved: true });
  }
  if (this.state.showSentToClientReport) {
    statusFilter.push({ sentToClient: true });
  }
  if (this.state.showRejectedReport) {
    statusFilter.push({ rejected: true });
  }
  if (this.state.showActionNeededReport) {
    statusFilter.push({
      $or: [
        {
          rejected: {
            $ne: true,
          },
          revised: {
            $ne: true,
          },
          approved: {
            $ne: true,
          },
          "lastModified.time": {
            $lte: new Date(moment(new Date()).subtract(2, "weeks")),
          },
        },
        {
          approved: true,
          eApproved: true,
          "approvedBy.acknowledgedBy": {
            $exists: false,
          },
        },
      ],
    });
  }

  let currencyFilter = [];

  if (this.state.showUSD) {
    currencyFilter.push({
      currency: {
        $eq: "USD",
      },
    });
  }

  if (this.state.showEUR) {
    currencyFilter.push({
      currency: {
        $eq: "EUR",
      },
    });
  }

  if (this.state.showGBP) {
    currencyFilter.push({
      currency: {
        $eq: "GBP",
      },
    });
  }

  let dateArray = [
    {
      "lastModified.time": {
        $gte: new Date(moment(this.state.startDate).startOf("day")),
        $lte: new Date(moment(this.state.endDate).endOf("day")),
      },
    },
    {
      "approvedBy.time": {
        $gte: new Date(moment(this.state.startDate).startOf("day")),
        $lte: new Date(moment(this.state.endDate).endOf("day")),
      },
    },
    {
      customDate: {
        $gte: new Date(moment(this.state.startDate).startOf("day")),
        $lte: new Date(moment(this.state.endDate).endOf("day")),
      },
    },
  ];

  if (statusFilter.length && !currencyFilter.length) {
    find = {
      $and: [
        {
          $or: statusFilter,
        },
        {
          $or: dateArray,
        },
      ],
    };
  } else if (currencyFilter.length && !statusFilter.length) {
    find = {
      $and: [
        {
          $or: currencyFilter,
        },
        {
          $or: dateArray,
        },
      ],
    };
  } else if (currencyFilter.length && statusFilter.length) {
    find = {
      $and: [
        {
          $or: statusFilter,
        },
        {
          $or: currencyFilter,
        },
        {
          $or: dateArray,
        },
      ],
    };
  } else {
    find = {
      $or: dateArray,
    };
  }

  thisQuery = "";

  if (this.state.currency) {
    find.currency = {
      $eq: this.state.currency.value,
    };
  }

  if (this.state.company) {
    find.company = {
      $regex: this.state.company.label,
      $options: "i",
    };
  }

  if (this.state.salesPerson) {
    find["salesPerson.value"] = {
      $eq: this.state.salesPerson.value,
    };
  }

  if (this.state.accountManagement) {
    find["accountManagement.value"] = {
      $eq: this.state.accountManagement.value,
    };
  }

  // console.log(find);

  this.setState(
    {
      query: thisQuery,
    },
    () => {
      if (window.location.hostname !== "localhost") {
        find.test = undefined;
      }

      if (this.state.lastDocNum && !reset) {
        find.docNum = { $lt: this.state.lastDocNum };
        // console.log(find);
      }

      find = {
        $and: [
          { ...find },
          {
            docNum: {
              $ne: "MAST",
            },
          },
        ],
      };

      this.props.db
        .collection(this.props.collection)
        .count(
          this.state.showApprovedReport ||
            this.state.showSentToClientReport ||
            this.state.showRejectedReport ||
            this.state.showActionNeededReport ||
            this.state.query
            ? find
            : { test: undefined }
        )
        .then((total) => {
          // let prevTotal = this.state.total;
          if (total) {
            this.setState({
              total: total,
            });
          } else {
            this.setState({
              total: 0,
              loading: false,
              noResults: true,
            });
          }

          this.props.db
            .collection(this.props.collection)
            .find(find, {
              sort: { docNum: -1 },
              projection: {
                "content.editorHTML": 0,
                contactHTML: 0,
                logo: 0,
                team: 0,
                attachedFiles: 0,
                rates: 0,
              },
            })
            .asArray()
            .then((documents) => {
              // console.log(find, documents);
              let updatedDocs;
              let noResults;

              if (reset) {
                updatedDocs = documents;
              } else {
                updatedDocs = update(this.state.documents, {
                  $push: documents,
                });
              }

              // set new keys
              for (let doc of updatedDocs) {
                let fromMember = [];
                if (doc.fromMember && doc.fromMember.length) {
                  fromMember = [...doc.fromMember];
                }
                if (doc.fromNigel) {
                  fromMember.push({
                    label: "Nigel Walker",
                  });
                } else if (doc.fromGwenael) {
                  fromMember.push({
                    label: "Gwenael Servant",
                  });
                } else if (doc.fromMark) {
                  fromMember.push({
                    label: "Mark Allen",
                  });
                } else if (doc.fromWei) {
                  fromMember.push({
                    label: "Wei Gao",
                  });
                } else if (doc.fromGuy) {
                  fromMember.push({
                    label: "Guy Tiene",
                  });
                } else if (doc.fromYiyi) {
                  fromMember.push({
                    label: "Yiyi Liu",
                  });
                } else if (doc.fromLulu) {
                  fromMember.push({
                    label: "Shiyun (Lulu) Ruan",
                  });
                }
                fromMember = this.removeDuplicates(fromMember, "label");
                doc.fromMember = [...fromMember];

                let status = "";

                if (doc.clone) {
                  status = "Clone";
                } else if (doc.approved && !doc.sentToClient && !doc.rejected) {
                  status = "Approved";
                } else if (!doc.approved && doc.sentToClient && !doc.rejected) {
                  status = "Sent to Client";
                } else if (!doc.approved && !doc.sentToClient && doc.rejected) {
                  status = "Rejected";
                } else if (!doc.approved && !doc.sentToClient && doc.revised) {
                  status = "Revised";
                }

                doc.status = status;

                let displayDate = doc.customDate
                  ? doc.customDate
                  : doc.approvedBy && doc.approvedBy.time && doc.approved
                  ? doc.approvedBy.time
                  : doc.lastModified && doc.lastModified.time
                  ? doc.lastModified.time
                  : doc.date;
                doc.displayDate = new Date(displayDate).getTime();
                doc.displaySalesPerson =
                  doc.salesPerson && doc.salesPerson.length
                    ? doc.salesPerson[0].label
                    : doc.salesPerson && doc.salesPerson.label;
                doc.displayAccountManagement =
                  doc.accountManagement && doc.accountManagement.length
                    ? doc.accountManagement[0].label
                    : doc.accountManagement && doc.accountManagement.label;

                // let totals = this.calculateTotalPrice(doc);
                // let totals = 0;
                // doc.totalPrice = totals.totalPrice;
                // doc.passThrough = totals.totalPT;
                // doc.directSpend = totals.totalDS;
                // doc.directSpend = this.totalBreakdown(
                //   "newSpendTotal",
                //   totals.totals
                // ) - parseFloat(doc.newSpendDiscount ? doc.newSpendDiscount : 0);
                // doc.passThrough = this.totalBreakdown(
                //   "passThroughTotal",
                //   totals.totals
                // );
              }

              updatedDocs = updatedDocs
                .filter((r) => {
                  let displayDate = r.displayDate;

                  if (
                    displayDate >=
                      new Date(moment(this.state.startDate).startOf("day")) &&
                    displayDate <=
                      new Date(moment(this.state.endDate).endOf("day"))
                  ) {
                    return true;
                  } else {
                    return false;
                  }
                })
                .filter((s) => {
                  if (Array.isArray(s.salesPerson)) {
                    if (
                      this.state.salesPerson &&
                      s.salesPerson[0].value !== this.state.salesPerson?.value
                    ) {
                      return false;
                    } else {
                      return true;
                    }
                  } else {
                    return true;
                  }
                })
                .filter((s) => {
                  if (Array.isArray(s.accountManagement)) {
                    if (
                      this.state.accountManagement &&
                      s.accountManagement[0].value !==
                        this.state.accountManagement?.value
                    ) {
                      return false;
                    } else {
                      return true;
                    }
                  } else {
                    return true;
                  }
                });

              if (updatedDocs.length) {
                noResults = false;
              } else {
                noResults = true;
              }

              this.setState(
                {
                  documents: updatedDocs,
                  // loading: false,
                  noResults: noResults,
                  searching: false,
                  // running: false,
                },
                () => {
                  if (this.state.documents.length) {
                    this.setState({
                      lastDocNum: this.state.documents[
                        this.state.documents.length - 1
                      ].docNum,
                    });
                  }

                  let reportFilters = {
                    startDate: this.state.startDate,
                    endDate: this.state.endDate,
                    showApprovedReport: this.state.showApprovedReport,
                    showRejectedReport: this.state.showRejectedReport,
                    showActionNeededReport: this.state.showActionNeededReport,
                    showSentToClientReport: this.state.showSentToClientReport,
                    query: this.state.query,
                    // fromMember: this.state.fromMember,
                    company: this.state.company,
                    salesPerson: this.state.salesPerson,
                    accountManagement: this.state.accountManagement,
                    showUSD: this.state.showUSD,
                    showEUR: this.state.showEUR,
                    showGBP: this.state.showGBP,
                    showAdvanced: this.state.showAdvanced,
                  };

                  this.setState({
                    reportFilters,
                  });

                  localStorage.setItem(
                    "sow-filters-report",
                    JSON.stringify(reportFilters)
                  );

                  // console.log("set tbody");

                  this.calculateTotalPriceWorker(this.state.documents);
                }
              );
              // console.log("data", documents);
            });
        });
    }
  );
}

export function removeDuplicates(myArr, prop) {
  return myArr.filter((obj, pos, arr) => {
    return arr.map((mapObj) => mapObj[prop]).indexOf(obj[prop]) === pos;
  });
}

export function injectActiveFilters() {
  // try {
  //   document.querySelector("#filter-results").innerHTML = `${moment(
  //     this.state.startDate
  //   ).format("L")} - ${moment(this.state.endDate).format("L")}${
  //     this.state.showApprovedReport ? "<br/> Approved" : ""
  //   }${this.state.showSentToClientReport ? "; Sent to Client" : ""}${
  //     this.state.showRejectedReport ? "; Rejected" : ""
  //   }${this.state.showActionNeededReport ? "; Action Needed" : ""}${
  //     this.state.company ? `; Company: ${this.state.company.label}` : ""
  //   }${
  //     this.state.salesPerson
  //       ? `; Sales Person: ${this.state.salesPerson.label}`
  //       : ""
  //   }${
  //     this.state.currency ? `; Currency: ${this.state.currency.label}` : ""
  //   }`;
  // } catch (err) {}
}

export function displayDocsOnLoad() {
  // this.props.client.auth
  //   .loginWithCredential(new AnonymousCredential())
  //   // .then(() => {
  //   //   this.props.db
  //   //     .collection(this.props.collection)
  //   //     .count()
  //   //     .then(total => {
  //   //       this.setState({
  //   //         total
  //   //       });
  //   //     });
  //   // })
  //   .then(() => {
  if (localStorage.getItem("sow-filters-report")) {
    this.setState(
      {
        loading: true,
        running: true,
        showApprovedReport: JSON.parse(
          localStorage.getItem("sow-filters-report")
        ).showApprovedReport,
        showRejectedReport: JSON.parse(
          localStorage.getItem("sow-filters-report")
        ).showRejectedReport,
        showSentToClientReport: JSON.parse(
          localStorage.getItem("sow-filters-report")
        ).showSentToClientReport,
        showActionNeededReport: JSON.parse(
          localStorage.getItem("sow-filters-report")
        ).showActionNeededReport,
        startDate: new Date(
          JSON.parse(localStorage.getItem("sow-filters-report")).startDate
        ),
        endDate: new Date(
          JSON.parse(localStorage.getItem("sow-filters-report")).endDate
        ),
        // fromMember: JSON.parse(localStorage.getItem("sow-filters-report"))
        //   .fromMember,
        company: JSON.parse(localStorage.getItem("sow-filters-report")).company,
        salesPerson: JSON.parse(localStorage.getItem("sow-filters-report"))
          .salesPerson,
        accountManagement: JSON.parse(
          localStorage.getItem("sow-filters-report")
        ).accountManagement,
        showUSD: JSON.parse(localStorage.getItem("sow-filters-report")).showUSD,
        showEUR: JSON.parse(localStorage.getItem("sow-filters-report")).showEUR,
        showGBP: JSON.parse(localStorage.getItem("sow-filters-report")).showGBP,
        showAdvanced: JSON.parse(localStorage.getItem("sow-filters-report"))
          .showAdvanced,
      },
      () => {
        this.displayDocs();
        // document.querySelector('#run-report-btn').click()
      }
    );
  }
  // })
  // .catch(console.error);
}

export function handleReactSelect(name, target) {
  this.setState({
    [name]: target,
  });
}

export function enableSearching() {
  this.setState({
    searching: true,
  });
}

export function runReport(e) {
  if (e) {
    e.preventDefault();
  }

  this.setState(
    {
      running: true,
      lastDocNum: null,
      loading: true,
      documents: [],
    },
    () => {
      this.displayDocs(this.state.query);
    }
  );
}

export function clearCompany() {
  this.setState({
    company: "",
  });
}

export function orderName(a, b) {
  let comparison = 0;
  if (a.Name > b.Name) {
    comparison = 1;
  } else if (a.Name < b.Name) {
    comparison = -1;
  }
  return comparison;
}

export function getSiblings(elem) {
  return Array.prototype.filter.call(elem.parentNode.children, function (
    sibling
  ) {
    return sibling !== elem;
  });
}

export function sortDocsBy(by, event) {
  let sortAsc = this.state.sortAsc;

  if (by === this.state.activeSort) {
    sortAsc = !this.state.sortAsc;
  }

  event.target.classList.add("active");

  if (sortAsc) {
    event.target.classList.add("asc");
  } else {
    event.target.classList.remove("asc");
  }

  for (let cell of this.getSiblings(event.target)) {
    cell.classList.remove("active");
  }

  this.setState(
    {
      activeSort: by,
      sortAsc,
    },
    () => {
      let currentDocs = [...this.state.documents];
      let sortedDocs = _.orderBy(currentDocs, [by], sortAsc ? "asc" : "desc");

      this.setState({
        documents: [...sortedDocs],
      });
    }
  );
}

export function totalBreakdown(type, totals) {
  let match = Object.keys(totals)
    .filter((key) =>
      // new RegExp(/([\d])-newSpendTotal/, 'g').test(
      //   key
      // )
      key.match(`-${type}`)
    )
    .reduce((obj, key) => {
      obj[key] = totals[key];
      return obj;
    }, {});

  let matchTotal = Object.values(match).reduce((a, b) => a + b);

  return matchTotal;
}

export function calculateChartData(filter) {
  let totalsByPerson = [];

  if (
    this.state.documents.length > 1
    // this.state.reportFilters.showApprovedReport &&
    // !this.state.reportFilters.showEUR &&
    // !this.state.reportFilters.showGBP
  ) {
    let byPerson = _.sortBy(this.state.documents, `${filter}[0].label`).filter(
      (doc) => doc?.[filter]?.length
    );

    let persons = [...new Set(byPerson.map((x) => x?.[filter][0]?.label))];

    for (let person of persons) {
      let personDocs = byPerson.filter(
        (doc) => doc?.[filter]?.[0]?.label === person
      );
      let price = personDocs
        .filter((d) => d.currency === "USD")
        .reduce((a, b) => a + b.totalPrice, 0);
      let priceEUR = personDocs
        .filter((d) => d.currency === "EUR")
        .reduce((a, b) => a + b.totalPrice, 0);
      let priceGBP = personDocs
        .filter((d) => d.currency === "GBP")
        .reduce((a, b) => a + b.totalPrice, 0);
      totalsByPerson.push({
        name: person,
        fName: person.split(" ")[0],
        docs: personDocs,
        number: personDocs.length,
        price,
        priceEUR,
        priceGBP,
        formattedPrice: "$" + this.formatCurrency(price),
        formattedPriceEUR: "€" + this.formatCurrency(priceEUR),
        formattedPriceGBP: "£" + this.formatCurrency(priceGBP),
      });
    }
  }

  totalsByPerson = _.orderBy(totalsByPerson, "price", "desc");

  return totalsByPerson;
}
