import moment from "moment";
import update from "immutability-helper";
// import { AnonymousCredential } from "mongodb-stitch-browser-sdk";

export function onFocus() {
  if (new Date().getTime() - this.state.loadedAt >= 0.5 * 60 * 1000) {
    this.displayDocs(this.state.query, null, true);
  }
}

export function clearDocs() {
  this.setState({
    lastDocNum: null,
    documents: [],
  });
}

export function clearSearch() {
  this.setState(
    {
      lastDocNum: null,
      documents: [],
      query: "",
      Search: "",
    },
    () => {
      this.displayDocs(this.state.query);
    }
  );
}

export function clearFilters(filter) {
  if (filter === "query") {
    this.clearSearch();
    return;
  }

  if (filter.startsWith("show")) {
    this.setState(
      {
        lastDocNum: null,
        documents: [],
        [filter]: false,
      },
      () => {
        this.displayDocs(this.state.query);
      }
    );
  }

  // this.setState(
  //   {
  //     lastDocNum: null,
  //     documents: [],
  //     query: "",
  //     Search: "",
  //     showApproved: false,
  //     showSentToClient: false,
  //     showRejected: false,
  //     showRevised: false,
  //     showActionNeeded: false,
  //   },
  //   () => {
  //     this.displayDocs(this.state.query);
  //   }
  // );
}

export function displayDocs(query, reset, refresh) {
  // console.log("display docs");
  // if (
  //   localStorage.getItem("sow-filters") &&
  //   JSON.parse(localStorage.getItem("sow-filters")).query
  // ) {
  //   query = JSON.parse(localStorage.getItem("sow-filters")).query;
  // }

  // query the remote DB and update the component state

  if (query) {
    query = query.trim();
  }

  if (query && !query.length) {
    this.setState({
      loading: false,
      searching: false,
    });
    return false;
  }

  var regex;
  var find;
  let thisQuery;
  let statusFilter = [];

  if (this.state.showApproved) {
    statusFilter.push({ approved: true });
  }
  if (this.state.showSentToClient) {
    statusFilter.push({ sentToClient: true });
  }
  if (this.state.showRejected) {
    statusFilter.push({ rejected: true });
  }
  if (this.state.showRevised) {
    statusFilter.push({ revised: true });
  }
  if (this.state.showActionNeeded) {
    statusFilter.push({
      $or: [
        {
          docNum: {
            $ne: "MAST",
          },
          rejected: {
            $ne: true,
          },
          revised: {
            $ne: true,
          },
          approved: {
            $ne: true,
          },
          "lastModified.time": {
            $lte: new Date(moment(new Date()).subtract(2, "weeks")),
          },
        },
        {
          docNum: {
            $ne: "MAST",
          },
          approved: true,
          eApproved: true,
          "approvedBy.acknowledgedBy": {
            $exists: false,
          },
        },
      ],
    });
  }

  if (query && query.length) {
    regex = new RegExp(query.replace(/[-\\^$*+?.()|[\]{}]/g, "\\$&"), "i");
    // find = { Title: { $regex: regex } };
    find =
      this.state.showApproved ||
      this.state.showSentToClient ||
      this.state.showRejected ||
      this.state.showRevised ||
      this.state.showActionNeeded
        ? {
            Title: { $regex: regex },
            $or: statusFilter,
          }
        : { Title: { $regex: regex } };
    // this.setState({
    //   query
    // });

    thisQuery = query;
  } else {
    find =
      this.state.showApproved ||
      this.state.showSentToClient ||
      this.state.showRejected ||
      this.state.showRevised ||
      this.state.showActionNeeded
        ? {
            $or: statusFilter,
          }
        : {};
    // this.setState({
    //   query: ""
    // });

    thisQuery = "";
  }

  // if (query && !query.trim().length) {
  //   console.log(query.trim().length)
  //   return false
  // }

  this.setState(
    {
      query: thisQuery,
    },
    () => {
      if (window.location.hostname !== "localhost") {
        find.test = undefined;
      }

      let findCount = { ...find };

      if (this.state.lastDocNum && !reset) {
        find.docNum = { $lt: this.state.lastDocNum };
        // console.log(find);
      }

      if (refresh) {
        delete find.docNum;
      }

      this.props.db
        .collection(this.props.collection)
        .count(
          this.state.showApproved ||
            this.state.showSentToClient ||
            this.state.showRejected ||
            this.state.showRevised ||
            this.state.showActionNeeded ||
            this.state.query
            ? findCount
            : { test: undefined }
        )
        .then((total) => {
          if (total) {
            this.setState(
              {
                total: total,
              },
              () => {
                // if (total <= prevTotal) {
                //   console.log('sum total', total, prevTotal);
                //   // try {
                //     document.querySelector("#doc-count").textContent = `(${prevTotal + total})`
                //   // } catch(err) {}
                // }
              }
            );
          } else {
            this.setState({
              total: 0,
              loading: false,
              noResults: true,
            });
          }

          this.props.db
            .collection(this.props.collection)
            .find(find, {
              limit: refresh ? this.state.documents.length : this.state.limit,
              // sort: { $natural: -1 },
              sort: { docNum: -1 },
              projection: {
                Title: 1,
                docNum: 1,
                lastModified: 1,
                createdBy: 1,
                date: 1,
                approved: 1,
                sentToClient: 1,
                rejected: 1,
                revised: 1,
                clone: 1,
                eApproved: 1,
                "approvedBy.acknowledgedBy": 1,
                crmCompany: 1,
              },
            })
            .asArray()
            .then((documents) => {
              // console.log(find, documents);
              let updatedDocs;
              let noResults;

              if (reset || refresh) {
                updatedDocs = documents;
              } else {
                updatedDocs = update(this.state.documents, {
                  $push: documents,
                });
              }

              if (documents.length) {
                noResults = false;
              } else {
                noResults = true;
              }

              this.setState(
                {
                  documents: updatedDocs,
                  loading: false,
                  noResults: noResults,
                  searching: false,
                  loadedAt: new Date().getTime(),
                },
                () => {
                  if (this.state.documents.length) {
                    this.setState({
                      lastDocNum: this.state.documents[
                        this.state.documents.length - 1
                      ].docNum,
                    });
                  }
                  if (
                    this.state.documents.length > this.state.limit &&
                    !refresh
                  ) {
                    try {
                      let objDiv = document.querySelectorAll(
                        // "#doc-list tbody tr"
                        "#doc-list .doc"
                      )[this.state.documents.length - this.state.limit - 1];
                      objDiv.scrollIntoView();
                    } catch (err) {}
                  }
                  localStorage.setItem(
                    "sow-filters",
                    JSON.stringify({
                      showApproved: this.state.showApproved,
                      showRejected: this.state.showRejected,
                      showRevised: this.state.showRevised,
                      showActionNeeded: this.state.showActionNeeded,
                      showSentToClient: this.state.showSentToClient,
                      query: this.state.query,
                    })
                  );
                }
              );
              // console.log("data", documents);
            });
        });
    }
  );
}

export function loadMore() {
  this.setState(
    {
      loading: true,
      limit: this.state.limit,
      page: this.state.page + 1,
    },
    () => {
      this.displayDocs(this.state.query);
    }
  );
}

export function displayDocsOnLoad() {
  // this.props.client.auth
  //   .loginWithCredential(new AnonymousCredential())
  //   // .then(() => {
  //   //   this.props.db
  //   //     .collection(this.props.collection)
  //   //     .count()
  //   //     .then(total => {
  //   //       this.setState({
  //   //         total
  //   //       });
  //   //     });
  //   // })
  //   .then(() => {
  if (localStorage.getItem("sow-filters")) {
    this.setState(
      {
        Search: JSON.parse(localStorage.getItem("sow-filters")).query,
        showApproved: JSON.parse(localStorage.getItem("sow-filters"))
          .showApproved,
        showRejected: JSON.parse(localStorage.getItem("sow-filters"))
          .showRejected,
        showRevised: JSON.parse(localStorage.getItem("sow-filters"))
          .showRevised,
        showSentToClient: JSON.parse(localStorage.getItem("sow-filters"))
          .showSentToClient,
        showActionNeeded: JSON.parse(localStorage.getItem("sow-filters"))
          .showActionNeeded,
      },
      () => {
        this.displayDocs(this.state.Search);
      }
    );
  } else {
    this.displayDocs();
  }
  // })
  // .catch(console.error);
}

export function addDoc(state) {
  // event.preventDefault();
  console.log("add", state, this.context.user);
  this.setState(
    {
      adding: true,
    },
    () => {
      const {
        newCrmCompany,
        newCompany,
        newType,
        newQuarter,
        newYear,
        newCustom,
      } = state;
      if ((newCrmCompany || newCompany.trim()) && newType) {
        let generatedName = this.generateDocName(
          (newCrmCompany && newCrmCompany.label) || newCompany,
          newType,
          newQuarter,
          newCustom,
          newYear
        );

        this.getNewDocNumber().then((newNum) => {
          this.props.db
            .collection(this.props.collection)
            .insertOne({
              createdBy: this.context.user.id,
              docNum: newNum,
              Title: `${newNum}_${generatedName}`,
              crmCompany:
                newCrmCompany && newCrmCompany.label ? newCrmCompany : null,
              company: (newCrmCompany && newCrmCompany.label) || newCompany,
              type: newType,
              quarter: newQuarter,
              custom: newCustom,
              year: newYear,
              date: moment().toDate(),
              sigDate: moment().toDate(),
              version: 1,
            })
            .then((result) => {
              this.setState(
                {
                  adding: false,
                },
                () => {
                  this.props.history.push(`/${result.insertedId}`);
                }
              );
            })
            // .then(this.displayDocs)
            .catch(console.error);
        });
      }
    }
  );
}

export function enableSearching() {
  this.setState({
    searching: true,
  });
}

export function pageChanged(e) {
  if (e.target.text) {
    this.setState(
      {
        page: parseInt(e.target.text, 10),
      },
      () => {
        this.displayDocs(this.state.query);
      }
    );
  }
}

export async function fetchTeam() {
  await this.props.db
    .collection("member")
    .find({})
    .asArray()
    .then((members) => {
      this.setState(
        {
          members,
        }
      );
    })
    .catch(console.error);
}