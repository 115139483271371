import * as Sentry from "@sentry/react";

function unwrapElement(el) {
  let parent = el.parentNode;
  while (el.firstChild) parent.insertBefore(el.firstChild, el);
  parent.removeChild(el);
}

function togglePreview() {
  this.cancelSection();

  this.setState(
    {
      reorder: false,
      preview: !this.state.preview,
      showForm: false,
      settings: false,
      generated: [],
      loadingPDF: false,
      progress: 0,
    },
    () => {
      if (this.state.preview) {
        this.setState(
          {
            loadingPDF: true,
            loadingPreview: true,
          },
          () => {
            window.scrollTo(0, 0);
            document.querySelector("#app-container").scrollTo(0, 0);
            setTimeout(() => {
              // console.log("generate PDF");
              window.addEventListener("afterprint", this.afterPrint, false);
              let pdf = document.getElementById("pdf");

              for (let page of document.querySelectorAll(".page")) {
                page.innerHTML = "";
              }

              for (let el of document.querySelectorAll("#document > *")) {
                if (el.id !== "content") {
                  pdf.querySelector("#page-1").append(el.cloneNode(true));
                }
              }

              // console.log('overview index', this.state.content.findIndex(v => v.title === 'Overview'));

              let needPage3 = true;

              if (
                this.state.content.findIndex((v) => v.title === "Overview") ===
                0
              ) {
                needPage3 = false;
              }

              if (
                // this.state.docType === "Order Form" ||
                // this.state.docType === "Exhibit/Event Proposal" ||
                !needPage3
              ) {
                // console.log("dont need extra page");
                for (let section of document.querySelectorAll(
                  "#content section"
                )) {
                  pdf.querySelector("#page-2").append(section.cloneNode(true));
                }
                document.querySelector("#page-3").remove();
              } else {
                // console.log("need extra page");
                pdf
                  .querySelector("#page-2")
                  .append(document.querySelector("#section-0").cloneNode(true));
                pdf
                  .querySelector("#page-2")
                  .append(document.querySelector("#section-1").cloneNode(true));
                pdf
                  .querySelector("#page-2")
                  .append(document.querySelector("#section-2").cloneNode(true));

                for (let section of document.querySelectorAll(
                  "#content section"
                )) {
                  pdf.querySelector("#page-3").append(section.cloneNode(true));
                }

                document.querySelector("#page-3 #section-0").remove();
                document.querySelector("#page-3 #section-1").remove();
                document.querySelector("#page-3 #section-2").remove();
              }

              // for (let section of document.querySelectorAll("#pdf section")) {
              //   this.unwrapElement(section)
              // }
              //
              for (let toolbar of pdf.querySelectorAll(
                ".deliverable-toolbar"
              )) {
                toolbar.remove();
              }

              for (let block of pdf.querySelectorAll(".block")) {
                unwrapElement(block);
              }

              // for (let table of pdf.querySelectorAll(".table-responsive")) {
              //   unwrapElement(table);
              // }

              for (let table of pdf.querySelectorAll(
                "span.deliverables-table"
              )) {
                unwrapElement(table);
              }

              for (let table of pdf.querySelectorAll("span.payments-table")) {
                unwrapElement(table);
              }

              for (let section of pdf.querySelectorAll("section")) {
                let title = section.querySelector(".section-title");
                if (
                  section.classList.contains("appendix-item") ||
                  section.classList.contains("page-break") ||
                  title.innerText.includes("Payment Schedule") ||
                  title.innerText === "Overview" ||
                  title.innerText.includes("Matrix")
                ) {
                  section.insertAdjacentHTML(
                    "beforebegin",
                    '</div><div class="page">'
                  );
                }
              }

              pdf.innerHTML = pdf.innerHTML.replace(
                /<div class="page"><\/div>/g,
                '</div><div class="page">'
              );

              // document.addEventListener("click", function(e) {
              //   if (e.target && e.target.classList.contains("page")) {
              //     // console.log(e.target)
              //     fixPage(e.target);
              //   }
              // });

              if (
                this.state.docType === "Biologics Report Order Form" ||
                // this.state.docType === "Exhibit/Event Proposal"
                !needPage3 ||
                window.location.search === "?debug" ||
                (!this.state.content[0].title.includes("Objective") &&
                  !this.state.content[1].title.includes("Why") &&
                  !this.state.content[2].title.includes("Execution"))
              ) {
                fixPage(pdf.querySelector("#page-2"));
              } else {
                fixPage(pdf.querySelector("#page-3"));
              }

              setTimeout(() => {
                // console.log("after fix");
                document.body.classList.add("scanned");

                for (let item of document.querySelectorAll(
                  "#pdf li:not(.hide-me) + li.hide-me"
                )) {
                  try {
                    item.parentNode
                      .closest("li.hide-me")
                      .classList.add("unhide-me");
                  } catch (err) {}
                }

                // setTimeout(() => {
                setTimeout(() => {
                  injectHeaderFooter();

                  // for (let table of pdf.querySelectorAll("table")) {
                  //   if (!table.querySelectorAll("tr").length) {
                  //       table.classList.add("hide-me");
                  //   } else if (table.querySelectorAll("tr").length === table.querySelectorAll("tr.hide-me:not(.unhide-me)").length) {
                  //     table.classList.add("hide-me");
                  //   }
                  // }

                  for (let list of pdf.querySelectorAll(".list-overflowing")) {
                    let items = list.querySelectorAll(":scope > *");
                    let count = 0;
                    for (let li of items) {
                      if (
                        li.classList.contains("hide-me") &&
                        !li.classList.contains("unhide-me")
                      ) {
                        count = count + 1;
                      }
                      if (count === items.length) {
                        list.classList.add("hide-me", "hide8");
                      }
                    }
                  }

                  for (let page of document.querySelectorAll(".page")) {
                    try {
                      if (
                        !page.querySelector("section") &&
                        page.id !== "page-1"
                      ) {
                        page.remove();
                      } else {
                        let items = page.querySelectorAll("section > *");
                        let count = 0;
                        for (let el of items) {
                          if (
                            el.classList.contains("hide-me") &&
                            !el.classList.contains("unhide-me")
                          ) {
                            count = count + 1;
                          }
                          if (count === items.length) {
                            page.remove();
                          }
                        }
                      }
                    } catch (err) {
                      console.error("error on hiding blank pages", err);
                    }
                  }

                  for (let table of document.querySelectorAll(".page table")) {
                    let tr = table.querySelectorAll("tr");
                    let count = 0;
                    for (let el of tr) {
                      if (
                        el.classList.contains("hide-me") &&
                        !el.classList.contains("unhide-me")
                      ) {
                        count = count + 1;
                      }
                      if (count === tr.length) {
                        table.classList.add("hide-me", "hide9");
                      }
                    }
                  }

                  let pages = document.querySelectorAll(".page");

                  for (let [i, page] of pages.entries()) {
                    page.querySelector(
                      ".page-count"
                    ).innerHTML = `Page <span class="page-num">${
                      i + 1
                    }</span> of ${pages.length}`;
                  }
                  let pageNum = "1";
                  let currentSection;
                  for (let title of pdf.querySelectorAll(
                    "#index-of-contents li a"
                  )) {
                    title.parentNode.replaceWith(
                      ...title.parentNode.childNodes
                    );

                    for (let sectionTitle of pdf.querySelectorAll(
                      "section h4.section-title:not(.hide-me)"
                    )) {
                      if (
                        sectionTitle.textContent.includes(title.textContent) &&
                        !isHidden(sectionTitle)
                      ) {
                        let newPageNum = sectionTitle
                          .closest(".page")
                          .querySelector(".page-num").textContent;

                        if (pageNum === newPageNum) {
                          // console.log(sectionTitle);
                          currentSection.innerHTML += ` / ${sectionTitle.innerHTML
                            .replace("Execution for", "Execution")
                            .replace(" For", "")}`;
                          title
                            .closest("li")
                            .setAttribute("style", "display: none");
                        } else {
                          currentSection = title;
                          pageNum = newPageNum;

                          let node = document.createElement("span");
                          node.setAttribute("style", "float: right");
                          let textnode = document.createTextNode(pageNum);
                          node.appendChild(textnode);
                          currentSection.append(node);
                        }
                      }
                    }

                    // if (i === 0) {
                    //   title.append(" / Why That's Nice / Execution");
                    // }
                  }

                  for (let [i, title] of pdf
                    .querySelectorAll("#index-of-contents li.appendix-item")
                    .entries()) {
                    if (!isHidden(title)) {
                      title.prepend(`Appendix ${toRoman(i + 1)}: `);
                    }
                  }

                  for (let [i, title] of pdf
                    .querySelectorAll(
                      "section.appendix-item .section-title:not(.hide-me)"
                    )
                    .entries()) {
                    if (!isHidden(title)) {
                      title.prepend(`Appendix ${toRoman(i + 1)}: `);
                    }
                  }

                  for (let [i, title] of pdf
                    .querySelectorAll(
                      "section.deliverable-item > .section-title:not(.hide-me)"
                    )
                    .entries()) {
                    // if (!title.classList.contains("hide-me")) {
                    let node = document.createElement("span");
                    let textnode = document.createTextNode(`${i + 1}.`);
                    node.appendChild(textnode);
                    title.prepend(node);
                    // }
                  }

                  try {
                    let widths = [];
                    for (let column of pdf.querySelectorAll(
                      "#table-0:not(.hide-me) thead .fit"
                    )) {
                      widths.push(column.offsetWidth + 0.33);
                    }
                    widths = widths.slice(0, 3);
                    // console.log("widths", widths);

                    for (let table of pdf.querySelectorAll(".total-table")) {
                      // console.log(table);
                      table.setAttribute(
                        "style",
                        `max-width: ${Math.ceil(
                          widths.reduce((a, b) => a + b, 0)
                        )}px`
                      );

                      for (let fit of table.querySelectorAll(".fit")) {
                        fit.setAttribute(
                          "style",
                          `width: ${Math.floor(
                            widths[0] > 1
                              ? widths[0]
                              : widths[1] > 1
                              ? widths[1]
                              : 100
                          )}px;min-width: ${Math.floor(
                            widths[0] > 1
                              ? widths[0]
                              : widths[1] > 1
                              ? widths[1]
                              : 100
                          )}px;white-space:nowrap`
                        );

                        fit.nextSibling.setAttribute(
                          "style",
                          `width: ${Math.floor(
                            widths[1] + widths[2]
                          )}px;min-width: ${Math.floor(
                            widths[1] + widths[2]
                          )}px;white-space:nowrap`
                        );
                      }

                      // table
                      //   .querySelector(".fit")
                      //   .setAttribute(
                      //     "style",
                      //     `width: ${Math.floor(
                      //       widths[0] > 1 ? widths[0] : widths[1] > 1 ? widths[1] : 100
                      //     )}px;min-width: ${Math.floor(
                      //       widths[0] > 1 ? widths[0] : widths[1] > 1 ? widths[1] : 100
                      //     )}px`
                      //   );
                      // table
                      //   .querySelector(".fit")
                      //   .nextSibling.setAttribute(
                      //     "style",
                      //     `width: ${Math.floor(
                      //       widths[1] + widths[2]
                      //     )}px;min-width: ${Math.floor(
                      //       widths[1] + widths[2]
                      //     )}px`
                      //   );
                    }
                  } catch (err) {
                    console.error("error on table widths", err);
                  }

                  document
                    .querySelectorAll("#pages *")
                    .forEach(({ childNodes: [...nodes] }) =>
                      nodes
                        .filter(
                          ({ nodeType }) => nodeType === document.TEXT_NODE
                        )
                        .forEach((textNode) => {
                          if (textNode.textContent.includes("[Page: ")) {
                            let pageTitle = textNode.textContent
                              .match(/\[(.*?)\]/)[1]
                              .replace("Page: ", "");

                            // console.log(pageTitle);

                            for (let sectionTitle of pdf.querySelectorAll(
                              "section h4.section-title:not(.hide-me)"
                            )) {
                              if (
                                sectionTitle.textContent.includes(pageTitle) &&
                                !isHidden(sectionTitle)
                              ) {
                                let pageNum = sectionTitle
                                  .closest(".page")
                                  .querySelector(".page-num").textContent;
                                // console.log(pageNum);

                                textNode.textContent = textNode.textContent.replace(
                                  /\[(.*?)\]/g,
                                  pageNum
                                );

                                break;
                              }
                            }
                          }
                        })
                    );

                  // replaceOnDocument(
                  //   document.querySelector("#pages"),
                  //   "[Page: Appendix III]",
                  //   "38"
                  // );

                  let elems = document.querySelectorAll(".section-title"),
                    res = Array.from(elems).find((v) =>
                      v.textContent.includes("Appendix I")
                    );

                  try {
                    res
                      .closest(".page")
                      .insertAdjacentHTML(
                        "beforebegin",
                        '<div class="page page-break"><h1>Appendix Section</h1></div>'
                      );
                  } catch (err) {
                    console.error("could not add new page under Appendix", err);
                  }

                  // if (window.location.search === "?debug") {
                  document.body.classList.add("loaded-pdf");

                  if (document.querySelector("#pdf .too-tall")) {
                    let section = document
                      .querySelector("#pdf .too-tall")
                      .closest("section").id;

                    this.setState(
                      {
                        preview: false,
                        loadingPDF: false,
                        loadingPreview: false,
                      },
                      () => {
                        document.querySelector(`#${section}`).scrollIntoView();

                        this.showNotification(
                          "error",
                          `This section is too large! Please split it into smaller parts.`
                        );
                      }
                    );

                    // alert("error");
                  } else {
                    this.setState(
                      {
                        loadingPDF: false,
                        loadingPreview: false,
                      },
                      () => {
                        document
                          .querySelector(".page:last-of-type")
                          .insertAdjacentHTML(
                            "afterend",
                            '<div class="page ty-page"><h1>Thank you for the opportunity, we look forward to working with you.</h1></div>'
                          );

                        if (this.props.pdf) {
                          this.printPDF();
                        }
                      }
                    );
                  }

                  // } else {
                  //   setTimeout(() => {
                  //     // for (let [i, page] of document
                  //     //   .querySelectorAll(".page")
                  //     //   .entries()) {
                  //     //   // page.addEventListener("click", e => {
                  //     //   //   this.convertToImg(i, page);
                  //     //   // });
                  //     //   this.convertToImg(i, page);
                  //     // }
                  //
                  //     // this.convertToImg(0);
                  //   }, 400);
                  // }
                }, 0);
                // }, 0);
              }, 0);
            });
          },
          400
        );
      } else {
        // setTimeout(() => {
        // console.log("remove print event");
        window.removeEventListener("afterprint", this.afterPrint, false);
        // }, 600);
      }
    }
  );
}

function toRoman(num) {
  var result = "";
  var decimal = [1000, 900, 500, 400, 100, 90, 50, 40, 10, 9, 5, 4, 1];
  var roman = [
    "M",
    "CM",
    "D",
    "CD",
    "C",
    "XC",
    "L",
    "XL",
    "X",
    "IX",
    "V",
    "IV",
    "I",
  ];
  for (var i = 0; i <= decimal.length; i++) {
    while (num % decimal[i] < num) {
      result += roman[i];
      num -= decimal[i];
    }
  }
  return result;
}

function isHidden(el) {
  var style = window.getComputedStyle(el);
  return (
    style.display === "none" ||
    style.visibility === "hidden" ||
    style.visibility === "collapse"
  );
}

function injectHeaderFooter() {
  for (let page of document.querySelectorAll(".page")) {
    if (!page.querySelector(".logos")) {
      page.prepend(document.querySelector(".logos").cloneNode(true));
    }
    if (!page.querySelector("footer")) {
      page.append(document.querySelector("footer").cloneNode(true));
    }

    page.classList.add("branded");
  }
}

function fixPage(page) {
  // console.log("fix page", page);

  if (page.nodeType !== 1) {
    page = page.nextSibling;
  }

  try {
    if (page.classList.contains("for-overflow")) {
      for (let list of page.querySelectorAll(".list-overflowing")) {
        let liCount = list.querySelectorAll("li").length;
        let hiddenLiCount = 0;
        for (let li of list.querySelectorAll("li")) {
          // console.log('hidden li', hiddenLiCount, liCount);
          if (li.classList.contains("hide-me")) {
            hiddenLiCount++;
          }

          if (hiddenLiCount === liCount) {
            list.classList.add("hide-me");
          }
        }
      }

      page.classList.remove("for-overflow");
    }

    let pageH = page.offsetHeight;

    // page.dataset.height = pageH

    let padT = window
      .getComputedStyle(page, null)
      .getPropertyValue("padding-top")
      .replace("px", "");
    let padB = window
      .getComputedStyle(page, null)
      .getPropertyValue("padding-bottom")
      .replace("px", "");
    let availableSpace = pageH - parseInt(padT, 10) - parseInt(padB, 10) - 32;

    let pageOffset = offset(page) - 80;

    // page.dataset.offset = pageOffset

    let overflowingSections = [];

    for (let section of page.querySelectorAll("section")) {
      if (
        section.querySelector(".section-title").innerText.includes("Matrix")
      ) {
        for (let image of section.querySelectorAll("img")) {
          let maxH =
            availableSpace -
            image.closest("section").querySelector(":scope > hr").offsetHeight -
            image.closest("section").querySelector(".section-title")
              .offsetHeight;
          image.setAttribute("style", `max-height: ${maxH}px`);
        }
      } else if (section.querySelector("p > img")) {
        for (let image of section.querySelectorAll("img")) {
          let maxH = availableSpace;
          // image.closest("section").querySelector(":scope > hr").offsetHeight -
          // image.closest("section").querySelector(".section-title").offsetHeight;
          image.setAttribute("style", `max-height: ${maxH}px`);
        }
      }

      let sectionOffset = offset(section);
      // section.dataset.offset = `${sectionOffset} >= ${pageOffset}`
      if (sectionOffset >= pageOffset) {
        section.classList.add("section-overflowing");

        for (let el of section.querySelectorAll(":scope > *")) {
          let elOffset = offset(el);

          if (
            el.tagName !== "TABLE" &&
            el.tagName !== "IMG" &&
            el.getBoundingClientRect().height > availableSpace
          ) {
            console.log(el, section.outerHTML, "too tall");
            Sentry.captureMessage(JSON.stringify(section.outerHTML));
            el.classList.add("too-tall");
            return false;
          }

          // el.dataset.offset = `${elOffset} >= ${pageOffset}`
          if (elOffset >= pageOffset) {
            if (el.tagName === "TABLE") {
              el.classList.add("table-overflowing");
              el.style.height = null;
              // if (el.querySelectorAll("tr").length > 2) {
              for (let tableEl of el.querySelectorAll("tr")) {
                let tableElOffset = offset(tableEl);
                if (tableElOffset >= pageOffset) {
                  tableEl.classList.add("tr-overflowing");
                }
              }
              // } else {
              //   el.classList.add("keep-together");
              // }
            } else if (el.tagName === "OL" || el.tagName === "UL") {
              el.classList.add("list-overflowing");
              for (let item of el.querySelectorAll("li")) {
                let listOffset = offset(item);
                // item.dataset.offset = `${listOffset} >= ${pageOffset}`
                if (listOffset >= pageOffset) {
                  item.classList.add("li-overflowing");
                }
              }
            } else {
              el.classList.add("overflowing");
            }
          }
        }

        overflowingSections.push(section.cloneNode(true));
      }
    }

    if (overflowingSections.length) {
      page.insertAdjacentHTML(
        "afterend",
        `<div class="page for-overflow"></div>`
      );

      try {
        for (let overflowing of page.querySelectorAll(".overflowing")) {
          // overflowing.remove();
          overflowing.classList.remove("overflowing");
          overflowing.classList.add("hide-me", "hide1");
        }

        for (let overflowing of page.querySelectorAll(".tr-overflowing")) {
          // overflowing.remove();
          overflowing.classList.remove("tr-overflowing");
          overflowing.classList.add("hide-me", "hide2");
        }

        for (let overflowing of page.querySelectorAll(".li-overflowing")) {
          // overflowing.remove();
          overflowing.classList.remove("li-overflowing");
          overflowing.classList.add("hide-me", "hide3");
        }
        //
        // for (let together of page.querySelectorAll(".keep-together")) {
        //   // overflowing.remove();
        //   together.classList.remove("keep-together");
        //   together.classList.add("hide-me", "hide8");
        // }

        if (page.nextSibling.classList.contains("for-overflow")) {
          for (let newSection of overflowingSections) {
            page.nextSibling.append(newSection);
          }
        }

        for (let el of page.nextSibling.querySelectorAll("section > *")) {
          if (el.classList.contains("table-overflowing")) {
            // if (el.classList.contains("keep-together")) {
            //   el.classList.remove("hide-me")
            //
            //   for (let tr of el.querySelectorAll("tr")) {
            //     tr.classList.remove("hide-me")
            //   }
            // }

            for (let tableEl of el.querySelectorAll("tr")) {
              if (tableEl.classList.contains("tr-overflowing")) {
                tableEl.classList.remove("tr-overflowing");
              } else {
                // tableEl.remove();
                tableEl.classList.remove("tr-overflowing");
                tableEl.classList.add("hide-me", "hide4");
              }
            }

            if (!el.querySelectorAll("tr:not(.hide-me)").length) {
              // el.remove();
              el.classList.add("hide-me", "hide5");
            }
          } else if (el.classList.contains("list-overflowing")) {
            let listItems = el.querySelectorAll("li");
            // let count = 0;
            for (let item of listItems) {
              if (item.classList.contains("li-overflowing")) {
                item.classList.remove("li-overflowing");
              } else {
                // item.remove();
                item.classList.remove("li-overflowing");
                item.classList.add("hide-me", "hide6");
                // count = count + 1;
                //
                // if (count === listItems.length) {
                //   el.classList.add("hide-me");
                // }
              }
            }
          } else if (el.classList.contains("overflowing")) {
            el.classList.remove("overflowing");
          } else {
            // el.remove();
            el.classList.remove("overflowing");
            el.classList.add("hide-me", "hide7");
          }
        }
      } catch (err) {
        console.error("err in overflowing", err);
      }
    }

    for (let section of page.querySelectorAll(".section-overflowing")) {
      section.classList.remove("section-overflowing");
    }

    page.classList.add("page-scanned");

    // setTimeout(() => {
    if (page.nextSibling) {
      fixPage(page.nextSibling);
    }
    // }, 2000)
  } catch (err) {
    console.error("err in fix page", page, err);
  }
}

function offset(el) {
  var rect = el.getBoundingClientRect(),
    scrollTop = window.pageYOffset || document.documentElement.scrollTop;
  return rect.bottom + scrollTop;
}

export default togglePreview;
