/**
 *
 * Admin
 *
 */

import React from "react";
import PropTypes from "prop-types";
import "./styles.scss";
import {
  Alert,
  Box,
  Button,
  Container,
  Grid,
  IconButton,
  Typography,
  TextField,
  Tabs,
  Tab,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Autocomplete,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import * as Functions from "./functions.js";
import { showNotification, getUserInfo } from "../../utils/functions";
import autoBind from "react-autobind";
import { Auth0Context } from "../../react-auth0-wrapper";
import socketIOClient from "socket.io-client";
import DatePicker from "@material-ui/lab/DatePicker";
import {
  handleInputChange,
  handleDateChange,
  handleReactSelect,
} from "../../utils/inputHandlers";
import UserList from "./UserList";
import Confirm from "../Confirm";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box pt={1}>
          <Box>{children}</Box>
        </Box>
      )}
    </div>
  );
}

export class Admin extends React.Component {
  // eslint-disable-line react/prefer-stateless-function
  constructor(props) {
    super(props);
    this.state = {
      accessId: "rul_tFL8TCf39cacmgHa",
      rolesId: "rul_pItBAbDk14CTTXf1",
      activeUsers: [],
      members: null,
      showEditMember: false,
      ...Functions.defaultMember,
      tab: "team",
      removeConfirm: false,
      userToRemove: null,
    };

    this.socket = null;

    autoBind(this);

    this.showNotification = showNotification.bind(this);
    this.getUserInfo = getUserInfo.bind(this);

    this.handleInputChange = handleInputChange.bind(this);
    this.handleDateChange = handleDateChange.bind(this);
    this.handleReactSelect = handleReactSelect.bind(this);

    this.fetchActiveUsers = Functions.fetchActiveUsers.bind(this);
    this.fetchTeam = Functions.fetchTeam.bind(this);
    this.fetchRules = Functions.fetchRules.bind(this);
    this.onFocus = Functions.onFocus.bind(this);
    this.handleTabChange = Functions.handleTabChange.bind(this);
    this.kickUser = Functions.kickUser.bind(this);
    this.saveUsers = Functions.saveUsers.bind(this);
    this.saveRoles = Functions.saveRoles.bind(this);
    this.updateRoleArray = Functions.updateRoleArray.bind(this);
    this.editMember = Functions.editMember.bind(this);
    this.addMember = Functions.addMember.bind(this);
    this.saveMember = Functions.saveMember.bind(this);
    this.cancelEditMember = Functions.cancelEditMember.bind(this);
    this.addUser = Functions.addUser.bind(this);
    this.removeUser = Functions.removeUser.bind(this);
    this.handleMenuOpen = Functions.handleMenuOpen.bind(this);
    this.handleRoleChange = Functions.handleRoleChange.bind(this);
  }

  static contextType = Auth0Context; // eslint-disable-line no-undef

  componentDidMount() {
    document.title = "Admin | SOW Generator";

    // if (!this.props.normalAdmin) {
    //   return false;
    // }

    this.socket = socketIOClient();

    window.addEventListener("focus", this.onFocus);

    this.fetchActiveUsers();
    this.fetchTeam();

    this.socket.on("activeUsers", (users) => {
      let activeUsers = [];

      for (let [user, data] of Object.entries(users)) {
        activeUsers.push({ id: user, data });
      }

      this.setState({
        activeUsers: activeUsers.length ? activeUsers : null,
      });
    });
  }

  componentWillUnmount() {
    window.removeEventListener("focus", this.onFocus);
  }

  render() {
    if (!this.props.user) {
      return (
        <Container>
          <Alert
            severity="info"
            variant="filled"
            style={{ alignItems: "center", justifyContent: "center" }}
          >
            Checking access level...
          </Alert>
        </Container>
      );
    }

    if (!this.props.normalAdmin) {
      return (
        <Container>
          <Alert
            severity="error"
            variant="filled"
            style={{ alignItems: "center", justifyContent: "center" }}
          >
            You don't have access to this page!
          </Alert>
        </Container>
      );
    }

    const { isAuthenticated } = this.context;
    return (
      isAuthenticated && (
        <Container
          id="admin"
          style={{
            paddingBottom: "60px",
          }}
        >
          <Grid
            display="flex"
            justifyContent="space-between"
            style={{
              position: "relative",
            }}
          >
            <Typography variant="h5">Admin</Typography>
          </Grid>
          <hr />

          <Box>
            <Tabs
              value={this.state.tab}
              onChange={this.handleTabChange}
              indicatorColor="primary"
              textColor="primary"
              // centered
            >
              <Tab label="Team" value="team" />
              {this.props.superAdmin && (
                <Tab label="Active Users" value="active-users" />
              )}
              {this.props.superAdmin && (
                <Tab label="Permissions" value="permissions" />
              )}
            </Tabs>
            <TabPanel
              id="main-tab"
              value={this.state.tab}
              index="team"
              style={{ position: "relative" }}
            >
              <Button
                variant="outlined"
                onClick={this.addMember}
                style={{
                  position: "absolute",
                  bottom: "100%",
                  right: 0,
                  marginBottom: "4px",
                }}
              >
                Add Member
              </Button>
              <UserList
                type="team"
                headCells={[
                  {
                    id: "Name",
                    label: "Name",
                    visible: true,
                  },
                  {
                    id: "Title",
                    numeric: false,
                    label: "Title",
                    visible: true,
                  },
                  {
                    id: "Department",
                    numeric: false,
                    label: "Department",
                    visible: true,
                  },
                ]}
                rows={this.state.members}
                editMember={this.editMember}
              />
            </TabPanel>
            {this.props.superAdmin && (
              <TabPanel value={this.state.tab} index="active-users">
                <UserList
                  type="active-users"
                  headCells={[
                    {
                      id: "data.user.name",
                      label: "User",
                      visible: true,
                    },
                    {
                      id: "data.roomname",
                      label: "SOW ID",
                      visible: true,
                    },
                  ]}
                  rows={this.state.activeUsers}
                  kickUser={this.kickUser}
                />
              </TabPanel>
            )}
            {this.props.superAdmin && (
              <TabPanel
                value={this.state.tab}
                index="permissions"
                style={{ position: "relative" }}
              >
                <Grid
                  container
                  style={{
                    position: "absolute",
                    bottom: "100%",
                    right: 0,
                    marginBottom: "4px",
                    width: "auto",
                  }}
                >
                  <form onSubmit={this.addUser} style={{ display: "flex" }}>
                    <TextField
                      fullWidth
                      margin="normal"
                      label="Email"
                      variant="outlined"
                      type="email"
                      size="small"
                      name="userEmail"
                      value={this.state.userEmail}
                      onChange={this.handleInputChange}
                      required
                      InputLabelProps={{ required: false }}
                      style={{ margin: 0 }}
                    />
                    &ensp;
                    <Button
                      size="small"
                      type="submit"
                      variant="outlined"
                      style={{ whiteSpace: "nowrap", width: 120 }}
                    >
                      Add User
                    </Button>
                  </form>
                </Grid>
                <UserList
                  type="permissions"
                  headCells={[
                    {
                      id: "meta.name",
                      label: "Name",
                      visible: true,
                    },
                    {
                      id: "id",
                      label: "Email",
                      visible: true,
                    },
                    {
                      id: "role",
                      label: "Role",
                      visible: true,
                    },
                  ]}
                  rows={this.state.roleArray}
                  handleRoleChange={this.handleRoleChange}
                  removeUserConfirm={(id) => {
                    this.setState({
                      removeConfirm: true,
                      userToRemove: id,
                    });
                  }}
                />
              </TabPanel>
            )}
          </Box>

          <Dialog
            id="import-modal"
            open={this.state.showEditMember}
            onClose={this.cancelEditMember}
            // fullWidth
            // maxWidth="md"
          >
            <DialogTitle
              disableTypography
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Box display="flex" alignItems="center">
                <Typography variant="h6">
                  {this.state.memberId ? "Edit" : "Add"} Team Member
                </Typography>
              </Box>
              <Box mr={-2} mt={-1}>
                <IconButton aria-label="close" onClick={this.cancelEditMember}>
                  <CloseIcon />
                </IconButton>
              </Box>
            </DialogTitle>
            <DialogContent
              sx={{
                "& .MuiFormControl-root": {
                  mt: 1,
                  mb: 1,
                  width: "100%",
                },
              }}
            >
              <form onSubmit={this.saveMember}>
                <TextField
                  fullWidth
                  margin="normal"
                  label="Name"
                  variant="outlined"
                  type="text"
                  size="small"
                  name="memberName"
                  value={this.state.memberName}
                  onChange={this.handleInputChange}
                  required
                  InputLabelProps={{ required: false }}
                />
                <TextField
                  fullWidth
                  margin="normal"
                  label="Title"
                  variant="outlined"
                  type="text"
                  size="small"
                  name="memberTitle"
                  value={this.state.memberTitle}
                  onChange={this.handleInputChange}
                />
                <TextField
                  fullWidth
                  margin="normal"
                  label="Email"
                  variant="outlined"
                  type="email"
                  size="small"
                  name="memberEmail"
                  value={this.state.memberEmail}
                  onChange={this.handleInputChange}
                />
                <Autocomplete
                  autoHighlight
                  value={
                    this.state.memberDepartment
                      ? {
                          value: this.state.memberDepartment,
                          label: this.state.memberDepartment,
                        }
                      : null
                  }
                  options={[
                    {
                      value: "",
                      label: "No Department",
                    },
                    {
                      value: "Strategy and Consulting",
                      label: "Strategy and Consulting",
                    },
                    {
                      value: "Research",
                      label: "Research",
                    },
                    {
                      value: "Scientific Content",
                      label: "Scientific Content",
                    },
                    {
                      value: "Creative",
                      label: "Creative",
                    },
                    {
                      value: "Project Management",
                      label: "Project Management",
                    },
                    {
                      value: "Web / Digital",
                      label: "Web / Digital",
                    },
                  ]}
                  getOptionLabel={(option) => option?.label || ""}
                  getOptionSelected={(option, value) =>
                    option.value === value.value
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Department"
                      variant="outlined"
                      size="small"
                      margin="normal"
                    />
                  )}
                  onChange={(e, value) =>
                    this.handleReactSelect("memberDepartment", value)
                  }
                />
                <TextField
                  fullWidth
                  margin="normal"
                  label="Description"
                  variant="outlined"
                  multiline
                  size="small"
                  name="memberDescription"
                  value={this.state.memberDescription}
                  onChange={this.handleInputChange}
                  rows={2}
                  maxRows={6}
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      pt: 0,
                      pb: 0,
                    },
                  }}
                />
                <TextField
                  fullWidth
                  margin="normal"
                  label="Experience"
                  variant="outlined"
                  type="text"
                  size="small"
                  name="memberExperience"
                  value={this.state.memberExperience}
                  onChange={this.handleInputChange}
                />
                <Grid
                  container
                  sx={{
                    "&": {
                      flexWrap: "nowrap",
                    },
                    // "& .MuiFormControl-root": {
                    //   mt: 0,
                    //   mb: 0,
                    // },
                    "& .MuiGrid-root": {
                      width: "100%",
                    },
                  }}
                >
                  <Grid item mr={1}>
                    <DatePicker
                      size="small"
                      label="Start Date"
                      inputFormat="MM/DD/yyyy"
                      value={this.state.memberStartDate}
                      onChange={(value) =>
                        this.handleDateChange("memberStartDate", value)
                      }
                      renderInput={(params) => (
                        <TextField {...params} size="small" />
                      )}
                    />
                  </Grid>
                  <Grid item ml={1}>
                    <DatePicker
                      size="small"
                      label="Pharma Date"
                      inputFormat="MM/DD/yyyy"
                      value={this.state.memberPharmaDate}
                      onChange={(value) =>
                        this.handleDateChange("memberPharmaDate", value)
                      }
                      renderInput={(params) => (
                        <TextField {...params} size="small" />
                      )}
                    />
                  </Grid>
                </Grid>
                <DialogActions style={{ paddingRight: 0 }}>
                  <Button
                    type="reset"
                    onClick={this.cancelEditMember}
                    color="secondary"
                  >
                    Cancel
                  </Button>

                  <Button type="submit" color="primary">
                    {this.state.memberId ? "Save" : "Add"}
                  </Button>
                </DialogActions>
              </form>
            </DialogContent>
          </Dialog>
          <Confirm
            open={this.state.removeConfirm}
            text="Are you sure want to revoke access for "
            highlight={this.state.userToRemove}
            closeDialog={() => {
              this.setState({ removeConfirm: false });
            }}
            title="Remove User"
            confirmAction={() => this.removeUser(this.state.userToRemove)}
            confirmLabel="Revoke Access"
            color="error"
          />
        </Container>
      )
    );
  }
}

Admin.propTypes = {
  user: PropTypes.object,
  normalAdmin: PropTypes.bool,
  superAdmin: PropTypes.bool,
};

export default Admin;
