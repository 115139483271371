/**
 *
 * Home
 *
 */

import React from "react";
import moment from "moment";
import PropTypes from "prop-types";
import "./styles.scss";
import { Link } from "react-router-dom";
import Search from "../Search";
import Settings from "../Settings";
import {
  Avatar,
  Box,
  Container,
  Grid,
  Chip,
  IconButton,
  CircularProgress,
  Menu,
  MenuItem,
  FormControlLabel,
  Checkbox,
  FormGroup,
  Tooltip,
  Paper,
  Typography,
} from "@material-ui/core";
import LoadingButton from "@material-ui/lab/LoadingButton";
import FilterListIcon from "@material-ui/icons/FilterList";
import * as Document from "../../utils/docMethods";
import * as Functions from "./functions.js";
import autoBind from "react-autobind";
import { Auth0Context } from "../../react-auth0-wrapper";
import {
  generateDocName,
  getAppVersion,
  showNotification,
  getUserInfo,
} from "../../utils/functions";
import { handleInputChange } from "../../utils/inputHandlers";
import Options from "./Options";
import Confirm from "../Confirm";
import BatchModal from "./BatchModal";
// var ObjectID = require("bson-objectid");

export class Home extends React.Component {
  // eslint-disable-line react/prefer-stateless-function
  constructor() {
    super();
    this.state = {
      documents: [],
      limit: 20,
      page: 1,
      // query: "",
      searching: false,
      loading: false,
      initialized: false,
      currentUserId: undefined,
      newCompany: "",
      newType: "",
      newYear: "",
      newQuarter: "",
      newCustom: "",
      showApproved: false,
      showSentToClient: false,
      showRejected: false,
      showRevised: false,
      showActionNeeded: false,
      loadedAt: new Date().getTime(),
      anchorEl: null,
      filters: [
        { name: "Approved", key: "showApproved" },
        { name: "Sent to Client", key: "showSentToClient" },
        { name: "Rejected", key: "showRejected" },
        { name: "Revised", key: "showRevised" },
        { name: "Action Needed", key: "showActionNeeded" },
      ],
      adding: false,
      open: false,
      deleting: false,
      cloning: false,
      batchCloning: false,
      batchProgress: 0,
      openBatch: false
    };

    autoBind(this);

    this.showNotification = showNotification.bind(this);
    this.getUserInfo = getUserInfo.bind(this);

    this.handleInputChange = handleInputChange.bind(this);

    this.generateDocName = generateDocName.bind(this);
    this.getAppVersion = getAppVersion.bind(this);

    this.deleteDoc = Document.deleteDoc.bind(this);
    this.cloneDoc = Document.cloneDoc.bind(this);
    this.batchCloneDoc = Document.batchCloneDoc.bind(this);
    this.getNewDocNumber = Document.getNewDocNumber.bind(this);

    this.onFocus = Functions.onFocus.bind(this);
    this.clearDocs = Functions.clearDocs.bind(this);
    this.clearSearch = Functions.clearSearch.bind(this);
    this.clearFilters = Functions.clearFilters.bind(this);
    this.displayDocs = Functions.displayDocs.bind(this);
    this.loadMore = Functions.loadMore.bind(this);
    this.displayDocsOnLoad = Functions.displayDocsOnLoad.bind(this);
    this.addDoc = Functions.addDoc.bind(this);
    this.enableSearching = Functions.enableSearching.bind(this);
    this.pageChanged = Functions.pageChanged.bind(this);
    this.fetchTeam = Functions.fetchTeam.bind(this);
  }

  static contextType = Auth0Context; // eslint-disable-line no-undef

  componentDidMount() {
    this.displayDocsOnLoad();
    this.getAppVersion();
    this.fetchTeam();

    document.title = "SOW Generator";
    document.querySelector("body").style.overflow = "hidden";
    window.addEventListener("focus", this.onFocus);
  }

  componentWillUnmount() {
    // this.setState({
    //   client: undefined
    // });
    window.removeEventListener("focus", this.onFocus);
    document.querySelector("body").style.removeProperty("overflow");
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (
      this.state.documents !== nextState.documents ||
      this.state.Search !== nextState.Search ||
      this.state.searching !== nextState.searching ||
      this.state.loading !== nextState.loading ||
      this.state.adding !== nextState.adding ||
      this.state.page !== nextState.page ||
      this.state.open !== nextState.open ||
      this.state.openBatch !== nextState.openBatch ||
      this.state.deleting !== nextState.deleting ||
      this.state.cloning !== nextState.cloning ||
      this.state.batchCloning !== nextState.batchCloning ||
      this.state.anchorEl !== nextState.anchorEl ||
      this.state.members !== nextProps.members ||
      this.props.normalAdmin !== nextProps.normalAdmin ||
      this.props.superAdmin !== nextProps.superAdmin
    ) {
      return true;
    }

    if (
      this.state.showApproved !== nextState.showApproved ||
      this.state.showSentToClient !== nextState.showSentToClient ||
      this.state.showRejected !== nextState.showRejected ||
      this.state.showRevised !== nextState.showRevised ||
      this.state.showActionNeeded !== nextState.showActionNeeded
    ) {
      this.clearDocs();

      localStorage.setItem(
        "sow-filters",
        JSON.stringify({
          // showApproved: document.querySelector("#show-approved").checked,
          query: this.state.query,
        })
      );

      return true;
    }
    return false;
  }

  openFilters(e) {
    this.setState({
      anchorEl: e.currentTarget,
    });
  }

  closeFilters() {
    this.setState({
      anchorEl: null,
    });
  }

  openDialog(deleteId, deleteTitle) {
    this.setState({
      open: true,
      deleteId,
      deleteTitle,
    });
  }

  closeDialog() {
    this.setState({
      open: false,
    });
  }

  openBatchDialog(docId, docTitle) {
    this.setState({
      openBatch: true,
      batchProgress: 0,
      docId,
      docTitle,
    });
  }

  closeBatchDialog() {
    this.setState({
      openBatch: false,
    });
  }

  render() {
    const { isAuthenticated } = this.context;
    return isAuthenticated ? (
      <Container id="home" style={{ paddingBottom: "60px" }}>
        <Grid container style={{ position: "relative" }}>
          <Grid item xs={8}>
            <Typography variant="h5">
              <div>
                SOWs&nbsp;
                <span id="doc-count">
                  {this.state.total ? `(${this.state.total})` : ""}
                </span>
              </div>
              <div className="active-criteria">
                {this.state.query && !this.state.searching && (
                  <Chip
                    size="small"
                    label={this.state.query}
                    onDelete={() => this.clearFilters("query")}
                  />
                )}
                {this.state.filters.map((filter) => {
                  return (
                    this.state[filter.key] && (
                      <Chip
                        color="secondary"
                        size="small"
                        key={filter.name}
                        label={filter.name}
                        onDelete={() => this.clearFilters(filter.key)}
                      />
                    )
                  );
                })}
              </div>
            </Typography>
          </Grid>

          <Grid
            item
            xs={4}
            display="flex"
            alignItems="center"
            justifyContent="flex-end"
          >
            <div>
              <Tooltip disableInteractive title="Filters">
                <IconButton
                  aria-controls="simple-menu"
                  aria-haspopup="true"
                  onClick={this.openFilters}
                >
                  <FilterListIcon size="small" />
                </IconButton>
              </Tooltip>
              <Menu
                id="status-dropdown"
                className={`dropdown-filter${
                  this.state.loading ? " disabled-children" : ""
                }`}
                anchorEl={this.state.anchorEl}
                keepMounted
                open={Boolean(this.state.anchorEl)}
                onClose={this.closeFilters}
              >
                {this.state.filters.map((filter) => {
                  return (
                    <MenuItem key={filter.name}>
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Checkbox
                              size="small"
                              name={filter.key}
                              checked={this.state[filter.key]}
                              onChange={(e) => {
                                this.handleInputChange(e);
                                // this.closeFilters();
                              }}
                            />
                          }
                          label={filter.name}
                        />
                      </FormGroup>
                    </MenuItem>
                  );
                })}
              </Menu>
            </div>
            <div id="search-docs">
              <Search
                onFinish={this.displayDocs}
                onClear={this.clearSearch}
                searching={this.state.searching}
                onSearch={this.enableSearching.bind(this)}
                handleInputChange={this.handleInputChange}
                query={this.state.Search}
                clearDocs={this.clearDocs}
              />
            </div>
          </Grid>
        </Grid>

        <hr />
        {this.state.documents.length ? (
          <>
            <div id="doc-list" className="listing">
              {this.state.documents.map((doc, index) => {
                let userInfo = doc.lastModified?.id
                  ? this.getUserInfo(doc.lastModified.id)
                  : this.getUserInfo(doc.createdBy);
                return (
                  <Paper
                    key={doc._id.toString()}
                    className={`doc id-${doc._id.toString()}${
                      doc.docNum === "MAST" ? " master" : ""
                    }${
                      doc.docNum !== "MAST" &&
                      !doc.approved &&
                      !doc.rejected &&
                      !doc.revised &&
                      doc.lastModified &&
                      doc.lastModified.time &&
                      moment(doc.lastModified.time).isBefore(
                        moment(new Date()).subtract(2, "weeks")
                      )
                        ? //   ||
                          // (doc.approved &&
                          //   doc.eApproved &&
                          //   !doc.approvedBy.acknowledgedBy)
                          " action-needed"
                        : ""
                    }${!doc.crmCompany ? " attention-needed" : ""}`}
                  >
                    <div className="list-name">
                      <Link to={`/${doc._id.toString()}`}>
                        <Typography variant="h6" className="doc-title">
                          {doc.Title}
                        </Typography>
                      </Link>
                    </div>
                    <div className="list-meta">
                      <Box display="flex" alignItems="center" mr={2}>
                        <Tooltip
                          disableInteractive
                          title={
                            doc.lastModified?.time
                              ? moment(doc.lastModified.time).format("lll")
                              : moment(doc.date).format("lll")
                          }
                          placement="left"
                        >
                          <Typography
                            sx={{
                              "&": {
                                fontSize: "12px",
                                mr: 2,
                                opacity: 0.6,
                                whiteSpace: "nowrap",
                              },
                            }}
                          >
                            {doc.lastModified?.time
                              ? moment(doc.lastModified.time).fromNow()
                              : moment(doc.date).fromNow()}
                          </Typography>
                        </Tooltip>
                        <span>
                          <Tooltip
                            disableInteractive
                            title={userInfo?.name || "User not found"}
                            placement="right"
                          >
                            <Avatar
                              src={userInfo?.picture}
                              alt={userInfo?.name}
                              sx={{
                                "&": { width: 28, height: 28 },
                              }}
                            />
                          </Tooltip>
                        </span>
                      </Box>
                      <Box
                        display="flex"
                        alignItems="center"
                        sx={{ "&": { minWidth: 100 }, "& > *": { ml: 0.5 } }}
                      >
                        {doc.clone ? <Chip size="small" label="Clone" /> : ""}
                        {doc.approved && !doc.sentToClient && !doc.rejected ? (
                          <Chip size="small" label="Approved" color="success" />
                        ) : (
                          ""
                        )}
                        {!doc.approved && doc.sentToClient && !doc.rejected ? (
                          <Chip
                            size="small"
                            label="Sent to Client"
                            color="info"
                          />
                        ) : (
                          ""
                        )}
                        {!doc.approved && !doc.sentToClient && doc.rejected ? (
                          <Chip size="small" label="Rejected" color="error" />
                        ) : (
                          ""
                        )}
                        {doc.revised ? (
                          <Chip
                            size="small"
                            label="Revised"
                            color="secondary"
                          />
                        ) : (
                          ""
                        )}
                        {doc.docNum === "MAST" ? (
                          <Chip size="small" label="Master" color="warning" />
                        ) : (
                          ""
                        )}
                      </Box>
                      <Options
                        doc={doc}
                        cloneDoc={this.cloneDoc}
                        openBatchDialog={this.openBatchDialog}
                        openDialog={this.openDialog}
                        cloning={this.state.cloning}
                        total={this.state.total}
                      />
                    </div>
                  </Paper>
                );
              })}
            </div>
            {this.state.total > this.state.documents.length && (
              <LoadingButton
                style={{ width: "120px" }}
                variant="outlined"
                color="secondary"
                onClick={this.loadMore.bind(this)}
                pending={this.state.loading}
              >
                Load More
              </LoadingButton>
            )}
          </>
        ) : this.state.noResults ? (
          "No documents found"
        ) : (
          <CircularProgress />
        )}
        <br />
        <br />
        <Settings
          onSubmit={this.addDoc}
          company={this.state.newCompany}
          type={this.state.newType}
          quarter={this.state.newQuarter}
          custom={this.state.newCustom}
          year={this.state.newYear}
          adding={this.state.adding}
        />
        <Confirm
          open={this.state.open}
          title="Delete SOW"
          text="Are you sure you want to delete "
          highlight={this.state.deleteTitle}
          id={this.state.deleteId}
          closeDialog={this.closeDialog}
          confirmAction={this.deleteDoc}
          confirmLabel="Delete"
          inFlight={this.state.deleting}
        />
        <BatchModal
          batchCloning={this.state.batchCloning}
          batchProgress={this.state.batchProgress}
          members={this.state.members}
          open={this.state.openBatch}
          closeDialog={this.closeBatchDialog}
          confirmAction={this.batchCloneDoc}
          title={this.state.docTitle}
          docId={this.state.docId}
          batchArray={this.stateBatchArray}
          numClones={this.state.numClones}
        />
      </Container>
    ) : (
      <div id="spinner">
        <CircularProgress disableShrink />
      </div>
    );
  }
}

Home.propTypes = {
  normalAdmin: PropTypes.bool,
  superAdmin: PropTypes.bool,
};

export default Home;
