import moment from "moment";
import axios from "axios";
import _ from "lodash";
// import { Auth0Context } from "../react-auth0-wrapper";

export function showNotification(variant, message) {
  let time = new Date().getTime();
  this.props.alert(variant, message, time);
}

export function generateDocName(company, type, quarter, custom, year, version) {
  let generatedName = `${company.replace(/ /g, "")} ${type} ${
    quarter && type === "PA" ? quarter + " " : ""
  }${custom && type === "Custom" ? custom + " " : ""}${year}`;

  generatedName = generatedName
    .trim()
    .replace(/ /g, "_")
    .replace("Custom_", "");

  if (version > 1) {
    generatedName = `${generatedName}_V${version}`;
  }

  return generatedName;
}

export const replaceOnDocument = (
  element,
  pattern,
  string,
  { target = element } = {}
) => {
  try {
    [
      target,
      ...target.querySelectorAll("*:not(script):not(noscript):not(style)"),
    ].forEach(({ childNodes: [...nodes] }) =>
      nodes
        .filter(({ nodeType }) => nodeType === document.TEXT_NODE)
        .forEach((textNode) => {
          if (textNode.textContent.includes(pattern)) {
            if (pattern.toLowerCase() === "[company name]") {
              try {
                textNode.parentNode.innerHTML = textNode.parentNode.innerHTML.replace(
                  /\[Company Name]/g,
                  `<span class="company-name">${string}</span>`
                );
              } catch (err) {}
            } else {
              textNode.textContent = textNode.textContent.replace(
                pattern,
                string
              );
            }
          }
        })
    );
  } catch (err) {
    console.error(err);
  }
};

export function injectCompany() {
  replaceOnDocument(
    document.querySelector("#document"),
    "[Company Name]",
    (this.state.crmCompany && this.state.crmCompany.label) || this.state.company
  );

  for (let company of document.querySelectorAll(
    "#content .company-name, #index-of-contents .company-name"
  )) {
    company.innerText =
      (this.state.crmCompany && this.state.crmCompany.label) ||
      this.state.company;
  }

  for (let table of document.querySelectorAll("#content .block table")) {
    if (!table.classList.contains("table")) {
      table.classList.add("table", "table-sm");
    }
  }
}

export function injectVariables() {
  for (let years of document.querySelectorAll("#content .years-experience")) {
    years.innerText = moment()
      .from(new Date("3/15/1995"), true)
      .replace(" years", "");
  }

  if (this.state.startDate) {
    for (let date of document.querySelectorAll("#content .start-year")) {
      date.innerText = moment(this.state.startDate).format("YYYY");
    }
  }

  if (this.state.endDate) {
    for (let date of document.querySelectorAll("#content .end-year")) {
      date.innerText = moment(this.state.endDate).format("YYYY");
    }
  }

  if (this.state.startDate) {
    for (let date of document.querySelectorAll("#content .start-date")) {
      date.innerText = moment(this.state.startDate).format("MMMM D, YYYY");
    }
  }

  if (this.state.endDate) {
    for (let date of document.querySelectorAll("#content .end-date")) {
      date.innerText = moment(this.state.endDate).format("MMMM D, YYYY");
    }
  }

  if (this.state.paymentSplit) {
    for (let text of document.querySelectorAll("#content .payment-split")) {
      text.innerText = this.state.paymentSplit;
    }
  }

  makeOrange(document.querySelectorAll("#content .section-title"));
} 

export function makeOrange(array) {
  for (let title of array) {
    let regExp = /\(([^)]+)\)/;
    let matches = regExp.exec(title.innerText);
    if (matches) {
      title.innerHTML = title.innerText
        .replace(/\(/g, '(<span class="orange">')
        .replace(/\)/g, "</span>)");
    }
  }
}

export function findLast(items, predicate) {
  for (var i = items.length - 1; i >= 0; i--) {
    var item = items[i];

    if (predicate(item)) {
      return i;
    }
  }
}

export function formatCurrency(num, showUSD) {
  // if (!showUSD) {
  //   if (this.state.currency === "EUR") {
  //     num = Math.ceil(num * this.state.rates.rates.EUR);
  //   } else if (this.state.currency === "GBP") {
  //     num = Math.ceil(num * this.state.rates.rates.GBP);
  //   }
  // }
  if (
    num ||
    (((this.state &&
      this.state.docType &&
      this.state.docType === "Gift Estimate") ||
      this.props.docType === "Gift Estimate") &&
      typeof parseFloat(num) === "number")
  ) {
    if (
      (this.state &&
        this.state.docType &&
        this.state.docType === "Gift Estimate") ||
      this.props.docType === "Gift Estimate"
    ) {
      num = parseFloat(num, 10).toFixed(2);
    }

    num = num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");

    if (num === "NaN") {
      num = "";
    }

    return num;
  }
}

export function formatNumber(num) {
  if (num) {
    // if ((this.state && this.state.docType && this.state.docType === "Gift Estimate") || this.props.docType === "Gift Estimate") {
    //   num = parseFloat(num, 10).toFixed(2)
    // }
    return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
  }
}

export function toNum(value, state) {
  // copied into ../workers/calculateTotalPriceWorker.js
  if (state) {
    if (state && state.docType && state.docType === "Gift Estimate") {
      return parseFloat(value, 10);
    } else {
      return parseInt(value, 10);
    }
  } else {
    if (
      (this.state &&
        this.state.docType &&
        this.state.docType === "Gift Estimate") ||
      this.props.docType === "Gift Estimate"
    ) {
      return parseFloat(value, 10);
    } else {
      return parseInt(value, 10);
    }
  }
}

export function displayNum(value) {
  if (
    (this.state &&
      this.state.docType &&
      this.state.docType === "Gift Estimate") ||
    this.props.docType === "Gift Estimate"
  ) {
    return parseFloat(value, 10).toFixed(2);
  } else {
    return parseInt(value, 10);
  }
}

export function getAppVersion() {
  axios
    .get("/api/version")
    .then((response) => {
      console.log("APP VERSION", response.data);
      console.log("CURRENT VERSION", localStorage.getItem("app-version"));
      this.setState(
        {
          appVersion: response.data,
        },
        () => {
          if (
            localStorage.getItem("app-version") &&
            localStorage.getItem("app-version") !== response.data
          ) {
            localStorage.setItem("app-version", response.data);
            window.location.reload();
          } else if (!localStorage.getItem("app-version")) {
            localStorage.setItem("app-version", response.data);
            window.location.reload();
          }
        }
      );
    })
    .catch((error) => {
      console.error(error);
    });
}

export function calculateTotalPrice(state, report) {
  // copied into ../workers/calculateTotalPriceWorker.js
  let totals = {};
  let totalPT = 0;
  let totalDS = 0;
  let totalPrice = 0;
  let optionalIndex;
  let optionalIndexes = [];

  Object.keys(state).forEach((key) => {
    if (
      key.startsWith("tableName-") &&
      (state[key] !== null || state[key] !== undefined)
    ) {
      if (state[key]?.includes("Optional")) {
        optionalIndex = parseInt(key.replace("tableName-", ""), 10);
        optionalIndexes.push(optionalIndex);
      }
    }
  });

  // console.log('OPTIONAL', optionalIndexes)

  if (!report) {
    this.setState({
      hasExistingSpend: false,
      hasPassThrough: false,
    });
  }

  _.times(state.tables, (i) => {
    // if (i !== optionalIndex) {
    totals[`${i}-existingSpendTotal`] = 0;
    totals[`${i}-passThroughTotal`] = 0;
    totals[`${i}-newSpendTotal`] = 0;

    for (let section of state.content.filter(
      (el) => parseInt(el.table) === i
    )) {
      if (section.deliverable) {
        let existingSpend = toNum(section.existingSpend || 0, state),
          passThrough = toNum(section.passThrough, state),
          discount = toNum(section.discount || 0, state),
          quantity = parseInt(section.quantity, 10) || 1,
          price = toNum(section.price || 0, state), // Direct Spend
          sectionTotal,
          passThroughDiscount = false;

        if (discount && passThrough && !price) {
          // console.log(i, "PASSTHROUGH DISCOUNT", section);
          passThroughDiscount = true;
          passThrough = toNum(section.passThrough, state) - discount / quantity;
          sectionTotal = quantity ? passThrough * quantity : passThrough;
        } else {
          // console.log(i, "DIRECT DISCOUNT");
          price = toNum(section.price || 0, state) - discount / quantity;
          sectionTotal = quantity ? price * quantity : price;
        }

        if (existingSpend > 0) {
          if (!report) {
            this.setState({
              hasExistingSpend: true,
            });
          }
        }

        if (passThrough || passThrough === 0) {
          if (!report) {
            this.setState({
              hasPassThrough: true,
            });
          }
        } else if (isNaN(passThrough)) {
          passThrough = 0;
        }

        totalPT = totalPT + passThrough;

        if (!passThroughDiscount) {
          totalDS = totalDS + sectionTotal;
        }

        totals[`${i}-existingSpendTotal`] =
          totals[`${i}-existingSpendTotal`] + existingSpend - existingSpend;
        totals[`${i}-passThroughTotal`] =
          totals[`${i}-passThroughTotal`] + passThrough;
        totals[`${i}-newSpendTotal`] = passThroughDiscount
          ? totals[`${i}-newSpendTotal`]
          : totals[`${i}-newSpendTotal`] + sectionTotal;

        // if (typeof optionalIndex === "number" && optionalIndex === i) {
        if (optionalIndexes.includes(i)) {
          totalPT = totalPT - passThrough;
          totalDS = totalDS - sectionTotal;
          totalPrice = totalPrice - passThrough - sectionTotal;
        }

        if (section.subsections) {
          for (let sub of section.subsections) {
            let subExistingSpend = toNum(sub.existingSpend || 0, state),
              subPassThrough = toNum(sub.passThrough || 0, state),
              subPrice = toNum(sub.price || 0, state),
              subQuantity = parseInt(sub.quantity, 10) || 1,
              subSectionTotal = subQuantity ? subPrice * subQuantity : subPrice;

            if (!report) {
              if (subExistingSpend > 0) {
                this.setState({
                  hasExistingSpend: true,
                });
              }
              if (subPassThrough > 0) {
                this.setState({
                  hasPassThrough: true,
                });
              }
            }

            totalPT = totalPT + subPassThrough;

            if (!passThroughDiscount) {
              totalDS = totalDS + subSectionTotal;
            }

            totals[`${i}-existingSpendTotal`] =
              totals[`${i}-existingSpendTotal`] +
              subExistingSpend -
              subExistingSpend;
            totals[`${i}-passThroughTotal`] =
              totals[`${i}-passThroughTotal`] + subPassThrough;
            totals[`${i}-newSpendTotal`] =
              totals[`${i}-newSpendTotal`] + subSectionTotal;

            // if (typeof optionalIndex === "number" && optionalIndex === i) {
            if (optionalIndexes.includes(i)) {
              totalPT = totalPT - subPassThrough;
              totalDS = totalDS - subSectionTotal;
              totalPrice = totalPrice - subPassThrough - subSectionTotal;
            }
          }
        }
      }
    }

    // console.log("total", i, totalPrice, totals);
    // }
  });

  let totalSum = Object.values(totals).reduce((a, b) => a + b, 0);

  totalDS = totalDS - toNum(state.newSpendDiscount || 0, state);

  totalPrice =
    totalPrice + totalSum - toNum(state.newSpendDiscount || 0, state);

  // console.log({
  //   totals,
  //   totalPT,
  //   totalDS,
  //   totalPrice,
  // });

  return {
    totals,
    totalPT,
    totalDS,
    totalPrice,
  };
}

export function getCurrencySymbol(currency) {
  let symbol;

  switch (currency) {
    case "GBP":
      symbol = "£";
      break;
    case "EUR":
      symbol = "€";
      break;
    default:
      symbol = "$";
  }

  return symbol;
}

export function getUserInfo(id) {
  return (
    this.context.team.find((user) => user.id.includes(id)) ||
    this.context.team.find((user) => user.email === id)
  );
}
