/**
 *
 * DeliverableOptions
 *
 */

import React from "react";
import {
  Grid,
  IconButton,
  Tooltip,
  Autocomplete,
  TextField,
  InputAdornment,
} from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import ButtonGroup from "@material-ui/core/ButtonGroup"
import autoBind from "react-autobind";
import "./styles.scss";

class DeliverableOptions extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      categoryOptions: [],
    };

    autoBind(this);
  }

  componentDidMount() {
    let categoryOptions = [];

    this.props.categories.map((section, i) => {
      return categoryOptions.push({
        value: section,
        label: section,
      });
    });

    this.setState({
      categoryOptions,
    });
  }

  render() {
    return (
      <>
        <Grid display="flex" alignItems="center" ml={-0.5} mr={-0.5} mb={2}>
          <Grid
            item
            display="flex"
            alignItems="center"
            style={{
              flex: 1,
            }}
          >
            <Autocomplete
              autoHighlight
              fullWidth
              value={
                this.props.formDeliverableCategory
                  ? {
                      value: this.props.formDeliverableCategory,
                      label: this.props.formDeliverableCategory,
                    }
                  : null
              }
              options={this.state.categoryOptions}
              getOptionLabel={(option) => option?.label || ""}
              getOptionSelected={(option, value) =>
                option.value === value.value
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Category"
                  variant="outlined"
                  size="small"
                  margin="normal"
                  required
                  InputLabelProps={{
                    required: false,
                  }}
                />
              )}
              onInputChange={(e, value) =>
                this.props.handleReactSelect("formDeliverableCategory", value)
              }
            />
          </Grid>
          <Grid
            item
            display="flex"
            sx={{
              "&": {
                marginLeft: "0 !important",
              },
              "& .MuiGrid-root": {
                maxWidth: 160,
                display: "flex",
                "& .expand-number": {
                  minWidth: 36,
                },
              },
            }}
          >
            {this.props.docType === "Gift Estimate" ? null : (
              <>
                <Grid item>
                  <TextField
                    id="edit-price"
                    className="currency has-expand"
                    size="small"
                    // margin="dense"
                    label="Direct Spend"
                    variant="outlined"
                    type="number"
                    name="formPrice"
                    value={this.props.formPrice}
                    onChange={this.props.handleInputChange}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          {this.props.currencySign}
                        </InputAdornment>
                      ),
                      inputProps: {
                        min: 0,
                      },
                    }}
                  />
                  <Tooltip
                    disableInteractive
                    title="# of rows to merge"
                    placement="top"
                  >
                    <TextField
                      size="small"
                      type="number"
                      InputProps={{
                        inputProps: {
                          min: 2,
                          max: 20,
                        },
                      }}
                      name="formExpandNewSpend"
                      className="expand-number"
                      value={this.props.formExpandNewSpend}
                      onChange={this.props.handleInputChange}
                      placeholder="↓"
                    />
                  </Tooltip>
                </Grid>
                <Grid item>
                  <TextField
                    id="edit-existing-spend"
                    className="currency has-expand"
                    size="small"
                    // margin="dense"
                    label="Existing Spend"
                    variant="outlined"
                    type="number"
                    name="formExistingSpend"
                    value={this.props.formExistingSpend}
                    onChange={this.props.handleInputChange}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          {this.props.currencySign}
                        </InputAdornment>
                      ),
                      inputProps: {
                        min: 0,
                      },
                    }}
                  />
                  <Tooltip
                    disableInteractive
                    title="# of rows to merge"
                    placement="top"
                  >
                    <TextField
                      size="small"
                      type="number"
                      InputProps={{
                        inputProps: {
                          min: 2,
                          max: 20,
                        },
                      }}
                      name="formExpandExistingSpend"
                      className="expand-number"
                      value={this.props.formExpandExistingSpend}
                      onChange={this.props.handleInputChange}
                      placeholder="↓"
                    />
                  </Tooltip>
                </Grid>
                <Grid item>
                  <TextField
                    id="edit-pass-through"
                    className="currency has-expand"
                    size="small"
                    // margin="dense"
                    label="Pass Through"
                    variant="outlined"
                    type="number"
                    name="formPassThrough"
                    value={this.props.formPassThrough}
                    onChange={this.props.handleInputChange}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          {this.props.currencySign}
                        </InputAdornment>
                      ),
                      inputProps: {
                        min: 0,
                      },
                    }}
                  />
                  <Tooltip
                    disableInteractive
                    title="# of rows to merge"
                    placement="top"
                  >
                    <TextField
                      size="small"
                      type="number"
                      InputProps={{
                        inputProps: {
                          min: 2,
                          max: 20,
                        },
                      }}
                      name="formExpandPassThrough"
                      className="expand-number"
                      value={this.props.formExpandPassThrough}
                      onChange={this.props.handleInputChange}
                      placeholder="↓"
                    />
                  </Tooltip>
                </Grid>
              </>
            )}
            <Grid item style={{ marginRight: 0 }}>
              <TextField
                id="edit-discount"
                className="currency"
                size="small"
                // margin="dense"
                label="Discount"
                variant="outlined"
                type="number"
                name="formDiscount"
                value={this.props.formDiscount}
                onChange={this.props.handleInputChange}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      {this.props.currencySign}
                    </InputAdornment>
                  ),
                  inputProps: {
                    min: 0,
                    step: 0.1,
                  },
                }}
              />
              <Tooltip disableInteractive title="Add Subsection">
                <IconButton
                  className="appended-button"
                  color="secondary"
                  size="small"
                  onClick={this.props.addSubsection}
                  type="button"
                >
                  <AddCircleOutlineIcon />
                </IconButton>
              </Tooltip>
            </Grid>
          </Grid>
        </Grid>
        <div className="sub-section-container">
          {this.props.formSubsections &&
            this.props.formSubsections.map((sub, i) => {
              return (
                <Grid
                  key={`${i}-subsection`}
                  className="sub-section"
                  display="flex"
                  alignItems="center"
                  ml={-0.5}
                  mr={-0.5}
                  mb={2}
                >
                  &emsp;-
                  <Grid
                    item
                    display="flex"
                    alignItems="center"
                    style={{
                      flex: 1,
                    }}
                  >
                    <TextField
                      fullWidth
                      label="Subsection Title"
                      variant="outlined"
                      size="small"
                      required
                      InputLabelProps={{
                        required: false,
                      }}
                      name={`${i}-formSubsectionTitle`}
                      value={this.props[`${i}-formSubsectionTitle`] || ""}
                      onChange={(e) => this.props.handleInputChange(e, i)}
                    />
                  </Grid>
                  <Grid
                    item
                    display="flex"
                    sx={{
                      "&": {
                        marginLeft: "0 !important",
                      },
                      "& .MuiGrid-root": {
                        maxWidth: 160,
                        display: "flex",
                        "& .expand-number": {
                          minWidth: 36,
                        },
                      },
                    }}
                  >
                    <Grid item>
                      <TextField
                        className="currency has-expand"
                        size="small"
                        // margin="dense"
                        label={
                          this.props.docType === "Gift Estimate"
                            ? "Unit Cost"
                            : "Direct Spend"
                        }
                        variant="outlined"
                        type="number"
                        name={`${i}-formSubsectionPrice`}
                        value={this.props[`${i}-formSubsectionPrice`] || ""}
                        onChange={this.props.handleInputChange}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              {this.props.currencySign}
                            </InputAdornment>
                          ),
                          inputProps: {
                            min: 0,
                            step: 0.01,
                          },
                        }}
                      />

                      <Tooltip
                        disableInteractive
                        title="# of rows to merge"
                        placement="top"
                      >
                        <TextField
                          size="small"
                          type="number"
                          InputProps={{
                            inputProps: {
                              min: 2,
                              max: 20,
                            },
                          }}
                          name={`${i}-formSubsectionExpandNewSpend`}
                          className="expand-number"
                          value={
                            this.props[`${i}-formSubsectionExpandNewSpend`] ||
                            ""
                          }
                          onChange={this.props.handleInputChange}
                          placeholder="↓"
                        />
                      </Tooltip>
                    </Grid>
                    {this.props.docType === "Gift Estimate" && (
                      <Grid item>
                        <TextField
                          className="quantity"
                          size="small"
                          label="Quantity"
                          variant="outlined"
                          type="number"
                          name={`${i}-formSubsectionQuantity`}
                          value={
                            this.props[`${i}-formSubsectionQuantity`] || ""
                          }
                          onChange={this.props.handleInputChange}
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                #
                              </InputAdornment>
                            ),
                            inputProps: {
                              min: 1,
                              step: 1,
                            },
                          }}
                        />
                      </Grid>
                    )}
                    {this.props.docType === "Gift Estimate" ? (
                      <>
                      <Grid item>
                        <TextField
                          className="currency"
                          size="small"
                          label="Total"
                          variant="outlined"
                          type="number"
                          value={(
                            this.props[`${i}-formSubsectionPrice`] *
                            this.props[`${i}-formSubsectionQuantity`]
                          ).toFixed(2)}
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                {this.props.currencySign}
                              </InputAdornment>
                            ),
                          }}
                          style={{
                            pointerEvents: "none",
                          }}
                        />
                      </Grid>
                      <Grid item>
                          <ButtonGroup className="has-expand appended-button-group">
                            
                            <Tooltip
                              title="Move Subsection"
                              placement="right"
                              disableInteractive
                            >
                              <IconButton
                                size="small"
                                onClick={(e) =>
                                  this.props.moveSubsection(e, i, 'up')
                                }
                              >
                                <ArrowUpwardIcon color="action" />
                              </IconButton>
                            </Tooltip>

                            {<Tooltip
                              title="Move Subsection"
                              placement="right"
                              disableInteractive
                            >
                              <IconButton
                                size="small"
                                onClick={(e) =>
                                  this.props.moveSubsection(e, i, 'down')
                                }
                              >
                                <ArrowDownwardIcon color="action" />
                              </IconButton>
                            </Tooltip>}

                            <Tooltip
                              title="Delete Subsection"
                              placement="right"
                              disableInteractive
                            >
                              <IconButton
                                size="small"
                                onClick={(e) =>
                                  this.props.deleteSubsection(e, i)
                                }
                              >
                                <DeleteIcon color="error" />
                              </IconButton>
                            </Tooltip>
                            
                          </ButtonGroup>
                        </Grid>
                      </>
                    ) : (
                      <>
                        <Grid item>
                          <TextField
                            className="currency has-expand"
                            size="small"
                            // margin="dense"
                            label="Existing Spend"
                            variant="outlined"
                            type="number"
                            name={`${i}-formSubsectionExistingSpend`}
                            value={
                              this.props[`${i}-formSubsectionExistingSpend`] ||
                              ""
                            }
                            onChange={this.props.handleInputChange}
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  {this.props.currencySign}
                                </InputAdornment>
                              ),
                              inputProps: {
                                min: 0,
                              },
                            }}
                          />
                          <Tooltip
                            disableInteractive
                            title="# of rows to merge"
                            placement="top"
                          >
                            <TextField
                              size="small"
                              type="number"
                              InputProps={{
                                inputProps: {
                                  min: 2,
                                  max: 20,
                                },
                              }}
                              name={`${i}-formSubsectionExpandExistingSpend`}
                              className="expand-number"
                              value={
                                this.props[
                                  `${i}-formSubsectionExpandExistingSpend`
                                ] || ""
                              }
                              onChange={this.props.handleInputChange}
                              placeholder="↓"
                            />
                          </Tooltip>
                        </Grid>
                        <Grid item>
                          <TextField
                            className="currency has-expand"
                            size="small"
                            // margin="dense"
                            label="Pass Through"
                            variant="outlined"
                            type="number"
                            name={`${i}-formSubsectionPassThrough`}
                            value={
                              this.props[`${i}-formSubsectionPassThrough`] || ""
                            }
                            onChange={this.props.handleInputChange}
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  {this.props.currencySign}
                                </InputAdornment>
                              ),
                              inputProps: {
                                min: 0,
                              },
                            }}
                          />
                          <Tooltip
                            disableInteractive
                            title="# of rows to merge"
                            placement="top"
                          >
                            <TextField
                              size="small"
                              type="number"
                              InputProps={{
                                inputProps: {
                                  min: 2,
                                  max: 20,
                                },
                              }}
                              name={`${i}-formSubsectionExpandPassThrough`}
                              className="expand-number"
                              value={
                                this.props[
                                  `${i}-formSubsectionExpandPassThrough`
                                ] || ""
                              }
                              onChange={this.props.handleInputChange}
                              placeholder="↓"
                            />
                          
                          </Tooltip>
                        </Grid>
                        <Grid item>
                          <ButtonGroup className="has-expand appended-button-group">
                            
                            <Tooltip
                              title="Move Subsection"
                              placement="right"
                              disableInteractive
                            >
                              <IconButton
                                size="small"
                                onClick={(e) =>
                                  this.props.moveSubsection(e, i, 'up')
                                }
                              >
                                <ArrowUpwardIcon color="action" />
                              </IconButton>
                            </Tooltip>

                            {<Tooltip
                              title="Move Subsection"
                              placement="right"
                              disableInteractive
                            >
                              <IconButton
                                size="small"
                                onClick={(e) =>
                                  this.props.moveSubsection(e, i, 'down')
                                }
                              >
                                <ArrowDownwardIcon color="action" />
                              </IconButton>
                            </Tooltip>}

                            <Tooltip
                              title="Delete Subsection"
                              placement="right"
                              disableInteractive
                            >
                              <IconButton
                                size="small"
                                onClick={(e) =>
                                  this.props.deleteSubsection(e, i)
                                }
                              >
                                <DeleteIcon color="error" />
                              </IconButton>
                            </Tooltip>
                            
                          </ButtonGroup>
                        </Grid>
                      </>
                    )}
                  </Grid>
                </Grid>
              );
            })}
        </div>
      </>
    );
  }
}

export default DeliverableOptions;
