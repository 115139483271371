import moment from "moment";
// import _ from "lodash";
import {
  chunkPromise,
  PromiseFlavor,
  ChunkPromiseCallbackForceStopError,
} from "chunk-promise";
import { generateDocName } from "./functions";

var ObjectID = require("bson-objectid");

export function deleteDoc() {
  let docId = this.state.deleteId;
  document.querySelector(`.id-${docId}`).classList.add("deleting");
  // console.log(ObjectID(docId), ObjectID(docId).toString(), docId);
  this.setState(
    {
      deleting: true,
    },
    () => {
      this.props.db
        .collection(this.props.collection)
        .findOne({
          _id: ObjectID(docId),
        })
        .then((result) => {
          let originalDoc = result;
          originalDoc.deletedAt = moment().toDate();
          this.props.db
            .collection("trash")
            .insertOne(originalDoc)
            .then((result) => {
              this.props.db
                .collection(this.props.collection)
                .deleteOne({
                  _id: ObjectID(docId),
                })
                .then(() => {
                  this.closeDialog();
                  this.setState({
                    deleting: false,
                    documents: [
                      ...this.state.documents.filter(
                        (doc) => doc._id.toString() !== docId
                      ),
                    ],
                  });
                  if (window.location.pathname === "/") {
                    // this.displayDocs(null, true);
                  } else {
                    this.props.history.push(`/`);
                  }
                  this.showNotification(
                    "success",
                    `SOW [${this.state.deleteTitle}] deleted!`
                  );
                })
                .catch((err) => {
                  console.error(err);
                  deleteFailed();
                });
            })
            .catch((err) => {
              console.error(err);
              deleteFailed();
            });
        })
        .catch((err) => {
          console.error(err);
          deleteFailed();
        });
    }
  );

  const deleteFailed = () => {
    this.setState({
      deleting: false,
    });
    document.querySelector(`.id-${docId}`).classList.remove("deleting");
    this.showNotification("error", "Failed to delete SOW!");
  };
}

export async function getNewDocNumber() {
  let docNum = await this.props.db
    .collection(this.props.collection)
    .find({ docNum: { $type: "int" } }, { limit: 1, sort: { docNum: -1 } })
    .toArray()
    .then((result) => {
      let prevDocTitle = result.length ? result[0].Title : "8220_";
      let prevNum = prevDocTitle.substr(0, prevDocTitle.indexOf("_"));
      if (Number.isInteger(parseInt(prevNum))) {
        let newNum = parseInt(prevNum) + 1;
        return newNum;
      }
    })
    .catch(console.error);

  return docNum;
}

export async function cloneDoc(e, docId, version, title, batch) {
  this.setState({
    query: "",
    cloning: true,
  });
  e?.preventDefault();
  let originalDoc;
  await this.props.db
    .collection(this.props.collection)
    .findOne({
      _id: ObjectID(docId),
    })
    .then((result) => {
      originalDoc = result;
      originalDoc._id = new ObjectID();
      return this.getNewDocNumber().then((newNum) => {
        let removedNum = originalDoc.Title.substring(
          originalDoc.Title.indexOf("_")
        );
        originalDoc.docNum = newNum;
        originalDoc.Title = newNum + removedNum;
        originalDoc.Title = originalDoc.Title.replace(/_V[\d]+/g, "");
        originalDoc.clone = true;
        originalDoc.approved = false;
        originalDoc.sentToClient = false;
        originalDoc.rejected = false;
        originalDoc.revised = false;
        originalDoc.date = moment().toDate();
        originalDoc.sigDate = moment().toDate();
        originalDoc.createdBy = this.context.user.id;
        originalDoc.lastModified = {};
        originalDoc.saved = null;
        originalDoc.token = null;
        originalDoc.eApproved = false;
        originalDoc.approvedBy = {};
        originalDoc.approvalCompany = "";
        originalDoc.approvalName = "";
        originalDoc.approvalTitle = "";
        originalDoc.attachedFiles = [];

        if (
          originalDoc.crmCompany &&
          originalDoc.crmCompany.label === "Master"
        ) {
          originalDoc.crmCompany = null;
        }

        if (batch) {
          originalDoc.salesPerson = batch.salesPerson;
          originalDoc.company = batch.companyName;
          originalDoc.contactHTML = batch.contactHTML;
          originalDoc.logo = batch.companyLogo;
          originalDoc.Title =
            newNum +
            "_" +
            generateDocName(
              originalDoc.company,
              originalDoc.type,
              originalDoc.quarter,
              originalDoc.custom,
              originalDoc.year,
              1
            );
          originalDoc.accountManagement = null;
        }

        if (version) {
          originalDoc.version = originalDoc.version + 1;
          originalDoc.Title = `${originalDoc.Title}_V${originalDoc.version}`;
          originalDoc.previousVersions = originalDoc.previousVersions
            ? originalDoc.previousVersions
            : [];
          originalDoc.previousVersions.push(docId);

          this.props.db
            .collection(this.props.collection)
            .updateOne(
              {
                _id: ObjectID(docId),
              },
              {
                $set: {
                  rejected: false,
                  sentToClient: false,
                  revised: true,
                },
              }
            )
            .then((result) => {});
        } else {
          originalDoc.version = 1;
          originalDoc.previousVersions = [];
        }

        return this.props.db
          .collection(this.props.collection)
          .insertOne(originalDoc)
          .then((result) => {
            this.setState({
              cloning: false,
            });
            if (window.location.pathname === "/") {
              this.displayDocs(null, true);
            } else {
              this.props.history.push(`/${result.insertedId.toString()}`);
            }
            if (version) {
              this.showNotification(
                "success",
                `New version of [${title}] added!`
              );
            } else {
              this.showNotification("success", `SOW [${title}] cloned!`);
            }
          })
          .catch((err) => {
            console.error(err);
            this.setState({
              cloning: false,
            });
          });
      });
    })
    .catch((err) => {
      console.error(err);
      this.setState({
        cloning: false,
      });
    });
}

export function batchCloneDoc(arrayDoc, originId) {
  this.setState({ batchCloning: true });

  if (arrayDoc.length) {
    let promiseArr = [];

    arrayDoc.forEach((doc) => {
      promiseArr.push(() => this.cloneDoc(null, originId, null, null, doc));
    });

    chunkPromise(promiseArr, {
      concurrent: 1,
      promiseFlavor: PromiseFlavor.PromiseAll,
      callback: async (chunkResults, index) => {
        if (!this.state.openBatch) {
          throw new ChunkPromiseCallbackForceStopError(
            `Callback force stop at chunk index ${index}`
          );
        }

        let batchProgress = (index / arrayDoc.length) * 100;
        this.setState({ batchProgress });
      },
    })
      .then((res) => {
        this.showNotification("success", "Batch Clone Success!");
        this.setState({ batchCloning: false });
        this.closeBatchDialog();
      })
      .catch((err) => {
        console.log("Batch Clone Failed!", err);
        this.showNotification("error", "Batch Clone Failed!");
      });
  }
}
