import moment from "moment";

export function handleInputChange(event) {
  let target = event.target;
  let value = target.type === "checkbox" ? target.checked : target.value;
  let name = target.name;

  if (typeof value === "string" && name.toLowerCase().includes("title")) {
    value = value.replace(/\s\s+/g, " ");
  }

  // if (name === "Search") {
  //   value = value.trim();
  // }

  this.setState(
    {
      [name]: value,
    },
    () => {
      if (name.startsWith("price-")) {
        this.checkPrices();
      }

      if (
        name === "showApproved" ||
        name === "showSentToClient" ||
        name === "showRejected" ||
        name === "showRevised" ||
        name === "showActionNeeded"
      ) {
        this.setState(
          {
            loading: true,
            documents: [],
          },
          () => {
            this.displayDocs(this.state.query.trim());
          }
        );
      }

      if (name === "Search") {
        this.setState({
          lastDocNum: null,
        });

        if (!value.trim().length) {
          this.setState({
            loading: false,
            searching: false,
          });
        }
      }
    }
  );
}

export function handleReactSelect(key, target) {
  // console.log(key, target)
  this.setState({
    [key]: target?.hasOwnProperty('value') ? target.value : target || null,
  });
}

export function handleDateChange(key, date) {
  // console.log(key, date._d)

  if (date?.hasOwnProperty("_d")) {
    date = date._d;
  }

  this.setState(
    {
      [key]: date,
    },
    () => {
      if (key === "newStartDate" && !this.state.newEndDate) {
        this.setState({
          newEndDate: moment(date).add(1, "year").toDate(),
        });
      }
    }
  );
}
