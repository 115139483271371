/**
 *
 * EditForm
 *
 */

import React from "react";
import {
  Alert,
  Grid,
  Button,
  Container,
  Box,
  Drawer,
  IconButton,
  Typography,
  Tooltip,
  Autocomplete,
  TextField,
  Checkbox,
  FormControlLabel,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import DeleteIcon from "@material-ui/icons/Delete";
import ImportExportIcon from "@material-ui/icons/ImportExport";
import _ from "lodash";
import * as Functions from "./functions.js";
import { Editor } from "@tinymce/tinymce-react";
import Search from "../../Search";
import { findLast } from "../../../utils/functions";
import {
  handleInputChange,
  handleReactSelect,
} from "../../../utils/inputHandlers";
import autoBind from "react-autobind";
import PropTypes from "prop-types";
import "./styles.scss";
import DeliverableOptions from "./DeliverableOptions";

class EditForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      formTitle: "",
      formAppendix: false,
      formDeliverable: false,
      formBreak: false,
      formSubsections: [],
      formDeliverableCategory: props.deliverableCategories[0],
      showImportModal: false,
      contactHTML: props.contactHTML,
      editContact: false,
      categories: [],
      sectionTitles: [],
      import: false,
    };

    autoBind(this);

    this.handleInputChange = handleInputChange.bind(this);
    this.handleReactSelect = handleReactSelect.bind(this);

    this.findLast = findLast.bind(this);

    this.handleEditorChange = Functions.handleEditorChange.bind(this);
    this.handleExecCommand = Functions.handleExecCommand.bind(this);
    this.handleReactSelectInputChange = Functions.handleReactSelectInputChange.bind(
      this
    );
    this.handleCreateTitle = Functions.handleCreateTitle.bind(this);
    this.handleFocus = Functions.handleFocus.bind(this);
    // this.handleMenuClose = Functions.handleMenuClose.bind(this);
    this.checkMerge = Functions.checkMerge.bind(this);
    this.saveContact = Functions.saveContact.bind(this);
    this.saveSection = Functions.saveSection.bind(this);
    this.addSection = Functions.addSection.bind(this);
    this.editSection = Functions.editSection.bind(this);
    this.toggleImportModal = Functions.toggleImportModal.bind(this);
    this.importSection = Functions.importSection.bind(this);
    this.clearSearch = Functions.clearSearch.bind(this);
    this.queueImport = Functions.queueImport.bind(this);
    this.enableSearching = Functions.enableSearching.bind(this);
    this.injectImport = Functions.injectImport.bind(this);
    this.addSubsection = Functions.addSubsection.bind(this);
    this.deleteSubsection = Functions.deleteSubsection.bind(this);
    this.moveSubsection = Functions.moveSubsection.bind(this);
    this.getCompanyAddress = Functions.getCompanyAddress.bind(this);
    this.getCompanyContact = Functions.getCompanyContact.bind(this);
  }

  componentDidMount() {}

  UNSAFE_componentWillReceiveProps(newProps) {
    // if (newProps.deliverableCategories !== this.props.deliverableCategories) {
    // console.log(newProps.deliverableCategories);

    let categories = [];

    for (let category of newProps.deliverableCategories) {
      categories.push(category.category);
    }

    this.setState({
      categories,
    });
    // }
  }

  componentDidUpdate(prevProps, nextProps) {
    // console.log(
    //   "did update",
    //   prevProps.searching,
    //   this.props.searching,
    //   nextProps.searching
    // );

    if (prevProps.editSection !== this.props.editSection) {
      if (this.props.editSection === "new") {
        this.addSection();
        return false;
      }

      if (this.props.editSection === "contact") {
        this.setState({
          edit: true,
          editContact: true,
          editorHTML: this.state.contactHTML,
          formDeliverable: false,
        });
        return false;
      }

      if (
        typeof this.props.editSection === "string" &&
        this.props.editSection.startsWith("quick-")
      ) {
        this.addSection();
        this.setState({
          formDeliverable: true,
          formDeliverableCategory: this.props.deliverableCategory,
          formTable: this.props.deliverableTable,
        });
        return false;
      }

      if (
        this.props.editSection !== (undefined || null) &&
        this.props.editSection >= 0
      ) {
        this.editSection(this.props.editSection);
      }

      if (this.props.contactHTML !== nextProps.contactHTML) {
        this.setState({
          contactHTML: this.props.contactHTML,
        });
      }
    }

    if (this.props.searching !== nextProps.searching) {
      // console.log('searching change')
      this.setState({
        searching: this.props.searching,
      });
    }
  }

  render() {
    let tableNames = [];
    _.times(this.props.tables, (i) => {
      // return (
      //   <option value={i} key={i}>
      //     {this.props[`tableName-${i}`] ||
      //       `Deliverables ${i + 1}`}
      //   </option>
      // );

      tableNames.push({
        value: i,
        label: this.props[`tableName-${i}`] || `Deliverables ${i + 1}`,
      });
    });

    return (
      <Drawer
        id="editor-drawer"
        anchor="bottom"
        open={this.props.showForm}
        variant="persistent"
      >
        <form
          id="add-section"
          onSubmit={
            this.state.editContact ? this.saveContact : this.saveSection
          }
          // className={this.props.showForm ? "show" : ""}
        >
          <Container>
            <Box
              p={3}
              sx={{
                "& .MuiFormControlLabel-root, .MuiFormControl-root, .MuiAutocomplete-root": {
                  m: 0,
                },
                "& .MuiGrid-item": {
                  position: "relative",
                  ml: 0.5,
                  mr: 0.5,
                  // "&:last-of-type": {
                  //   mr: 0,
                  // },
                },
                "& .has-expand .MuiInputBase-root": {
                  borderTopRightRadius: 0,
                  borderBottomRightRadius: 0,
                },
                "& .expand-number": {
                  marginLeft: "-1px",
                  ".MuiInputBase-formControl": {
                    borderTopLeftRadius: 0,
                    borderBottomLeftRadius: 0,
                  },
                  ".MuiInputBase-input": {
                    paddingLeft: 0,
                    paddingRight: 0,
                    textAlign: "center",
                  },
                  fieldset: {
                    borderLeftColor: "rgba(0, 0, 0, 0.115)",
                  },
                },
                "& .has-expand": {
                  fieldset: {
                    borderRightColor: "rgba(0, 0, 0, 0.115)",
                  },
                },
              }}
            >
              <Box
                mb={1}
                display="flex"
                alignItems="center"
                justifyContent="space-between"
              >
                <Typography variant="h5">
                  {this.state.edit ? "Edit" : "Add"}{" "}
                  {this.state.formDeliverable ? "Deliverable" : "Section"}
                </Typography>

                <Box display="flex" alignItems="center">
                  <IconButton
                    onClick={this.props.cancelSection}
                    sx={{ "&": { mr: -2 } }}
                  >
                    <CloseIcon />
                  </IconButton>
                </Box>
              </Box>

              <hr />
              {!this.state.editContact && (
                <Grid
                  display="flex"
                  alignItems="center"
                  ml={-0.5}
                  mr={-0.5}
                  mb={2}
                >
                  <Grid
                    item
                    display="flex"
                    alignItems="center"
                    className={`${
                      this.state.formDeliverable &&
                      !this.state.formDeliverableCategory
                        ? "lockout"
                        : ""
                    }`}
                    style={{ flex: 1, position: "relative" }}
                  >
                    {this.state.formDeliverable &&
                      !this.state.formDeliverableCategory && (
                        <Alert
                          // variant="filled"
                          severity="error"
                          sx={{
                            "&": {
                              position: "absolute",
                              left: 0,
                              right: 0,
                              top: 0,
                              bottom: 0,
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                            },
                          }}
                        >
                          Please select a category before entering a title
                        </Alert>
                      )}

                    <Autocomplete
                      autoHighlight
                      fullWidth
                      freeSolo
                      value={
                        {
                          value: this.state.formTitle,
                          label: this.state.formTitle,
                        } || null
                      }
                      options={
                        this.state.formDeliverableCategory
                          ? this.state.sectionTitles
                          : []
                      }
                      getOptionLabel={(option) => option?.label || ""}
                      getOptionSelected={(option, value) =>
                        option.value === value.value
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Section Title"
                          variant="outlined"
                          size="small"
                          margin="normal"
                          required
                          InputLabelProps={{ required: false }}
                        />
                      )}
                      onInputChange={(e, value) =>
                        this.handleReactSelect("formTitle", value)
                      }
                      onOpen={this.handleFocus}
                    />
                  </Grid>
                  <Grid item>
                    <FormControlLabel
                      control={
                        <Checkbox
                          sx={{ "&": { ml: -1 } }}
                          size="small"
                          id="edit-appendix"
                          color="primary"
                          checked={this.state.formAppendix}
                          onChange={this.handleInputChange}
                          disabled={this.state.formDeliverable}
                          name="formAppendix"
                        />
                      }
                      label="Appendix item"
                    />
                  </Grid>
                  <Grid item>
                    <FormControlLabel
                      control={
                        <Checkbox
                          size="small"
                          id="edit-deliverable"
                          color="primary"
                          checked={this.state.formDeliverable}
                          onChange={this.handleInputChange}
                          disabled={this.state.formAppendix}
                          name="formDeliverable"
                        />
                      }
                      label="Deliverable"
                    />
                  </Grid>
                  {!this.state.formDeliverable && !this.state.formAppendix && (
                    <Grid item>
                      <FormControlLabel
                        control={
                          <Checkbox
                            size="small"
                            id="edit-break"
                            color="primary"
                            name="formBreak"
                            checked={this.state.formBreak}
                            onChange={this.handleInputChange}
                          />
                        }
                        label="Start on New Page"
                      />
                    </Grid>
                  )}

                  {this.state.formDeliverable && this.props.tables > 1 && (
                    <Grid item pl={0.5}>
                      <Autocomplete
                        autoHighlight
                        disableClearable
                        id="table-select"
                        value={
                          {
                            value: this.state.formTable,
                            label:
                              tableNames[this.state.formTable] &&
                              tableNames[this.state.formTable].label,
                          } || null
                        }
                        options={tableNames}
                        getOptionLabel={(option) => option?.label || ""}
                        getOptionSelected={(option, value) =>
                          option.value === value.value
                        }
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Table"
                            variant="outlined"
                            size="small"
                            margin="normal"
                            required
                            InputLabelProps={{ required: false }}
                          />
                        )}
                        onChange={(e, value) =>
                          this.handleReactSelect("formTable", value)
                        }
                      />
                    </Grid>
                  )}
                </Grid>
              )}
              {this.state.formDeliverable && (
                <DeliverableOptions
                  {...this.state}
                  {...this.props}
                  handleInputChange={this.handleInputChange}
                  handleReactSelect={this.handleReactSelect}
                  addSubsection={this.addSubsection}
                  deleteSubsection={this.deleteSubsection}
                  moveSubsection={this.moveSubsection}
                />
              )}
              <Box mb={2}>
                <div id="tiny-toolbar" />
                <Editor
                  apiKey="rs9nv85e1xvyq4n5p2x7hp4qfesauxczefnfhz6m4d1gjy3b"
                  // initialValue={this.state.editorHTML}
                  value={this.state.editorHTML}
                  init={{
                    // oninit: "setPlainText",
                    paste_as_text: true,
                    plugins:
                      "link code table lists autoresize noneditable image paste tinymcespellchecker",
                    // content_css: "../assets/css/tiny.css",
                    menubar: false,
                    toolbar: `undo redo | bold underline italic | alignleft aligncenter alignright | numlist bullist | superscript subscript | image | link | code | table | formatselect | removeformat | insertVariable ${
                      this.props.crmCompany ? " | CRM" : ""
                    }`,
                    inline: true,
                    fixed_toolbar_container: "#tiny-toolbar",
                    file_picker_callback: function (cb, value, meta) {
                      var input = document.createElement("input");
                      input.setAttribute("type", "file");
                      input.setAttribute("accept", "image/*");
                      input.onchange = function () {
                        var file = this.files[0];

                        var reader = new FileReader();
                        reader.onload = function () {
                          var id = "blobid" + new Date().getTime();
                          var blobCache =
                            window.tinymce.activeEditor.editorUpload.blobCache;
                          var base64 = reader.result.split(",")[1];
                          var blobInfo = blobCache.create(id, file, base64);
                          blobCache.add(blobInfo);

                          /* call the callback and populate the Title field with the file name */
                          cb(blobInfo.blobUri(), { title: file.name });
                        };
                        reader.readAsDataURL(file);
                      };

                      input.click();
                    },
                    block_formats:
                      "Orange=orange; Green=green; Red=red; Paragraph=p;",
                    formats: {
                      orange: {
                        inline: "span",
                        classes: "orange",
                      },
                      green: {
                        inline: "span",
                        classes: "green",
                      },
                      red: {
                        inline: "span",
                        classes: "red",
                      },
                    },
                    style_formats: [
                      { title: "Orange", format: "orange" },
                      { title: "Green", format: "green" },
                      { title: "Red", format: "red" },
                    ],
                    valid_elements: "*[*]",
                    extended_valid_elements:
                      "a[onclick|class|href|target=_blank],script[charset|defer|language|src|type],style,script[async|charset|defer|src|type|id]",
                    valid_children:
                      "+body[style],+body[script],+div[link],+body[div],+body[p]",
                    setup: (editor) => {
                      // editor.ui.registry.addButton("insertCompany", {
                      //   text: "Insert Company",
                      //   onAction: () => {
                      //     editor.insertContent(
                      //       `<span class="mceNonEditable orange">[Company Name]</span>`
                      //     );
                      //   }
                      // });
                      // editor.ui.registry.addButton("insertCompanyCaps", {
                      //   text: "INSERT COMPANY",
                      //   onAction: () => {
                      //     editor.insertContent(
                      //       `<span class="mceNonEditable orange capitalize">[Company Name]</span>`
                      //     );
                      //   }
                      // });
                      editor.ui.registry.addMenuButton("insertVariable", {
                        text: "Insert",
                        fetch: function (callback) {
                          var items = [
                            {
                              type: "menuitem",
                              text: "Company",
                              onAction: function () {
                                editor.insertContent(
                                  `<span class="mceNonEditable orange">[Company Name]</span>`
                                );
                              },
                            },
                            {
                              type: "menuitem",
                              text: "COMPANY",
                              onAction: function () {
                                editor.insertContent(
                                  `<span class="mceNonEditable orange capitalize">[Company Name]</span>`
                                );
                              },
                            },
                            {
                              type: "menuitem",
                              text: "Start Date",
                              onAction: function () {
                                editor.insertContent(
                                  `<span class="mceNonEditable orange start-date">[Start Date]</span>`
                                );
                              },
                            },
                            {
                              type: "menuitem",
                              text: "End Date",
                              onAction: function () {
                                editor.insertContent(
                                  `<span class="mceNonEditable orange end-date">[End Date]</span>`
                                );
                              },
                            },
                            {
                              type: "menuitem",
                              text: "Years of Experience",
                              onAction: function () {
                                editor.insertContent(
                                  `<span class="mceNonEditable orange years-experience">[Years of Experience]</span>`
                                );
                              },
                            },
                            {
                              type: "menuitem",
                              text: "Number of Payments",
                              onAction: function () {
                                editor.insertContent(
                                  `<span class="mceNonEditable orange payment-split">[Number of Payments]</span>`
                                );
                              },
                            },
                          ];
                          callback(items);
                        },
                      });
                      // editor.ui.registry.addButton("insertPayment", {
                      //   text: "Insert Payment",
                      //   onAction: () => {
                      //     editor.insertContent(
                      //       `<span className="mceNonEditable payments-table">[Payments Schedule]</span>`
                      //     );
                      //   }
                      // });
                      // editor.ui.registry.addButton("insertDeliverables", {
                      //   text: "Insert Deliverables",
                      //   onAction: () => {
                      //     editor.insertContent(
                      //       `<span className="mceNonEditable deliverables-table">[Deliverables]</span>`
                      //     );
                      //   }
                      // });
                      // editor.ui.registry.addMenuButton("insertCRMContact", {
                      //   text: "Contact",
                      //   fetch: callback => {
                      //     this.getCompanyContact().then(contacts => {
                      //       let items = [];
                      //
                      //       if (contacts) {
                      //         for (let contact of contacts.list) {
                      //           items.push({
                      //             type: "menuitem",
                      //             text: `${contact.name}`,
                      //             onAction: function() {
                      //               editor.insertContent(
                      //                 `<p>
                      //                 ${contact.name}<br>
                      //                 ${
                      //                   contact.accountRole
                      //                     ? `${contact.accountRole}<br>`
                      //                     : ""
                      //                 }
                      //                 ${
                      //                   contact.emailAddress
                      //                     ? contact.emailAddress
                      //                     : ""
                      //                 }
                      //                 </p>`
                      //               );
                      //             }
                      //           });
                      //         }
                      //       }
                      //       callback(items);
                      //     });
                      //   }
                      // });
                      // editor.ui.registry.addMenuButton("insertCRMAddress", {
                      //   text: "Address",
                      //   fetch: callback => {
                      //     this.getCompanyAddress().then(addresses => {
                      //       let items = [];
                      //
                      //       if (addresses) {
                      //         for (let address of addresses) {
                      //           items.push({
                      //             type: "menuitem",
                      //             text: `${address.addressLabel} (${address.addressStreet})`,
                      //             onAction: function() {
                      //               editor.insertContent(
                      //                 `<p>
                      //                 ${address.addressStreet}<br>
                      //                 ${
                      //                   address.addressStreet2
                      //                     ? `${address.addressStreet2}<br>`
                      //                     : ""
                      //                 }
                      //                 ${address.addressCity}, ${
                      //                   address.addressState
                      //                 } ${address.addressPostalCode}<br>
                      //                 ${address.addressCountry}
                      //               </p>`
                      //               );
                      //             }
                      //           });
                      //         }
                      //       }
                      //       callback(items);
                      //     });
                      //   }
                      // });
                      editor.ui.registry.addMenuButton("CRM", {
                        text: "CRM",
                        fetch: (callback) => {
                          var headings = document.evaluate(
                            "//span[contains(., 'CRM')]",
                            document,
                            null,
                            XPathResult.ANY_TYPE,
                            null
                          );
                          var thisHeading = headings.iterateNext();

                          thisHeading
                            .closest(".tox-toolbar__group")
                            .classList.add("loading");

                          this.getCompanyAddress().then((addresses) => {
                            let addressList = [];

                            if (addresses?.length) {
                              for (let address of addresses) {
                                addressList.push({
                                  type: "menuitem",
                                  text: `${
                                    address.addressLabel
                                      ? `${address.addressLabel}`
                                      : ""
                                  }${
                                    address.addressStreet
                                      ? ` (${address.addressStreet})`
                                      : "Unlabeled Address"
                                  }`,
                                  onAction: function () {
                                    editor.insertContent(
                                      `<p>
                      ${address.addressStreet}<br>
                      ${
                        address.addressStreet2
                          ? `${address.addressStreet2}<br>`
                          : ""
                      }
                      ${address.addressCity}, ${address.addressState} ${
                                        address.addressPostalCode
                                      }<br>
                      ${address.addressCountry}
                    </p>`
                                    );
                                  },
                                });
                              }
                            } else {
                              addressList.push({
                                type: "menuitem",
                                text: `No addresses found`,
                              });
                            }

                            this.getCompanyContact().then((contacts) => {
                              let contactList = [];

                              if (contacts.list?.length) {
                                for (let contact of contacts.list) {
                                  contactList.push({
                                    type: "menuitem",
                                    text: `${contact.name}`,
                                    onAction: function () {
                                      editor.insertContent(
                                        `<p>
                  ${contact.name}
                  ${contact.accountRole ? `<br>${contact.accountRole}` : ""}
                  ${contact.emailAddress ? `<br>${contact.emailAddress}` : ""}
                  </p>`
                                      );
                                    },
                                  });
                                }
                              } else {
                                contactList.push({
                                  type: "menuitem",
                                  text: `No contacts found`,
                                });
                              }

                              var items = [
                                {
                                  type: "nestedmenuitem",
                                  text: "Contact",
                                  getSubmenuItems: function () {
                                    return contactList;
                                  },
                                },
                                {
                                  type: "nestedmenuitem",
                                  text: "Address",
                                  getSubmenuItems: function () {
                                    return addressList;
                                  },
                                },
                              ];
                              thisHeading
                                .closest(".tox-toolbar__group")
                                .classList.remove("loading");
                              callback(items);
                            });
                          });
                        },
                      });
                      editor.on("blur", function () {
                        // editor.execCommand('mceSave')
                        return false;
                      });
                      editor.on("focus", function () {
                        if (window.getSelection) {
                          if (window.getSelection().empty) {
                            // Chrome
                            window.getSelection().empty();
                          } else if (window.getSelection().removeAllRanges) {
                            // Firefox
                            window.getSelection().removeAllRanges();
                          }
                        } else if (document.selection) {
                          // IE?
                          document.selection.empty();
                        }
                      });
                      // editor.on("paste", function(e) {
                      //   let paste = (
                      //     e.clipboardData || window.clipboardData
                      //   )
                      //   .getData("text");
                      //   console.log("paste", paste);
                      //   editor.setContent(
                      //     'test'
                      //   )
                      // });
                    },
                  }}
                  // onEditorChange={() => this.handleEditorChange(tinymce.activeEditor.getContent())}
                  onEditorChange={this.handleEditorChange}
                  onExecCommand={this.handleExecCommand}
                />
              </Box>
              <Box display="flex" justifyContent="space-between">
                <Box
                  ml={-1}
                  sx={{
                    "& > *": {
                      mr: 1,
                    },
                  }}
                >
                  {this.state.edit && this.props.editSection !== "contact" && (
                    <Tooltip
                      disableInteractive
                      title="Delete Section"
                      enterDelay={250}
                      enterNextDelay={250}
                    >
                      <IconButton
                        size="small"
                        type="reset"
                        onClick={() =>
                          this.props.removeSection(null, this.props.editSection)
                        }
                      >
                        <DeleteIcon color="error" />
                      </IconButton>
                    </Tooltip>
                  )}
                  {!this.state.editContact && (
                    <Tooltip
                      size="small"
                      disableInteractive
                      title="Import Section"
                      enterDelay={250}
                      enterNextDelay={250}
                    >
                      <IconButton
                        color="secondary"
                        onClick={() =>
                          this.importSection(
                            this.props.editSection,
                            this.state.formTitle
                          )
                        }
                      >
                        <ImportExportIcon />
                      </IconButton>
                    </Tooltip>
                  )}
                </Box>
                <Button
                  disableElevation
                  type="submit"
                  variant="contained"
                  color="primary"
                >
                  Apply
                </Button>
              </Box>

              <Dialog
                id="import-modal"
                open={this.state.import}
                onClose={this.toggleImportModal}
                fullWidth
                maxWidth="md"
              >
                <DialogTitle
                  id="alert-dialog-title"
                  disableTypography
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <Box display="flex" alignItems="center">
                    <ImportExportIcon />
                    &ensp;
                    <Typography variant="h6">Import Section</Typography>
                  </Box>
                  <Box mr={-2} mt={-1}>
                    <IconButton
                      aria-label="close"
                      onClick={this.toggleImportModal}
                    >
                      <CloseIcon />
                    </IconButton>
                  </Box>
                </DialogTitle>
                <DialogContent>
                  <div className="mega-search">
                    <Search
                      onFinish={this.props.displayResults}
                      onClear={this.clearSearch}
                      searching={this.state.searching}
                      onSearch={this.enableSearching}
                      // query={this.state.query}
                      handleInputChange={this.handleInputChange}
                      query={this.state.Search}
                      company
                    />
                  </div>
                  {this.props.documents && this.props.documents.length ? (
                    <Box id="import-results" fullWidth>
                      {this.props.documents.map((doc, index) => {
                        let section = doc.content.find((el) =>
                          el.title
                            .toLowerCase()
                            .replace(/[^a-z0-9+]+/gi, " ")
                            .includes(
                              this.props.query
                                .trim()
                                .toLowerCase()
                                .replace(/[^a-z0-9+]+/gi, " ")
                            )
                        );
                        return (
                          <Box
                            className={`editable${
                              index === this.state.importIndex
                                ? " selected"
                                : ""
                            }`}
                            key={doc._id.toString()}
                            onClick={() =>
                              this.queueImport(
                                section?.title,
                                section.editorHTML,
                                index
                              )
                            }
                          >
                            <Typography
                              variant="h5"
                              className="doc-title"
                              sx={{ "&": { mb: 1 } }}
                            >
                              {doc.Title}
                            </Typography>
                            <Typography variant="h6" sx={{ "&": { mb: 1 } }}>
                              {section?.title}
                            </Typography>
                            <div
                              className="small"
                              dangerouslySetInnerHTML={{
                                __html: section?.editorHTML,
                              }}
                            />
                          </Box>
                        );
                      })}
                    </Box>
                  ) : (
                    <DialogContentText id="alert-dialog-description">
                      Enter a valid SOW name
                    </DialogContentText>
                  )}
                </DialogContent>
                <DialogActions>
                  <Button onClick={this.toggleImportModal} color="secondary">
                    Cancel
                  </Button>

                  <Button
                    color="primary"
                    disabled={!this.state.importHTML}
                    onClick={this.injectImport}
                  >
                    Import
                  </Button>
                </DialogActions>
              </Dialog>
            </Box>
          </Container>
        </form>
      </Drawer>
    );
  }
}

EditForm.propTypes = {
  editSection: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  content: PropTypes.array,
  showForm: PropTypes.bool,
  cancelSection: PropTypes.func,
  removeSection: PropTypes.func,
  saveSection: PropTypes.func,
  deliverableCategories: PropTypes.array,
  deliverableCategory: PropTypes.string,
  tables: PropTypes.number,
  documents: PropTypes.array,
  query: PropTypes.string,
  searching: PropTypes.bool,
  displayResults: PropTypes.func,
  importFinished: PropTypes.func,
  contactHTML: PropTypes.string,
  saveContact: PropTypes.func,
  addBelowIndex: PropTypes.number,
  clearImport: PropTypes.func,
};

export default EditForm;
