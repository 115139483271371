import moment from "moment";
var ObjectID = require("bson-objectid");

export function handleQuickInputChange(event) {
  let target = event.target;
  let value = target.value;
  let name = target.name;

  if (typeof value === "string" && name.toLowerCase().includes("title")) {
    value = value.replace(/\s\s+/g, " ");
  }

  let index = parseInt(name.split("-")[0]);

  if (Number.isInteger(index)) {
    let currentContent = [...this.state.brief.content];

    currentContent[index] = {
      ...currentContent[index],
      title: value,
    };

    this.setState({
      brief: {
        ...this.state.brief,
        content: [...currentContent],
      },
      [name]: value,
    });
  } else if (name.includes("project")) {
    if (name === "projectTitle") {
      name = "title";
    }

    this.setState({
      brief: {
        ...this.state.brief,
        [name]: value,
      },
    });
  }
}

export function hideAlert() {
  this.setState({
    showAlert: false,
  });
}

export function fetchBriefs() {
  // console.log("FETCH BRIEFSSSS");

  this.setState(
    {
      brief: {
        _id: null,
      },
      error: "",
    },
    () => {
      this.props.db
        .collection(this.props.collection)
        .findOne(
          { _id: ObjectID(this.props.match.params.id) },
          {
            projection: {
              Title: 1,
              docNum: 1,
              company: 1,
              content: 1,
              approved: 1,
            },
          }
        )
        .then((doc) => {
          if (!doc) {
            this.setState({ error: "Brief not found", loading: false });
            return false;
          }

          if (doc.docNum === "MAST") {
          } else {
            if (!doc.approved) {
              this.setState({
                error: "SOW must be approved to create Briefs",
                loading: false,
              });
              return false;
            }
          }

          let deliverables = [...doc.content.filter((s) => s.deliverable)];

          this.setState(
            {
              sowID: doc._id.toString(),
              docTitle: doc.Title,
              company: doc.company,
              deliverables,
            },
            () => {
              if (this.props.match.params.brief_id) {
                this.fetchBrief(this.props.match.params.brief_id, "2");
              } else {
                // console.log("FETCH ALL");
                this.props.db
                  .collection("brief")
                  .find(
                    {
                      sowID: this.state.sowID,
                    },
                    {
                      projection: {},
                    }
                  )
                  .asArray()
                  .then((briefs) => {
                    this.setState({
                      brief: {
                        _id: null,
                      },
                      briefs,
                      dirty: false,
                      loading: false,
                    });
                    document.title = `Briefs - ${this.state.docTitle}`;
                    // console.log("briefs", briefs);
                  })
                  .catch(console.error);
              }
            }
          );
        })
        .catch((err) => console.error(err));
    }
  );
}

export function fetchBrief(id, instance) {
  // console.log("FETCH BRIEF", instance);
  this.props.db
    .collection("brief")
    .findOne({ _id: ObjectID(id) })
    .then((doc) => {
      if (!doc) {
        this.setState({
          error: "Brief not found",
          brief: { _id: null },
          loading: false,
        });
        return false;
      }

      // console.log(doc);
      document.title = `${doc.title} - ${this.state.docTitle}`;
      document
        .querySelector("#app-container")
        .classList.add("doc-view", "briefs");
      document.querySelector("#app-container").scrollTo(0, 0);
      this.setState(
        {
          brief: doc,
          showAlert: true,
        },
        () => {
          setTimeout(() => {
            this.setState({
              loading: false,
            });
          }, 1000);
        }
      );
    })
    .catch((err) => console.error(err));
}

export function toggleBriefForm() {
  this.setState({
    showCreateBrief: !this.state.showCreateBrief,
  });
}

export function createBrief(e) {
  e.preventDefault();

  const formData = new FormData(e.target),
    formDataObj = Object.keys(Object.fromEntries(formData.entries()));

  let briefName = Object.values(Object.fromEntries(formData.entries()))[0];

  formDataObj.shift();

  let content = [];

  for (let deliverable of formDataObj) {
    content.push({
      title: deliverable,
    });
  }

  if (!content.length) {
    this.showNotification("error", `Please select at least one deliverable`);
    return false;
  }

  // console.log(briefName, content)

  this.setState(
    {
      saving: true,
    },
    () => {
      this.props.db
        .collection("brief")
        .insertOne({
          sowID: this.state.sowID,
          createdBy: this.context.user.id,
          title: briefName,
          content,
          version: 1,
        })
        .then((result) => {
          this.setState(
            {
              showCreateBrief: false,
              saving: false,
            },
            () => {
              this.props.history.push(
                `/${this.props.match.params.id}/brief/${result.insertedId}`
              );
            }
          );
        })
        .catch((err) => {
          console.error(err);
          this.setState({
            saving: false,
          });
        });
    }
  );
}

export function cancelCreateBrief() {
  this.toggleBriefForm();
}

export function updateBrief() {
  if (!this.state.brief.title.length) {
    this.showNotification("error", `Please enter a Project Name`);
    return false;
  }

  this.setState(
    {
      saving: true,
    },
    () => {
      this.props.db
        .collection("brief")
        .updateOne(
          {
            _id: ObjectID(this.state.brief._id.toString()),
          },
          {
            $set: {
              title: this.state.brief.title,
              description: this.state.brief.description,
              projectManager: this.state.brief.projectManager,
              content: this.state.brief.content,
              lastModified: {
                id: this.context.user.id,
                time: new Date(),
              },
            },
          }
        )
        .then((result) => {
          this.showNotification("success", `Brief saved!`);
          this.setState(
            {
              saving: false,
              dirty: false,
            },
            () => {}
          );
        });
    }
  );
}

export function deleteBrief() {
  let docId = this.state.deleteId;

  document.querySelector(`.id-${docId}`).classList.add("deleting");

  this.setState(
    {
      deleting: true,
    },
    () => {
      this.props.db
        .collection("brief")
        .findOne({
          _id: ObjectID(docId),
        })
        .then((result) => {
          let originalDoc = result;
          originalDoc.deletedAt = moment().toDate();
          this.props.db
            .collection("trash")
            .insertOne(originalDoc)
            .then((result) => {
              this.props.db
                .collection("brief")
                .deleteOne({
                  _id: ObjectID(docId),
                })
                .then(() => {
                  this.closeDialog();
                  this.fetchBriefs();
                  this.showNotification(
                    "error",
                    `Brief [${this.state.deleteTitle}] deleted!`
                  );
                  this.setState({
                    deleting: false,
                  });
                })
                .catch(console.error);
            })
            .catch(console.error);
        })
        .catch(console.error);
    }
  );
}

export function addMilestone(e, index) {
  e.preventDefault();

  // console.log(index);

  let currentContent = [...this.state.brief.content];

  let milestone = {
    date: this.state[`${index}-date`],
    project: this.state[`${index}-project`],
    milestone: this.state[`${index}-milestone`],
    team: this.state[`${index}-team`],
  };

  let currentMilestones = currentContent[index].milestones || [];

  if (this.state.editMode) {
    // console.log("splice");
    currentMilestones.splice(this.state.editMilestoneIndex, 1, milestone);
  } else {
    // console.log("push");
    currentMilestones.push(milestone);
  }

  // console.log(currentMilestones);

  currentContent[index] = {
    ...currentContent[index],
    milestones: [...currentMilestones],
  };

  // console.log(currentContent);

  this.setState({
    brief: {
      ...this.state.brief,
      content: [...currentContent],
    },
    [`${index}-date`]: "",
    // [`${index}-project`]: "",
    [`${index}-milestone`]: "",
    [`${index}-team`]: "",
    editIndex: null,
    editMilestoneIndex: null,
    editMode: false,
  });
}

export function deleteMilestone(e, index, milestoneIndex) {
  e.preventDefault();

  let currentContent = [...this.state.brief.content];

  let currentMilestones = currentContent[index].milestones;

  currentMilestones.splice(milestoneIndex, 1);

  // console.log(currentMilestones);

  currentContent[index] = {
    ...currentContent[index],
    milestones: [...currentMilestones],
  };

  this.setState({
    brief: {
      ...this.state.brief,
      content: [...currentContent],
    },
    editIndex: null,
    editMilestoneIndex: null,
    editMode: false,
    [`${index}-date`]: "",
    [`${index}-project`]: "",
    [`${index}-milestone`]: "",
    [`${index}-team`]: "",
  });
}

export function deleteDeliverable(index) {
  // console.log(index);

  let currentContent = [...this.state.brief.content];

  // currentContent.splice(index, 1);

  let updatedContent = currentContent.filter((item, i) => i !== index);

  this.setState({
    brief: {
      ...this.state.brief,
      content: [...updatedContent],
    },
  });
}

export function addDeliverable(index) {
  let currentContent = [...this.state.brief.content];

  currentContent.splice(index + 1, 0, {
    description: "",
    jobNumber: "",
    milestones: [],
    title: "",
  });

  this.setState({
    brief: {
      ...this.state.brief,
      content: [...currentContent],
    },
  });
}

export function scrollToMilestone(id) {
  let element = document.querySelector(`#milestone-${id} .editable`);
  element.scrollIntoView({ behavior: "smooth", block: "center" });
  element.click();
}

export function resetFields() {
  let newState = {};

  Object.keys(this.state).forEach((key) => {
    // if (key.startsWith("price-")) {
    if (key.match(/^\d/)) {
      newState[key] = "";
    }
  });

  this.setState(newState);
  this.setState({
    editIndex: null,
    editMilestoneIndex: null,
    editMode: false,
  });
}

export function editMilestone(index, milestoneIndex) {
  this.resetFields();

  let thisMilestone = {
    ...this.state.brief.content[index].milestones[milestoneIndex],
  };

  // console.log(index, milestoneIndex, thisMilestone);

  if (
    Number.isInteger(this.state.editIndex) &&
    Number.isInteger(this.state.editMilestoneIndex) &&
    this.state.editIndex === index &&
    this.state.editMilestoneIndex === milestoneIndex
  ) {
    this.setState({
      editIndex: null,
      editMilestoneIndex: null,
      editMode: false,
      [`${index}-date`]: "",
      [`${index}-project`]: "",
      [`${index}-milestone`]: "",
      [`${index}-team`]: "",
    });
  } else {
    this.setState({
      editIndex: index,
      editMilestoneIndex: milestoneIndex,
      [`${index}-date`]: thisMilestone.date,
      [`${index}-project`]: thisMilestone.project,
      [`${index}-milestone`]: thisMilestone.milestone,
      [`${index}-team`]: thisMilestone.team,
      editMode: true,
    });
  }
}

export function editDeliverableTitle(index) {
  this.resetFields();

  let thisTitle = this.state.brief.content[index].title;

  // console.log(index, thisTitle);

  this.setState({
    editIndex: index,
    [`${index}-title`]: thisTitle,
  });
}

export function editorChange(html, index, key) {
  let currentContent = [...this.state.brief.content];

  if (index !== null) {
    currentContent[index] = {
      ...currentContent[index],
      [key]: html,
    };

    this.setState({
      brief: {
        ...this.state.brief,
        content: [...currentContent],
      },
    });
  } else {
    this.setState({
      brief: {
        ...this.state.brief,
        description: html,
      },
    });
  }
}

export function keyStrokes(event) {
  try {
    if ((event.ctrlKey || event.metaKey) && event.which === 83) {
      if (this.state.dirty) {
        this.updateBrief();
      }

      event.preventDefault();
      return false;
    }
  } catch (err) {}
}

export function onBeforePrint(event) {
  if ("activeElement" in document) {
    document.activeElement.blur();
  }
}
