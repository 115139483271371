export function sentConfirm(callback) {
  this.setState(
    {
      sentCallback: callback,
    },
    () => {
      if (
        !this.state.sentToClient &&
        !this.state.approved &&
        this.state.docNum !== "MAST" &&
        this.state.isAdmin
      ) {
        this.setState({
          sentToClientConfirm: true,
        });
      } else {
        if (this.state.sentCallback) {
          this.state.sentCallback();
        }
      }
    }
  );
}

export function sentToClientSOW() {
  this.setState(
    {
      sentToClient: true,
      rejected: false,
      revised: false,
      sentToClientConfirm: false,
    },
    () => {
      this.updateDoc();

      if (this.state.preview) {
        this.slackMessage(
          `generated PDF for approval by ${this.state.user.name}`
        );
      }

      if (this.state.sentCallback) {
        setTimeout(() => {
          this.state.sentCallback();
        }, 1000);
      }
    }
  );
}

export function sentCancel() {
  this.setState(
    {
      sentToClientConfirm: false,
    },
    () => {
      if (this.state.sentCallback) {
        this.state.sentCallback();
      }
    }
  );
}

export function approveSOW() {
  this.setState(
    {
      approved: true,
      sentToClient: false,
      rejected: false,
      revised: false,
      approveConfirm: false,
    },
    () => {
      let previousDate = this.state.customDate || this.state.lastModified?.time;
      this.updateDoc({
        customDate: previousDate,
        sentToClient: false,
        approvedBy: {
          name: this.state.user.name,
          time: this.state.customDate || new Date(),
        },
      });

      this.slackMessage(`approved by ${this.state.user.name}`);
    }
  );
}

export function unapproveSOW() {
  this.setState(
    {
      approved: false,
      historyArray: [],
      unapproveConfirm: false,
    },
    () => {
      this.updateDoc();
    }
  );
}

export function rejectSOW() {
  this.updateDoc({
    rejected: true,
    revised: false,
    sentToClient: false,
    approved: false,
  });
}

export function unrejectSOW() {
  this.updateDoc({
    rejected: false,
  });
}

export function confirmApproval() {
  if (
    this.state.approved &&
    this.state.eApproved &&
    !this.state.approvedBy.acknowledgedBy
  ) {
    this.updateDoc({
      approvedBy: {
        ...this.state.approvedBy,
        acknowledgedBy: {
          name: this.state.user.name,
          time: new Date(),
        },
      },
    });

    this.slackMessage(`e-Approval confirmed by ${this.state.user.name}`, true);
    this.slackMessage(`e-Approval confirmed by ${this.state.user.name}`);
  }
}
