/**
 *
 * Brief EditForm
 *
 */

import React from "react";
import PropTypes from "prop-types";
import { Editor } from "@tinymce/tinymce-react";
import {
  handleInputChange,
  handleReactSelect,
} from "../../../utils/inputHandlers";
import autoBind from "react-autobind";
import "./styles.scss";

class EditForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};

    autoBind(this);

    this.handleInputChange = handleInputChange.bind(this);
    this.handleReactSelect = handleReactSelect.bind(this);
  }

  componentDidMount() {}

  handleEditorChange(editorHTML, editor) {
    // console.log("CHANGE DETECTED", editorHTML);
    // this.setState({ editorHTML });
    this.props.editorChange(editorHTML);
  }

  handleExecCommand(e, editor) {
    this.setState({ editorHTML: e.target.getContent() });
  }

  render() {
    return (
      <Editor
        apiKey="rs9nv85e1xvyq4n5p2x7hp4qfesauxczefnfhz6m4d1gjy3b"
        // initialValue={this.state.editorHTML}
        value={this.props.state}
        init={{
          // oninit: "setPlainText",
          paste_as_text: true,
          plugins:
            "link code table lists autoresize noneditable image paste tinymcespellchecker",
          // content_css: "../assets/css/tiny.css",
          auto_focus: false,
          menubar: false,
          statusbar: false,
          toolbar: `undo redo | bold underline italic | alignleft aligncenter alignright | numlist bullist | superscript subscript | image | code | removeformat`,
          inline: true,
          contextmenu: "spellchecker image",
          // fixed_toolbar_container: "#top-bar",
          file_picker_callback: function (cb, value, meta) {
            var input = document.createElement("input");
            input.setAttribute("type", "file");
            input.setAttribute("accept", "image/*");
            input.onchange = function () {
              var file = this.files[0];

              var reader = new FileReader();
              reader.onload = function () {
                var id = "blobid" + new Date().getTime();
                var blobCache =
                  window.tinymce.activeEditor.editorUpload.blobCache;
                var base64 = reader.result.split(",")[1];
                var blobInfo = blobCache.create(id, file, base64);
                blobCache.add(blobInfo);

                /* call the callback and populate the Title field with the file name */
                cb(blobInfo.blobUri(), { title: file.name });
              };
              reader.readAsDataURL(file);
            };

            input.click();
          },
          valid_elements: "*[*]",
          extended_valid_elements:
            "a[onclick|class|href|target=_blank],script[charset|defer|language|src|type],style,script[async|charset|defer|src|type|id]",
          valid_children:
            "+body[style],+body[script],+div[link],+body[div],+body[p]",
          setup: function (theEditor) {
            theEditor.on("focus", function () {
              if (window.getSelection) {
                if (window.getSelection().empty) {
                  // Chrome
                  window.getSelection().empty();
                } else if (window.getSelection().removeAllRanges) {
                  // Firefox
                  window.getSelection().removeAllRanges();
                }
              } else if (document.selection) {
                // IE?
                document.selection.empty();
              }
            });
            // theEditor.on("blur", function () {
            //   this.contentAreaContainer.parentElement.querySelector(
            //     ".tox-editor-header"
            //   ).style.display = "none";
            // });
            theEditor.on("init", function () {
              // window.tinymce.get(this.id).getBody().setAttribute('contenteditable',false);
              // window.tinymce.get(this.id).getBody().setAttribute('contenteditable',true);
              // theEditor.getBody().setAttribute("contenteditable", false);
              //
              // setTimeout(() => {
              //   theEditor.getBody().setAttribute("contenteditable", true);
              // }, 500);
            });
          },
        }}
        onEditorChange={this.handleEditorChange}
        onExecCommand={this.handleExecCommand}
      />
    );
  }
}

EditForm.propTypes = {
  editorChange: PropTypes.func.isRequired,
  state: PropTypes.string,
};


export default EditForm;
