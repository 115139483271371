/**
 *
 * DeliverablesTable
 *
 */

import React from "react";
import { Box, Grid, IconButton, TextField } from "@material-ui/core";
import DoneIcon from "@material-ui/icons/Done";
import CloseIcon from "@material-ui/icons/Close";
import {
  displayNum,
  formatNumber,
  formatCurrency,
} from "../../../utils/functions";
import AnchorLink from "react-anchor-link-smooth-scroll";
import ScrollIntoView from "react-scroll-into-view";
import PropTypes from "prop-types";
import "./styles.scss";

class DeliverablesTable extends React.Component {
  constructor(props) {
    super(props);

    this.displayNum = displayNum.bind(this);
    this.formatNumber = formatNumber.bind(this);
    this.formatCurrency = formatCurrency.bind(this);
  }

  generateDiscountRow(section) {
    // this will determine which table column (Pass Through or Direct Spend) to put the discount in
    let hasDirectSpend =
      section.price || section.subsections.filter((s) => s.price).length;
    let passThroughDiscount = section.passThrough && !hasDirectSpend;
    let directSpendDiscount =
      (!section.passThrough && hasDirectSpend) ||
      (section.passThrough && hasDirectSpend) ||
      this.props.docType === "Gift Estimate";
    return (
      <tr className={`discount`}>
        {this.props.docType === "Gift Estimate" ? (
          <td colSpan="4">Discount</td>
        ) : (
          <>
            <td colSpan="2">Discount</td>
            <td className="currency existing-spend" />
            <td
              className={`currency pass-through${
                passThroughDiscount ? " has-value" : ""
              }`}
            >
              {passThroughDiscount && (
                <>
                  -
                  {section.discount
                    ? this.formatCurrency(section.discount)
                    : ""}
                </>
              )}
            </td>
          </>
        )}

        <td
          className={`currency new-spend${
            directSpendDiscount ? " has-value" : ""
          }`}
          style={
            this.props.docType === "Gift Estimate"
              ? { display: "table-cell" }
              : {}
          }
        >
          {directSpendDiscount ? (
            <>
              -{section.discount ? this.formatCurrency(section.discount) : ""}
            </>
          ) : null}
        </td>
      </tr>
    );
  }

  render() {
    let prevTableCount = this.props.content.filter(
      (section) =>
        section.deliverable &&
        parseInt(section.table) <= parseInt(this.props.tableIndex - 1)
    ).length;

    let j = 0 + prevTableCount;

    let deliverableCategories = [];

    for (let category of this.props.deliverableCategories) {
      deliverableCategories.push(category.category);
    }

    let noDelivCount = 0;

    // let subTotal = this.props.subTotals.filter(
    //   (subtotal) => subtotal.index === this.props.tableIndex
    // )[0];

    return (
      <React.Fragment key={this.props.tableIndex}>
        {/* <div
      className="deliverable-table-container"
      key={this.props.tableIndex}
      style={{
      order:
       this.props[`tableName-${this.props.tableIndex}`] &&
           this.props[`tableName-${this.props.tableIndex}`].includes(
            "Optional"
           )
            ? this.props.tables + 1
               : this.props.tableIndex
        }}
        > */}
        {/* {this.props.editTableNameIndex ? ( */}
        <form
          className={`quick-edit-form arrow-bottom ${
            this.props.editTableNameIndex ? "show" : ""
          }`}
          onSubmit={(e) => this.props.saveTableName(e, this.props.tableIndex)}
        >
          <Grid display="flex" alignItems="center">
            <Box mr={1} sx={{ flex: 1 }}>
              <TextField
                fullWidth
                margin="dense"
                label="Table Name"
                variant="outlined"
                type="text"
                size="small"
                name={`formTableName-${this.props.tableIndex}`}
                value={this.props.formTableName}
                onChange={this.props.handleInputChange}
                InputLabelProps={{ shrink: true }}
              />
            </Box>
            <Box display="flex" alignItems="center">
              <IconButton size="small" variant="outlined" type="submit">
                <DoneIcon color="primary" />
              </IconButton>
              <IconButton
                size="small"
                variant="outlined"
                type="reset"
                onClick={() =>
                  this.props.cancelTableName(this.props.tableIndex)
                }
              >
                <CloseIcon />
              </IconButton>
            </Box>
          </Grid>
        </form>
        {/* ) : (
          ""
        )} */}
        <table
          id={`table-${this.props.tableIndex}`}
          className={`deliverables-table ${
            this.props[`tableName-${this.props.tableIndex}`] &&
            this.props[`tableName-${this.props.tableIndex}`].includes(
              "Optional"
            )
              ? "optional-table"
              : ""
          } table table-sm table-bordered`}
        >
          <thead>
            <tr className="">
              <th
                className={`editable${
                  this.props.editTableNameIndex ? " selected" : ""
                }`}
                onClick={(e) =>
                  this.props.editTableName(e, this.props.tableIndex)
                }
                scope="col"
                colSpan="3"
              >
                {this.props[`tableName-${this.props.tableIndex}`] ||
                  `Deliverables ${
                    this.props.tableIndex > 0
                      ? ` ${this.props.tableIndex + 1}`
                      : ""
                  }`}
              </th>
              <th scope="col" className="fit existing-spend">
                Existing Spend
              </th>
              <th scope="col" className="fit pass-through">
                Pass Through
              </th>
              {this.props.docType === "Gift Estimate" ? (
                <>
                  <th scope="col" className="fit new-spend">
                    Unit Cost
                  </th>
                  <th scope="col" className="fit quantity">
                    Quantity
                  </th>
                  <th scope="col" className="fit total">
                    Total
                  </th>
                </>
              ) : (
                <th scope="col" className="fit new-spend">
                  Direct Spend
                </th>
              )}
            </tr>
          </thead>
          <tbody>
            {deliverableCategories.map((deliverableCategory, i) => {
              if (
                this.props.content.filter(
                  (section) =>
                    section.deliverable &&
                    section.deliverableCategory === deliverableCategory &&
                    parseInt(section.table) === parseInt(this.props.tableIndex)
                ).length
              ) {
                return (
                  <React.Fragment key={deliverableCategory}>
                    <tr
                      className="table-active deliverable-category editable"
                      onClick={() =>
                        this.props.quickDeliverable(
                          deliverableCategory,
                          this.props.tableIndex || 0
                        )
                      }
                    >
                      <th colSpan="6">
                        {deliverableCategory}
                        {/* <button
                  onClick={() =>
                    this.quickDeliverable(
                      deliverableCategory
                    )
                  }
                  className="btn btn-sm btn-outline-secondary float-right"
                >
                  Add Deliverable
                </button> */}
                      </th>
                    </tr>
                    {this.props.content.map((section, index) => {
                      if (
                        parseInt(section.table) ===
                        parseInt(this.props.tableIndex)
                      ) {
                        let span = 1;
                        if (section.discount) {
                          span = 2;
                        }
                        if (
                          section.deliverable &&
                          section.deliverableCategory === deliverableCategory
                        ) {
                          j++;
                          // this.props.incrementDeliverable();
                          // this.props.incrementJ()

                          let rowSpanHeader =
                            (section.subsections &&
                              section.subsections.length) ||
                            section.existingSpend ||
                            section.passThrough ||
                            section.quantity ||
                            section.price
                              ? section.subsections &&
                                section.subsections.length + span
                              : "false";
                          let rowSpan =
                            // (section.subsections &&
                            //   section.subsections.length) ||
                            section.existingSpend ||
                            section.passThrough ||
                            section.quantity ||
                            section.price
                              ? section.subsections &&
                                section.subsections.length + 1
                              : "false";
                          let colSpan =
                            section.subsections &&
                            section.subsections.length &&
                            !section.existingSpend &&
                            !section.passThrough &&
                            !section.price
                              ? "5"
                              : "2";
                          return (
                            <React.Fragment key={`deliverable-${index}`}>
                              <tr
                                data-category={deliverableCategory}
                                className={`${
                                  section.deliverable
                                    ? "deliverable-item editable"
                                    : ""
                                } ${
                                  section.expandExistingSpend
                                    ? `expand-existing-spend-${section.expandExistingSpend}`
                                    : ""
                                } ${
                                  section.expandPassThrough
                                    ? `expand-pass-through-${section.expandPassThrough}`
                                    : ""
                                } ${
                                  section.expandNewSpend
                                    ? `expand-new-spend-${section.expandNewSpend}`
                                    : ""
                                }`}
                                onClick={() => this.props.editSection(index)}
                              >
                                <th
                                  scope="row"
                                  className={`editable ${
                                    this.props.selectedRows.includes(index)
                                      ? "selected"
                                      : ""
                                  }`}
                                  rowSpan={rowSpanHeader}
                                  onClick={(e) =>
                                    this.props.selectRow(e, index)
                                  }
                                >
                                  {/* {this.props.deliverableNumber} */}
                                  {/* {this.props.j} */}
                                  {j}
                                </th>
                                <td colSpan={colSpan}>
                                  <ScrollIntoView
                                    selector={`#section-${index}`}
                                    // onClick={e => e.stopPropagation()}
                                  >
                                    <AnchorLink
                                      offset="100"
                                      href={`#section-${index}`}
                                      onClick={() =>
                                        this.props.editSection(index)
                                      }
                                      // onClick={e => e.stopPropagation()}
                                    >
                                      {section.title}
                                    </AnchorLink>
                                  </ScrollIntoView>
                                  {/* {section.quantity &&
                                      parseInt(
                                        section.quantity,
                                        10
                                      ) > 1 ? (
                                        <span>{` (${
                                          section.quantity
                                        })`}</span>
                                      ) : (
                                        ""
                                      )} */}
                                </td>

                                <td
                                  className={`existing-spend currency${
                                    section.existingSpend ? " has-value" : ""
                                  }`}
                                  rowSpan={
                                    section.expandExistingSpend
                                      ? section.expandExistingSpend
                                      : rowSpan
                                  }
                                >
                                  {section.existingSpend
                                    ? this.formatCurrency(section.existingSpend)
                                    : ""}
                                </td>
                                <td
                                  className={`pass-through currency${
                                    section.passThrough ? " has-value" : ""
                                  }`}
                                  rowSpan={
                                    section.expandPassThrough
                                      ? section.expandPassThrough
                                      : rowSpan
                                  }
                                >
                                  {section.passThrough
                                    ? this.formatCurrency(section.passThrough)
                                    : ""}
                                </td>
                                <td
                                  className={`new-spend currency${
                                    section.price ? " has-value" : ""
                                  }`}
                                  rowSpan={
                                    section.expandNewSpend
                                      ? section.expandNewSpend
                                      : rowSpan
                                  }
                                >
                                  {this.formatCurrency(
                                    section.quantity
                                      ? section.price * section.quantity
                                      : section.price
                                  )}
                                </td>
                              </tr>
                              {section.subsections &&
                                section.subsections.map((sub, i) => {
                                  return (
                                    <tr
                                      // className={`subsection ${section.existingSpend !=
                                      // 0 ? "hide-sign": ""}`}
                                      key={`subsection-${i}`}
                                      className={`subsection ${
                                        section.subsections?.length &&
                                        (section.existingSpend ||
                                          section.passThrough ||
                                          section.quantity ||
                                          section.price)
                                          ? "hide-cells"
                                          : ""
                                      } ${
                                        section.subsections?.length &&
                                        sub.expandExistingSpend
                                          ? `expand-existing-spend-${sub.expandExistingSpend}`
                                          : ""
                                      } ${
                                        section.subsections?.length &&
                                        sub.expandPassThrough
                                          ? `expand-pass-through-${sub.expandPassThrough}`
                                          : ""
                                      } ${
                                        section.subsections?.length &&
                                        sub.expandNewSpend
                                          ? `expand-new-spend-${sub.expandNewSpend}`
                                          : ""
                                      }`}
                                    >
                                      <td colSpan="2">
                                        &emsp;- {sub.title}
                                        {/* {sub.quantity &&
                                        parseInt(sub.quantity, 10) > 1 ? (
                                          <span>{` (${sub.quantity})`}</span>
                                        ) : null} */}
                                      </td>
                                      {this.props.docType ===
                                      "Gift Estimate" ? (
                                        !section.quantity && (
                                          <>
                                            <td
                                              className={`new-spend currency${
                                                sub.price ? " has-value" : ""
                                              }`}
                                              // style={{ whiteSpace: "nowrap" }}
                                              rowSpan={
                                                sub.expandNewSpend
                                                  ? sub.expandNewSpend
                                                  : "1"
                                              }
                                            >
                                              {this.formatCurrency(sub.price)}
                                              {/* {" "} */}
                                              {/* <em className="small">
                                                {sub.unit}
                                              </em> */}
                                            </td>
                                            <td className="quantity text-right">
                                              {this.formatNumber(sub.quantity)}
                                            </td>
                                            <td
                                              className={`new-spend currency${
                                                sub.price ? " has-value" : ""
                                              }`}
                                              style={{ whiteSpace: "nowrap" }}
                                              rowSpan={
                                                sub.expandNewSpend
                                                  ? sub.expandNewSpend
                                                  : "1"
                                              }
                                            >
                                              {this.formatCurrency(
                                                sub.quantity
                                                  ? sub.price * sub.quantity
                                                  : sub.price
                                              )}
                                            </td>
                                          </>
                                        )
                                      ) : (
                                        <>
                                          {!section.existingSpend && (
                                            <td
                                              className={`existing-spend currency${
                                                sub.existingSpend
                                                  ? " has-value"
                                                  : ""
                                              }`}
                                              rowSpan={
                                                sub.expandExistingSpend
                                                  ? sub.expandExistingSpend
                                                  : "1"
                                              }
                                            >
                                              {sub.existingSpend
                                                ? this.formatCurrency(
                                                    sub.existingSpend
                                                  )
                                                : ""}
                                            </td>
                                          )}
                                          {!section.passThrough && (
                                            <td
                                              className={`pass-through currency${
                                                sub.passThrough
                                                  ? " has-value"
                                                  : ""
                                              }`}
                                              rowSpan={
                                                sub.expandPassThrough
                                                  ? sub.expandPassThrough
                                                  : "1"
                                              }
                                            >
                                              {sub.passThrough
                                                ? this.formatCurrency(
                                                    sub.passThrough
                                                  )
                                                : ""}
                                            </td>
                                          )}
                                          {!section.quantity && (
                                            <td
                                              className={`new-spend currency${
                                                sub.price ? " has-value" : ""
                                              }`}
                                              rowSpan={
                                                sub.expandNewSpend
                                                  ? sub.expandNewSpend
                                                  : "1"
                                              }
                                            >
                                              {this.formatCurrency(
                                                sub.quantity
                                                  ? sub.price * sub.quantity
                                                  : sub.price
                                              )}
                                            </td>
                                          )}
                                        </>
                                      )}
                                    </tr>
                                  );
                                })}
                              {!!section.discount &&
                                this.generateDiscountRow(section)}
                            </React.Fragment>
                          );
                        } else {
                          return false;
                        }
                      } else {
                        return false;
                      }
                    })}
                  </React.Fragment>
                );
              } else if (
                this.props.content.filter(
                  (section) =>
                    section.deliverable &&
                    parseInt(section.table) === parseInt(this.props.tableIndex)
                ).length === 0
              ) {
                noDelivCount++;
                if (noDelivCount === 1) {
                  return (
                    <tr
                      key={`empty-row-${i}`}
                      className="editable no-deliv text-center"
                      onClick={() =>
                        this.props.quickDeliverable(
                          null,
                          this.props.tableIndex || 0
                        )
                      }
                    >
                      <td colSpan="10">Click here to add deliverable</td>
                    </tr>
                  );
                } else {
                  return false;
                }
              } else {
                return false;
              }
            })}
          </tbody>

          <tfoot>
            <tr className="sub-total">
              <td colSpan={this.props.docType === "Gift Estimate" ? "5" : "3"}>
                Sub Total
              </td>
              <td
                className={`existing-spend currency${
                  this.props.totals[
                    `${this.props.tableIndex}-existingSpendTotal`
                  ]
                    ? " has-value"
                    : ""
                }`}
              >
                {this.formatCurrency(
                  this.props.totals[
                    `${this.props.tableIndex}-existingSpendTotal`
                  ]
                )}
              </td>
              <td
                className={`pass-through currency${
                  this.props.totals[`${this.props.tableIndex}-passThroughTotal`]
                    ? " has-value"
                    : ""
                }`}
              >
                {this.formatCurrency(
                  this.props.totals[`${this.props.tableIndex}-passThroughTotal`]
                )}
              </td>
              <td
                className={`new-spend currency${
                  this.props.totals[`${this.props.tableIndex}-newSpendTotal`]
                    ? " has-value"
                    : ""
                }`}
              >
                {this.formatCurrency(
                  this.props.totals[`${this.props.tableIndex}-newSpendTotal`]
                )}
              </td>
            </tr>
          </tfoot>
        </table>
        {this.props.docType === "Gift Estimate" ? null : (
          <>
            <table
              className="section-total total-table table table-sm table-striped table-bordered"
              style={{ maxWidth: this.props.totalWidth }}
            >
              <tfoot>
                <tr>
                  <td className="fit" colSpan="">
                    Section Total
                  </td>
                  <td colSpan="" className="has-value currency text-center">
                    {this.props.totals[
                      `${this.props.tableIndex}-passThroughTotal`
                    ] ||
                    this.props.totals[`${this.props.tableIndex}-newSpendTotal`]
                      ? this.formatCurrency(
                          this.props.totals[
                            `${this.props.tableIndex}-passThroughTotal`
                          ] +
                            this.props.totals[
                              `${this.props.tableIndex}-newSpendTotal`
                            ]
                        )
                      : "Free"}
                  </td>
                </tr>
              </tfoot>
            </table>
            <table
              className="sub-total total-table hidden table table-sm table-striped table-bordered"
              style={{ maxWidth: this.props.totalWidth }}
              data-id={this.props.tableIndex}
            >
              <tfoot>
                <tr>
                  <td className="fit editable" colSpan="">
                    Sub Total
                  </td>
                  <td
                    colSpan=""
                    className="has-value currency text-center"
                  ></td>
                </tr>
              </tfoot>
            </table>
          </>
        )}
        {/* </div> */}
      </React.Fragment>
    );
  }
}

DeliverablesTable.propTypes = {
  tableIndex: PropTypes.number,
  deliverableCategories: PropTypes.array,
  content: PropTypes.array,
  currency: PropTypes.string,
  totals: PropTypes.object,
  tables: PropTypes.number,
  totalWidth: PropTypes.number,
  formatCurrency: PropTypes.func,
  editSection: PropTypes.func,
  editTableName: PropTypes.func,
  quickDeliverable: PropTypes.func,
  saveTableName: PropTypes.func,
  cancelTableName: PropTypes.func,
  editTableNameIndex: PropTypes.bool,
  formTableName: PropTypes.string,
  handleInputChange: PropTypes.func,
  deliverableNumber: PropTypes.number,
  incrementDeliverable: PropTypes.func,
  selectRow: PropTypes.func,
  selectedRows: PropTypes.array,
};

export default DeliverablesTable;
