/**
 *
 * Doc
 *
 */

import React from "react";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import "./styles.scss";
import Settings from "../Settings";
import {
  Alert,
  Box,
  Container,
  CircularProgress,
  LinearProgress,
} from "@material-ui/core";
import _ from "lodash";
import {
  injectCompany,
  injectVariables,
  formatCurrency,
  formatNumber,
  toNum,
  generateDocName,
  makeOrange,
  getAppVersion,
  calculateTotalPrice,
  showNotification,
} from "../../utils/functions";
import { handleInputChange, handleDateChange } from "../../utils/inputHandlers";
// import tz from "moment-timezone"; // eslint-disable-line no-unused-vars
import NavigationPrompt from "react-router-navigation-prompt";
import Error from "../Error";
import TopBar from "./TopBar";
import Steps from "./Steps";
import EditForm from "./EditForm";
import Preview from "./Preview";
import autoBind from "react-autobind";
import { Auth0Context } from "../../react-auth0-wrapper";
import * as Document from "../../utils/docMethods";
import * as Idle from "./helpers/idle.js";
import * as Sections from "./helpers/sections.js";
import * as Inject from "./helpers/inject.js";
import * as Fetch from "./helpers/fetch.js";
import * as Update from "./helpers/update.js";
import * as Payment from "./helpers/payment.js";
import * as s3 from "./helpers/s3.js";
import * as Status from "./helpers/status.js";
import * as eApproval from "./helpers/eApproval.js";
import * as Revisions from "./helpers/revisions.js";
import * as Tables from "./helpers/tables.js";
import * as Functions from "./functions.js";
import { slackMessage } from "./helpers/slack.js";
import { setUser } from "./helpers/user.js";
import togglePreview from "../../utils/print";
import IdleTimer from "react-idle-timer";
import Confirm from "../Confirm";

class Doc extends React.Component {
  // eslint-disable-line react/prefer-stateless-function
  constructor(props) {
    super(props);

    this.state = Inject.defaultState;
    this.socket = null;
    autoBind(this);

    this.onChange = (editorState) => {
      this.setState({ editorState });
    };

    this.showNotification = showNotification.bind(this);

    this.handleInputChange = handleInputChange.bind(this);
    this.handleDateChange = handleDateChange.bind(this);

    this.slackMessage = slackMessage.bind(this);
    this.setUser = setUser.bind(this);
    this.togglePreview = togglePreview.bind(this);
    this.injectCompany = injectCompany.bind(this);
    this.injectVariables = injectVariables.bind(this);
    this.formatCurrency = formatCurrency.bind(this);
    this.formatNumber = formatNumber.bind(this);
    this.toNum = toNum.bind(this);
    this.generateDocName = generateDocName.bind(this);
    this.makeOrange = makeOrange.bind(this);
    this.getAppVersion = getAppVersion.bind(this);
    this.calculateTotalPrice = calculateTotalPrice.bind(this);

    this.deleteDoc = Document.deleteDoc.bind(this);
    this.cloneDoc = Document.cloneDoc.bind(this);
    this.getNewDocNumber = Document.getNewDocNumber.bind(this);

    this.takeOverTO = null;
    this.idleTimer = null;
    this.onAction = Idle._onAction.bind(this);
    this.onActive = Idle._onActive.bind(this);
    this.onIdle = Idle._onIdle.bind(this);
    this.requestTakeOver = Idle.requestTakeOver.bind(this);
    this.approveTakeOver = Idle.approveTakeOver.bind(this);

    this.addSection = Sections.addSection.bind(this);
    this.removeSection = Sections.removeSection.bind(this);
    this.editSection = Sections.editSection.bind(this);
    this.saveSection = Sections.saveSection.bind(this);
    this.cancelSection = Sections.cancelSection.bind(this);
    this.addBelow = Sections.addBelow.bind(this);

    this.injectDocState = Inject.injectDocState.bind(this);
    this.injectPayment = Inject.injectPayment.bind(this);
    this.injectDeliverables = Inject.injectDeliverables.bind(this);

    this.displayDoc = Fetch.displayDoc.bind(this);
    this.checkToken = Fetch.checkToken.bind(this);
    this.loadMoreRevisions = Fetch.loadMoreRevisions.bind(this);
    this.fetchCurrencyRates = Fetch.fetchCurrencyRates.bind(this);
    this.displayDocOnLoad = Fetch.displayDocOnLoad.bind(this);
    this.fetchTeam = Fetch.fetchTeam.bind(this);
    this.fetchCategories = Fetch.fetchCategories.bind(this);

    this.prepDocState = Update.prepDocState.bind(this);
    this.updatedSalesPerson = Update.updatedSalesPerson.bind(this);
    this.updatedAccountManagement = Update.updatedAccountManagement.bind(this);
    this.updateDoc = Update.updateDoc.bind(this);

    this.savePayment = Payment.savePayment.bind(this);
    this.resetCustomPrices = Payment.resetCustomPrices.bind(this);
    this.cancelCustomPrices = Payment.cancelCustomPrices.bind(this);
    this.resetCustomDates = Payment.resetCustomDates.bind(this);
    this.cancelCustomDates = Payment.cancelCustomDates.bind(this);
    this.calculatePayments = Payment.calculatePayments.bind(this);
    this.addCustomPrice = Payment.addCustomPrice.bind(this);
    this.addCustomDate = Payment.addCustomDate.bind(this);
    this.checkPrices = Payment.checkPrices.bind(this);
    this.setTotalPrice = Payment.setTotalPrice.bind(this);

    this.getSignedRequest = s3.getSignedRequest.bind(this);
    this.uploadFile = s3.uploadFile.bind(this);
    this.uploadS3 = s3.uploadS3.bind(this);
    this.deleteFile = s3.deleteFile.bind(this);

    this.sentConfirm = Status.sentConfirm.bind(this);
    this.sentToClientSOW = Status.sentToClientSOW.bind(this);
    this.sentCancel = Status.sentCancel.bind(this);
    this.approveSOW = Status.approveSOW.bind(this);
    this.unapproveSOW = Status.unapproveSOW.bind(this);
    this.rejectSOW = Status.rejectSOW.bind(this);
    this.unrejectSOW = Status.unrejectSOW.bind(this);
    this.confirmApproval = Status.confirmApproval.bind(this);

    this.onCopy = eApproval.onCopy.bind(this);
    this.generateToken = eApproval.generateToken.bind(this);
    this.getLocAndIP = eApproval.getLocAndIP.bind(this);
    this.getAddressFromIP = eApproval.getAddressFromIP.bind(this);
    this.getAddress = eApproval.getAddress.bind(this);
    this.handleApproveSubmit = eApproval.handleApproveSubmit.bind(this);
    this.sendEmail = eApproval.sendEmail.bind(this);

    this.loadRevision = Revisions.loadRevision.bind(this);
    this.restore = Revisions.restore.bind(this);
    this.cancelRevision = Revisions.cancelRevision.bind(this);
    this.applyRevision = Revisions.applyRevision.bind(this);
    this.undo = Revisions.undo.bind(this);
    this.redo = Revisions.redo.bind(this);
    this.saveHistory = Revisions.saveHistory.bind(this);

    this.addTable = Tables.addTable.bind(this);
    this.removeTable = Tables.removeTable.bind(this);
    this.editTableName = Tables.editTableName.bind(this);
    this.saveTableName = Tables.saveTableName.bind(this);
    this.cancelTableName = Tables.cancelTableName.bind(this);
    this.totalWidth = Tables.totalWidth.bind(this);
    this.setStepsTableWidth = Tables.setStepsTableWidth.bind(this);

    this.onSortEnd = Functions.onSortEnd.bind(this);
    this.unableToSave = Functions.unableToSave.bind(this);
    this.loadDoc = Functions.loadDoc.bind(this);
    this.handlePreview = Functions.handlePreview.bind(this);
    this.handleConfirmNavigationClick = Functions.handleConfirmNavigationClick.bind(
      this
    );
    this.keyStrokes = Functions.keyStrokes.bind(this);
    this.convertToImg = Functions.convertToImg.bind(this);
    this.afterPrint = Functions.afterPrint.bind(this);
    this.isHidden = Functions.isHidden.bind(this);
    this.quickDeliverable = Functions.quickDeliverable.bind(this);
    this.saveContact = Functions.saveContact.bind(this);
    this.editContact = Functions.editContact.bind(this);
    this.selectRow = Functions.selectRow.bind(this);
    this.deleteRows = Functions.deleteRows.bind(this);
    this.clearRows = Functions.clearRows.bind(this);
    this.editSettings = Functions.editSettings.bind(this);
    this.saveSettings = Functions.saveSettings.bind(this);
    this.toggleOptions = Functions.toggleOptions.bind(this);
    this.toggleSettings = Functions.toggleSettings.bind(this);
    this.stringToNum = Functions.stringToNum.bind(this);
    this.sortDeliverables = Functions.sortDeliverables.bind(this);
    this.mapOrder = Functions.mapOrder.bind(this);
    this.addDiscount = Functions.addDiscount.bind(this);
    this.cancelDiscount = Functions.cancelDiscount.bind(this);
    this.saveDiscount = Functions.saveDiscount.bind(this);
    this.reorder = Functions.reorder.bind(this);
    this.editSubTotalName = Functions.editSubTotalName.bind(this);
    this.saveSubTotalName = Functions.saveSubTotalName.bind(this);
    this.cancelSubTotalName = Functions.cancelSubTotalName.bind(this);
    this.removeSubTotal = Functions.removeSubTotal.bind(this);
    this.addSubTotal = Functions.addSubTotal.bind(this);
    this.applyTeam = Functions.applyTeam.bind(this);
    this.displayResults = Functions.displayResults.bind(this);
    this.importFinished = Functions.importFinished.bind(this);
    this.clearImport = Functions.clearImport.bind(this);
    this.printPDF = Functions.printPDF.bind(this);
    this.toggleCompanyLink = Functions.toggleCompanyLink.bind(this);
    this.linkCompany = Functions.linkCompany.bind(this);
    this.cancelCompanyLink = Functions.cancelCompanyLink.bind(this);
    this.setCompany = Functions.setCompany.bind(this);
  }

  static contextType = Auth0Context; // eslint-disable-line no-undef

  // static getDerivedStateFromError(error) {
  //   return { hasError: true, errorInfo: error };
  // }

  componentDidMount() {
    sessionStorage.removeItem("before-revision");
    // sessionStorage.removeItem('sow-history')

    if (this.props.token && this.props.token.length) {
      this.setState({
        allDone: true,
      });
    } else {
      try {
        this.setState({
          user: this.context.user || this.props.user,
        });
      } catch (err) {}
    }

    this.loadDoc();

    this.getAppVersion();

    if (this.props.token) {
      document.querySelector("#app-container").style.top = 0;
    }

    document.addEventListener("keydown", this.keyStrokes, false);

    setTimeout(() => {
      this.setStepsTableWidth();
    }, 1500);
  }

  componentWillUnmount() {
    document.querySelector("#app-container").classList.remove("doc-view");
    if (!this.props.token) {
      try {
        this.socket.emit("leave");
      } catch (err) {}
    }
    document.body.classList.remove("scanned", "loaded-pdf", "debug");
    document.removeEventListener("keydown", this.keyStrokes, false);

    this.setState({
      user: null,
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.user !== prevProps.user) {
      // this.setUser();
      this.setState({
        user: this.props.user,
      });

      try {
        this.saveHistory();
      } catch (err) {}
    }

    if (this.state.user !== prevState.user) {
      this.setUser();
    }

    if (this.state.preview !== prevState.preview) {
      document.body.classList.remove("scanned", "loaded-pdf", "debug");
      document.querySelector("#print").innerHTML = "";

      if (document.querySelector("#pages")) {
        document.querySelector("#pages").innerHTML =
          '<div id="page-1" class="page"></div> <div id="page-2" class="page"></div> <div id="page-3" class="page"></div>';
      }
    }

    if (this.state.user && this.props.loggedOut !== prevProps.loggedOut) {
      this.socket.emit("leave");
    }

    if (this.props.match.url !== prevProps.match.url) {
      this.loadDoc();
      setTimeout(() => {
        this.setStepsTableWidth();
      }, 1500);
    }

    if (this.state.docId) {
      // if (
      //   this.state.isAdmin &&
      //   !prevState.crmCompany &&
      //   this.state.loaded &&
      //   prevState.crmCompany !== this.state.crmCompany
      // ) {
      //   this.setState({
      //     confirmCompany: true,
      //     showCompanyLink: true
      //   });
      // }

      if (
        prevState.content !== this.state.content ||
        // JSON.stringify(prevState.content) !== JSON.stringify(this.state.content) ||
        (prevState.crmCompany &&
          this.state.crmCompany &&
          prevState.crmCompany !== this.state.crmCompany) ||
        prevState.company !== this.state.company ||
        prevState.docNum !== this.state.docNum ||
        prevState.type !== this.state.type ||
        prevState.year !== this.state.year ||
        prevState.quarter !== this.state.quarter ||
        prevState.custom !== this.state.custom ||
        prevState.date !== this.state.date ||
        JSON.stringify(prevState.customDate) !==
          JSON.stringify(this.state.customDate) ||
        JSON.stringify(prevState.sigDate) !==
          JSON.stringify(this.state.sigDate) ||
        prevState.currency !== this.state.currency ||
        prevState.address !== this.state.address ||
        prevState.startDate !== this.state.startDate ||
        prevState.endDate !== this.state.endDate ||
        prevState.paymentSplit !== this.state.paymentSplit ||
        prevState.uponApproval !== this.state.uponApproval ||
        prevState.customPrices !== this.state.customPrices ||
        prevState.customDates !== this.state.customDates ||
        prevState.logo !== this.state.logo ||
        prevState.logoInternal !== this.state.logoInternal ||
        prevState.newSpendDiscount !== this.state.newSpendDiscount ||
        prevState.contactHTML !== this.state.contactHTML ||
        prevState.team !== this.state.team ||
        prevState.tables !== this.state.tables ||
        prevState.attachedFiles !== this.state.attachedFiles ||
        prevState.fromMember !== this.state.fromMember ||
        prevState.salesPerson !== this.state.salesPerson ||
        prevState.accountManagement !== this.state.accountManagement ||
        prevState.subTotals !== this.state.subTotals ||
        prevState.docType !== this.state.docType
      ) {
        this.setState({
          dirty: true,
        });

        if (this.state.loaded && !this.state.undoRedo && this.state.isAdmin) {
          if (prevState.attachedFiles !== this.state.attachedFiles) {
          } else {
            try {
              this.saveHistory();
            } catch (err) {}
          }
        }
      }

      if (prevState.content !== this.state.content) {
        this.setTotalPrice();
      }

      if (prevState.totalWidth !== this.state.totalWidth) {
        this.injectDeliverables();
        this.setStepsTableWidth();
      }

      if (
        prevState.startDate !== this.state.startDate ||
        prevState.endDate !== this.state.endDate ||
        prevState.paymentSplit !== this.state.paymentSplit ||
        prevState.uponApproval !== this.state.uponApproval
      ) {
        this.calculatePayments();
      }

      if (
        (prevState.paymentSplit &&
          prevState.paymentSplit !== this.state.paymentSplit) ||
        (prevState.totalPrice && prevState.totalPrice !== this.state.totalPrice)
      ) {
        this.resetCustomPrices();
      }
    }

    if (prevState.docId && prevState.docId !== this.state.docId) {
      // this.socket.emit("updateWorkingSOW", this.state.docId);
      this.setState(
        {
          admin: null,
          allDone: false,
        },
        () => {
          this.socket.emit("leave");
          this.setUser();
        }
      );
    }
  }

  componentDidCatch(error, info) {
    console.error("[STACK ERROR]", info.componentStack, error);
    // console.log(sessionStorage.getItem("sow-recent-history"));

    sessionStorage.setItem(
      "sow-recent-history",
      JSON.stringify(this.prepDocState())
    );
  }

  render() {
    let splitTotal = this.state.paymentArray.length;
    let team = [];

    if (this.state.team && this.state.members) {
      Object.keys(this.state.team).forEach((key) => {
        for (const member of this.state.members) {
          if (key.replace("member-", "") === member._id.toString()) {
            if (
              member.Name === "Gwenael Servant" ||
              member.Name === "Nigel Walker"
            ) {
              team.unshift(member);

              if (team[0].Name === "Gwenael Servant") {
                team.splice(0, 2, team[1], team[0]);
              }
            } else {
              team.push(member);
            }
          }
        }
      });
    }

    let tableNames = {};

    Object.keys(this.state).forEach((key) => {
      if (key.startsWith("tableName-")) {
        tableNames[key] = this.state[key];
      }
    });

    let tableList = [];
    _.times(this.state.tables, (i) => {
      // return (
      //   <option value={i} key={i}>
      //     {this.props[`tableName-${i}`] ||
      //       `Deliverables ${i + 1}`}
      //   </option>
      // );

      tableList.push({
        value: i,
        label: this.state[`tableName-${i}`] || `Deliverables ${i + 1}`,
      });
    });

    // console.log("table names", tableNames);

    let canApprove;

    if (this.state.attachedFiles && this.state.attachedFiles.length) {
      canApprove = true;
    } else if (this.state.newManualApprove) {
      canApprove = true;
    }

    // let canSubmitApprove;
    //
    // try {
    //   if (
    //     this.state.approvalName &&
    //     this.state.approvalTitle &&
    //     this.state.approvalCompany &&
    //     this.state.approvalEmail
    //     // this.state.sigVerified &&
    //     // !this.sigCanvas.isEmpty()
    //   ) {
    //     canSubmitApprove = true;
    //   } else {
    //     canSubmitApprove = false;
    //   }
    // } catch (err) {}

    let currencySign = "$";

    if (this.state.currency === "EUR") {
      currencySign = "€";
    } else if (this.state.currency === "GBP") {
      currencySign = "£";
    }

    return this.state.doc && this.state.loaded ? (
      <>
        {!this.state.allDone && (
          <div id="spinner">
            <CircularProgress disableShrink />
          </div>
        )}

        <NavigationPrompt when={this.state.dirty}>
          {({ onConfirm, onCancel }) => (
            <Confirm
              open={true}
              text="You have unsaved changes, are you sure you want to leave"
              closeDialog={onCancel}
              title="Unsaved Changes"
              confirmAction={onConfirm}
              confirmLabel="Leave without saving"
            />
          )}
        </NavigationPrompt>

        <Container
          id="container"
          className={`${this.state.isAdmin ? "admin" : ""}${
            this.state.approved ? " approved" : ""
          }${this.state.docNum === "MAST" ? " master-template" : ""}${
            this.state.sentToClient ? " sent-to-client" : ""
          }${this.state.revised ? " revised" : ""}${
            this.state.showForm ? " editor-open" : ""
          } ${this.state.currency}${this.state.preview ? " preview" : ""}${
            !this.state.hasExistingSpend ? " no-existing-spend" : ""
          }${!this.state.hasPassThrough ? " no-pass-through" : ""}${
            !this.state.isAdmin && this.state.admin ? " locked-out" : ""
          }${this.props.token ? " has-token" : ""}${
            !this.state.crmCompany ? " need-company" : ""
          }${this.props.normalAdmin ? " super-admin" : ""}`}
        >
          <IdleTimer
            ref={(ref) => {
              this.idleTimer = ref;
            }}
            element={document}
            onActive={this.onActive}
            onIdle={this.onIdle}
            onAction={this.onAction}
            debounce={250}
            timeout={1000 * 60 * 10}
            // timeout={1000 * 60 * 0.1}
          />
          {this.props.token ? null : (
            // <Form
            //   id="approve-bar"
            //   className={`fixed-bottom`}
            //   onSubmit={(e) => this.handleApproveSubmit(e)}
            // >
            //   <Container>
            //     {this.state.approved ? (
            //       <p className="text-center">
            //         <strong>
            //           Thank you for your approval, this has been sent to That's
            //           Nice for their record
            //         </strong>
            //         .<br />
            //         <span className="small">
            //           Approved by{" "}
            //           {this.state.approvedBy && this.state.approvedBy.name} on{" "}
            //           {this.state.approvedBy &&
            //             moment(this.state.approvedBy.time).format("LLLL")}{" "}
            //           ({moment.tz(moment.tz.guess()).zoneAbbr()}) <br />
            //           Location:{" "}
            //           {this.state.approvedBy?.location?.address
            //             ? this.state.approvedBy.location.address
            //             : "N/A"}
            //           <br />
            //           IP: {this.state.approvedBy && this.state.approvedBy.ip}
            //         </span>
            //       </p>
            //     ) : (
            //       <>
            //         <Form.Row>
            //           <Form.Group as={Col}>
            //             <p>
            //               By providing your e-signature, and clicking the
            //               "Confirm Approval" button, you certify full
            //               understanding and agreement of fees and payment
            //               schedule listed in the SOW, as well as service
            //               descriptions and Terms stated in this document.
            //             </p>
            //             <span className="sig-preview">
            //               {this.state.approvalName}
            //             </span>
            //           </Form.Group>
            //           <Form.Group as={Col}>
            //             <Form.Row>
            //               <Form.Group as={Col} className="floating-label">
            //                 <Form.Control
            //                   type="text"
            //                   name="approvalName"
            //                   title="Name"
            //                   className="form-control public-btn"
            //                   id="edit-approval-name"
            //                   placeholder="Name"
            //                   value={this.state.approvalName}
            //                   onChange={this.handleInputChange}
            //                   required
            //                 />
            //                 <Form.Label>Name</Form.Label>
            //               </Form.Group>
            //             </Form.Row>
            //             <Form.Row>
            //               <Form.Group as={Col} className="floating-label">
            //                 <Form.Control
            //                   type="text"
            //                   name="approvalTitle"
            //                   title="Title"
            //                   className="form-control public-btn"
            //                   id="edit-approval-title"
            //                   placeholder="Title"
            //                   value={this.state.approvalTitle}
            //                   onChange={this.handleInputChange}
            //                   required
            //                 />
            //                 <Form.Label>Title</Form.Label>
            //               </Form.Group>
            //             </Form.Row>
            //             <Form.Row>
            //               <Form.Group as={Col} className="floating-label">
            //                 <Form.Control
            //                   type="text"
            //                   name="approvalCompany"
            //                   title="Company"
            //                   className="form-control public-btn"
            //                   id="edit-approval-company"
            //                   placeholder="Company"
            //                   value={this.state.approvalCompany}
            //                   onChange={this.handleInputChange}
            //                   required
            //                 />
            //                 <Form.Label>Company</Form.Label>
            //               </Form.Group>
            //             </Form.Row>
            //             <Form.Row>
            //               <Form.Group as={Col} className="floating-label">
            //                 <Form.Control
            //                   type="email"
            //                   name="approvalEmail"
            //                   title="Email"
            //                   className="form-control public-btn"
            //                   id="edit-approval-email"
            //                   placeholder="Email"
            //                   value={this.state.approvalEmail}
            //                   onChange={this.handleInputChange}
            //                   required
            //                 />
            //                 <Form.Label>Email</Form.Label>
            //               </Form.Group>
            //             </Form.Row>
            //             <Form.Row>
            //               <Form.Group as={Col}>
            //                 <Button
            //                   className="public-btn"
            //                   block
            //                   variant="success"
            //                   type="submit"
            //                   disabled={!canSubmitApprove}
            //                 >
            //                   {canSubmitApprove
            //                     ? "Confirm Approval"
            //                     : "All fields required"}
            //                 </Button>
            //               </Form.Group>
            //             </Form.Row>
            //           </Form.Group>
            //         </Form.Row>
            //       </>
            //     )}
            //   </Container>
            // </Form>
            <TopBar
              title={this.state.title}
              docNum={this.state.docNum}
              reorder={this.state.reorder}
              options={this.state.options}
              settings={this.state.settings}
              preview={this.state.preview}
              dirty={this.state.dirty}
              editSettings={this.editSettings}
              toggleReorder={this.reorder}
              toggleOptions={this.toggleOptions}
              toggleSettings={this.toggleSettings}
              togglePreview={this.togglePreview}
              updateDoc={this.updateDoc}
              addSection={this.addSection}
              saving={this.state.saving}
              undo={this.undo}
              redo={this.redo}
              historyArray={this.state.historyArray}
              historyIndex={this.state.historyIndex}
              approved={this.state.approved}
              approvedBy={this.state.approvedBy}
              lastModified={this.state.doc.lastModified}
              sentToClient={this.state.sentToClient}
              rejected={this.state.rejected}
              revised={this.state.revised}
              unapproveSOW={() => this.setState({ unapproveConfirm: true })}
              attachedFiles={this.state.attachedFiles}
              printPDF={this.printPDF}
              loadingPDF={this.state.loadingPDF}
              superAdmin={this.props.superAdmin}
              normalAdmin={this.props.normalAdmin}
              token={this.state.token}
              teamworkID={this.state.teamworkID}
              importingTeamwork={this.state.importingTeamwork}
              eApproved={this.state.eApproved}
              confirmApproval={this.confirmApproval}
              crmCompany={this.state.crmCompany}
            />
          )}

          {
            // !this.state.approved &&
            this.state.admin && (
              <Settings
                show={this.state.settings}
                side
                onSubmit={this.saveSettings}
                onClick={this.toggleSettings}
                docNum={this.state.newDocNum}
                crmCompany={this.state.newCrmCompany}
                company={this.state.newCompany}
                type={this.state.newType}
                quarter={this.state.newQuarter}
                custom={this.state.newCustom}
                docType={this.state.newDocType}
                year={this.state.newYear}
                customDate={this.state.newCustomDate}
                sigDate={this.state.newSigDate}
                currency={this.state.newCurrency}
                logoInternal={this.state.newLogoInternal}
                address={this.state.newAddress}
                delete={(e) =>
                  this.deleteDoc(e, this.state.docId, this.state.doc.Title)
                }
                clone={(e) =>
                  this.cloneDoc(e, this.state.docId, null, this.state.doc.Title)
                }
                newVersion={(e) =>
                  this.cloneDoc(e, this.state.docId, true, this.state.doc.Title)
                }
                createdBy={Fetch.createdBy}
                lastModified={this.state.doc.lastModified}
                fromMember={this.state.fromMember}
                salesPerson={this.state.salesPerson}
                accountManagement={this.state.accountManagement}
                revisions={this.state.revisions}
                loadRevision={this.loadRevision}
                loadingMoreRevisions={this.state.loadingMoreRevisions}
                cancelRevision={this.cancelRevision}
                applyRevision={this.applyRevision}
                revisionIndex={this.state.revisionIndex}
                totalRevisions={this.state.totalRevisions}
                loadMoreRevisions={this.loadMoreRevisions}
                restore={this.restore}
                createdAt={Fetch.createdAt}
                normalAdmin={this.props.normalAdmin}
                // importTeamwork={this.importTeamwork}
                importingTeamwork={this.state.importingTeamwork}
                rejected={this.state.rejected}
                rejectSOW={this.rejectSOW}
                unrejectSOW={this.unrejectSOW}
                teamworkID={this.state.teamworkID}
                approved={this.state.approved}
                members={this.state.members}
                updatedSalesPerson={this.updatedSalesPerson}
                updatedAccountManagement={this.updatedAccountManagement}
                cloning={this.state.cloning}
              />
            )
          }

          <Box id="main" style={{ margin: "0px" }}>
            <EditForm
              editSection={this.state.editSection}
              content={this.state.content}
              showForm={this.state.showForm}
              cancelSection={this.cancelSection}
              removeSection={this.removeSection}
              saveSection={this.saveSection}
              deliverableCategories={this.state.deliverableCategories}
              deliverableCategory={this.state.deliverableCategory}
              deliverableTable={this.state.deliverableTable}
              tables={this.state.tables}
              documents={this.state.documents}
              query={this.state.query}
              searching={this.state.searching}
              displayResults={this.displayResults}
              importFinished={this.importFinished}
              contactHTML={this.state.contactHTML}
              saveContact={this.saveContact}
              addBelowIndex={this.state.addBelowIndex}
              {...tableNames}
              clearImport={this.clearImport}
              showNotification={this.showNotification}
              docType={this.state.docType}
              currencySign={currencySign}
              crmCompany={this.state.crmCompany}
            />
            <Steps
              state={this.state}
              tableNames={tableNames}
              splitTotal={splitTotal}
              canApprove={canApprove}
              deleteRows={this.deleteRows}
              clearRows={this.clearRows}
              editSection={this.editSection}
              handleInputChange={this.handleInputChange}
              handleDateChange={this.handleDateChange}
              editTableName={this.editTableName}
              cancelTableName={this.cancelTableName}
              saveTableName={this.saveTableName}
              editSubTotalName={this.editSubTotalName}
              saveSubTotalName={this.saveSubTotalName}
              cancelSubTotalName={this.cancelSubTotalName}
              removeSubTotal={this.removeSubTotal}
              selectRow={this.selectRow}
              addTable={this.addTable}
              removeTable={this.removeTable}
              savePayment={this.savePayment}
              addCustomPrice={this.addCustomPrice}
              resetCustomPrices={this.resetCustomPrices}
              cancelCustomPrices={this.cancelCustomPrices}
              addCustomDate={this.addCustomDate}
              resetCustomDates={this.resetCustomDates}
              cancelCustomDates={this.cancelCustomDates}
              applyTeam={this.applyTeam}
              onCopy={this.onCopy}
              generateToken={this.generateToken}
              uploadS3={this.uploadS3}
              deleteFileConfirm={(e) =>
                this.setState({
                  attachmentConfirm: true,
                  deleteFileInfo: { ...e },
                })
              }
              approveConfirm={() => this.setState({ approveConfirm: true })}
              requestTakeOver={this.requestTakeOver}
              quickDeliverable={this.quickDeliverable}
              addDiscount={this.addDiscount}
              saveDiscount={this.saveDiscount}
              cancelDiscount={this.cancelDiscount}
              token={this.props.token}
              superAdmin={this.props.superAdmin}
              normalAdmin={this.props.normalAdmin}
            />

            {/* {this.props.token && (
              <ButtonGroup
                // size="sm"
                id="ioc-btn"
                style={{ display: "none" }}
              >
                <OverlayTrigger
                  placement="right"
                  overlay={
                    <Tooltip disableInteractive>Index of Contents</Tooltip>
                  }
                  delay={{ show: 250 }}
                >
                  <Button
                    variant="outline-secondary"
                    onClick={this.toggleOptions}
                    className="public-btn"
                  >
                    {this.state.options ? <MdClose /> : <MdMenu />}
                  </Button>
                </OverlayTrigger>
              </ButtonGroup>
            )} */}
            <Preview
              title={this.state.title}
              lastModified={this.state.doc.lastModified}
              date={this.state.date}
              customDate={this.state.customDate}
              sigDate={this.state.sigDate}
              crmCompany={this.state.crmCompany}
              company={
                (this.state.crmCompany && this.state.crmCompany.label) ||
                this.state.company
              }
              version={this.state.version}
              content={this.state.content}
              reorder={this.state.reorder}
              onSortEnd={this.onSortEnd}
              team={team}
              members={this.state.members}
              handlePreview={this.handlePreview}
              editContact={this.editContact}
              editSection={this.editSection}
              removeSection={this.removeSection}
              editSectionIndex={this.state.editSection}
              editSettings={this.editSettings}
              departments={this.state.departments}
              logo={this.state.logo}
              logoInternal={this.state.logoInternal}
              contactHTML={this.state.contactHTML}
              fromMember={this.state.fromMember}
              salesPerson={this.state.salesPerson}
              addBelow={this.addBelow}
              addBelowIndex={this.state.addBelowIndex}
              docType={this.state.docType}
              approved={this.state.approved}
              approvedBy={this.state.approvedBy}
              tables={this.state.tables}
              {...tableNames}
              eApproved={this.state.eApproved}
              approvalCompany={this.state.approvalCompany}
              address={this.state.address}
              dark={this.props.dark}
              nigelTitle={this.state.nigelTitle}
            />
            {/* <Modal
              id="company-modal"
              show={
                this.state.showCompanyLink &&
                this.state.loaded &&
                this.state.allDone
              }
              onHide={
                this.state.confirmCompany
                  ? this.cancelCompanyLink
                  : this.toggleCompanyLink
              }
              backdrop="static"
            >
              <Modal.Header closeButton>
                <Modal.Title className="d-flex align-items-center">
                  Link Company
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                {this.state.confirmCompany ? (
                  <>
                    {this.state.crmCompany && (
                      <>
                        <p>
                          Are you sure you want to link{" "}
                          <strong className="orange">
                            {this.state.crmCompany.label}
                          </strong>{" "}
                          to this SOW?
                        </p>
                        <p>
                          Old company name:{" "}
                          <strong>{this.state.company}</strong>
                          <br />
                          New company name:{" "}
                          <strong>{this.state.crmCompany.label}</strong>
                          <br />
                          <a
                            href={`http://crm.thatsnice.com/espo/#Account/view/${this.state.crmCompany.value}`}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            View {this.state.crmCompany.label} in CRM
                          </a>
                        </p>
                      </>
                    )}
                  </>
                ) : (
                  <p>
                    We've connected the SOW app to the CRM! Please choose the
                    correct company to link to this SOW from the Settings panel.
                  </p>
                )}
              </Modal.Body>
              <Modal.Footer>
                {this.state.confirmCompany ? (
                  <>
                    <Button
                      variant="secondary"
                      onClick={this.cancelCompanyLink}
                    >
                      Cancel
                    </Button>
                    <Button variant="primary" onClick={this.setCompany}>
                      Confirm
                    </Button>
                  </>
                ) : (
                  <>
                    <Button
                      variant="secondary"
                      onClick={this.toggleCompanyLink}
                    >
                      Dismiss
                    </Button>
                    <Button variant="primary" onClick={this.linkCompany}>
                      Choose Now
                    </Button>
                  </>
                )}
              </Modal.Footer>
            </Modal> */}
            {!!this.state.loadingPDF && (
              <div id="spinner">
                <div>
                  <CircularProgress disableShrink />
                  &emsp;Generating{" "}
                  {this.state.loadingPreview ? "PDF Preview" : "Printable PDF"}
                  ...
                </div>
                {!this.state.loadingPreview && (
                  <LinearProgress
                    variant="determinate"
                    value={this.state.progress}
                    style={{ width: "60vw" }}
                  />
                )}
              </div>
            )}
            <div id="pdf" className="document">
              <Alert
                id="print-warning"
                severity="warning"
                sx={{
                  "&": {
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    margin: "auto",
                    mb: 2,
                    width: "210mm",
                  },
                }}
              >
                This is not a printable PDF. Click the print button above to
                generate a printable version (turn margins OFF).
              </Alert>
              <div id="pages" />
            </div>
          </Box>
        </Container>

        {/* Modals */}
        <Confirm
          open={this.state.sentToClientConfirm}
          text="Do you want to tag this SOW as "
          highlight="Sent to Client"
          closeDialog={this.sentCancel}
          title="Sent Confirmation"
          confirmAction={this.sentToClientSOW}
          confirmLabel="Yes"
          cancelLabel="No"
          color="primary"
        />
        <Confirm
          open={this.state.approveConfirm}
          text="Are you sure you want to tag this SOW as "
          highlight="Approved"
          closeDialog={() => this.setState({ approveConfirm: false })}
          title="Approve SOW"
          confirmAction={this.approveSOW}
          confirmLabel="Approve"
          color="primary"
        />
        <Confirm
          open={this.state.unapproveConfirm}
          text="Are you sure want to unapprove this SOW to make changes"
          closeDialog={() => this.setState({ unapproveConfirm: false })}
          title="Unapprove SOW"
          confirmAction={this.unapproveSOW}
          confirmLabel="Unapprove"
          color="error"
        />
        <Confirm
          open={this.state.attachmentConfirm}
          text="Are you sure you want to delete the following attachment "
          highlight={this.state.deleteFileInfo?.name}
          closeDialog={() =>
            this.setState({ attachmentConfirm: false, deleteFileInfo: null })
          }
          title="Delete Attachment"
          confirmAction={this.deleteFile}
          confirmLabel="Delete"
          color="error"
        />
        <Confirm
          open={this.state.takeOverConfirm}
          text={`${this.state.takeOverName} wants to take over and edit this document, do you want to allow this`}
          closeDialog={() => {
            this.setState({ takeOverConfirm: false });
            clearTimeout(this.takeOverTO);
            this.socket.emit("rejectTakeOver", this.state.docId);
          }}
          title="Looks like you're inactive!"
          confirmAction={() => {
            clearTimeout(this.takeOverTO);
            this.approveTakeOver(this.state.takeOverName);
          }}
          confirmLabel="Allow"
          color="error"
        />
      </>
    ) : (
      <>
        {this.state.error ? (
          <Error message={this.state.error} user={this.state.user} />
        ) : (
          <div id="spinner">
            <CircularProgress disableShrink />
          </div>
        )}
      </>
    );
  }
}

Doc.propTypes = {
  client: PropTypes.object.isRequired,
  normalAdmin: PropTypes.bool,
  user: PropTypes.object,
  alert: PropTypes.func,
  loggedOut: PropTypes.bool,
  token: PropTypes.string,
  dark: PropTypes.bool,
};

export default withRouter(Doc);
