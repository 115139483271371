import update from "immutability-helper";
var ObjectID = require("bson-objectid");

export function loadRevision(id, i) {
  if (typeof this.state.revisionIndex !== "number" && this.state.dirty) {
    let sessionState = this.prepDocState();
    sessionStorage.setItem("before-revision", JSON.stringify(sessionState));
  }

  // Object.keys(this.state).forEach(key => {
  //   if (key.startsWith("tableName-") || key.startsWith("price-")) {
  //     delete this.state[key];
  //   }
  // });

  this.props.db
    .collection("revisions")
    .findOne({ _id: ObjectID(id) })
    .then((doc) => {
      // if (!doc) {
      //   this.setState({ error: "Revision not found" });
      //   return false;
      // }
      // console.log("doc data", doc);

      doc.data.approved = false;
      doc.data.sentToClient = false;
      doc.data.teamworkID = this.state.teamworkID;

      this.setState(
        {
          revisionIndex: i,
        },
        () => {
          this.injectDocState(doc.data);
        }
      );
    })
    .catch((err) => console.error(err));

  // this.setState(parsedState, () => {
  //   this.setState(
  //     {
  //       revisionIndex: i
  //     },
  //     () => {
  //       this.injectCompany();
  //       this.injectVariables();
  //       this.injectPayment();
  //       this.injectDeliverables();
  //     }
  //   );
  // });
}

export function restore() {
  this.injectDocState(
    JSON.parse(sessionStorage.getItem("sow-recent-history"))
  );
  this.setState(
    {
      historyArray: [],
    },
    () => {
      sessionStorage.removeItem("sow-recent-history");
    }
  );
}

export function cancelRevision() {
  this.setState(
    {
      revisionIndex: null,
    },
    () => {
      if (sessionStorage.getItem("before-revision")) {
        let parsedState = JSON.parse(
          sessionStorage.getItem("before-revision")
        );
        // this.setState(parsedState, () => {
        //   this.injectCompany();
        //   this.injectVariables();
        //   this.injectPayment();
        //   this.injectDeliverables();
        //   sessionStorage.removeItem("before-revision");
        // });

        this.injectDocState(parsedState);
      } else {
        this.displayDoc();
      }
    }
  );
}

export function applyRevision() {
  this.setState(
    {
      revisionIndex: null,
    },
    () => {
      this.showNotification("success", "SOW reverted!");
    }
  );
}

export function undo() {
  if (
    this.state.historyIndex < this.state.historyArray.length &&
    this.state.historyIndex >= 0
  ) {
    this.setState(
      {
        undoRedo: true,
        historyIndex: this.state.historyIndex + 1,
      },
      () => {
        this.injectDocState(this.state.historyArray[this.state.historyIndex]);
      }
    );
  }
}

export function redo() {
  if (
    this.state.historyIndex <= this.state.historyArray.length &&
    this.state.historyIndex > 0
  ) {
    this.setState(
      {
        undoRedo: true,
        historyIndex: this.state.historyIndex - 1,
      },
      () => {
        this.injectDocState(this.state.historyArray[this.state.historyIndex]);
      }
    );
  }
}

export function saveHistory() {
  // let history = JSON.parse(sessionStorage.getItem('sow-history')) || []
  //
  // let sessionState = this.prepDocState()
  //
  // history.unshift(sessionState)
  //
  // if (this.state.historyArray && this.state.historyArray.length >= 1) {
  //   console.log("recent saved");
  //   sessionStorage.setItem(
  //     "sow-recent-history",
  //     JSON.stringify(this.prepDocState())
  //   );
  // }

  this.setState(
    {
      historyIndex: 0,
      historyArray: update(this.state.historyArray, {
        $unshift: [this.prepDocState()],
      }),
    },
    () => {
      // console.log("save to history", this.state.historyArray.length);
    }
  );
}
