/**
 *
 * UserMenu
 *
 */

import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useAuth0 } from "../../react-auth0-wrapper";
import "./styles.scss";
import {
  Avatar,
  IconButton,
  Menu,
  MenuItem,
  CircularProgress,
} from "@material-ui/core";
import PropTypes from "prop-types";
import * as Sentry from "@sentry/react";

const UserMenu = (props) => {
  const { isAuthenticated, logout, user } = useAuth0();
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    props.loggedIn(user);
  }, [props, user]);

  useEffect(() => {
    Sentry.setUser({ email: user?.email });
  }, [user]);

  return (
    <div id="user-menu">
      {isAuthenticated ? (
        <>
          <IconButton size="small" aria-haspopup="true" onClick={handleClick}>
            <Avatar src={user?.picture} alt={user?.given_name} />
          </IconButton>
          <Menu
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
            id="user-nav"
          >
            <MenuItem className="name" disabled>
              {user?.name}
            </MenuItem>
            {/* <MenuItem className="switch">
              <FormControlLabel
                style={{ margin: 0 }}
                control={
                  <Switch
                    size="small"
                    checked={props.dark}
                    onChange={props.toggleTheme}
                    name="dark-mode"
                    className={props.dark ? "enabled" : ""}
                    inputProps={{ "aria-label": "secondary checkbox" }}
                  />
                }
                label="Dark Mode"
              />
            </MenuItem> */}
            {user?.["https://sow.thatsnice.com/roles"].includes("admin") && (
              <MenuItem onClick={handleClose} component={Link} to={`/admin`}>
                Admin
              </MenuItem>
            )}
            <MenuItem
              onClick={() => {
                props.loggedOut();
                logout();
              }}
            >
              Logout
            </MenuItem>
          </Menu>
        </>
      ) : (
        <CircularProgress size="small" />
      )}
    </div>
  );
};

UserMenu.propTypes = {
  loggedIn: PropTypes.func,
  loggedOut: PropTypes.func,
  toggleTheme: PropTypes.func,
  dark: PropTypes.bool,
};

export default UserMenu;
