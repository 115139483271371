import Geocode from "react-geocode";
import axios from 'axios';
import iplocation from "iplocation";
import { deviceType, browserName } from "react-device-detect";

var ObjectID = require("bson-objectid");
Geocode.setApiKey("AIzaSyBAPmV8kx4T8ephPa2EnNvZPd47nf3zvtI");

export function onCopy() {
  this.showNotification("success", `Link copied!`);

  this.sentConfirm(() => {
    this.slackMessage(
      `generated secure link for approval by ${this.state.user.name}`
    );
  });
};

export function generateToken() {
  this.setState(
    {
      token: URL.createObjectURL(new Blob([])).slice(-36).replace(/-/g, ""),
      canGenerate: false,
    },
    () => {
      this.props.db
        .collection(this.props.collection)
        .updateOne(
          {
            _id: ObjectID(this.state.docId),
          },
          {
            $set: {
              token: this.state.token,
            },
          }
        )
        .then(() => {
          this.setState({
            canGenerate: true,
            link: `${
              window.location.origin + window.location.pathname
            }?token=${this.state.token}`,
          });
        });
    }
  );
}

export function getLocAndIP() {
  // console.log("get loc and IP");

  axios
    .get("/api/getip")
    .then((response) => {
      // console.log("ip", response.data);
      this.setState(
        {
          ip: response.data,
        },
        () => {
          navigator.geolocation.getCurrentPosition(
            (location) => {
              // console.log("location", location);
              this.setState(
                {
                  lat: location.coords.latitude,
                  lon: location.coords.longitude,
                },
                () => {
                  this.getAddress();
                }
              );
            },
            (error) => {
              if (error.code === error.PERMISSION_DENIED) {
                // console.log("geolocation disabled");

                this.getAddressFromIP();
              }
            }
          );
        }
      );
    })
    .catch((error) => {
      console.error(error);
    });
}

export function getAddressFromIP() {
  iplocation(this.state.ip)
    .then((res) => {
      // console.log("ip", res);

      let address = `${res.city}, ${res.regionCode}${
        res.postal ? " " + res.postal : ""
      }, ${res.countryCode}`;
      // console.log("address", address);
      this.setState({
        address,
      });
    })
    .catch((err) => {
      console.error("ip error", err);
    });
}

export function getAddress() {
  Geocode.fromLatLng(this.state.lat, this.state.lon).then(
    (response) => {
      console.log("address", response);
      // const address = response.results[0].formatted_address;
      let postal_code;
      let country;
      let city;
      let state;

      for (let component of response.results[0].address_components) {
        if (component.types.includes("postal_code")) {
          postal_code = component.long_name;
        }
        if (component.types.includes("country")) {
          country = component.short_name;
        }
        if (component.types.includes("administrative_area_level_1")) {
          state = component.short_name;
        }

        if (component.types.includes("locality")) {
          city = component.long_name;
        }
      }
      // console.log("postal", postal_code);
      // console.log("country", country);
      // console.log("state", state);
      // console.log("city", city);
      // console.log("address", address);

      let address = `${city}, ${state}${
        postal_code ? " " + postal_code : ""
      }, ${country}`;

      this.setState({
        approvalAddress: address,
      });
    },
    (error) => {
      console.error(error);
    }
  );
}

export function handleApproveSubmit(e) {
  e.preventDefault();

  // if (this.sigCanvas.isEmpty()) {
  //   window.alert("Signature is blank!");
  //   return false;
  // }

  this.setState(
    {
      approved: true,
      approvedBy: {
        name: this.state.approvalName,
        title: this.state.approvalTitle,
        company: this.state.approvalCompany,
        // signature: this.sigCanvas.toData(),
        time: new Date(),
        location: {
          lat: this.state.lat,
          lon: this.state.lon,
          address: this.state.approvalAddress,
        },
        ip: this.state.ip,
        browserName,
        deviceType,
      },
      sentToClient: false,
      eApproved: true,
    },
    () => {
      document.getElementById("sig-required").scrollIntoView({
        behavior: "smooth",
      });

      this.props.db
        .collection(this.props.collection)
        .updateOne(
          {
            _id: ObjectID(this.state.docId),
          },
          {
            $set: {
              sentToClient: false,
              approved: true,
              approvedBy: this.state.approvedBy,
              eApproved: true,
            },
          }
        )
        .then(() => {
          this.setState(
            {
              canGenerate: true,
              link: `${
                window.location.origin + window.location.pathname
              }?token=${this.state.token}`,
            },
            () => {
              this.slackMessage(
                `e-Approved by ${this.state.approvalName}, ${this.state.approvalTitle}, ${this.state.approvalCompany}`,
                true
              );
              this.slackMessage(
                `e-Approved by ${this.state.approvalName}, ${this.state.approvalTitle}, ${this.state.approvalCompany}`
              );

              this.sendEmail();
            }
          );
        });
    }
  );
}

export function sendEmail() {
  axios
    .get("/api/approvalemail", {
      params: {
        to: this.state.approvalEmail,
        link: this.state.link,
        title: this.state.title,
        name: this.state.approvalName,
      },
    })
    .then((response) => {
      // console.log("subtask", response);
      // console.log(response);
    })
    .catch((error) => {
      console.error(error);
    });
}
