import React from "react";
import { Box, Menu, Button, IconButton } from "@material-ui/core";
import autoBind from "react-autobind";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import DeleteIcon from "@material-ui/icons/Delete";
import VisibilityIcon from "@material-ui/icons/Visibility";
import { Link } from "react-router-dom";

class Options extends React.Component {
  constructor() {
    super();

    this.state = {
      anchorEl: null,
    };

    autoBind(this);
  }

  handleMenu(event) {
    this.setState({ anchorEl: event.currentTarget }, () => {
      // event.currentTarget.closest('.MuiPaper-root').classList.add('active')
    });
  }

  handleClose() {
    this.setState({ anchorEl: null });
  }

  render() {
    const { anchorEl } = this.state;
    const { doc, sowID } = this.props;
    const open = Boolean(anchorEl);

    return (
      <Box className="options-btn">
        <IconButton
          size="small"
          aria-owns={open ? "menu-appbar" : null}
          aria-haspopup="true"
          onClick={this.handleMenu}
        >
          <MoreVertIcon />
        </IconButton>
        <Menu
          className="options-menu"
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          open={open}
          onClose={this.handleClose}
          // onClick={this.handleClose}
          sx={{
            "& .MuiMenu-paper": {
              width: "100%",
              maxWidth: 170,
            },
            "& .MuiButton-root": {
              justifyContent: "flex-start",
              padding: "6px 16px",
              borderRadius: 0,
              height: 40,
            },
            "& .MuiButton-startIcon": {
              marginRight: 3,
            },
          }}
        >
          <Button
            fullWidth
            startIcon={<VisibilityIcon />}
            variant="text"
            color="primary"
            component={Link}
            to={`/${sowID}/brief/${doc._id.toString()}`}
          >
            View
          </Button>
          <Button
            fullWidth
            startIcon={<DeleteIcon />}
            variant="text"
            color="error"
            onClick={(e) => {
              this.handleClose();
              this.props.openDialog(doc._id.toString(), doc.title);
            }}
          >
            Delete
          </Button>
        </Menu>
      </Box>
    );
  }
}

export default Options;
