/**
 *
 * Reports
 *
 */

import React from "react";
import PropTypes from "prop-types";
import "./styles.scss";
import Filters from "./Filters";
import {
  IconButton,
  Container,
  CircularProgress,
  LinearProgress,
  Box,
  Tooltip,
  Grid,
  Typography,
} from "@material-ui/core";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import NavigateBeforeIcon from "@material-ui/icons/NavigateBefore";
import * as Document from "../../utils/docMethods";
import * as Functions from "./functions.js";
import { formatNumber, formatCurrency } from "../../utils/functions";
import { handleInputChange, handleDateChange } from "../../utils/inputHandlers";
import autoBind from "react-autobind";
import { Auth0Context } from "../../react-auth0-wrapper";
import Chart from "./Chart";
import {
  generateDocName,
  getAppVersion,
  showNotification,
} from "../../utils/functions";
import calculateTotalPrice from "../../workers/calculateTotalPriceWorker.js";
import WebWorker from "../../utils/workerSetup";
import SOWList from "./SOWList";
import Carousel from "react-material-ui-carousel";

class Breakdown extends React.Component {
  constructor() {
    super();
    this.formatCurrency = formatCurrency.bind(this);
  }
  render() {
    return (
      <table className="cost-breakdown">
        <tbody>
          <tr>
            <td>Pass Through:</td>
            <td className="currency">
              {this.formatCurrency(parseFloat(this.props.pt).toFixed(2))}
            </td>
          </tr>
          <tr>
            <td>Direct Spend:</td>
            <td className="currency">
              {this.formatCurrency(parseFloat(this.props.ds).toFixed(2))}
            </td>
          </tr>
        </tbody>
      </table>
    );
  }
}

export class Reports extends React.Component {
  // eslint-disable-line react/prefer-stateless-function
  constructor() {
    super();
    this.state = {
      documents: [],
      page: 1,
      searching: false,
      loading: false,
      initialized: false,
      currentUserId: undefined,
      showApprovedReport: false,
      showSentToClientReport: false,
      showRejectedReport: false,
      showActionNeededReport: false,
      startDate: new Date(),
      endDate: new Date(),
      running: false,
      members: [],
      company: "",
      showAdvanced: false,
      currency: null,
      showUSD: false,
      showEUR: false,
      showGBP: false,
      activeSort: "Title",
      sortAsc: false,
      reportFilters:
        JSON.parse(localStorage.getItem("sow-filters-report")) || {},
      anchorEl: null,
      listHeight: 0,
    };

    autoBind(this);

    this.showNotification = showNotification.bind(this);

    this.handleInputChange = handleInputChange.bind(this);
    this.handleDateChange = handleDateChange.bind(this);

    this.generateDocName = generateDocName.bind(this);
    this.getAppVersion = getAppVersion.bind(this);

    this.deleteDoc = Document.deleteDoc.bind(this);
    this.cloneDoc = Document.cloneDoc.bind(this);
    this.getNewDocNumber = Document.getNewDocNumber.bind(this);
    this.formatNumber = formatNumber.bind(this);
    this.formatCurrency = formatCurrency.bind(this);

    this.setSalesPerson = Functions.setSalesPerson.bind(this);
    this.fetchTeam = Functions.fetchTeam.bind(this);
    this.fetchFilterData = Functions.fetchFilterData.bind(this);
    this.loadMembers = Functions.loadMembers.bind(this);
    this.clearDocs = Functions.clearDocs.bind(this);
    this.clearSearch = Functions.clearSearch.bind(this);
    this.clearFilters = Functions.clearFilters.bind(this);
    this.toggleAdvanced = Functions.toggleAdvanced.bind(this);
    this.displayDocs = Functions.displayDocs.bind(this);
    this.removeDuplicates = Functions.removeDuplicates.bind(this);
    this.injectActiveFilters = Functions.injectActiveFilters.bind(this);
    this.displayDocsOnLoad = Functions.displayDocsOnLoad.bind(this);
    this.handleReactSelect = Functions.handleReactSelect.bind(this);
    this.enableSearching = Functions.enableSearching.bind(this);
    this.runReport = Functions.runReport.bind(this);
    this.clearCompany = Functions.clearCompany.bind(this);
    this.orderName = Functions.orderName.bind(this);
    this.getSiblings = Functions.getSiblings.bind(this);
    this.sortDocsBy = Functions.sortDocsBy.bind(this);
    this.totalBreakdown = Functions.totalBreakdown.bind(this);
    this.calculateChartData = Functions.calculateChartData.bind(this);
    this.setListHeight = Functions.setListHeight.bind(this);
  }

  static contextType = Auth0Context; // eslint-disable-line no-undef

  calculateTotalPriceWorker(state) {
    this.worker = new WebWorker(calculateTotalPrice);
    this.worker.postMessage(JSON.stringify(state));
    this.worker.onerror = (err) => {
      console.error(err);
    };
    this.worker.addEventListener("message", (event) => {
      this.setState(
        {
          documents: event.data,
          loading: false,
          running: false,
        },
        () => {
          // console.log("documents", event.data);
          this.setListHeight();
          this.worker.terminate();
        }
      );
    });
  }

  componentDidMount() {
    this.displayDocsOnLoad();
    // this.fetchTeam();
    this.fetchFilterData();
    this.getAppVersion();

    document.title = "Reports | SOW Generator";
  }

  componentWillUnmount() {}

  componentDidUpdate(prevProps, prevState) {
    if (
      this.props.user !== prevProps.user &&
      this.props.normalAdmin !== prevProps.normalAdmin
    ) {
      this.setSalesPerson();
    }
  }

  openStatusFilters(e) {
    this.setState({
      statusAnchorEl: e.currentTarget,
    });
  }

  openCurrencyFilters(e) {
    this.setState({
      currencyAnchorEl: e.currentTarget,
    });
  }

  closeFilters() {
    this.setState({
      statusAnchorEl: null,
      currencyAnchorEl: null,
    });
  }

  render() {
    // calculate totals by currency
    let currencyUSD = this.state.documents.filter((d) => d.currency === "USD");
    let totalUSD = currencyUSD.reduce((a, b) => a + b.totalPrice, 0);
    let totalUSD_PT = currencyUSD.reduce((a, b) => a + b.passThrough, 0);
    let totalUSD_DS = currencyUSD.reduce((a, b) => a + b.directSpend, 0);

    let currencyEUR = this.state.documents.filter((d) => d.currency === "EUR");
    let totalEUR = currencyEUR.reduce((a, b) => a + b.totalPrice, 0);
    let totalEUR_PT = currencyEUR.reduce((a, b) => a + b.passThrough, 0);
    let totalEUR_DS = currencyEUR.reduce((a, b) => a + b.directSpend, 0);

    let currencyGBP = this.state.documents.filter((d) => d.currency === "GBP");
    let totalGBP = currencyGBP.reduce((a, b) => a + b.totalPrice, 0);
    let totalGBP_PT = currencyGBP.reduce((a, b) => a + b.passThrough, 0);
    let totalGBP_DS = currencyGBP.reduce((a, b) => a + b.directSpend, 0);

    let totalsBySalesPerson = this.calculateChartData("salesPerson");
    let totalsByAccountManagement = this.calculateChartData(
      "accountManagement"
    );

    const { isAuthenticated } = this.context;
    return isAuthenticated ? (
      <Container id="reports" style={{ paddingBottom: "60px" }}>
        <Filters
          state={this.state}
          runReport={this.runReport}
          clearFilters={this.clearFilters}
          handleInputChange={this.handleInputChange}
          handleReactSelect={this.handleReactSelect}
          handleDateChange={this.handleDateChange}
          orderName={this.orderName}
          loadMembers={this.loadMembers}
          normalAdmin={this.props.normalAdmin}
          toggleAdvanced={this.toggleAdvanced}
          openStatusFilters={this.openStatusFilters}
          openCurrencyFilters={this.openCurrencyFilters}
          closeFilters={this.closeFilters}
          statusAnchorEl={this.statusAnchorEl}
          currencyAnchorEl={this.currencyAnchorEl}
        />

        <hr />

        {this.state.running ? (
          // <CircularProgress />
          <Box>
            <LinearProgress />
            <br />
            <div style={{ textAlign: "center" }}>Generating Report...</div>
          </Box>
        ) : (
          <>
            {this.state.documents.length ? (
              <>
                <div
                  id="report-totals"
                  style={{ position: "fixed", bottom: 0, left: 0, right: 0 }}
                >
                  <Container>
                    <Grid
                      container
                      alignItems="center"
                      justifyContent="space-between"
                      pt={1}
                      pb={1}
                    >
                      <Typography variant="h5">
                        Total ({this.state.documents.length}):
                      </Typography>
                      <Typography variant="h5">
                        {!!totalGBP && (
                          <Tooltip
                            title={
                              <Breakdown pt={totalGBP_PT} ds={totalGBP_DS} />
                            }
                          >
                            <span>
                              £{" "}
                              {this.formatCurrency(
                                parseFloat(totalGBP).toFixed(2)
                              )}
                            </span>
                          </Tooltip>
                        )}
                        {!!totalEUR && (
                          <Tooltip
                            title={
                              <Breakdown pt={totalEUR_PT} ds={totalEUR_DS} />
                            }
                          >
                            <span>
                              €{" "}
                              {this.formatCurrency(
                                parseFloat(totalEUR).toFixed(2)
                              )}
                            </span>
                          </Tooltip>
                        )}
                        {!!totalUSD && (
                          <Tooltip
                            title={
                              <Breakdown pt={totalUSD_PT} ds={totalUSD_DS} />
                            }
                          >
                            <span>
                              ${" "}
                              {this.formatCurrency(
                                parseFloat(totalUSD).toFixed(2)
                              )}
                            </span>
                          </Tooltip>
                        )}
                      </Typography>
                    </Grid>
                  </Container>
                </div>
                {(totalsBySalesPerson.length > 1 ||
                  totalsByAccountManagement.length > 1) && (
                  <Box mb={2}>
                    <Carousel
                      className="report-carousel"
                      autoPlay={false}
                      // animation="slide"
                      navButtonsAlwaysVisible={true}
                      // cycleNavigation={false}
                      onChange={this.setListHeight}
                      indicators={false}
                      fullHeightHover={false}
                      NavButton={({ onClick, className, style, next, prev }) => {
                        return (
                          <IconButton
                            onClick={onClick}
                            className={className}
                            style={{
                              // transform: `translateX(${next ? "" : "-"}50px)`,
                              backgroundColor: "transparent",
                              color: "inherit",
                              margin: 0,
                            }}
                          >
                            {next && <NavigateNextIcon />}
                            {prev && <NavigateBeforeIcon />}
                          </IconButton>
                        );
                      }}
                      navButtonsWrapperProps={{
                        style: {
                          margin: "-70px",
                          transform: "translateY(70px) translateY(-50%)",
                          top: "50%",
                          display: "flex",
                          alignItems: "center",
                          height: "auto",
                        },
                      }}
                    >
                      {!!totalsBySalesPerson.length && (
                        <Box>
                          <Chart
                            title="Sales Person"
                            totals={totalsBySalesPerson}
                            formatCurrency={this.formatCurrency}
                            numDocs={this.state.documents.length}
                          />
                        </Box>
                      )}
                      {!!totalsByAccountManagement.length && (
                        <Box>
                          <Chart
                            title="Account Management"
                            totals={totalsByAccountManagement}
                            formatCurrency={this.formatCurrency}
                            numDocs={this.state.documents.length}
                          />
                        </Box>
                      )}
                    </Carousel>
                  </Box>
                )}

                <SOWList
                  headCells={[
                    {
                      id: "Title",
                      label: "SOW",
                      visible: true,
                    },
                    {
                      id: "status",
                      numeric: false,
                      label: "Status",
                      visible: true,
                    },
                    {
                      id: "displaySalesPerson",
                      numeric: false,
                      label: "Sales Person",
                      visible: true,
                    },
                    {
                      id: "displayAccountManagement",
                      numeric: false,
                      label: "Account Management",
                      visible: true,
                    },
                    {
                      id: "displayDate",
                      numeric: false,
                      label: "Date",
                      visible: true,
                    },
                    {
                      id: "totalPrice",
                      numeric: true,
                      label: "Price",
                      visible: true,
                    },
                  ]}
                  rows={this.state.documents}
                  formatCurrency={this.formatCurrency}
                  height={this.state.listHeight}
                />
              </>
            ) : (
              <>
                {this.state.noResults && (
                  <Typography variant="h6">No results</Typography>
                )}
              </>
            )}
          </>
        )}
      </Container>
    ) : (
      <div id="spinner">
        <CircularProgress />
      </div>
    );
  }
}

Reports.propTypes = {
  normalAdmin: PropTypes.bool,
  user: PropTypes.object,
};

export default Reports;
