import socketIOClient from "socket.io-client";

export function setUser() {
  let user = this.state.user || this.props.user;

  this.setState(
    {
      user,
    },
    () => {
      this.socket = socketIOClient({
        query: `user=${encodeURIComponent(
          JSON.stringify(this.state.user)
        )}&room=${this.state.docId}`,
      });
      // console.log("user state", this.state.user);
      if (user) {
        this.socket.on("userArray", (users) => {
          // console.log("userArray", users, this.state.isAdmin, this.state.admin);
          if (users.length) {
            let admin = users[0].user;
            let isAdmin = user.email === admin.email ? true : false;

            this.setState(
              {
                users,
                admin,
                isAdmin,
              },
              () => {
                if (isAdmin) {
                  // console.log('send admin idle status', this.state.adminIdle)
                  this.socket.emit("idleStatus", {
                    docId: this.state.docId,
                    idleStatus: this.state.adminIdle,
                  });
                }
              }
            );
          }

          this.setState(
            {
              allDone: true,
            },
            () => {
              this.setStepsTableWidth();
              // if (this.state.isAdmin && !this.state.crmCompany) {
              //   this.setState({
              //     showCompanyLink: true
              //   });
              // }
            }
          );
        });

        this.socket.on("refresh", (message) => {
          if (!this.state.isAdmin) {
            this.displayDoc();
            this.showNotification("warning", "SOW has just been updated!");
          }
        });

        this.socket.on("notifyIdle", (message) => {
          if (!this.state.isAdmin) {
            // console.log("active admin is idle");
            this.setState({
              adminIdle: true,
              // requestingTakeOver: false,
            });
          }
        });

        this.socket.on("notifyActive", (message) => {
          if (!this.state.isAdmin) {
            // console.log("active admin is idle");
            this.setState({
              adminIdle: false,
              // requestingTakeOver: false,
            });
          }
        });

        this.socket.on("idleStatus", (status) => {
          if (!this.state.isAdmin) {
            // console.log('recieved idle status', status)

            this.setState({
              adminIdle: status,
            });
          }
        });

        this.socket.on("takeOverReject", (status) => {
          if (!this.state.isAdmin) {
            this.setState({
              adminIdle: false,
              rejectTakeOver: true,
              requestingTakeOver: false,
            });
          }
        });

        this.socket.on("takeOverApprove", (name) => {
          this.setState({
            admin: this.state.users.find((u) => u.user.name === name)?.user,
          });

          if (!this.state.isAdmin && this.state.user.name === name) {
            this.displayDoc();
            this.setState({
              isAdmin: true,
              adminIdle: false,
              requestingTakeOver: false,
              rejectTakeOver: false
            });
          }
        });

        this.socket.on("takeOverRequest", (name) => {
          this.setState(
            {
              takeOverName: name,
            },
            () => {
              if (this.state.isAdmin) {
                this.takeOverTO = setTimeout(() => {
                  this.setState(
                    {
                      takeOverConfirm: false,
                    },
                    () => {
                      document.querySelector("#modal-confirm")?.click();
                    }
                  );
                }, 5000);

                this.setState({
                  takeOverConfirm: true,
                });
              }
            }
          );
        });

        this.socket.on("kickUser", (email) => {
          if (this.state.user.email === email) {
            // document.querySelector("#user-menu .dropdown-toggle").click();
            // document.querySelector("#logout").click();

            if (this.state.dirty) {
              this.updateDoc(null, () => {
                this.props.history.push("/");
              });
            } else {
              this.props.history.push("/");
            }

            this.showNotification(
              "error",
              "You were removed from the SOW by an admin"
            );
          }
        });
      }

      // this.socket.emit("joinRoom", {
      //   user: this.props.user,
      //   document: this.state.docId,
      //   time: new Date()
      // });
    }
  );
}
