/**
 *
 * Reports Filters
 *
 */

import React from "react";
import PropTypes from "prop-types";
import {
  Button,
  Chip,
  Autocomplete,
  TextField,
  Grid,
  Tooltip,
  Menu,
  MenuItem,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Typography,
} from "@material-ui/core";
import LoadingButton from "@material-ui/lab/LoadingButton";
import FilterListIcon from "@material-ui/icons/FilterList";
import DateRangeIcon from "@material-ui/icons/DateRange";
import DatePicker from "@material-ui/lab/DatePicker";
import "./styles.scss";
import moment from "moment";

function Filters(props) {
  let { state } = props;
  let filters = {
    status: [
      { name: "Approved", key: "showApprovedReport" },
      { name: "Sent to Client", key: "showSentToClientReport" },
      { name: "Rejected", key: "showRejectedReport" },
      { name: "Action Needed", key: "showActionNeededReport" },
    ],
    currency: [
      { name: "USD ($)", key: "showUSD" },
      { name: "EUR (€)", key: "showEUR" },
      { name: "GBP (£)", key: "showGBP" },
    ],
  };

  return (
    <Grid
      id="report-filters"
      flexDirection="column"
      className={state.loading ? "disabled" : ""}
    >
      <Grid>
        <form style={{ width: "100%" }} onSubmit={props.runReport}>
          <Grid display="flex" justifyContent="space-between">
            <Grid item>
              <Typography variant="h5">Reports</Typography>
            </Grid>
            <Grid
              item
              sx={{
                "&": {
                  display: "flex",
                  flex: 1,
                  justifyContent: "flex-end",
                  m: -0.5,
                  mb: 1,
                },
                "& > div": {
                  flex: 1,
                  m: 0.5,
                  maxWidth: 220,
                },
              }}
            >
              <Grid
                item
                sx={{
                  "&": {
                    // ml: 1,
                    // position: "absolute",
                    // top: "50%",
                    // transform: "translateY(-50%)",
                    // left: "100%",
                    textAlign: "right",
                  },
                }}
              >
                <Tooltip disableInteractive title="Filters">
                  <Button
                    disableElevation
                    id="toggle-advanced"
                    variant={state.showAdvanced ? "contained" : "text"}
                    color="secondary"
                    onClick={props.toggleAdvanced}
                    type="button"
                    style={{
                      minWidth: "auto",
                      maxWidth: 40,
                    }}
                  >
                    <FilterListIcon />
                  </Button>
                </Tooltip>
              </Grid>
              <Grid item>
                <DatePicker
                  label="Start Date"
                  inputFormat="MM/DD/yyyy"
                  value={state.startDate}
                  onChange={(value) =>
                    props.handleDateChange("startDate", value)
                  }
                  renderInput={(params) => (
                    <TextField {...params} size="small" fullWidth />
                  )}
                />
              </Grid>
              <Grid item>
                <DatePicker
                  label="End Date"
                  inputFormat="MM/DD/yyyy"
                  value={state.endDate}
                  onChange={(value) => props.handleDateChange("endDate", value)}
                  renderInput={(params) => (
                    <TextField {...params} size="small" fullWidth />
                  )}
                />
              </Grid>
              <Grid item position="relative" style={{ maxWidth: 200 }}>
                <LoadingButton
                  disableElevation
                  fullWidth
                  id="run-report-btn"
                  variant="contained"
                  type="submit"
                  pending={state.loading}
                >
                  Run Report
                </LoadingButton>
              </Grid>
            </Grid>
          </Grid>
          <Grid
            sx={{
              "&": {
                display: "flex",
                justifyContent: "flex-end",
                m: -0.5,
                mb: 1,
              },
              "& > div": {
                flex: 0,
                m: 0.5,
                maxWidth: 220,
                "&:nth-last-of-type(n+3)": {
                  flex: 1,
                },
              },
              "& .MuiFormControl-root": {
                m: 0,
              },
            }}
          >
            {state.showAdvanced && (
              <>
                <Grid item>
                  <Autocomplete
                    autoHighlight
                    value={state.company || null}
                    options={state.companies || []}
                    getOptionLabel={(option) => option?.label || ""}
                    getOptionSelected={(option, value) =>
                      option.value === value.value
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Company"
                        variant="outlined"
                        size="small"
                        margin="normal"
                        InputLabelProps={{ required: false }}
                      />
                    )}
                    onChange={(e, value) =>
                      props.handleReactSelect("company", value)
                    }
                  />
                </Grid>
                <Grid item>
                  <Autocomplete
                    autoHighlight
                    value={state.salesPerson || null}
                    options={
                      state.members &&
                      state.members.sort(props.orderName).map((member) => ({
                        label: member.Name,
                        value: member._id.toString(),
                      }))
                    }
                    getOptionLabel={(option) => option?.label || ""}
                    getOptionSelected={(option, value) =>
                      option.value === value.value
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Sales Person"
                        variant="outlined"
                        size="small"
                        margin="normal"
                        InputLabelProps={{ required: false }}
                      />
                    )}
                    onChange={(e, value) =>
                      props.handleReactSelect("salesPerson", value)
                    }
                    disabled={!props.normalAdmin}
                    onOpen={props.loadMembers}
                  />
                </Grid>
                <Grid item>
                  <Autocomplete
                    autoHighlight
                    value={state.accountManagement || null}
                    options={
                      state.members &&
                      state.members.sort(props.orderName).map((member) => ({
                        label: member.Name,
                        value: member._id.toString(),
                      }))
                    }
                    getOptionLabel={(option) => option?.label || ""}
                    getOptionSelected={(option, value) =>
                      option.value === value.value
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Account Management"
                        variant="outlined"
                        size="small"
                        margin="normal"
                        InputLabelProps={{ required: false }}
                      />
                    )}
                    onChange={(e, value) =>
                      props.handleReactSelect("accountManagement", value)
                    }
                    disabled={!props.normalAdmin}
                    onOpen={props.loadMembers}
                  />
                </Grid>
                <Grid item>
                  <Button
                    fullWidth
                    color="secondary"
                    variant="outlined"
                    aria-controls="simple-menu"
                    aria-haspopup="true"
                    onClick={props.openStatusFilters}
                    type="button"
                  >
                    Status
                  </Button>
                  <Menu
                    id="status-dropdown"
                    anchorEl={state.statusAnchorEl}
                    getContentAnchorEl={null}
                    keepMounted
                    open={Boolean(state.statusAnchorEl)}
                    onClose={props.closeFilters}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "center",
                    }}
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "center",
                    }}
                  >
                    {filters.status.map((filter) => {
                      return (
                        <MenuItem key={filter.name}>
                          <FormGroup>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  size="small"
                                  name={filter.key}
                                  checked={state[filter.key]}
                                  onChange={props.handleInputChange}
                                />
                              }
                              label={filter.name}
                            />
                          </FormGroup>
                        </MenuItem>
                      );
                    })}
                  </Menu>
                </Grid>
                <Grid item>
                  <Button
                    fullWidth
                    color="secondary"
                    variant="outlined"
                    aria-controls="simple-menu"
                    aria-haspopup="true"
                    onClick={props.openCurrencyFilters}
                    type="button"
                  >
                    Currency
                  </Button>
                  <Menu
                    id="currency-dropdown"
                    anchorEl={state.currencyAnchorEl}
                    getContentAnchorEl={null}
                    keepMounted
                    open={Boolean(state.currencyAnchorEl)}
                    onClose={props.closeFilters}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "center",
                    }}
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "center",
                    }}
                  >
                    {filters.currency.map((filter) => {
                      return (
                        <MenuItem key={filter.name}>
                          <FormGroup>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  size="small"
                                  name={filter.key}
                                  checked={state[filter.key]}
                                  onChange={props.handleInputChange}
                                />
                              }
                              label={filter.name}
                            />
                          </FormGroup>
                        </MenuItem>
                      );
                    })}
                  </Menu>
                </Grid>
              </>
            )}
          </Grid>
        </form>
      </Grid>
      <Grid>
        <div className="active-criteria">
          {/* {state.query && !state.searching ? (
              <span id="search-results" className="text-secondary">
                Search Results for: {state.query}
              </span>
            ) : null} */}
          <span id="filter-results" className="text-secondary">
            <Tooltip disableInteractive title="Date Range">
              <Chip
                size="small"
                icon={<DateRangeIcon />}
                label={`${moment(state.reportFilters.startDate).format(
                  "L"
                )} - ${moment(state.reportFilters.endDate).format("L")}`}
              />
            </Tooltip>

            {filters.status.map((filter) => {
              return (
                state.reportFilters[filter.key] && (
                  <Chip
                    color="primary"
                    size="small"
                    key={filter.name}
                    label={filter.name}
                    onDelete={() => props.clearFilters(filter.key)}
                  />
                )
              );
            })}

            {state.reportFilters.company && (
              <Tooltip disableInteractive title="Company">
                <Chip
                  color="info"
                  size="small"
                  label={
                    state.reportFilters.company
                      ? `${state.reportFilters.company.label}`
                      : ""
                  }
                  onDelete={() => props.clearFilters("company")}
                />
              </Tooltip>
            )}

            {state.reportFilters.salesPerson && (
              <Tooltip disableInteractive title="Sales Person">
                <Chip
                  color="secondary"
                  size="small"
                  label={
                    state.reportFilters.salesPerson
                      ? `${state.reportFilters.salesPerson.label}`
                      : ""
                  }
                  onDelete={() => props.clearFilters("salesPerson")}
                />
              </Tooltip>
            )}

            {state.reportFilters.accountManagement && (
              <Tooltip disableInteractive title="Account Management">
                <Chip
                  color="secondary"
                  size="small"
                  label={
                    state.reportFilters.accountManagement
                      ? `${state.reportFilters.accountManagement.label}`
                      : ""
                  }
                  onDelete={() => props.clearFilters("accountManagement")}
                />
              </Tooltip>
            )}

            {filters.currency.map((filter) => {
              return (
                state.reportFilters[filter.key] && (
                  <Chip
                    color="default"
                    size="small"
                    key={filter.name}
                    label={filter.name}
                    onDelete={() => props.clearFilters(filter.key)}
                  />
                )
              );
            })}
          </span>
        </div>
      </Grid>
    </Grid>
  );
}

Filters.propTypes = {
  state: PropTypes.object.isRequired,
  normalAdmin: PropTypes.bool.isRequired,
  runReport: PropTypes.func.isRequired,
  handleDateChange: PropTypes.func.isRequired,
  handleInputChange: PropTypes.func.isRequired,
  handleReactSelect: PropTypes.func.isRequired,
  orderName: PropTypes.func.isRequired,
  loadMembers: PropTypes.func.isRequired,
  toggleAdvanced: PropTypes.func.isRequired,
  clearFilters: PropTypes.func.isRequired,
};

export default Filters;
