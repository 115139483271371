export function _onAction(e) {
  // console.log("user did something", e);
  if (this.state.isAdmin && this.state.admin && this.state.adminIdle) {
    this.setState({
      adminIdle: false,
    });

    this.socket.emit("active", this.state.docId);

    clearTimeout(this.takeOverTO);
  }
}

export function _onActive(e) {
  // console.log("user is active", e);
  // console.log("time remaining", this.idleTimer.getRemainingTime());
  if (this.state.isAdmin && this.state.admin && this.state.adminIdle) {
    // if (this.state.dirty) {
    //   this.updateDoc()
    // }
    //
    // setTimeout(() => {
    //   window.location.reload();
    // }, 5000)

    this.setState({
      adminIdle: false,
    });

    this.socket.emit("active", this.state.docId);

    clearTimeout(this.takeOverTO);
  }
}

export function _onIdle(e) {
  // console.log("user is idle", e);
  // console.log("last active", this.idleTimer.getLastActiveTime());

  if (this.state.isAdmin && this.state.admin) {
    // if (this.state.dirty) {
    //   this.updateDoc()
    // }
    //
    // setTimeout(() => {
    //   window.location.reload();
    // }, 5000)

    this.setState({
      adminIdle: true,
    });

    this.socket.emit("idle", this.state.docId);
  }
}

export function requestTakeOver() {
  this.setState({
    requestingTakeOver: true,
    rejectTakeOver: false,
  });

  this.socket.emit("requestTakeOver", {
    name: this.state.user.name,
    docId: this.state.docId,
  });
}

export function approveTakeOver(name) {
  const onFinish = () => {
    this.setState({
      isAdmin: false,
      adminIdle: false,
      showForm: false,
      settings: false,
      takeOverConfirm: false,
      rejectTakeOver: false,
    });
  };

  if (this.state.dirty && this.state.isAdmin) {
    this.updateDoc(null, () => {
      this.socket.emit("approveTakeOver", {
        name,
        docId: this.state.docId,
      });

      onFinish();
    });
  } else {
    this.socket.emit("approveTakeOver", {
      name,
      docId: this.state.docId,
    });

    onFinish();
  }
}
