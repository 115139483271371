/**
 *
 * PrivateRoute
 *
 */

import React, { useEffect, useContext } from "react";
import { Route } from "react-router-dom";
import { useAuth0 } from "../react-auth0-wrapper";
import { CircularProgress } from "@material-ui/core";
import { Auth0Context } from "../react-auth0-wrapper";

const PrivateRoute = ({ component: Component, path, ...rest }) => {
  const { isAuthenticated, loginWithRedirect, loading } = useAuth0();
  const theme = useContext(Auth0Context);

  useEffect(() => {
    const fn = async () => {
      if (loading === false && !isAuthenticated) {
        await loginWithRedirect({
          appState: { targetUrl: path },
        });
      }
    };
    fn();
  }, [isAuthenticated, loginWithRedirect, path, loading]);

  const render = (props) =>
    isAuthenticated === true ? (
      <Component {...props} />
    ) : (
      <div id="spinner">
        <CircularProgress disableShrink />
      </div>
    );

  return theme.team.length ? (
    <Route path={path} render={render} {...rest} />
  ) : (
    <div id="spinner">
      <CircularProgress disableShrink />
    </div>
  );
};

export default PrivateRoute;
